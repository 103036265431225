import React, { useState } from 'react';
import { INote } from 'domain/store/NoteModel';
import { useNavigate } from 'hooks/useNavigate';
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import { MoreVert, Person } from '@material-ui/icons';
import { EMPLOYEE_JOURNEYS_ROUTE_PATH } from 'views/routes/Routes';

export const SquadFeedTimelineItemAction: React.FC<{ note: INote }> = ({ note }) => {
  const navigate = useNavigate();
  const [menuEl, setMenuEl] = useState<Element | null>(null);
  const handleClose = () => setMenuEl(null);
  return (
    <>
      <IconButton
        aria-controls={`more-${note.noteId}-menu`}
        aria-haspopup={'true'}
        onClick={(e) => {
          setMenuEl(e.currentTarget);
        }}>
        <MoreVert />
      </IconButton>
      <Menu
        id={`more-${note.noteId}-menu`}
        anchorEl={menuEl}
        keepMounted
        open={!!menuEl}
        onClose={handleClose}>
        <MenuItem
          onClick={() => {
            handleClose();
            navigate(`${EMPLOYEE_JOURNEYS_ROUTE_PATH}/${note.employee.idpId}`);
          }}>
          <Person />
          View Profile
        </MenuItem>
      </Menu>
    </>
  );
};
