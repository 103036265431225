import { Box, Link, TableCell, TableRow } from '@material-ui/core';
import { IEmployeeTalentModel } from 'domain/store/talentAnalysisTool/TalentAnalysisToolModel';
import { useFlightRiskService } from 'hooks/useFlightRiskService';
import { useLongTermLeaveService } from 'hooks/useLongTermLeaveService';
import { useRoleChangePathwayService } from 'hooks/useRoleChangePathwayService';
import { useStore } from 'hooks/useStore';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Avatar } from 'views/components/avatar/Avatar';
import { StatusIcon } from 'views/components/EmployeeStatus/StatusIcon/StatusIcon';
import { DialogWithDatepicker } from 'views/routes/employeeJourney/components/employeeSidebar/components/longTermLeaveFlag/DialogWithDatepicker';
import { EMPLOYEE_JOURNEYS_ROUTE_PATH } from 'views/routes/Routes';
import { AlertCheckbox } from 'views/routes/squad/components/alertCheckbox/AlertCheckbox';
import styles from './EmployeeRow.module.scss';
import { LongTermLeaveCheckbox } from './LongTermLeaveCheckbox';
import { RoleChangePathwaySelector, TimeframeOption } from './RoleChangePathwaySelector';
import { TalentCodeSelector } from './TalentCodeSelector';

export const EmployeeRow: React.FC<{
  employee: IEmployeeTalentModel;
  onDataChanged: () => void;
}> = ({
  employee: {
    idpId: employeeIdpId,
    givenName,
    familyName,
    role,
    // department,
    statusType,
    talentCode,
    isFlightRisk: propIsFlightRisk,
    isRoleChangePathway: propHasRoleChangePathway,
    isOnProbation,
    isOnLongTermLeave: propIsOnLongTermLeave,
    longTermLeaveExpectedReturn: propLongTermLeaveExpectedReturn,
    roleChangePathwayTimeframeInMonths: propRoleProgressionPathwayTimeframeInMonths,
  },
  onDataChanged,
}) => {
  const store = useStore();

  const { isFlightRisk, toggleFlightRisk, isLoading: isLoadingFlightRisk } = useFlightRiskService(
    propIsFlightRisk
  );

  const [isLeaveDialogOpen, setIsLeaveDialogOpen] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const employeeExpectedReturn = propLongTermLeaveExpectedReturn ?? null;
  const [expectedLeaveReturn, setExpectedLeaveReturn] = useState<string | null>(
    employeeExpectedReturn
  );
  const filterNull = useCallback(
    (source: string | null) => {
      return !source ? employeeExpectedReturn : source;
    },
    [employeeExpectedReturn]
  );
  const [newExpectedReturn, setNewExpectedReturn] = useState<string | null>(
    filterNull(expectedLeaveReturn)
  );

  useEffect(() => {
    setExpectedLeaveReturn(employeeExpectedReturn);
    setNewExpectedReturn(filterNull(employeeExpectedReturn));
  }, [employeeExpectedReturn, filterNull]);

  const toggleLeave = () => {
    setIsLeaveDialogOpen(true);
  };

  const resetDialogStates = () => {
    setNewExpectedReturn(filterNull(expectedReturn));
    setIsLeaveDialogOpen(false);
    setIsDisabled(true);
  };

  const onDisagreeLeaveClick = () => {
    resetDialogStates();
  };

  const handleLeaveChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setNewExpectedReturn(e.target.value as string);
    setIsDisabled(false);
  };

  const {
    isOnLongTermLeave,
    expectedReturn,
    updateLongTermLeave,
    isLoading: isLoadingLeaveChange,
  } = useLongTermLeaveService(propIsOnLongTermLeave, propLongTermLeaveExpectedReturn);

  const {
    timeframe,
    updateRoleChangePathway,
    isLoading: isLoadingRoleChangePathway,
  } = useRoleChangePathwayService(propRoleProgressionPathwayTimeframeInMonths);

  const onFlightRiskAgreeClick = async () => {
    try {
      await toggleFlightRisk(employeeIdpId);
      onDataChanged();
    } catch (error) {
      store.notifications.addError('Failed to update Flight Risk flag');
    }
  };

  const onLongTermLeaveAgreeClick = async () => {
    try {
      await updateLongTermLeave({
        employeeIdpId,
        expectedReturn: newExpectedReturn === expectedReturn ? null : newExpectedReturn,
      });
      resetDialogStates();
      onDataChanged();
    } catch (e) {
      if (e?.response?.status === 409) {
        store.notifications.addError(
          'Conflict occurred saving changes, please refresh the page, and try again after.'
        );
      } else if (e?.response?.status === 403) {
        store.notifications.addError('Not authorised to save changes.');
      } else {
        store.notifications.addError('Failed to update Long Term Leave flag');
      }
    }
  };

  const onRoleChangePathwayAgreeClick = async (selectedValue: TimeframeOption) => {
    try {
      await updateRoleChangePathway({
        employeeIdpId,
        timeframe: selectedValue.value,
      });
      onDataChanged();
    } catch (e) {
      if (e?.response?.status === 409) {
        store.notifications.addError(
          'Conflict occurred saving changes, please refresh the page, and try again after.'
        );
      } else if (e?.response?.status === 403) {
        store.notifications.addError('Not authorised to save changes.');
      } else {
        store.notifications.addError('Failed to update Role Change Pathway flag');
      }
    }
  };

  const displayName = `${givenName} ${familyName}`;

  return (
    <TableRow>
      <TableCell>
        <Link
          className={styles.name}
          component={RouterLink}
          to={`${EMPLOYEE_JOURNEYS_ROUTE_PATH}/${employeeIdpId}`}>
          <Avatar
            familyName={familyName}
            givenName={givenName}
            idpId={employeeIdpId?.trim()?.length ? employeeIdpId : undefined}
            className={styles.avatarContainer}
          />
          <span>
            {givenName} {familyName}
          </span>
        </Link>
      </TableCell>
      <TableCell>{role}</TableCell>
      {/* <TableCell>{department}</TableCell> */}
      <TableCell>
        <StatusIcon color={statusType} />
      </TableCell>
      <TableCell>
        <TalentCodeSelector
          employeeIdpId={employeeIdpId}
          talentCode={talentCode}
          employeeName={displayName}
          onChanged={onDataChanged}
        />
      </TableCell>
      <TableCell>
        <RoleChangePathwaySelector
          employeeDisplayName={displayName}
          selectedValue={timeframe}
          onAgreeClick={onRoleChangePathwayAgreeClick}
          isLoading={isLoadingRoleChangePathway}
        />
      </TableCell>
      <TableCell>
        <Box display={'flex'}>
          <Box justifyContent={'left'}>
            <AlertCheckbox
              isLoading={isLoadingFlightRisk}
              checked={isFlightRisk}
              onAgreeClick={onFlightRiskAgreeClick}
              dialogTitle={'Flight Risk Change'}
              dialogText={
                isFlightRisk
                  ? `You are about to clear the Flight Risk flag for ${displayName}`
                  : `You are about to set the Flight Risk flag to ${displayName}'?`
              }
            />
          </Box>
        </Box>
      </TableCell>
      <TableCell>
        <Box display={'flex'}>
          <Box justifyContent={'left'}>
            <>
              <DialogWithDatepicker
                isOpen={isLeaveDialogOpen}
                title={'Update Long Term Leave'}
                onAgreeClick={onLongTermLeaveAgreeClick}
                onDisagreeClick={onDisagreeLeaveClick}
                currentExpectedReturn={expectedReturn}
                onDateChange={handleLeaveChange}
                employeeDisplayName={displayName}
                isDisabled={isDisabled}
              />
              <LongTermLeaveCheckbox
                checked={isOnLongTermLeave}
                onToggle={toggleLeave}
                isLoading={isLoadingLeaveChange}
                employeeExpectedReturn={expectedReturn}
              />
            </>
          </Box>
        </Box>
      </TableCell>
      <TableCell>{isOnProbation ? 'Yes' : 'No'}</TableCell>
    </TableRow>
  );
};
