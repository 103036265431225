import { HierarchyCircularNode } from 'd3-hierarchy';

export interface ChartData {
  name: string;
  value: number;
  colour: string;
}

export interface PackData extends ChartData {
  children: PackData[];
}

interface UniqueId {
  id: string;
  href: string;
  uri: string;
}

export type PackDatum = HierarchyCircularNode<PackData>;

export interface GroupDatum extends PackDatum {
  leafUid: UniqueId;
  clipUid: UniqueId;
}

export class IdProvider {
  protected static uniqueBaseId = 0;

  public static uid(name: string): UniqueId {
    const id = this.uniqueBaseId++;
    const idStr = `${name}-${id}`;
    return {
      id: idStr,
      href: `#${idStr}`,
      uri: `url(#${idStr})`,
    };
  }
}

export type ChartEventCallback = (datum: GroupDatum, elem: SVGGElement) => void;
