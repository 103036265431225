import { Chip } from '@material-ui/core';
import { RoleChangeStatus } from 'domain/store/employeeJourney/RoleChangeModel';
import React from 'react';
import styles from './RoleChangeStatusChip.module.scss';

interface IRoleChangeStatusChipProps {
  status: RoleChangeStatus;
}

export const RoleChangeStatusChip: React.FC<IRoleChangeStatusChipProps> = ({ status }) => {
  return (
    <Chip
      label={status}
      variant={status === 'approved' || status === 'rejected' ? 'outlined' : 'default'}
      size="small"
      className={`${styles.root} ${styles[status]}`}
    />
  );
};
