import { Box, Link, Typography } from '@material-ui/core';
import LinkIcon from '@material-ui/icons/Link';
import { Skeleton } from '@material-ui/lab';
import { useEmployeeJourney } from 'hooks/useEmployeeJourney';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { EmployeeSidebarDivider } from 'views/components/EmployeeSidebarDivider/EmployeeSidebarDivider';
import { ArchivedChip } from 'views/components/archivedChip/ArchivedChip';
import { Avatar } from 'views/components/avatar/Avatar';
import { PrimaryFlagDropdown } from 'views/components/primaryFlagDropdown/PrimaryFlagDropdown';
import { FlightRiskFlag } from 'views/routes/employeeJourney/components/employeeSidebar/components/flightRiskFlag/FlightRiskFlag';
import { LongTermLeaveFlag } from 'views/routes/employeeJourney/components/employeeSidebar/components/longTermLeaveFlag/LongTermLeaveFlag';
import { RoleChangePathwayFlag } from 'views/routes/employeeJourney/components/employeeSidebar/components/roleChangePathwayFlag/RoleChangePathwayFlag';
import styles from './EmployeeSidebar.module.scss';
import { SidebarContent } from './components/SidebarContent/SidebarContent';
import { Probation } from './components/probation/Probation';
import { StatusFlag } from './components/status/StatusFlag';

export const EmployeeSidebar: React.FC = observer(() => {
  const journey = useEmployeeJourney();
  const { employee, isLoaded } = journey;

  return (
    <Box className={styles.employeeSidebar}>
      <Box className={styles.employeeDetailsContainer}>
        <div className={styles.avatarContainer}>
          <Avatar
            className={styles.avatar}
            givenName={employee?.givenName}
            familyName={employee?.familyName}
            idpId={isLoaded ? employee?.idpId : undefined}
          />
        </div>
        <div className={styles.details}>
          <Typography
            color={employee?.isFormerEmployee ? 'textSecondary' : 'textPrimary'}
            variant={'h6'}>
            <Box fontWeight="fontWeightBold" component={'span'}>
              {isLoaded ? employee?.displayName : <Skeleton variant={'text'} />}
            </Box>
          </Typography>
          {employee?.isFormerEmployee && <ArchivedChip />}
          <Typography color={'textPrimary'} variant={'subtitle1'} component={'div'}>
            <Box fontWeight="fontWeightMedium" component={'span'}>
              {isLoaded ? `${employee?.position}` : <Skeleton variant={'text'} />}
            </Box>
          </Typography>
          {/* Capability (Department) data is no longer available */}
          {/* <Typography color={'textPrimary'} variant={'overline'} component={'div'}>
            <Box fontWeight="fontWeightMedium" component={'span'} className={styles.capability}>
              {isLoaded ? `${employee?.capability}` : <Skeleton variant={'text'} />}
            </Box>
          </Typography> */}
        </div>
      </Box>
      <Box className={styles.detailsContainer}>
        <Box component={'div'} marginTop={'0.5em'}>
          <SidebarContent
            heading={'Squad'}
            details={employee?.squadName}
            isLoaded={isLoaded}
            hasChildren={true}
          />
        </Box>
        <Box component={'div'} marginTop={'0.5em'}>
          <SidebarContent
            heading={'Tenure'}
            details={employee?.tenureFormatted}
            isLoaded={isLoaded}
            hasChildren={true}
          />
        </Box>
        <Box component={'div'} marginTop={'0.5em'}>
          <SidebarContent
            heading={'Last Retro Date'}
            details={employee?.lastRetroFormatted}
            isLoaded={isLoaded}
            hasChildren={true}
          />
        </Box>
      </Box>

      {employee?.emailAddress && (
        <>
          <EmployeeSidebarDivider />
          <Typography color={'textPrimary'} variant={'h6'} component={'div'}>
            <Box fontWeight="fontWeightMedium" component={'span'}>
              <Link
                className={styles.purpleMeLink}
                href={`${process.env.REACT_APP_PURPLEME_URL}user-profile?email=${employee?.emailAddress}`}
                target="_blank">
                <span>PurpleMe</span>
                <LinkIcon />
              </Link>
            </Box>
          </Typography>
        </>
      )}

      {(!employee || employee!.flags.status) && (
        <>
          <EmployeeSidebarDivider />
          <Box className={styles.statusCodeContainer}>
            {!isLoaded ? (
              <Skeleton variant={'text'} />
            ) : (
              <StatusFlag
                employeeId={employee!.idpId}
                employeeDisplayName={employee!.displayName}
                employeeStatusModel={employee!.flags.status}
                journey={journey}
                disabled={!!employee?.isFormerEmployee}
              />
            )}
          </Box>
        </>
      )}

      {employee &&
        (employee!.flags.flightRisk ||
          employee!.flags.longTermLeave ||
          employee!.flags.primaryFlag ||
          employee!.flags.roleChangePathway) && (
          <>
            <EmployeeSidebarDivider />
            <Box className={styles.flagsContainer}>
              <SidebarContent heading={'Flags'} />
              {employee!.flags.flightRisk && (
                <FlightRiskFlag
                  employeeId={employee!.idpId}
                  employeeDisplayName={employee!.displayName}
                  flightRiskStatusModel={employee!.flags.flightRisk}
                  disabled={!!employee?.isFormerEmployee}
                />
              )}
              {employee.probation.isInProbation && (
                <Probation
                  probation={employee!.probation}
                  overrideDisabledStyle={true}
                  disabled={!!employee?.isFormerEmployee}
                />
              )}
              {employee!.flags.roleChangePathway && (
                <RoleChangePathwayFlag
                  employeeId={employee!.idpId}
                  employeeDisplayName={employee!.displayName}
                  roleChangePathwayModel={employee!.flags.roleChangePathway}
                  disabled={!!employee?.isFormerEmployee}
                />
              )}
              {employee!.flags.longTermLeave && (
                <LongTermLeaveFlag
                  employeeId={employee!.idpId}
                  employeeDisplayName={employee!.displayName}
                  longTermLeaveModel={employee!.flags.longTermLeave}
                  disabled={!!employee?.isFormerEmployee}
                />
              )}
            </Box>
          </>
        )}

      {(!employee || employee!.flags.primaryFlag) && (
        <>
          <EmployeeSidebarDivider />
          <Box className={styles.talentCodeContainer}>
            <SidebarContent heading={'Talent Code'} hasChildren={false} />
            <PrimaryFlagDropdown journey={journey} disabled={!!employee?.isFormerEmployee} />
          </Box>
        </>
      )}

      {/* No leave or PD data available */}
      {/* {employee && employee.leaveDetails && (
        <>
          <EmployeeSidebarDivider />
          <Box className={styles.balancesContainer}>
            <Box
              display={'flex'}
              alignItems={'center'}
              className={styles.balanceTitle}
              fontWeight="fontWeightMedium"
              component={'span'}>
              <Typography color={'textPrimary'} variant={'subtitle1'} component={'div'}>
                Balances
              </Typography>
              <Tooltip title={'As At Close Of Last Pay'}>
                <InfoOutlined />
              </Tooltip>
            </Box>
            <BalanceItem title={'Annual Leave Balance'} value={employee!.annualLeaveDays} />
            <BalanceItem title={'Personal Leave Balance'} value={employee!.personalLeaveDays} />
            <BalanceItem title={'Used Professional Leave'} value={employee!.pdTakenDays} />
          </Box>
        </>
      )} */}
    </Box>
  );
});
