import React from 'react';
import styles from './SquadCatchUpItem.module.scss';
import { Box, TableCell, TableRow } from '@material-ui/core';
import { Avatar } from 'views/components/avatar/Avatar';
import { Link } from 'react-router-dom';
import { EMPLOYEE_JOURNEYS_ROUTE_PATH } from 'views/routes/Routes';
import { ISquadCatchUpItem } from 'domain/store/catchUps/SquadCatchUpsModel';

interface ISquadCatchUpItemProps {
  catchup: ISquadCatchUpItem;
}

export const SquadCatchUpItem: React.FC<ISquadCatchUpItemProps> = ({ catchup }) => {
  return (
    <TableRow>
      <TableCell align="left">
        <Box display="flex" flexDirection="row" alignItems="center">
          <Avatar
            className={styles.avatar}
            idpId={catchup.employee.idpId}
            givenName={catchup.employee.givenName}
            familyName={catchup.employee.familyName}
          />
          <Link
            to={`${EMPLOYEE_JOURNEYS_ROUTE_PATH}/${catchup.employee.idpId}`}
            className={styles.linkToEmployee}>
            {catchup.employee.displayName}
          </Link>
        </Box>
      </TableCell>
      <TableCell>
        {catchup.lastCatchupDateUTC ? catchup.lastCatchupDateAsLocalString : '-'}
      </TableCell>
      <TableCell>
        {catchup.personWhoCaughtUpWithEmployee ? (
          <Box display="flex" flexDirection="row" alignItems="center">
            <Avatar
              className={styles.avatar}
              idpId={catchup.personWhoCaughtUpWithEmployee.idpId}
              givenName={catchup.personWhoCaughtUpWithEmployee.givenName}
              familyName={catchup.personWhoCaughtUpWithEmployee.familyName}
            />
            <Link
              to={`${EMPLOYEE_JOURNEYS_ROUTE_PATH}/${catchup.personWhoCaughtUpWithEmployee.idpId}`}
              className={styles.linkToEmployee}>
              {catchup.personWhoCaughtUpWithEmployee.displayName}
            </Link>
          </Box>
        ) : (
          '-'
        )}
      </TableCell>
      <TableCell>
        {catchup.daysSinceLastCatchup === undefined
          ? '-'
          : `${catchup.daysSinceLastCatchup} ${
              catchup.daysSinceLastCatchup === 1 ? 'day' : 'days'
            }`}
      </TableCell>
    </TableRow>
  );
};
