import { AppBar, Tab, Tabs } from '@material-ui/core';
import { useBackToTopButton } from 'hooks/useBackToTopButton';
import { usePermissions } from 'hooks/usePermissions';
import React, { ReactNode } from 'react';
import { Link, Switch, Redirect, useLocation, Route } from 'react-router-dom';
import { BackToTopButton } from '../employeeJourney/components/employeeTimeline/components/backToTopButton/BackToTopButton';
import styles from './Admin.module.scss';
import { ManageRoles } from './components/manageRoles/ManageRoles';
import { AssignSquads } from './components/manageSquads/AssignSquads';

interface TabItem {
  link: string;
  title: ReactNode;
  component: ReactNode;
  disabled: boolean;
}

export const Admin: React.FC = () => {
  const { canAssignSquads, canManageRoles } = usePermissions();
  const location = useLocation();

  const {
    showBackToTopButton,
    scrollToTopOfTimeline,
    scrollableContainerRef,
  } = useBackToTopButton();

  const tabItems: TabItem[] = [
    {
      title: 'Squad Member Access',
      component: <ManageRoles />,
      link: 'manage-roles',
      disabled: !canManageRoles,
    },
    {
      title: 'Non-Squad Member Access',
      component: <AssignSquads />,
      link: 'assign-squads',
      disabled: !canAssignSquads,
    },
  ].filter((t) => !t.disabled);

  const activeTab: number = tabItems.findIndex(
    (item) => location.pathname === `/admin/${item.link}`
  );

  return (
    <div className={styles.root}>
      <AppBar position={'static'} color={'default'} className={styles.appBar}>
        <Tabs
          value={activeTab === -1 ? 0 : activeTab}
          centered={true}
          classes={{ indicator: styles.indicator }}>
          {tabItems.map((item, index) => (
            <Tab
              component={Link}
              to={`/admin/${item.link}`}
              key={index}
              label={item.title}
              disabled={item.disabled}
            />
          ))}
        </Tabs>
      </AppBar>
      <div className={styles.tabBodyContainer} ref={scrollableContainerRef}>
        <Switch>
          {tabItems.map(
            (item) =>
              !item.disabled && (
                <Route path={`/admin/${item.link}`} key={item.link}>
                  {item.component}
                </Route>
              )
          )}
          <Redirect to={'/admin/manage-roles'} />
        </Switch>
        {showBackToTopButton && (
          <div className={styles.backToTopContainer}>
            <BackToTopButton scrollToTop={scrollToTopOfTimeline} edge={'end'} />
          </div>
        )}
      </div>
    </div>
  );
};
