import React from 'react';
import styles from './EmployeeListPopperItem.module.scss';
import { TableCell } from '@material-ui/core';
import { Avatar } from 'views/components/avatar/Avatar';
import { Link } from 'react-router-dom';
import { ITrendsEmployeesItem } from 'domain/store/trends/TrendsEmployeesModel';
import { EMPLOYEE_JOURNEYS_ROUTE_PATH } from 'views/routes/Routes';
import { ISupportingOpportunityEmployee } from 'domain/store/trends/SupportingOpportunitiesWithEmployeesModel';

interface IEmployeeListPopperItemProps {
  employee: ITrendsEmployeesItem | ISupportingOpportunityEmployee;
}

export const EmployeeListPopperItem: React.FC<IEmployeeListPopperItemProps> = function (props) {
  const getEmployeeId = () =>
    'employeeIdpId' in props.employee ? props.employee.employeeIdpId : props.employee.idpId;

  return (
    <TableCell classes={{ root: styles.tableCell }}>
      <div className={styles.nameCellRoot}>
        <div className={styles.avatarContainer}>
          <Avatar
            className={styles.avatar}
            idpId={getEmployeeId()}
            givenName={props.employee.displayName.split(' ')[0]}
            familyName={props.employee.displayName.split(' ')[1]}
          />
        </div>
        <Link
          to={`${EMPLOYEE_JOURNEYS_ROUTE_PATH}/${getEmployeeId()}`}
          className={styles.linkToEmployee}>
          {props.employee.displayName}
        </Link>
      </div>
    </TableCell>
  );
};
