import { Box, Link, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import cn from 'classnames';
import { IEmployee } from 'domain/store/employeeJourney/EmployeeModel';
import { EmployeeStatusLabels } from 'domain/store/employeeJourney/EmployeeStatusModel';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { EmployeeSidebarDivider } from 'views/components/EmployeeSidebarDivider/EmployeeSidebarDivider';
import { StatusIcon } from 'views/components/EmployeeStatus/StatusIcon/StatusIcon';
import { Avatar } from 'views/components/avatar/Avatar';
import { EMPLOYEE_JOURNEYS_ROUTE_PATH } from 'views/routes/Routes';
import { SidebarContent } from 'views/routes/employeeJourney/components/employeeSidebar/components/SidebarContent/SidebarContent';
import styles from './EmployeeNoteSidebar.module.scss';

interface IEmployeeNoteSidebarProps {
  className?: string;
  employee?: IEmployee;
  isLoading: boolean;
}

export const EmployeeNoteSidebar: React.FC<IEmployeeNoteSidebarProps> = ({
  className,
  employee,
  isLoading,
}) => {
  return (
    <Box
      data-testid={'employee-note-sidebar'}
      className={cn(className, styles.employeeNoteSidebar)}>
      <Box className={styles.employeeDetailsContainer}>
        <div className={styles.avatarContainer}>
          <Avatar
            className={styles.avatar}
            givenName={employee?.givenName}
            familyName={employee?.familyName}
            idpId={!isLoading ? employee?.idpId : undefined}
          />
        </div>
        <div className={styles.details}>
          <Link
            variant={'inherit'}
            component={RouterLink}
            to={`${EMPLOYEE_JOURNEYS_ROUTE_PATH}/${employee?.idpId}`}>
            {
              <Typography
                color={employee?.isFormerEmployee ? 'textSecondary' : 'textPrimary'}
                variant={'h6'}>
                <Box fontWeight="fontWeightBold" component={'span'}>
                  {!isLoading ? employee?.displayName : <Skeleton variant={'text'} />}
                </Box>
              </Typography>
            }
          </Link>

          {employee?.isFormerEmployee && (
            <Typography color={'textSecondary'} variant={'body2'} component={'div'}>
              (Archived)
            </Typography>
          )}
          <Typography color={'textPrimary'} variant={'subtitle1'} component={'div'}>
            <Box fontWeight="fontWeightMedium" component={'span'}>
              {!isLoading ? `${employee?.position}` : <Skeleton variant={'text'} />}
            </Box>
          </Typography>
          {/* <Typography color={'textPrimary'} variant={'overline'} component={'div'}>
            <Box fontWeight="fontWeightMedium" component={'span'} className={styles.capability}>
              {!isLoading ? `${employee?.capability}` : <Skeleton variant={'text'} />}
            </Box>
          </Typography> */}
        </div>
      </Box>
      <Box component={'div'} marginTop={'1em'}>
        <SidebarContent
          heading={'Squad'}
          details={employee?.squadName}
          isLoaded={!isLoading}
          hasChildren={true}
        />
      </Box>
      <Box component={'div'} marginTop={'1em'}>
        <SidebarContent
          heading={'Tenure'}
          details={employee?.tenureFormatted}
          isLoaded={!isLoading}
          hasChildren={true}
        />
      </Box>
      <Box component={'div'} marginTop={'1em'}>
        <SidebarContent
          heading={'Last Retro Date'}
          details={employee?.lastRetroFormatted}
          isLoaded={!isLoading}
          hasChildren={true}
        />
      </Box>

      {(!employee || employee!.flags.status) && (
        <>
          <EmployeeSidebarDivider />
          <Box className={styles.statusCodeContainer}>
            {employee ? <SidebarContent heading={'Status'} /> : <Skeleton variant={'text'} />}
            <Typography color={'textPrimary'} variant={'body1'} component={'div'}>
              {employee ? (
                <StatusIcon
                  color={employee!.flags.status?.value?.status ?? 0}
                  status={EmployeeStatusLabels[employee!.flags.status?.value?.status ?? 0]}
                />
              ) : (
                <Skeleton variant={'text'} />
              )}
            </Typography>
          </Box>
        </>
      )}

      {(!employee || employee!.flags.primaryFlag) && (
        <>
          <EmployeeSidebarDivider />
          <Box className={styles.talentCodeContainer}>
            {employee ? (
              <SidebarContent
                heading={'Talent Code'}
                details={employee!.flags.primaryFlag?.value?.name ?? 'No Primary Flag'}
                isLoaded={!isLoading}
                hasChildren={true}
              />
            ) : (
              <Skeleton variant={'text'} />
            )}
          </Box>
        </>
      )}

      {employee &&
        (employee!.flags.flightRisk ||
          employee!.flags.longTermLeave ||
          employee!.flags.primaryFlag ||
          employee!.flags.roleChangePathway) && (
          <>
            <EmployeeSidebarDivider />
            <Box className={styles.flagsContainer}>
              <SidebarContent heading={'Flags'} />
              {employee!.flags.flightRisk?.value?.isFlightRisk && (
                <FlagText text={'Is a Flight Risk'} testId={'flight-risk-flag'} />
              )}
              {employee.probation.isInProbation && (
                <FlagText text={'Is on Probation'} testId={'in-probation-flag'} />
              )}
              {employee!.flags.roleChangePathway?.value?.hasRoleChangePathway && (
                <FlagText text={'Is on the Role Change Pathway'} testId={'role-change-flag'} />
              )}
              {employee!.flags.longTermLeave?.value?.isOnLongTermLeave && (
                <FlagText text={'Is on Long Term Leave'} testId={'long-term-leave-flag'} />
              )}
            </Box>
          </>
        )}
      {/* No leave or PD data available */}
      {/* {employee && employee.leaveDetails && (
        <>
          <EmployeeSidebarDivider />
          <Box className={styles.balancesContainer}>
            <Box
              display={'flex'}
              alignItems={'center'}
              className={styles.balanceTitle}
              fontWeight="fontWeightMedium"
              component={'span'}>
              <Typography color={'textPrimary'} variant={'subtitle1'} component={'div'}>
                Balances
              </Typography>
              <Tooltip title={'As At Close Of Last Pay'}>
                <InfoOutlined />
              </Tooltip>
            </Box>
            <BalanceItem
              title={'Annual Leave Balance'}
              value={employee?.annualLeaveDays}
              testId="annual-leave-balance"
            />
            <BalanceItem
              title={'Personal Leave Balance'}
              value={employee?.personalLeaveDays}
              testId="personal-leave-balance"
            />
            <BalanceItem
              title={'Used Professional Leave'}
              value={employee?.pdTakenDays}
              testId="used-professional-leave"
            />
          </Box>
        </>
      )} */}
    </Box>
  );
};

interface IFlagTextProps {
  text: string;
  testId: string;
}

const FlagText = (props: IFlagTextProps) => {
  return (
    <Typography color={'textPrimary'} variant={'body1'} component={'div'} noWrap={true}>
      <Box
        data-testid={`employee-notesidebar-${props.testId}`}
        fontWeight="fontWeightLight"
        component={'span'}
        className={styles.flagsNoTextTransform}>
        {props.text}
      </Box>
    </Typography>
  );
};
