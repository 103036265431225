import React from 'react';

// eslint-disable-next-line
export function useEmployeeListPopper() {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | SVGElement | null>(null);
  const [offset, setOffset] = React.useState<[number, number] | undefined>(undefined);
  const [ignoreNextClose, setIgnoreNextClose] = React.useState(false);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);

  const handleClose = () => {
    if (ignoreNextClose) {
      setIgnoreNextClose(false);
      return;
    }
    setAnchorEl(null);
  };

  return { anchorEl, setAnchorEl, offset, setOffset, handleClick, handleClose, setIgnoreNextClose };
}
