import { Box, Checkbox, FormControlLabel, Typography } from '@material-ui/core';
import { SwitchBaseProps } from '@material-ui/core/internal/SwitchBase';
import { ILongTermLeaveModelValue } from 'domain/store/employeeJourney/LongTermLeaveModel';
import { usePermissions } from 'hooks/usePermissions';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React from 'react';
import { LoadingIndicator } from 'views/components/loadingIndicator/LoadingIndicator';
import { UpdatedByInfoIcon } from 'views/routes/employeeJourney/components/employeeSidebar/components/updatedByInfoIcon/UpdatedByInfoIcon';
import styles from './LongTermLeaveFlagCheckbox.module.scss';

interface LongTermLeaveFlagCheckboxProps {
  longTermLeave: ILongTermLeaveModelValue | null;
  loading: boolean;
  onToggle?: () => void;
  disabled: boolean;
}

export const LongTermLeaveFlagCheckbox: React.FC<LongTermLeaveFlagCheckboxProps> = observer(
  ({ longTermLeave, onToggle, loading, disabled }) => {
    const { canEdit, employees } = usePermissions();

    return (
      <>
        <Box display={'flex'} alignItems={'center'}>
          <FormControlLabel
            disabled={disabled}
            control={
              loading ? (
                <LoadingIndicator size={'1.5em'} className={styles.loadingIndicator} />
              ) : (
                <Checkbox
                  checked={!!longTermLeave?.isOnLongTermLeave}
                  onClick={() => onToggle && onToggle()}
                  readOnly={!canEdit(employees.longTermLeave)}
                  disabled={onToggle === undefined}
                  inputProps={
                    {
                      'data-testid': 'long-term-leave-checkbox',
                    } as SwitchBaseProps['inputProps'] & { 'data-testid': string }
                  }
                />
              )
            }
            label={
              <Typography color={'textPrimary'} variant={'body1'} component={'div'} noWrap={true}>
                <Box fontWeight="fontWeightLight" component={'span'}>
                  Long Term Leave
                </Box>
              </Typography>
            }
          />
          {!loading && longTermLeave && (
            <UpdatedByInfoIcon
              updatedByDisplayName={longTermLeave.modifiedBy.displayName}
              modifiedDateTime={longTermLeave.modifiedDateTime}
            />
          )}
        </Box>
        <Box className={styles.expectedReturn}>
          {!loading && longTermLeave?.isOnLongTermLeave && (
            <Typography color={'textPrimary'} variant={'subtitle1'} component={'div'}>
              <Box fontWeight="fontWeightLight" component={'span'}>
                Expected Return: {moment(longTermLeave!.expectedReturn).format('DD/MM/YYYY')}
              </Box>
            </Typography>
          )}
        </Box>
      </>
    );
  }
);
