import React from 'react';
import { useEmployeePhoto } from 'hooks/useEmployeePhoto';
import { Avatar as MuiAvatar } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import styles from './Avatar.module.scss';
import cn from 'classnames';

interface AvatarProps {
  idpId?: string;
  givenName?: string;
  familyName?: string;
  className?: string;
}

// Basically lifted this from https://stackoverflow.com/questions/3426404/create-a-hexadecimal-colour-based-on-a-string-with-javascript
const idpIdToColor = (input: string) => {
  let hash = 0;
  for (let i = 0; i < input.length; i++) {
    hash = input.charCodeAt(i) + ((hash << 5) - hash);
  }

  const c = (hash & 0x00ffffff).toString(16).toUpperCase();

  return '00000'.substring(0, 6 - c.length) + c;
};

export const Avatar: React.FC<AvatarProps> = ({ idpId, givenName, familyName, className }) => {
  const { photo, isLoading } = useEmployeePhoto(idpId);
  const initials = givenName && familyName ? `${givenName[0]}${familyName[0]}` : '';
  const altText = givenName && familyName ? `${givenName} ${familyName}` : 'User';

  return (
    <div className={cn(styles.root, className)}>
      {isLoading || !idpId ? (
        <Skeleton variant={'circle'} style={{ width: '100%', height: '100%' }} />
      ) : photo ? (
        <MuiAvatar
          style={{ width: '100%', height: '100%', textDecoration: 'none' }}
          alt={altText}
          title={altText}
          src={photo}
          variant={'circular'}
        />
      ) : (
        <MuiAvatar
          style={{
            width: '100%',
            height: '100%',
            backgroundColor: `#${idpIdToColor(idpId ? idpId : altText)}`,
            textDecoration: 'none',
          }}
          alt={altText}
          title={altText}
          variant={'circular'}>
          {initials}
        </MuiAvatar>
      )}
    </div>
  );
};
