import { TextField } from '@material-ui/core';
import moment from 'moment';
import React, { ChangeEvent, useState } from 'react';
import styles from './DialogWithDatepicker.module.scss';

interface IDatepickerProps {
  value: string | null;
  onChange: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  isUpdating: boolean;
}

export const LeaveDatepicker: React.FC<IDatepickerProps> = (props: IDatepickerProps) => {
  const [value, setValue] = useState<string | null>(props.value);
  const onDateChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    !props.isUpdating && setValue(e.target.value);
    props.onChange(e);
  };

  return (
    <div className={styles.inputContainer}>
      <TextField
        className={styles.datePicker}
        type="date"
        placeholder="Enter event date"
        variant="outlined"
        fullWidth
        InputLabelProps={{ shrink: false }}
        value={value || ''}
        onChange={(e) => onDateChange(e)}
        inputProps={{ min: moment().format('YYYY-MM-DD') }}
      />
    </div>
  );
};
