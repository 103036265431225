import { ILongTermLeaveDto } from 'domain/store/dataTranferObjects/ILongTermLeaveDto';
import { flow, getEnv } from 'domain/store/util';
import { Instance, types } from 'mobx-state-tree';
import { api } from 'shared/api/interfaces';

export const LongTermLeaveModifier = types.model('LongTermLeaveModifier', {
  idpId: types.identifier,
  displayName: types.string,
});

export interface ILongTermLeaveModel extends Instance<typeof LongTermLeaveModel> {}

export interface ILongTermLeaveWithMobx extends ILongTermLeaveDto {
  modifiedBy: Instance<typeof LongTermLeaveModifier>;
}

export interface ILongTermLeaveModelValue extends Instance<typeof LongTermLeaveModelValue> {}

export const LongTermLeaveModelValue = types.model('LongTermLeaveModelValue', {
  isOnLongTermLeave: types.boolean,
  modifiedBy: LongTermLeaveModifier,
  modifiedDateTime: types.string,
  expectedReturn: types.maybeNull(types.string),
});

export const LongTermLeaveModel = types
  .model('LongTermLeaveModel', {
    value: types.maybeNull(LongTermLeaveModelValue),
  })
  .actions((self) => {
    const toggleLongTermLeaveStatus = function* (
      employeeIdpId: string,
      expectedReturn: string | null
    ): Generator<Promise<ILongTermLeaveWithMobx>, ILongTermLeaveWithMobx, ILongTermLeaveWithMobx> {
      const { ajax } = getEnv(self);

      const result = yield ajax
        .post('Employee/LongTermLeave', {
          json: {
            employeeIdpId,
            flagValue: !self.value || expectedReturn !== null,
            expectedReturn: expectedReturn,
          },
        })
        .json<api.LongTermLeaveDto>();

      self.value = LongTermLeaveModelValue.create(result);
      return result;
    };

    return {
      toggleLongTermLeaveStatus: flow(toggleLongTermLeaveStatus),
    };
  });

export interface ILongTermLeave extends Instance<typeof LongTermLeaveModel> {}

export function createLongTermLeaveModel(ltl: api.LongTermLeaveDtoValueDto): ILongTermLeave | null {
  if (!ltl) {
    return null;
  }

  return LongTermLeaveModel.create(ltl);
}
