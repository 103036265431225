import { Button, Link, TableCell, TableRow } from '@material-ui/core';
import { useRoleChange } from 'hooks/useRoleChange';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { api } from 'shared/api/interfaces';
import { Avatar } from 'views/components/avatar/Avatar';
import { RoleChangeStatusChip } from 'views/components/roleChangeStatusChip/RoleChangeStatusChip';
import { EMPLOYEE_JOURNEYS_ROUTE_PATH } from 'views/routes/Routes';
import styles from '../../RoleChangeTable.module.scss';

interface IEmployeeWithRoleChangeRowProps {
  employee: api.EmployeeWithRoleChangeDto;
  setSelectedEmployee: (value: api.EmployeeWithRoleChangeDto) => void;
}

export const EmployeeWithRoleChangeRow: React.FC<IEmployeeWithRoleChangeRowProps> = ({
  employee,
  setSelectedEmployee,
}) => {
  const { getRoleChangeCycle } = useRoleChange();

  return (
    <TableRow key={employee.roleChange.roleChangeId}>
      <TableCell>
        <Link
          className={styles.name}
          component={RouterLink}
          to={`${EMPLOYEE_JOURNEYS_ROUTE_PATH}/${employee.idpId}`}>
          <Avatar
            familyName={employee.familyName}
            givenName={employee.givenName}
            idpId={employee.idpId?.trim()?.length ? employee.idpId : undefined}
            className={styles.avatarContainer}
          />
          <span>
            {employee.givenName} {employee.familyName}
          </span>
        </Link>
      </TableCell>
      <TableCell>{employee.role}</TableCell>
      <TableCell>{employee.roleChange.targetRole}</TableCell>
      <TableCell>{employee.department}</TableCell>
      <TableCell>{employee.talentCode || 'N/A'}</TableCell>
      <TableCell>{getRoleChangeCycle(employee.roleChange)}</TableCell>
      <TableCell>
        <Button color="primary" onClick={() => setSelectedEmployee(employee)}>
          View Role Change
        </Button>
      </TableCell>
      <TableCell>
        <RoleChangeStatusChip
          status={
            employee.roleChange.isApproved !== null
              ? employee?.roleChange.isApproved
                ? 'approved'
                : 'rejected'
              : 'submitted'
          }
        />
      </TableCell>
    </TableRow>
  );
};
