import { FormControl, Grid, InputLabel, MenuItem, Select } from '@material-ui/core';
import React, { ChangeEvent } from 'react';
export interface ITableFilterSelectProps {
  label: string;
  value: string;
  options: string[];
  disallowAny?: boolean;
  onChange: (
    event: ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => void;
}

export const TableFilterSelect: React.FC<ITableFilterSelectProps> = (props) => {
  return (
    <Grid item xs>
      <FormControl variant={'outlined'} fullWidth>
        <InputLabel>{props.label}</InputLabel>
        <Select label={props.label} onChange={props.onChange} value={props.value}>
          {(props.disallowAny
            ? props.options.filter((o) => !!o)
            : ['', ...props.options.filter((o) => !!o)]
          ).map((o, i) => (
            <MenuItem value={o} key={i}>
              {o || 'Any'}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Grid>
  );
};
