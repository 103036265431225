import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';

interface ICheckBoxProps {
  index: number;
  label: string;
  color: string;
  checked: boolean;
  onChange: (index: number) => void;
}

export const CustomColorCheckBox: React.FC<ICheckBoxProps> = (props: ICheckBoxProps) => {
  const CustomCheckBox = withStyles({
    root: {
      color: props.color,
      '&$checked': {
        color: props.color,
      },
    },
    checked: {},
  })((props: CheckboxProps) => <Checkbox color="default" {...props} />);

  const handleOnchange = () => {
    props.onChange(props.index);
  };

  return (
    <FormControlLabel
      control={<CustomCheckBox checked={props.checked} onChange={handleOnchange} />}
      label={props.label}
    />
  );
};
