import { Color } from '@material-ui/lab/Alert';
import { Instance, types } from 'mobx-state-tree';

export interface INotification extends Instance<typeof Notification> {}

const Notification = types.model('Notification', {
  message: types.string,
  severity: types.enumeration<Color>(['info', 'success', 'error', 'warning']),
});

export const NotificationModel = types
  .model('NotificationsModel', {
    notificationsToShow: types.array(Notification),
  })
  .actions((self) => {
    function addNotification(message: string, severity: INotification['severity']) {
      self.notificationsToShow.push({ message, severity });
    }

    const addSuccess = (msg: string) => addNotification(msg, 'success');

    const addError = (msg: string) => addNotification(msg, 'error');

    function closeNotification(notification: INotification) {
      self.notificationsToShow.remove(notification);
    }

    return { addSuccess, addError, closeNotification };
  });
