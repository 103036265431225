import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Chip, IconButton, Typography } from '@material-ui/core';
import { INote } from 'domain/store/NoteModel';
import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { SensitiveAttribute } from 'views/components/sensitiveAttribute/SensitiveAttribute';
import { FaceToFaceDiscussionAttribute } from 'views/components/faceToFaceDiscussionAttribute/FaceToFaceDiscussionAttribute';
import { Tag } from 'views/components/tag/Tag';
import styles from 'views/routes/employeeJourney/components/employeeTimeline/components/noteTimelineItem/NoteTimelineItem.module.scss';
import 'github-markdown-css';
import cn from 'classnames';
import { NoteStatusChip } from 'views/routes/myNotes/components/listNotes/components/NoteStatusChip';

interface NoteTimelineItemProps {
  note: INote;
  editable?: boolean;
  deletable?: boolean;
  onEdit: (noteId: number) => void;
  onDelete: (noteId: number) => void;
  editDisabled: boolean;
}

const parseContent = (allContent: string) => {
  const maxCharactersToShowInitially = 500;
  const showMoreButtonRequired = allContent.length > maxCharactersToShowInitially;
  const reducedContent = allContent.substring(0, maxCharactersToShowInitially) + '...';

  return { allContent, reducedContent, showMoreButtonRequired };
};

export const NoteTimelineItem: React.FC<NoteTimelineItemProps> = ({
  note,
  editable,
  deletable,
  onEdit,
  onDelete,
  editDisabled,
}) => {
  const [showAll, setShowAll] = useState(false);
  const date = note.eventDateMoment!.local();

  const { allContent, reducedContent, showMoreButtonRequired } = parseContent(note.content);
  const toggleShowMore = () => setShowAll(!showAll);

  return (
    <li className={styles.noteTimelineItemComponent}>
      <div className={styles.dateContainer}>
        <div>{date.format('dddd')}</div>
        <div>{date.format('DD MMMM')}</div>
      </div>
      <div className={styles.noteContainer}>
        <div className={styles.noteContentContainer}>
          <div className={styles.headerContainer}>
            <Typography variant={'h5'} component={'h3'} className={styles.subject}>
              {note.subject}
              {note.isPeopleLeaderConversation === true && (
                <Chip
                  size="small"
                  color="secondary"
                  variant="default"
                  label={'PL CHAT'}
                  classes={{ root: styles.materialOverride }}
                  className={styles.plChatChip}
                />
              )}
              {note.status === 'pending' && (
                <div>
                  <NoteStatusChip note={note} />
                </div>
              )}
            </Typography>
            <div className={styles.attributes}>
              <div className={styles.actionButtonBar}>
                {editable && (
                  <IconButton
                    color="primary"
                    onClick={() => onEdit(note.noteId)}
                    data-testid="edit-note"
                    className={styles.editButton}
                    disabled={editDisabled}>
                    <FontAwesomeIcon icon={faEdit} size={'xs'} />
                  </IconButton>
                )}
                {deletable && (
                  <IconButton
                    color="secondary"
                    onClick={() => onDelete(note.noteId)}
                    data-testid="delete-note"
                    className={styles.deleteButton}>
                    <FontAwesomeIcon icon={faTrash} size={'xs'} />
                  </IconButton>
                )}
              </div>
              <FaceToFaceDiscussionAttribute
                isFaceToFaceDiscussion={!!note.isFaceToFaceDiscussion}
              />
              {note.sensitivity === 'sensitive' && <SensitiveAttribute isSensitive={true} />}
              {note.sensitivity === 'non-sensitive' && <SensitiveAttribute isSensitive={false} />}
            </div>
          </div>
          <div className={styles.tagsContainer}>
            {note.tags.map((tag, index) => (
              <Tag
                key={index}
                name={tag.name}
                isSensitive={tag.isSensitive}
                reviewRequired={tag.reviewRequired}
              />
            ))}
          </div>
          <div className={cn('markdown-body', styles.contentContainer)}>
            <ReactMarkdown
              className={styles.markdownPreview}
              source={!showMoreButtonRequired || showAll ? allContent : reducedContent}
              skipHtml={true}
            />
            {showMoreButtonRequired && !showAll && <div className={styles.contentFade} />}
          </div>
          <div className={styles.authorContainer}>Author: {note.author.displayName}</div>
          {showMoreButtonRequired && (
            <div className={styles.showMoreContainer}>
              <Button onClick={toggleShowMore}>{showAll ? 'Show Less' : 'Show more'}</Button>
            </div>
          )}
        </div>
      </div>
    </li>
  );
};
