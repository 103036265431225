import { types } from 'mobx-state-tree';

export const CountModel = types.model('CountModel', {
  name: types.string,
  count: types.number,
});

export const CountByMonthModel = types.model('CountByMonthModel', {
  year: types.number,
  month: types.string,
  counts: types.array(CountModel),
});

export const MonthlyTrendModel = types.model('MonthlyTrendModel', {
  labels: types.array(types.string),
  data: types.array(CountByMonthModel),
});
