import {
  green,
  cyan,
  deepPurple,
  purple,
  blue,
  amber,
  deepOrange,
  indigo,
  lightBlue,
  lightGreen,
  lime,
  orange,
  pink,
  red,
  teal,
  yellow,
} from '@material-ui/core/colors';

export enum ColourSet {
  TealGreen,
  LimeOrange,
  RedPurple,
  Blue,
}

// eslint-disable-next-line
const mapColours = (colourSet: ColourSet): any[] => {
  switch (colourSet) {
    case ColourSet.TealGreen:
      return [teal, cyan, lightGreen, green];
    case ColourSet.LimeOrange:
      return [lime, amber, yellow, orange];
    case ColourSet.RedPurple:
      return [deepOrange, red, pink, purple];
    case ColourSet.Blue:
      return [deepPurple, indigo, blue, lightBlue];
  }
};

export const generateColours = (numberOfColours: number, colourSet: ColourSet): string[] => {
  const colours: string[] = [];
  let colourGroup = undefined;

  const colourGroups = mapColours(colourSet);

  for (let i = 0; i * 4 < numberOfColours; i++) {
    const indexOfColourGroups = i >= colourGroups.length ? i % colourGroups.length : i;
    colourGroup = colourGroups[indexOfColourGroups];

    colours.push(colourGroup[800]);
    colours.push(colourGroup[600]);
    colours.push(colourGroup[400]);
    colours.push(colourGroup[200]);
  }

  return colours.slice(0, numberOfColours);
};
