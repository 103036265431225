import React from 'react';
import { useTrends } from 'hooks/useTrends';
import { Skeleton } from '@material-ui/lab';
import { observer } from 'mobx-react-lite';
import { Bar } from 'react-chartjs-2';
import { useEmployeeListPopper } from 'hooks/useEmployeeListPopper';
import { EmployeeListPopper } from '../employeeListPopper/EmployeeListPopper';
import { IRoleCount } from 'domain/store/trends/TrendsEmployeesModel';
import styles from './RoleDistributionChart.module.scss';

export const RoleDistributionChart: React.FC = observer(() => {
  const { trendsEmployees, roleDistributionOrder } = useTrends();

  const orderedRoles = trendsEmployees.roleCounts
    .filter((c) => roleDistributionOrder.indexOf(c.role) > -1)
    .sort((a, b) => roleDistributionOrder.indexOf(a.role) - roleDistributionOrder.indexOf(b.role));

  const abbreviations = Object.assign(
    {},
    ...orderedRoles.map((x) => ({
      [x.role]: x.role
        .split(' ')
        .map((n) => n[0])
        .join(''),
    }))
  );

  const CHART_OPTIONS = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    scales: {
      xAxes: [
        {
          stacked: true,
          gridLines: {
            display: true,
            drawBorder: true,
            drawOnChartArea: false,
            drawTicks: false,
            lineWidth: 1,
          },
          ticks: {
            padding: 10,
            callback: (value: string) => abbreviations[value],
          },
        },
      ],
      yAxes: [
        {
          stacked: true,
          display: true,
        },
      ],
    },
    onHover: (event: MouseEvent, chartElement: HTMLElement[]) => {
      if (event.currentTarget)
        (event.currentTarget as HTMLElement).style.cursor = chartElement[0] ? 'pointer' : 'default';
    },
  };

  const chartData = {
    labels: orderedRoles.map((item: IRoleCount) => item.role),
    datasets: [
      {
        backgroundColor: '#5DDAE7',
        borderColor: '#313232',
        borderWidth: 0,
        data: orderedRoles.map((item: IRoleCount) => item.count),
      },
    ],
  };

  const popper = useEmployeeListPopper();
  const divRef = React.useRef<HTMLDivElement | null>(null);
  // eslint-disable-next-line
  const [selectedElement, setSelectedElement] = React.useState<any>(undefined);

  const handleClick = (e: HTMLElement[]) => {
    // eslint-disable-next-line
    const element = e[0] as any;
    if (!element) return;

    // Without this, selecting subsequent bars will fire the ClickAwayListener's handleClose() *after* this assigns anchorEl, thereby dismissing the new popper
    if (popper.anchorEl) {
      popper.setIgnoreNextClose(true);
    }

    setSelectedElement(element);
    popper.setAnchorEl(divRef.current);
    popper.setOffset([
      element._model.x - (divRef.current?.getBoundingClientRect()?.width || 0) / 2,
      0,
    ]);
  };

  const getSelectedElementEmployees = () => {
    if (!selectedElement) return [];
    const index = selectedElement._index;
    const jobTitle = selectedElement._chart.data.labels[index];

    return trendsEmployees.employeesWithMatchingJobTitle(jobTitle);
  };

  return (
    <>
      <div ref={divRef}>
        {trendsEmployees.state === 'done' ? (
          <Bar data={chartData} options={CHART_OPTIONS} onElementsClick={handleClick} />
        ) : (
          <Skeleton variant={'rect'} classes={{ root: styles.skeleton }} />
        )}
      </div>
      <EmployeeListPopper
        anchorEl={popper.anchorEl}
        handleClose={popper.handleClose}
        color={'#5DDAE7'}
        title={selectedElement?._model.label}
        employees={getSelectedElementEmployees()}
        offset={popper.offset}
      />
    </>
  );
});
