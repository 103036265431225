import React, { ReactNode } from 'react';
import { Tab, Tabs } from '@material-ui/core';
import styles from './Tab.module.scss';

export interface TabItem {
  title: ReactNode;
  testId?: string;
  disabled?: boolean;
  component?: ReactNode;
}

interface Props {
  className?: string;
  items: TabItem[];
  centered?: boolean;
  value: number;
  onChange?: (selectedIndex: number) => void;
}

export const TabComponent: React.FC<Props> = ({ items, centered, onChange, value, className }) => {
  const handleChange = (event: unknown, newValue: number) => {
    onChange && onChange(newValue);
  };

  return (
    <Tabs
      className={className}
      value={value}
      onChange={handleChange}
      centered={centered}
      classes={{ indicator: styles.indicator }}>
      {items.map((item, index) => (
        <Tab
          key={index}
          label={item.title}
          data-testid={item.testId}
          disabled={item.disabled || item.component === undefined}
        />
      ))}
    </Tabs>
  );
};
