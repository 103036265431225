import { faMinusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, IconButton, TableCell, TableRow } from '@material-ui/core';
import { Avatar } from 'views/components/avatar/Avatar';
import { IGroupMemberModel } from 'domain/store/catchUps/CatchUpsModel';
import React, { useState } from 'react';
import styles from './MemberRow.module.scss';
import { observer } from 'mobx-react-lite';
import { useCatchUpGroups } from 'hooks/useCatchUpGroups';
import Skeleton from '@material-ui/lab/Skeleton/Skeleton';
import { CREATE_NOTE_ROUTE_PATH, EMPLOYEE_JOURNEYS_ROUTE_PATH } from 'views/routes/Routes';
import { Link } from 'react-router-dom';
import { AlertDialog } from 'views/components/alertDialog/AlertDialog';
import { LoadingIndicator } from 'views/components/loadingIndicator/LoadingIndicator';
import { FrequencySelector } from '../FrequencySelector/FrequencySelector';
import cn from 'classnames';
import { useStore } from 'hooks/useStore';

export interface MemberRowProps {
  member: IGroupMemberModel | null;
}

export const MemberRow: React.FC<MemberRowProps> = observer(({ member }: MemberRowProps) => {
  const { updateCatchupFrequency, removeMemberFromGroup } = useCatchUpGroups();

  const store = useStore();

  const [isUpdatingFrequency, setIsUpdatingFrequency] = useState(false);
  const [isRemovingMember, setIsRemovingMember] = useState(false);
  const [selectedFrequency, setSelectedFrequency] = useState<number | undefined>(
    member?.catchupFrequencyInWeeks
  );
  const [isDeleteGroupDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);

  const handleRemoveMember = () => {
    if (member?.catchupGroupId) {
      setIsRemovingMember(true);
      setIsDeleteDialogOpen(false);
      removeMemberFromGroup(member.catchupGroupId, member?.idpId)
        .catch((e) => {
          if (e?.response?.status === 409) {
            store.notifications.addError(
              'Conflict occurred saving changes, please refresh the page, and try again after.'
            );
          } else if (e?.response?.status === 403) {
            store.notifications.addError('Not authorised to save changes.');
          } else {
            store.notifications.addError('Unable to remove user from the group, please try again.');
          }
        })
        .finally(() => {
          setIsRemovingMember(false);
        });
    }
  };

  const handleFrequencyChange = (frequencyId: number) => {
    setIsUpdatingFrequency(true);
    setSelectedFrequency(frequencyId);
    member &&
      updateCatchupFrequency(member?.catchupGroupId, member?.idpId, frequencyId)
        .catch(() => {
          store.notifications.addError('Unable to update frequency, please try again.');
        })
        .finally(() => setIsUpdatingFrequency(false));
  };

  return (
    <TableRow>
      <TableCell align="left">
        <Box display="flex" flexDirection="row" alignItems="center">
          <Avatar
            className={styles.avatar}
            idpId={member?.idpId}
            givenName={member?.givenName}
            familyName={member?.familyName}
          />
          <Link
            to={`${EMPLOYEE_JOURNEYS_ROUTE_PATH}/${member?.idpId}`}
            className={styles.linkToEmployee}>
            {member?.displayName}
          </Link>
        </Box>
      </TableCell>
      <TableCell align="center">
        {member ? (
          <>{member.lastCatchupDateUTC ? member.lastCatchupDateAsLocalString : '-'}</>
        ) : (
          <Skeleton width={'50%'} />
        )}
      </TableCell>
      <TableCell align="center">
        {member ? (
          <>{member.nextCatchupDateUTC ? member.nextCatchupDateAsLocalString : '-'}</>
        ) : (
          <Skeleton width={'50%'} />
        )}
      </TableCell>
      <TableCell align="right">
        {member ? (
          member.daysUntilNextCatchup ? (
            <span
              className={cn(styles.daysUntilNextCatchup, {
                [styles.green]: member.daysUntilNextCatchup < 0,
                [styles.orange]: member.daysUntilNextCatchup <= -7,
                [styles.red]: member.daysUntilNextCatchup <= -14,
              })}>
              {`${member.daysUntilNextCatchup} ${
                member.daysUntilNextCatchup === 1 ? 'day' : 'days'
              }`}
            </span>
          ) : (
            '-'
          )
        ) : (
          <Skeleton width={'50%'} />
        )}
      </TableCell>
      <TableCell align="center">
        {member ? (
          <FrequencySelector
            onFrequencyChange={(id) => handleFrequencyChange(id)}
            isUpdatingFrequency={isUpdatingFrequency}
            value={selectedFrequency ?? 0}
          />
        ) : (
          <Skeleton width={'50%'} />
        )}
      </TableCell>
      <TableCell align="center">
        {member ? (
          <Button
            variant="contained"
            color="primary"
            size="large"
            className={styles.button}
            href={`${CREATE_NOTE_ROUTE_PATH}?employeeId=${member.idpId}`}>
            Add a Note
          </Button>
        ) : (
          <Skeleton width={'50%'} />
        )}
      </TableCell>
      <TableCell align="center">
        {member ? (
          !isRemovingMember ? (
            <IconButton onClick={() => setIsDeleteDialogOpen(true)}>
              <FontAwesomeIcon icon={faMinusCircle} color="red" size="xs" />
            </IconButton>
          ) : (
            <LoadingIndicator size={'1rem'}></LoadingIndicator>
          )
        ) : (
          <Skeleton width={'50%'} />
        )}
      </TableCell>
      <AlertDialog
        title="Delete member"
        text={`Are you sure you want to remove the member "${member?.displayName}" from the group`}
        isOpen={isDeleteGroupDialogOpen}
        onDisagreeClick={() => setIsDeleteDialogOpen(false)}
        onAgreeClick={handleRemoveMember}
      />
    </TableRow>
  );
});
