import { types } from 'mobx-state-tree';
import { flow, getEnv, LoadingStateModel } from 'domain/store/util';
import {
  createNote,
  INote,
  IPaginatedNotes,
  NoteModel,
  NoteSensitivity,
} from 'domain/store/NoteModel';
import { api } from 'shared/api/interfaces';

export type ReviewState = 'in-progress' | 'reviewed' | 'unknown';

export const NotesModelMap = types.map(NoteModel);

export const ReviewModel = types
  .model('ForReview', {
    status: LoadingStateModel,
    reviewState: types.optional(
      types.enumeration<ReviewState>(['in-progress', 'reviewed', 'unknown']),
      'unknown'
    ),
    numberOfPendingNotes: types.integer,
  })
  .actions((self) => ({
    getNote: flow(function* (noteId: number): Generator<Promise<INote>, INote | null, INote> {
      try {
        const { ajax } = getEnv(self);
        self.status = 'loading';

        const note = yield ajax
          .get('Notes/noteId', { searchParams: { noteId } })
          .json<api.NoteDto>()
          .then((n) => createNote(n));

        self.reviewState = note.status === 'pending' ? 'in-progress' : 'reviewed';
        return note;
      } catch (e) {
        if (e.response && e.response.status === 403) {
          self.status = 'unauthorized';
        } else {
          self.status = 'error';
        }
      } finally {
        self.status = 'done';
      }

      return null;
    }),

    getNotes: flow(function* (
      selectedSquad: string,
      page: number,
      size: number
    ): Generator<Promise<IPaginatedNotes>, IPaginatedNotes, IPaginatedNotes> {
      const { ajax } = getEnv(self);
      self.status = 'loading';

      let notes: IPaginatedNotes = {
        notes: [],
        totalCount: 0,
      };

      try {
        if (self.reviewState === 'reviewed') {
          notes = yield ajax
            .get('Notes/reviewed/me', { searchParams: { page, size } })
            .json<api.PaginatedNoteDto>()
            .then((result) => {
              return {
                notes: result.notes.map((note) => createNote(note)),
                totalCount: result.totalCount,
              };
            });
        } else {
          self.reviewState = 'in-progress';
          notes = yield ajax
            .get('Notes/to-review', {
              searchParams: { squadName: selectedSquad, page, size },
            })
            .json<api.PaginatedNoteDto>()
            .then((result) => {
              return {
                notes: result.notes.map((note) => createNote(note)),
                totalCount: result.totalCount,
              };
            });
        }

        self.status = 'done';
      } catch (e) {
        if (e.response && e.response.status === 403) {
          self.status = 'unauthorized';
        } else {
          self.status = 'error';
        }
      }

      return notes;
    }),
    getNumberPendingNotes: flow(function* (
      selectedSquad: string
    ): Generator<Promise<number>, void, number> {
      const { ajax } = getEnv(self);
      const numberOfNotes = yield ajax
        .get('Notes/to-review/count', { searchParams: { squadName: selectedSquad } })
        .json<number>();

      self.numberOfPendingNotes = numberOfNotes;
    }),
    updateNoteSensitivity: flow(function* (sensitivity: NoteSensitivity, noteId: number) {
      const { ajax } = getEnv(self);
      yield ajax('Notes/updatenotesensitivity', {
        json: { sensitivity, noteId } as api.UpdateNoteSensitivityRequest,
        method: 'post',
      }).json();

      self.numberOfPendingNotes -= 1;
    }),
    updateReviewState: function (state: ReviewState): void {
      self.reviewState = state;
    },
  }));
