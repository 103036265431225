import React, { useState } from 'react';
import styles from 'views/components/shell/components/search/Search.module.scss';
import { useNavigate } from 'hooks/useNavigate';
import { EmployeeDropdown } from 'views/components/employeeDropdown/EmployeeDropdown';
import { EMPLOYEE_JOURNEYS_ROUTE_PATH } from 'views/routes/Routes';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { usePermissions } from 'hooks/usePermissions';

interface Props {
  className?: string;
}

export const Search: React.FC<Props> = ({ className }) => {
  const navigate = useNavigate();
  const {
    employees: { canSearchForFormerEmployees },
  } = usePermissions();

  const [includeFormerEmployees, setIncludeFormerEmployees] = useState(false);

  return (
    <div className={className}>
      <EmployeeDropdown
        defaultValue={null}
        onChange={(employee, clearCallback) => {
          if (employee) {
            navigate(`${EMPLOYEE_JOURNEYS_ROUTE_PATH}/${employee.idpId}`);
            clearCallback();
          }
        }}
        textBoxClassName={styles.headerSearch}
        includeFormerEmployees={includeFormerEmployees}
      />
      {canSearchForFormerEmployees && (
        <FormControlLabel
          control={
            <Checkbox
              classes={{ root: styles.checkbox, colorSecondary: styles.checked }}
              checked={includeFormerEmployees}
              onChange={(_, value) => setIncludeFormerEmployees(value)}
            />
          }
          label="Include former employees"
          className={styles.includeFormerEmployees}
        />
      )}
    </div>
  );
};
