import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import { useRoleChange } from 'hooks/useRoleChange';
import { useStore } from 'hooks/useStore';
import { useUser } from 'hooks/useUser';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { api } from 'shared/api/interfaces';
import { LoadingIndicator } from 'views/components/loadingIndicator/LoadingIndicator';
import { getComparator, Order, stableSort } from 'views/components/table/SortHelper';
import { HeadCell, SortTableHeader } from 'views/components/table/SortTableHeader';
import { TableFilterSelect } from 'views/components/table/TableFilterSelect/TableFilterSelect';
import { TablePaginationActions } from 'views/components/table/TablePaginationActions';
import { EmployeeWithRoleChangeRow } from './components/employeeWithRoleChangeRow/EmployeeWithRoleChangeRow';
import { RoleChangeSidePanel } from './components/roleChangeSidePanel/RoleChangeSidePanel';
import styles from './RoleChangeTable.module.scss';

const validRoleChangeStatuses = ['', 'Approved', 'Rejected', 'Submitted'] as const;
const tableCells: HeadCell[] = [
  { id: 'givenName', label: 'Name', isSortable: true },
  { id: 'role', label: 'Current Position', isSortable: true },
  { id: 'roleChange', label: 'Propose Position', isSortable: true },
  // { id: 'department', label: 'Capability', isSortable: true },
  { id: 'talentCode', label: 'Talent Code', isSortable: true },
  { id: 'changeCycle', label: 'Change Cycle', isSortable: false },
  { id: 'action', label: 'Action', isSortable: false },
  { id: 'status', label: 'Status', isSortable: false },
];
type RoleChangeStatus = typeof validRoleChangeStatuses[number];

export const RoleChangeTable: React.FC = () => {
  const { selectedSquad } = useUser();
  const {
    getRoleChangeSubmissionsForSquad,
    isLoading,
    getRoleChangeCycle,
    currentRoleChangeCycle,
  } = useRoleChange();
  const {
    notifications: { addError },
  } = useStore();

  const [selectedCurrentPosition, setSelectedCurrentPosition] = useState('');
  const [selectedProposedPosition, setSelectedProposedPosition] = useState('');
  // const [selectedCapability, setSelectedCapability] = useState('');
  const [selectedTalentCode, setSelectedTalentCode] = useState('');
  const [selectedChangeCycle, setSelectedChangeCycle] = useState('');
  const [selectedChangeStatus, setSelectedChangeStatus] = useState<RoleChangeStatus>('');

  const [employees, setEmployees] = useState<api.EmployeeWithRoleChangeDto[]>([]);
  const [selectedEmployee, setSelectedEmployee] = useState<
    api.EmployeeWithRoleChangeDto | undefined
  >(undefined);
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof api.EmployeeWithRoleChangeDto>('displayName');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    setEmployees([]);
    getRoleChangeSubmissionsForSquad(selectedSquad)
      .then((result) => {
        setEmployees(result);
        setSelectedChangeCycle(currentRoleChangeCycle);
      })
      .catch((err) => {
        if (err?.response?.status === 403) {
          addError('Not authorised to access role changes.');
        } else {
          addError(`Failed to get role changes for ${selectedSquad}`);
        }
      });
  }, [selectedSquad, getRoleChangeSubmissionsForSquad, currentRoleChangeCycle, addError]);

  const roleChangeMatchesSelectedStatus = (roleChange: api.RoleChangeDto) => {
    if (selectedChangeStatus === '') return true;
    if (selectedChangeStatus === 'Submitted' && roleChange.isApproved === null) return true;
    if (selectedChangeStatus === 'Approved' && roleChange.isApproved) return true;
    if (selectedChangeStatus === 'Rejected' && roleChange.isApproved === false) return true;
    return false;
  };

  const filteredEmployees = employees.filter(
    (e) =>
      (selectedCurrentPosition === '' || selectedCurrentPosition === e.role) &&
      (selectedProposedPosition === '' || selectedProposedPosition === e.roleChange.targetRole) &&
      // (selectedCapability === '' || selectedCapability === e.department) &&
      (selectedTalentCode === '' || selectedTalentCode === e.talentCode) &&
      selectedChangeCycle === getRoleChangeCycle(e.roleChange) &&
      roleChangeMatchesSelectedStatus(e.roleChange)
  );

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof api.EmployeeWithRoleChangeDto
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <>
      <Grid container direction="row" spacing={2} className={styles.filterWrapper}>
        <TableFilterSelect
          label="Current Position"
          value={selectedCurrentPosition}
          options={Array.from(new Set<string>(employees.map((e) => e.role)))}
          onChange={(event) => setSelectedCurrentPosition((event.target as HTMLInputElement).value)}
        />
        <TableFilterSelect
          label="Proposed Position"
          value={selectedProposedPosition}
          options={Array.from(new Set<string>(employees.map((e) => e.roleChange.targetRole)))}
          onChange={(event) =>
            setSelectedProposedPosition((event.target as HTMLInputElement).value)
          }
        />
        {/* <TableFilterSelect
          label="Capability"
          value={selectedCapability}
          options={Array.from(new Set<string>(employees.map((e) => e.department)))}
          onChange={(event) => setSelectedCapability((event.target as HTMLInputElement).value)}
        /> */}
        <TableFilterSelect
          label="Talent Code"
          value={selectedTalentCode}
          options={Array.from(new Set<string>(employees.map((e) => e.talentCode)))}
          onChange={(event) => setSelectedTalentCode((event.target as HTMLInputElement).value)}
        />
        <TableFilterSelect
          label="Change Cycle"
          value={selectedChangeCycle}
          disallowAny
          options={Array.from(
            new Set<string>([
              ...employees
                .map((e) => getRoleChangeCycle(e.roleChange))
                .sort((a, b) => moment(a).diff(b))
                .reverse(),
              currentRoleChangeCycle,
            ])
          )}
          onChange={(event) => setSelectedChangeCycle((event.target as HTMLInputElement).value)}
        />
        <TableFilterSelect
          label="Status"
          value={selectedChangeStatus}
          options={[...validRoleChangeStatuses]}
          onChange={(event) => setSelectedChangeStatus(event.target.value as RoleChangeStatus)}
        />
      </Grid>
      {isLoading ? (
        <LoadingIndicator text={'Loading role changes'} />
      ) : (
        <Paper className={styles.table}>
          <TableContainer className={styles.toolTableContainer}>
            <Table stickyHeader>
              <SortTableHeader
                order={order}
                orderBy={orderBy}
                labels={tableCells}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {filteredEmployees.length ? (
                  stableSort<api.EmployeeWithRoleChangeDto>(
                    filteredEmployees,
                    getComparator(order, orderBy)
                  )
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((e) => (
                      <EmployeeWithRoleChangeRow
                        key={e.roleChange.roleChangeId}
                        employee={e}
                        setSelectedEmployee={setSelectedEmployee}
                      />
                    ))
                ) : (
                  <TableRow>
                    <TableCell className={styles.nothingFound} colSpan={8} align="center">
                      No role changes match your current filters
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            page={page}
            count={filteredEmployees.length}
            rowsPerPage={rowsPerPage}
            onChangePage={(e, p) => setPage(p)}
            ActionsComponent={TablePaginationActions}
            onChangeRowsPerPage={(e) => {
              setPage(0);
              setRowsPerPage(Number(e.target.value));
            }}
          />
        </Paper>
      )}
      <RoleChangeSidePanel employee={selectedEmployee} setSelectedEmployee={setSelectedEmployee} />
    </>
  );
};
