import React, { useCallback, useEffect, useState } from 'react';
import { EditNote } from './components/editNote/EditNote';
import { ListNotes } from './components/listNotes/ListNotes';
import { ITag } from 'domain/store/TagsModel';
import { INoteRequest, NoteSensitivity, NoteStatus } from 'domain/store/NoteModel';
import { useUser } from 'hooks/useUser';
import { useStore } from 'hooks/useStore';
import moment from 'moment';
import styles from './MyNotes.module.scss';
import { LoadingIndicator } from 'views/components/loadingIndicator/LoadingIndicator';
import { EmployeeSearchResult } from 'domain/store/singletons/GraphModel';
import { Redirect, useParams } from 'react-router-dom';
import { useNavigate } from 'hooks/useNavigate';
import { useQueryParams } from 'hooks/useQueryParams';
import { CREATE_NOTE_ROUTE_PATH, UNAUTHORISED_ROUTE_PATH } from 'views/routes/Routes';
import { Grid, Hidden } from '@material-ui/core';
import { EmployeeNoteSidebar } from 'views/routes/myNotes/components/employeeNoteSidebar/EmployeeNoteSidebar';
import { AlertDialog, AlertDialogProps } from 'views/components/alertDialog/AlertDialog';
import { useGraph } from 'hooks/useGraph';
import { IEmployee } from 'domain/store/employeeJourney/EmployeeModel';
import { usePermissions } from 'hooks/usePermissions';

export type FormItem =
  | 'employee'
  | 'date'
  | 'tags'
  | 'subject'
  | 'description'
  | 'isPeopleLeaderConversation'
  | 'sensitivity'
  | 'isFaceToFaceDiscussion';
export type FormError = { [key in FormItem]?: string };

// Employee needs a null state in order to reset the Employee field. This a quirk of Material UI
export interface IFormState {
  employee?: EmployeeSearchResult | null;
  date: string;
  tags?: ITag[];
  subject: string;
  description: string;
  isPeopleLeaderConversation: boolean;
  errors: FormError;
  noteStatus: NoteStatus;
  sensitivity: NoteSensitivity | undefined;
  isFaceToFaceDiscussion: boolean | undefined;
}

const MAX_SUBJECT_LENGTH = 256;

export type ModeType = 'create' | 'edit';

export interface MyNotesProps {
  mode: ModeType;
}

const immutableEmptyFormState = (): IFormState => {
  return {
    employee: null,
    date: '',
    tags: [],
    subject: '',
    description: '',
    isPeopleLeaderConversation: false,
    errors: {},
    noteStatus: 'draft',
    sensitivity: undefined,
    isFaceToFaceDiscussion: undefined,
  };
};

const immutableEmptyAlertDialog = (): AlertDialogProps => {
  return {
    isOpen: false,
    title: '',
    text: '',
    agreeButtonText: undefined,
    disagreeButtonText: undefined,
    onAgreeClick: undefined,
    onDisagreeClick: undefined,
  };
};

export const MyNotes: React.FC<MyNotesProps> = ({ mode }) => {
  const store = useStore();
  const userStore = useUser();
  const { notes } = usePermissions();
  const navigate = useNavigate();
  const { noteId } = useParams<{ noteId?: string }>();
  const { employeeId } = useQueryParams<{ employeeId: string | null }>();
  const { loadEmployee } = useGraph();
  const [showDrafts, setShowDrafts] = useState<boolean>(true);
  const [isSubmitting, setIsSubmitting] = useState<false | 'draft' | 'published'>(false);
  const [isOkToSaveAsDraft, setisOkToSaveAsDraft] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [formState, setFormState] = useState<IFormState>(immutableEmptyFormState());
  const [validationQueue, setValidationQueue] = useState<FormItem[]>([]);
  const [initialFormState, setInitialFormState] = useState<IFormState>(immutableEmptyFormState());
  const [redirect, setRedirect] = useState<string | null>('');
  const [alertDialog, setAlertDialog] = useState<AlertDialogProps>(immutableEmptyAlertDialog());
  const [employee, setEmployee] = useState<IEmployee>();
  const [isLoadingEmployee, setIsLoadingEmployee] = useState<boolean>(false);

  useEffect(() => {
    if (noteId) return;
    loadEmployee.reset();
    setFormState(immutableEmptyFormState());
    setInitialFormState(immutableEmptyFormState());
    setIsSubmitting(false);
    setisOkToSaveAsDraft(false);
    setValidationQueue([]);
    setAlertDialog(immutableEmptyAlertDialog());
    setRedirect(null);
    setEmployee(undefined);
    setIsLoadingEmployee(false);
  }, [loadEmployee, mode, employeeId, noteId]);

  const showSensitivity = useCallback((): boolean => {
    if (!notes.canBypassReview || !formState.tags) {
      return false;
    }

    let showSensitivity = false;
    for (const tag of formState.tags) {
      if (tag.isSensitive) {
        return false;
      }

      if (tag.reviewRequired) {
        showSensitivity = true;
      }
    }

    return showSensitivity;
  }, [notes.canBypassReview, formState.tags]);

  const handleFormStateChange = useCallback(
    (state: IFormState, item?: FormItem | FormItem[]) => {
      item && setValidationQueue(validationQueue.concat(item));

      if (item === 'employee') setEmployee(undefined); // clear sidebar

      setFormState(state);
    },
    [validationQueue]
  );

  useEffect(() => {
    const setFormStateEmployee = (e?: IEmployee | null) => {
      const state: IFormState = {
        ...formState,
        employee: e,
      };
      setInitialFormState(state);
      setFormState(state);
      setValidationQueue((self) => self.concat('employee'));
      setAlertDialog(immutableEmptyAlertDialog());
    };

    let active = true;
    const currentEmployeeId = formState.employee?.idpId ?? employeeId;
    if (currentEmployeeId) {
      setIsLoadingEmployee(true);
      loadEmployee.searchForEmployee(currentEmployeeId).then((foundEmployee) => {
        setEmployee(foundEmployee ?? undefined);
        if (loadEmployee.state === 'unauthorized') {
          if (employeeId || noteId) {
            active && setRedirect(UNAUTHORISED_ROUTE_PATH);
          } else {
            setAlertDialog({
              isOpen: true,
              title: 'Not Authorised',
              text: 'You are not authorised to add a note for this employee.',
              agreeButtonText: 'OK',
              onAgreeClick: () => setFormStateEmployee(null),
            });
          }
        } else if (foundEmployee !== null && employeeId && !formState.employee) {
          active && setFormStateEmployee(foundEmployee);
        }
        setIsLoadingEmployee(false);
      });
    } else {
      setEmployee(undefined);
    }
    return () => {
      active = false;
    };
    // Including the suggested formState as a dependency will cause the employee to be re-loaded every time the form changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.employee, employeeId, loadEmployee, noteId]);

  const isFormStateDirty = useCallback(() => {
    if (formState.employee?.idpId !== initialFormState.employee?.idpId) {
      return true;
    }
    if (formState.date !== initialFormState.date) {
      return true;
    }
    if (formState.subject !== initialFormState.subject) {
      return true;
    }
    if (formState.tags?.length !== initialFormState.tags?.length) {
      return true;
    }
    if (formState.tags && initialFormState.tags) {
      const origIds = initialFormState.tags.map((t) => t.tagId);
      const currentIds = formState.tags.map((t) => t.tagId);
      if (origIds.filter((t) => !currentIds.includes(t)).length !== 0) {
        return true;
      }
    }
    if (formState.subject !== initialFormState.subject) {
      return true;
    }
    if (formState.isPeopleLeaderConversation !== initialFormState.isPeopleLeaderConversation) {
      return true;
    }
    if (formState.isFaceToFaceDiscussion !== initialFormState.isFaceToFaceDiscussion) {
      return true;
    }
    return formState.description !== initialFormState.description;
  }, [formState, initialFormState]);

  const getNoteById = useCallback(
    async (id: string): Promise<IFormState> => {
      const noteId = Number(id);
      const response = await userStore.loadEditNote(noteId);

      return {
        ...immutableEmptyFormState(),
        employee: response.employee,
        date: response.eventDateMoment!.format('yyyy-MM-DD'),
        tags: response.tags,
        subject: response.subject,
        description: response.content || '',
        isPeopleLeaderConversation: response.isPeopleLeaderConversation,
        noteStatus: response.status,
        sensitivity: response.sensitivity,
        isFaceToFaceDiscussion: response.isFaceToFaceDiscussion,
      };
    },
    [userStore]
  );

  useEffect(() => {
    let active = true;
    if (noteId) {
      active && setIsLoading(true);
      active && setShowDrafts(false); //default to previous tab if editing an existing note.
      active &&
        getNoteById(noteId)
          .then((note: IFormState) => {
            active && setShowDrafts(note.noteStatus === 'draft');
            active && setInitialFormState(note);
            active && setFormState(note);
          })
          .catch(() => {
            active && setFormState(immutableEmptyFormState());
            active && setInitialFormState(immutableEmptyFormState());
            if (active) {
              store.notifications.addError(
                'There was an error loading the note. Please try again later.'
              );
            }
          })
          .finally(() => {
            active && setIsLoading(false);
            active &&
              setValidationQueue([
                'employee',
                'date',
                'tags',
                'subject',
                'description',
                'isFaceToFaceDiscussion',
              ]);
          });
    }
    return () => {
      active = false;
    };
  }, [noteId, getNoteById, store.notifications]);

  const createNoteRequest = (
    employee: EmployeeSearchResult,
    tags: number[],
    sensitivity: NoteSensitivity | undefined
  ): INoteRequest => {
    return {
      employeeIdpId: employee.idpId,
      content: formState.description,
      tags: tags,
      eventDate: formState.date,
      subject: formState.subject,
      isPeopleLeaderConversation: formState.isPeopleLeaderConversation,
      sensitivity: sensitivity,
      noteStatus: undefined,
      isFaceToFaceDiscussion: formState.isFaceToFaceDiscussion,
    };
  };

  const saveNote = async (noteRequest: INoteRequest) => {
    await userStore
      .saveNote(noteRequest, Number(noteId) || undefined)
      .then(() => {
        store.notifications.addSuccess('The note is saved');
        resetForm();
        setShowDrafts(noteRequest.noteStatus === 'draft');
        if (noteRequest.noteStatus === 'draft') {
          userStore.loadDraftNotes();
        } else {
          userStore.loadSubmittedNotes();
        }
        navigate(CREATE_NOTE_ROUTE_PATH);
      })
      .catch((e) => {
        if (e?.response?.status === 403) {
          store.notifications.addError('Not authorised to save note for employee');
        } else if (e?.response?.status === 409) {
          store.notifications.addError(
            'Conflict occurred saving changes, please refresh the page, and try again after.'
          );
        } else {
          store.notifications.addError('Unable to save note, please try again');
        }
      })
      .finally(() => setIsSubmitting(false));
  };

  const handleSubmitNote = async () => {
    if (handleValidation()) {
      const employee: EmployeeSearchResult | undefined | null = formState.employee;
      if (employee) {
        const tags = formState.tags?.map((t) => t.tagId) || [];
        const noteRequest = createNoteRequest(employee, tags, formState.sensitivity);
        setIsSubmitting('published');
        await saveNote(noteRequest);
      }
    }
  };

  const handleSubmitDraft = async () => {
    if (handleDraftValidation()) {
      const employee: EmployeeSearchResult | undefined | null = formState.employee;
      if (employee) {
        const tags = formState.tags?.map((t) => t.tagId) || [];
        const noteRequest = createNoteRequest(employee, tags, formState.sensitivity);
        noteRequest.noteStatus = 'draft';
        setIsSubmitting('draft');
        await saveNote(noteRequest);
      }
    }
  };

  const handleDraftValidation = useCallback(() => {
    return (
      formState.employee !== undefined &&
      formState.employee !== null &&
      formState.date.trim().length > 0 &&
      formState.subject.trim().length > 0 &&
      formState.subject.length <= MAX_SUBJECT_LENGTH &&
      formState.isFaceToFaceDiscussion !== undefined &&
      ((formState.tags?.length || 0) > 0 || formState.description.trim().length > 0)
    );
  }, [formState]);

  useEffect(() => {
    setisOkToSaveAsDraft(handleDraftValidation());
  }, [formState, handleDraftValidation]);

  const getValidationError = useCallback(
    (item: FormItem): string | undefined => {
      const validationErrors: FormError = {
        employee: formState.employee ? undefined : 'Please select an employee.',
        date: formState.date.length
          ? moment().diff(formState.date) < 0
            ? 'Date cannot be in the future'
            : undefined
          : 'Please select a date.',
        tags: (formState.tags?.length || 0) > 0 ? undefined : 'Please select at least one tag.',
        subject: formState.subject
          ? formState.subject.length > MAX_SUBJECT_LENGTH
            ? 'Subject is too long. Please provide a subject less than 256 characters.'
            : undefined
          : 'Please provide a subject.',
        description: formState.description ? undefined : 'Please provide details.',
        sensitivity:
          showSensitivity() && formState.sensitivity === undefined
            ? 'Please specify the sensitivity.'
            : undefined,
        isFaceToFaceDiscussion:
          formState.isFaceToFaceDiscussion === undefined
            ? 'Please specify whether this was a "Direct conversation with the employee" or "Feedback/observations about the employee".'
            : undefined,
      };

      return validationErrors[item];
    },
    [formState, showSensitivity]
  );

  const handleValidateFormItem = useCallback(
    (name: FormItem) => {
      const newState = {
        ...formState,
      };

      newState.errors[name] = getValidationError(name);
      setFormState(newState);
    },
    [formState, getValidationError]
  );

  const handleValidation = () => {
    const newErrorState = {
      employee: getValidationError('employee'),
      date: getValidationError('date'),
      tags: getValidationError('tags'),
      subject: getValidationError('subject'),
      description: getValidationError('description'),
      sensitivity: notes.canBypassReview ? getValidationError('sensitivity') : undefined,
      isFaceToFaceDiscussion: getValidationError('isFaceToFaceDiscussion'),
    };
    setFormState({ ...formState, errors: newErrorState });
    const invalid = Object.values(newErrorState).some((v) => v !== undefined);

    return !invalid;
  };

  useEffect(() => {
    if (validationQueue.length) {
      const errors = formState.errors;
      validationQueue.forEach((item) => {
        errors[item] = getValidationError(item);
      });
      setFormState({ ...formState, errors });
      setValidationQueue([]);
    }
  }, [validationQueue, handleValidateFormItem, formState, getValidationError]);

  const resetForm = () => {
    setEmployee(undefined);
    setFormState(immutableEmptyFormState());
    setInitialFormState(immutableEmptyFormState());
    setIsSubmitting(false);
  };

  const onAgreeClickRestoreInitialFormValues = () => {
    setFormState({ ...initialFormState, errors: {} });
    setAlertDialog(immutableEmptyAlertDialog());
  };

  const onDisagreeClick = () => setAlertDialog(immutableEmptyAlertDialog());

  const onUndoPrompt = () => {
    const shouldShowAlert = isFormStateDirty() && !isSubmitting;
    setAlertDialog({
      isOpen: shouldShowAlert,
      title: 'Undo changes',
      text: 'Are you sure you want to undo your changes?',
      onAgreeClick: onAgreeClickRestoreInitialFormValues,
      onDisagreeClick: onDisagreeClick,
    });
  };

  const formTitle =
    mode !== 'create' && initialFormState.subject?.trim().length > 0
      ? initialFormState.subject.trim()
      : 'New Note';

  return (
    <Grid container wrap={'nowrap'} className={styles.container}>
      {redirect && <Redirect to={redirect} />}
      <Grid item sm={3} className={styles.noteListContainer}>
        <ListNotes
          selectedNoteId={noteId ? Number(noteId) : undefined}
          showDrafts={showDrafts}
          resetForm={resetForm}
        />
      </Grid>
      <Grid item xs className={styles.noteFormContainer}>
        {isLoading ? (
          <LoadingIndicator />
        ) : (
          <EditNote
            formState={formState}
            formTitle={formTitle}
            mode={mode}
            showSensitivity={showSensitivity()}
            onChange={handleFormStateChange}
            onSubmitNote={handleSubmitNote}
            onSubmitDraft={handleSubmitDraft}
            onValidateFormItem={handleValidateFormItem}
            onUndoChanges={onUndoPrompt}
            isFormStateDirty={isFormStateDirty()}
            isOkToSaveAsDraft={isOkToSaveAsDraft}
            isSubmitting={isSubmitting}
          />
        )}
      </Grid>

      <AlertDialog
        isOpen={alertDialog.isOpen}
        title={alertDialog.title}
        text={alertDialog.text}
        agreeButtonText={alertDialog.agreeButtonText}
        onAgreeClick={alertDialog.onAgreeClick}
        onDisagreeClick={alertDialog.onDisagreeClick}
      />

      {formState.employee && (
        <Hidden mdDown>
          <Grid item sm={2}>
            <div className={styles.employeeProfileContainer}>
              <EmployeeNoteSidebar employee={employee} isLoading={isLoadingEmployee} />
            </div>
          </Grid>
        </Hidden>
      )}
    </Grid>
  );
};
