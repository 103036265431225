import React from 'react';
import { observer } from 'mobx-react-lite';
import { Checkbox, FormControlLabel, Box, Typography } from '@material-ui/core';
import { SwitchBaseProps } from '@material-ui/core/internal/SwitchBase';
import { LoadingIndicator } from 'views/components/loadingIndicator/LoadingIndicator';
import styles from './FlightRisk.module.scss';
import { UpdatedByInfoIcon } from 'views/routes/employeeJourney/components/employeeSidebar/components/updatedByInfoIcon/UpdatedByInfoIcon';
import { IFlightRiskStatusModelValue } from 'domain/store/employeeJourney/FlightRiskModel';

interface FlightRiskProps {
  flightRiskStatusModelValue: IFlightRiskStatusModelValue | null;
  loading: boolean;
  onToggle?: () => void;
  disabled: boolean;
}

export const FlightRisk: React.FC<FlightRiskProps> = observer(
  ({ flightRiskStatusModelValue, onToggle, loading, disabled }) => {
    return (
      <Box display={'flex'} alignItems={'center'}>
        <FormControlLabel
          disabled={disabled}
          control={
            loading ? (
              <Box>
                <LoadingIndicator size={'1.5em'} className={styles.loadingIndicator} />
              </Box>
            ) : (
              <Checkbox
                checked={flightRiskStatusModelValue?.isFlightRisk || false}
                onClick={() => onToggle && onToggle()}
                disabled={onToggle === undefined}
                inputProps={
                  {
                    'data-testid': 'flight-risk-checkbox',
                  } as SwitchBaseProps['inputProps'] & { 'data-testid': string }
                }
              />
            )
          }
          label={
            <Typography color={'textPrimary'} variant={'body1'} component={'div'}>
              <Box fontWeight="fontWeightLight" component={'span'}>
                Flight risk
              </Box>
            </Typography>
          }
        />
        {!loading && flightRiskStatusModelValue && (
          <UpdatedByInfoIcon
            updatedByDisplayName={flightRiskStatusModelValue.modifiedBy.displayName}
            modifiedDateTime={flightRiskStatusModelValue.modifiedDateTime}
          />
        )}
      </Box>
    );
  }
);
