import {
  Container,
  Grid,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
} from '@material-ui/core';
import { ISquadUser } from 'domain/store/UserModel';
import { useStore } from 'hooks/useStore';
import { useUser } from 'hooks/useUser';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { LoadingIndicator } from 'views/components/loadingIndicator/LoadingIndicator';
import { PageHeader } from 'views/components/pageHeader/PageHeader';
import { SquadNotSelected } from 'views/components/squadNotSelected/SquadNotSelected';
import { getComparator, Order, stableSort } from 'views/components/table/SortHelper';
import { HeadCell, SortTableHeader } from 'views/components/table/SortTableHeader';
import { TableFilterSelect } from 'views/components/table/TableFilterSelect/TableFilterSelect';
import { EmployeeJourneysRow } from 'views/routes/employeeJourneys/components/employeeJourneysRow/EmployeeJourneysRow';
import { DEFAULT_PAGE_NUMBER, DEFAULT_ROWS_PER_PAGE } from 'views/ViewConstants';
import styles from './EmployeeJourneys.module.scss';
import { TablePaginationActions } from 'views/components/table/TablePaginationActions';

const tableCells: HeadCell[] = [
  { id: 'displayName', label: 'Name', isSortable: true },
  { id: 'position', label: 'Position', isSortable: true },
  // { id: 'capability', label: 'Capability', isSortable: true },
  { id: 'action', label: 'Action', isSortable: false },
];

export const EmployeeJourneys: React.FC = observer(() => {
  const { selectedSquad, getEmployeesForSquad } = useUser();
  const {
    notifications: { addError },
  } = useStore();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  // const [selectedCapability, setSelectedCapability] = useState<string>('');
  const [selectedPosition, setSelectedPosition] = useState<string>('');

  const [page, setPage] = useState<number>(DEFAULT_PAGE_NUMBER);
  const [rowsPerPage, setRowsPerPage] = useState<number>(DEFAULT_ROWS_PER_PAGE);
  const [employees, setEmployees] = useState<ISquadUser[]>([]);
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof ISquadUser>('displayName');

  useEffect(() => {
    if (selectedSquad) {
      setIsLoading(true);
      getEmployeesForSquad(selectedSquad)
        .then((res) => setEmployees(res))
        .catch((e) => {
          addError(`Failed to get role changes for ${selectedSquad}`);
        })
        .finally(() => setIsLoading(false));
    }
  }, [selectedSquad, getEmployeesForSquad, addError]);

  const filteredEmployees = employees.filter(
    (e) => selectedPosition === '' || selectedPosition === e.position
    // && (selectedCapability === '' || selectedCapability === e.capability)
  );

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof ISquadUser) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <>
      {selectedSquad ? (
        <Container>
          <PageHeader title="Employees" />
          <Grid
            container
            spacing={2}
            direction="row"
            alignItems="flex-start"
            className={styles.gridContainer}>
            <Grid item xs={2}>
              <TableFilterSelect
                label={'Position'}
                value={selectedPosition}
                options={Array.from(new Set<string>(employees.map((e) => e.position)))}
                onChange={(e) => {
                  setSelectedPosition((e.target as HTMLInputElement).value);
                  setPage(0);
                }}
              />
            </Grid>
            {/* <Grid item xs={2}>
              <TableFilterSelect
                label={'Capability'}
                value={selectedCapability}
                options={Array.from(new Set<string>(employees.map((e) => e.capability)))}
                onChange={(e) => {
                  setSelectedCapability((e.target as HTMLInputElement).value);
                  setPage(0);
                }}
              />
            </Grid> */}
          </Grid>

          {isLoading ? (
            <LoadingIndicator text={'Loading Employees'} />
          ) : (
            <TableContainer component={Paper}>
              <Table>
                <SortTableHeader
                  order={order}
                  orderBy={orderBy}
                  labels={tableCells}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {stableSort<ISquadUser>(filteredEmployees, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((employee, index) => (
                      <EmployeeJourneysRow key={index} employee={employee} />
                    ))}
                </TableBody>
              </Table>
              <TablePagination
                component={'div'}
                count={filteredEmployees.length}
                onChangePage={(e, p) => setPage(p)}
                page={page}
                rowsPerPage={rowsPerPage}
                ActionsComponent={TablePaginationActions}
                onChangeRowsPerPage={(e) => {
                  setPage(0);
                  setRowsPerPage(+e.target.value);
                }}
              />
            </TableContainer>
          )}
        </Container>
      ) : (
        <SquadNotSelected />
      )}
    </>
  );
});
