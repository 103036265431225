import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@material-ui/core';
import React, { useState } from 'react';

export interface IRenameGroupDialogProps {
  isOpen: boolean;
  groupName: string;
  onOkClick: (groupName: string) => void;
  onCancelClick: () => void;
}

export const RenameGroupDialog: React.FC<IRenameGroupDialogProps> = (
  props: IRenameGroupDialogProps
) => {
  const [groupName, setGroupName] = useState<string>(props.groupName);

  const onCancelClick = () => {
    props.onCancelClick();
  };

  const onOkClick = (groupName: string) => {
    props.onOkClick(groupName);
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth={'xs'}
      data-testid={'dialog-test-id'}
      onClose={onCancelClick}
      open={props.isOpen}
      aria-labelledby="rename-catchup-dialog-title"
      aria-describedby="rename-catchup-dialog-description">
      <DialogTitle id="arename-catchup-dialog-title" data-testid={'dialog-title'}>
        Change the group name
      </DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          fullWidth={true}
          label="Group Name"
          onChange={(e) => setGroupName(e.target.value)}
          value={groupName}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onOkClick(groupName)} color="primary">
          Save
        </Button>
        <Button onClick={onCancelClick} color="secondary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};
