import React, { useEffect, useState } from 'react';
import { Button, Container, Grid, Typography } from '@material-ui/core';
import { LoadingIndicator } from 'views/components/loadingIndicator/LoadingIndicator';
import { NoteItem } from 'views/routes/squad/components/noteReview/components/NoteItem';
import { useUser } from 'hooks/useUser';
import { observer } from 'mobx-react-lite';
import { useQueryParams } from 'hooks/useQueryParams';
import styles from './NoteReview.module.scss';
import { INote } from 'domain/store/NoteModel';
import { ReviewState } from 'domain/store/ReviewModel';
import InfiniteScroll from 'react-infinite-scroller';
import { DEFAULT_PAGE_NUMBER, DEFAULT_ROWS_PER_PAGE } from 'views/ViewConstants';
import { SquadNotSelected } from 'views/components/squadNotSelected/SquadNotSelected';

export const NoteReview: React.FC = observer(() => {
  const {
    selectedSquad,
    review: { getNote, getNotes, updateReviewState, reviewState },
  } = useUser();
  const { noteId } = useQueryParams<{ noteId: string | null }>();
  const [shouldShowOtherNotes, setShouldShowOtherNotes] = useState(!noteId);
  const [notes, setNotes] = useState<INote[]>([]);
  const [page, setPage] = useState(DEFAULT_PAGE_NUMBER);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [initialLoadHasCompleted, setInitialLoadHasCompleted] = useState(false);
  const [initialSquad] = useState(selectedSquad);

  useEffect(() => {
    if (noteId && !shouldShowOtherNotes) {
      setIsLoading(true);
      getNote(Number(noteId))
        .then((n) => n && setNotes([n]))
        .finally(() => setIsLoading(false));
    }
  }, [getNote, noteId, shouldShowOtherNotes]);

  useEffect(() => {
    resetPage();
  }, [selectedSquad]);

  const loadNextPage = () => {
    if (isLoading) return;
    setIsLoading(true);
    getNotes(selectedSquad, page, DEFAULT_ROWS_PER_PAGE)
      .then((result) => {
        const combinedNotes = [...notes, ...result.notes];
        setNotes(combinedNotes);
        setPage(page + 1);
        setHasMore(combinedNotes.length < result.totalCount);
      })
      .finally(() => {
        setInitialLoadHasCompleted(true);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    shouldShowOtherNotes && loadNextPage();
    // This is only here to fire off the initial request, so we don't want to give it any dependencies that might cause it to run again
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldShowOtherNotes]);

  const resetPage = () => {
    setNotes([]);
    setPage(0);
    setHasMore(true);
  };

  useEffect(() => {
    if (selectedSquad !== initialSquad) {
      setShouldShowOtherNotes(true);
    }
  }, [selectedSquad, initialSquad]);

  const swapView = (state: ReviewState) => {
    setShouldShowOtherNotes(true);
    updateReviewState(state);
    resetPage();
  };

  return shouldShowOtherNotes && !selectedSquad ? (
    <SquadNotSelected />
  ) : (
    <InfiniteScroll
      loadMore={loadNextPage}
      hasMore={shouldShowOtherNotes && initialLoadHasCompleted && hasMore}
      // 'key={0}' provided to prevent warning (https://github.com/oVirt/ovirt-web-ui/issues/562)
      loader={<LoadingIndicator key={0} />}
      useWindow={false}>
      <Container>
        <Grid container direction={'column'} spacing={3} wrap={'nowrap'}>
          <Grid item>
            <Grid container justify={'flex-end'} spacing={2}>
              <Grid item>
                <Button
                  className={styles.buttonSwitcher}
                  variant={reviewState === 'in-progress' ? 'contained' : 'outlined'}
                  color={'primary'}
                  data-testid="in-progress-button"
                  disableElevation
                  onClick={() => swapView('in-progress')}>
                  In Progress
                </Button>
              </Grid>
              <Grid item>
                <Button
                  className={styles.buttonSwitcher}
                  variant={reviewState === 'reviewed' ? 'contained' : 'outlined'}
                  color={'primary'}
                  data-testid="reviewed-button"
                  disableElevation
                  onClick={() => swapView('reviewed')}>
                  Reviewed
                </Button>
              </Grid>
            </Grid>
          </Grid>
          {notes.length || isLoading ? (
            notes.map((note) => (
              <Grid item key={note.noteId} data-testid={'note-' + note.noteId}>
                <NoteItem note={note} onNoteApproved={resetPage} />
              </Grid>
            ))
          ) : noteId && !shouldShowOtherNotes ? (
            <Grid item>
              <Typography>
                There is no note with id {noteId}{' '}
                {reviewState === 'in-progress' ? 'requiring review' : 'that has been reviewed'}
              </Typography>
            </Grid>
          ) : (
            <Grid item>
              <Typography>
                {reviewState === 'in-progress'
                  ? 'There are no notes currently requiring review'
                  : 'You have not reviewed any notes yet'}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Container>
    </InfiniteScroll>
  );
});
