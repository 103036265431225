import React from 'react';
import { observer } from 'mobx-react-lite';
import { Checkbox, FormControlLabel, Box, Typography } from '@material-ui/core';
import { SwitchBaseProps } from '@material-ui/core/internal/SwitchBase';
import { LoadingIndicator } from 'views/components/loadingIndicator/LoadingIndicator';
import { UpdatedByInfoIcon } from '../updatedByInfoIcon/UpdatedByInfoIcon';
import styles from './RoleChangePathway.module.scss';
import { TimeframeDropdown } from '../roleChangePathwayTimeframe/TimeframeDropdown';
import { IRoleChangePathwayModelValue } from 'domain/store/employeeJourney/RoleChangePathwayModel';
import { usePermissions } from 'hooks/usePermissions';

interface RoleChangePathwayControlProps {
  roleChangePathwayModelValue: IRoleChangePathwayModelValue | null;
  loading: boolean;
  onToggle?: () => void;
  timeframes: number[];
  onSelectChangeOnSidebar: (event: React.ChangeEvent<{ value: unknown }>) => void;
  disabled: boolean;
}

export const RoleChangePathwayControl: React.FC<RoleChangePathwayControlProps> = observer(
  ({
    roleChangePathwayModelValue,
    onToggle,
    loading,
    timeframes,
    onSelectChangeOnSidebar,
    disabled,
  }) => {
    const {
      canEdit,
      employees: { roleChangePathway },
    } = usePermissions();
    const loadingIndicator = (
      <LoadingIndicator size={'1.5em'} className={styles.loadingIndicator} />
    );

    return (
      <>
        <Box display={'flex'} alignItems={'center'}>
          <FormControlLabel
            disabled={disabled}
            control={
              loading ? (
                loadingIndicator
              ) : (
                <Checkbox
                  checked={!!roleChangePathwayModelValue?.hasRoleChangePathway}
                  onClick={() => onToggle && onToggle()}
                  readOnly={!canEdit(roleChangePathway)}
                  disabled={onToggle === undefined}
                  inputProps={
                    {
                      'data-testid': 'role-change-pathway-checkbox',
                    } as SwitchBaseProps['inputProps'] & { 'data-testid': string }
                  }
                />
              )
            }
            label={
              <Typography color={'textPrimary'} variant={'body1'} component={'div'} noWrap={true}>
                <Box fontWeight="fontWeightLight" component={'span'}>
                  Role Change Pathway
                </Box>
              </Typography>
            }
          />
          {!loading && roleChangePathwayModelValue && (
            <UpdatedByInfoIcon
              updatedByDisplayName={roleChangePathwayModelValue?.modifiedBy.displayName}
              modifiedDateTime={roleChangePathwayModelValue?.modifiedDateTime}
            />
          )}
        </Box>
        {loading
          ? loadingIndicator
          : roleChangePathwayModelValue?.hasRoleChangePathway && (
              <TimeframeDropdown
                value={roleChangePathwayModelValue!.timeframe}
                timeframes={timeframes}
                onSelectChange={onSelectChangeOnSidebar}
                isUpdatingTimeframe={true}
              />
            )}
      </>
    );
  }
);
