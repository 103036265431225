import { Instance, types } from 'mobx-state-tree';
import { flow, getEnv, LoadingStateModel } from 'domain/store/util';
import { makeTrendsUrl } from './TrendsModel';
import { MonthlyTrendModel } from './MonthlyTrendModel';
import { api } from 'shared/api/interfaces';
import { errorLog } from 'index';

interface ITalentCodeTrendsByMonth extends Instance<typeof MonthlyTrendModel> {}

export const TalentCodeTrendsByMonthModel = types
  .model('TalentCodeTrendsByMonth', {
    value: types.optional(MonthlyTrendModel, {}),
    state: LoadingStateModel,
  })
  .actions((self) => ({
    loadTalentCodeTrendsByMonth: flow(function* (
      selectedSquad: string | null
    ): Generator<
      Promise<ITalentCodeTrendsByMonth>,
      ITalentCodeTrendsByMonth,
      ITalentCodeTrendsByMonth
    > {
      if (selectedSquad) {
        const { ajax } = getEnv(self);

        self.state = 'loading';
        try {
          self.value = yield ajax
            .get(makeTrendsUrl('TalentCodesByMonth'), {
              searchParams: { squadName: selectedSquad },
            })
            .json<api.MonthlyTrendsDto>()
            .then((x) => MonthlyTrendModel.create(x));
          self.state = 'done';
        } catch (e) {
          errorLog('Failed to load talent codes by month data', e);
          self.state = 'error';
        }
      }
      return self.value;
    }),
  }));
