import { faPaperPlane, faSave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, FormControl, FormGroup, TextField } from '@material-ui/core';
import { SwitchBaseProps } from '@material-ui/core/internal/SwitchBase';
import cn from 'classnames';
import {
  getStatus,
  RoleChangeStatus,
  RoleChangeAttachmentFile,
} from 'domain/store/employeeJourney/RoleChangeModel';
import { useEmployeeJourney } from 'hooks/useEmployeeJourney';
import { usePermissions } from 'hooks/usePermissions';
import { useRoleChange } from 'hooks/useRoleChange';
import { useStore } from 'hooks/useStore';
import { useUser } from 'hooks/useUser';
import { observer } from 'mobx-react-lite';
import moment, { utc } from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { api } from 'shared/api/interfaces';
import { AlertDialog } from 'views/components/alertDialog/AlertDialog';
import { LoadingIndicator } from 'views/components/loadingIndicator/LoadingIndicator';
import { RoleChangeStatusChip } from 'views/components/roleChangeStatusChip/RoleChangeStatusChip';
import { ValidationIcon, ValidationStatus } from 'views/components/validationIcon/ValidationIcon';
import { RoleChangeCheckbox } from './components/roleChangeCheckbox/RoleChangeCheckbox';
import { RoleChangeCycleRadioGroup } from './components/roleChangeCycleRadioGroup/RoleChangeCycleRadioGroup';
import { RoleChangeSkills } from './components/roleChangeSkills/RoleChangeSkills';
import styles from './RoleChange.module.scss';
import { NavigationAlert } from 'views/components/navigationAlert/NavigationAlert';
import { RoleChangeBusinessCaseAttachment } from './components/roleChangeBusinessCaseAttachment/RoleChangeBusinessCaseAttachment';
import { Positions } from '../../../../../shared/constants/Positions';

interface IRoleChangeProps {
  roleChange: api.RoleChangeDto | undefined;
  setSelectedRoleChange: (value: api.RoleChangeDto | undefined) => void;
  setRoleChangeHasStarted: (value: boolean) => void;
  roleChangeHasStarted: boolean;
}

type FormItem =
  | 'isNotOnProbation'
  | 'hasCompletedRetros'
  | 'noPerformancePlan'
  | 'prerequisites'
  | 'skillRatings'
  | 'businessCaseAttachment';

type FormError = { [key in FormItem]?: string };

interface IFormState {
  targetRole: string;
  skipCurrentPromotionCycle: boolean;
  createdAt: string | undefined;
  hasCompletedRetros: boolean;
  noPerformanceImprovementPlan: boolean;
  isDraft: boolean | undefined;
  businessCaseAttachmentFileName: string | undefined;
  businessCaseAttachmentContentType: string | undefined;
  businessCaseAttachmentFileContent: string | undefined;
  businessCaseAttachmentContentModified: boolean;
  errors: FormError;
  submitterAdditionalComments: string | undefined;
}

const immutableEmptyFormState = (): IFormState => {
  return {
    targetRole: '',
    skipCurrentPromotionCycle: false,
    createdAt: undefined,
    hasCompletedRetros: false,
    noPerformanceImprovementPlan: false,
    isDraft: undefined,
    businessCaseAttachmentFileName: undefined,
    businessCaseAttachmentContentType: undefined,
    businessCaseAttachmentFileContent: undefined,
    businessCaseAttachmentContentModified: false,
    errors: {},
    submitterAdditionalComments: undefined,
  };
};

export const RoleChange: React.FC<IRoleChangeProps> = observer(
  ({ roleChange, setSelectedRoleChange, setRoleChangeHasStarted, roleChangeHasStarted }) => {
    const { employee, positions } = useEmployeeJourney();
    const {
      isLoading,
      upsertRoleChange,
      loadSkills,
      revertCurrentRoleChangeToDraft,
      downloadRoleChangeBusinessCaseAttachment,
    } = useRoleChange();
    const {
      notifications: { addError, addSuccess },
    } = useStore();
    const {
      canEdit,
      employees: { roleChanges },
    } = usePermissions();

    const { givenName, familyName } = useUser();

    const employeeCurrentRole = employee?.position ?? 'Select Position';
    const positionsArray = positions.slice(
      positions.indexOf(employeeCurrentRole) + 1,
      positions.length
    );
    const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
    const [currentRoleSkillRatings, setCurrentRoleSkillRatings] = useState<api.SkillRatingDto[]>(
      []
    );
    const [initialCurrentRoleSkillRatings, setInitialCurrentRoleSkillRatings] = useState<
      api.SkillRatingDto[]
    >([]);
    const [targetRoleSkillRatings, setTargetRoleSkillRatings] = useState<api.SkillRatingDto[]>([]);
    const [initialTargetRoleSkillRatings, setInitialTargetRoleSkillRatings] = useState<
      api.SkillRatingDto[]
    >([]);
    const [isSkillsLoaded, setIsSkillsLoaded] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [lastModifiedBy, setLastModifiedBy] = useState<{
      user: string;
      date: string;
    } | null>(null);
    const [formState, setFormState] = useState<IFormState>({
      ...immutableEmptyFormState(),
      targetRole: positionsArray[0],
    });
    const [initialFormState, setInitialFormState] = useState<IFormState>({
      ...formState,
    });
    const [validationQueue, setValidationQueue] = useState<FormItem[]>([]);
    const [showSkillRatingValidationIcons, setShowSkillRatingValidationIcons] = useState(false);
    const [currentRoleSkills, setCurrentRoleSkills] = useState<api.CapabilityRoleDto>();
    const [targetRoleSkills, setTargetRoleSkills] = useState<api.CapabilityRoleDto>();

    const handleRoleSkillChange = (
      item: api.SkillRatingDto,
      skills: api.SkillRatingDto[],
      setter: React.Dispatch<React.SetStateAction<api.SkillRatingDto[]>>
    ) => {
      const tempSkills = [...skills];
      const skillRating = tempSkills.find((r) => r.coreValueId === item.coreValueId);
      if (skillRating) {
        skillRating.rating = item.rating;
        setter(tempSkills);
        setValidationQueue(['skillRatings']);
      }
    };

    const isReadOnly = !canEdit(roleChanges) || formState.isDraft === false;

    const loadRoleSkills = (
      currentRole: string,
      targetRole: string,
      skillRatings: api.SkillRatingDto[] | undefined = undefined
    ) => {
      setIsSkillsLoaded(false);
      const requireCurrentRoleSkills =
        currentRole === Positions.Associate ? [targetRole] : [currentRole, targetRole];
      employee &&
        loadSkills(employee.capability, requireCurrentRoleSkills)
          .then((s) => {
            const currentRs = s.find((x) => x.name === currentRole);
            const targetRs = s.find((x) => x.name === targetRole);
            setCurrentRoleSkills(currentRs);
            setTargetRoleSkills(targetRs);
            if (skillRatings) {
              if (currentRs) {
                const currentRoleRatings = skillRatings.filter(
                  (value) => currentRs!.coreValues.findIndex((y) => y.id === value.coreValueId) >= 0
                );
                setCurrentRoleSkillRatings(
                  currentRoleRatings.map((r) => {
                    return { coreValueId: r.coreValueId, rating: r.rating };
                  })
                );

                setInitialCurrentRoleSkillRatings(
                  currentRoleRatings.map((r) => {
                    return { coreValueId: r.coreValueId, rating: r.rating };
                  })
                );
              }
              const targetRoleRatings = skillRatings.filter(
                (value) => targetRs!.coreValues.findIndex((y) => y.id === value.coreValueId) >= 0
              );

              setTargetRoleSkillRatings(
                targetRoleRatings.map((r) => {
                  return { coreValueId: r.coreValueId, rating: r.rating };
                })
              );

              setInitialTargetRoleSkillRatings(
                targetRoleRatings.map((r) => {
                  return { coreValueId: r.coreValueId, rating: r.rating };
                })
              );

              setValidationQueue(['skillRatings']);
            } else {
              if (currentRs) {
                setCurrentRoleSkillRatings(
                  currentRs!.coreValues.map((r) => {
                    return { coreValueId: r.id, rating: 0 };
                  })
                );

                setInitialCurrentRoleSkillRatings(
                  currentRs!.coreValues.map((r) => {
                    return { coreValueId: r.id, rating: 0 };
                  })
                );
              }

              setTargetRoleSkillRatings(
                targetRs!.coreValues.map((r) => {
                  return { coreValueId: r.id, rating: 0 };
                })
              );

              setInitialTargetRoleSkillRatings(
                targetRs!.coreValues.map((r) => {
                  return { coreValueId: r.id, rating: 0 };
                })
              );
            }
          })
          .finally(() => setIsSkillsLoaded(true));
    };
    const handleSubmitRoleChange = () => {
      if (employee) {
        setConfirmationDialogOpen(false);
        setIsSubmitting(true);
        const combinedSkillRatings = targetRoleSkillRatings.concat(currentRoleSkillRatings);
        upsertRoleChange({
          ...formState,
          roleChangeId: roleChange?.roleChangeId,
          employeeIdpId: employee.idpId,
          skillRatings: combinedSkillRatings,
          saveAsDraft: false,
        })
          .then(() => {
            addSuccess('Role change is submitted');
            setFormState({ ...formState, isDraft: false });
            setInitialFormState({ ...formState, isDraft: false });

            setInitialCurrentRoleSkillRatings(
              currentRoleSkillRatings.map((r) => {
                return { coreValueId: r.coreValueId, rating: r.rating };
              })
            );

            setInitialTargetRoleSkillRatings(
              targetRoleSkillRatings.map((r) => {
                return { coreValueId: r.coreValueId, rating: r.rating };
              })
            );

            setLastModifiedBy({
              user: `${givenName} ${familyName}`,
              date: moment().format('DD/MM/YYYY'),
            });
            setSelectedRoleChange(roleChange);
          })
          .catch((err) => {
            if (err?.response?.status === 409) {
              addError(
                'Conflict occurred saving changes, please refresh the page, and try again after.'
              );
            } else if (err?.response?.status === 403) {
              addError('Not authorised to save changes.');
            } else {
              addError('Failed to submit role change');
            }
          })
          .finally(() => {
            setIsSubmitting(false);
            setRoleChangeHasStarted(false);
          });
      }
    };

    const handleRevertToDraft = () => {
      if (roleChange) {
        setIsSubmitting(true);
        revertCurrentRoleChangeToDraft(roleChange.roleChangeId)
          .then(() => {
            setFormState({ ...formState, isDraft: true });
            setInitialFormState({ ...formState, isDraft: true });
          })
          .catch((err) => {
            if (err?.response?.status === 409) {
              addError(
                'Conflict occurred saving changes, please refresh the page, and try again after.'
              );
            } else if (err?.response?.status === 403) {
              addError('Not authorised to save changes.');
            } else {
              addError('Failed to revert role change to draft');
            }
          })
          .finally(() => setIsSubmitting(false));
      }
    };

    const handleSaveAsDraft = async () => {
      if (employee) {
        setIsSubmitting(true);
        const combinedSkillsRatings = targetRoleSkillRatings.concat(currentRoleSkillRatings);
        await upsertRoleChange({
          ...formState,
          roleChangeId: roleChange?.roleChangeId,
          employeeIdpId: employee.idpId,
          skillRatings: combinedSkillsRatings,
          saveAsDraft: true,
        })
          .then(() => {
            addSuccess('Draft role change is saved');
            setFormState({ ...formState, isDraft: true });
            setInitialFormState({ ...formState, isDraft: true });
            setInitialCurrentRoleSkillRatings(
              currentRoleSkillRatings.map((r) => {
                return { coreValueId: r.coreValueId, rating: r.rating };
              })
            );
            setInitialTargetRoleSkillRatings(
              targetRoleSkillRatings.map((r) => {
                return { coreValueId: r.coreValueId, rating: r.rating };
              })
            );
            setLastModifiedBy({
              user: `${givenName} ${familyName}`,
              date: moment().format('DD/MM/YYYY'),
            });
            setSelectedRoleChange(roleChange);
          })
          .catch((err) => {
            if (err?.response?.status === 409) {
              addError(
                'Conflict occurred saving changes, please refresh the page, and try again after.'
              );
            } else if (err?.response?.status === 403) {
              addError('Not authorised to save changes.');
            } else {
              addError('Failed to save draft');
            }
          })
          .finally(() => {
            setIsSubmitting(false);
            setRoleChangeHasStarted(false);
          });
      }
    };

    const handleBusinessCaseFileModified = (
      file: RoleChangeAttachmentFile,
      fileModified: boolean
    ) => {
      setFormState((formState) => {
        return {
          ...formState,
          ...{
            businessCaseAttachmentFileName: file.name,
            businessCaseAttachmentFileContent: file.base64,
            businessCaseAttachmentContentType: file.contentType,
            businessCaseAttachmentContentModified: fileModified,
          },
        };
      });
      setValidationQueue(['businessCaseAttachment']);
    };

    const handleFileDownload = (fileName: string | undefined) => {
      if (employee === null || fileName === undefined) {
        addError('for downloading the file, employee and fileName should be present');
        return;
      }

      if (roleChange) {
        downloadRoleChangeBusinessCaseAttachment(employee.idpId, roleChange.roleChangeId, fileName)
          .then((blob: Blob) => {
            const url = window.URL.createObjectURL(new Blob([blob]));
            const a = document.createElement('a');
            a.setAttribute('style', 'display:none;');
            document.body.appendChild(a);
            a.download = fileName;
            a.href = url;
            a.target = '_blank';
            a.click();
            document.body.removeChild(a);
          })
          .catch((err) => {
            if (err?.response?.status === 404) addError('File not found');
          });
      }
    };

    const handleFileDelete = (fileName: string | undefined) => {
      if (employee === null || fileName === undefined) {
        return;
      }
      setFormState((formState) => {
        return {
          ...formState,
          ...{
            businessCaseAttachmentFileName: undefined,
            businessCaseAttachmentContentType: undefined,
            businessCaseAttachmentFileContent: undefined,
            businessCaseAttachmentContentModified: true,
          },
        };
      });

      setValidationQueue(['businessCaseAttachment']);
    };

    useEffect(() => {
      if (!roleChange && !roleChangeHasStarted) return;

      if (roleChange && employee) {
        loadRoleSkills(employee.position, roleChange.targetRole, roleChange.skillRatings);
        setFormState((formState) => {
          return {
            ...formState,
            ...roleChange,
          };
        });
        setInitialFormState((initialFormState) => {
          return {
            ...formState,
            ...roleChange,
          };
        });
        setLastModifiedBy({
          user: roleChange.lastModifiedBy,
          date: utc(roleChange.lastModifiedAtUtc).local().format('DD/MM/YYYY'),
        });
      }

      if (roleChangeHasStarted && employee && roleChange?.isDraft === undefined) {
        loadRoleSkills(employee.position, positionsArray[0]);
        setFormState((formState) => {
          return {
            ...formState,
          };
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [employee, roleChange]);

    const getValidationError = useCallback(
      (item: FormItem): string | undefined => {
        const validationErrors: FormError = {
          isNotOnProbation: !employee?.probation.isInProbation ? undefined : '',
          hasCompletedRetros: formState.hasCompletedRetros ? undefined : '',
          noPerformancePlan: formState.noPerformanceImprovementPlan ? undefined : '',

          prerequisites:
            !employee?.probation.isInProbation &&
            formState.hasCompletedRetros &&
            formState.noPerformanceImprovementPlan
              ? undefined
              : 'All prerequisites must be satisfied prior to submission',

          skillRatings:
            targetRoleSkillRatings.every((r) => r.rating >= 3) &&
            currentRoleSkillRatings.every((r) => r.rating >= 3)
              ? undefined
              : 'All skills & behaviours must be rated as No Brainer or above prior to submission',

          businessCaseAttachment:
            (formState.targetRole === Positions.ManagingConsultant ||
              formState.targetRole === Positions.PrincipalConsultant) &&
            !formState.businessCaseAttachmentFileName
              ? `Business Case Attachment is mandatory for ${Positions.ManagingConsultant} and ${Positions.PrincipalConsultant} roles`
              : undefined,
        };

        return validationErrors[item];
      },
      [employee, formState, currentRoleSkillRatings, targetRoleSkillRatings]
    );

    const handleValidateFormItem = useCallback(
      (name: FormItem) => {
        const newState = {
          ...formState,
        };

        newState.errors[name] = getValidationError(name);
        setFormState(newState);
      },
      [formState, getValidationError]
    );

    const handleValidation = () => {
      handleValidateFormItem('isNotOnProbation');
      handleValidateFormItem('hasCompletedRetros');
      handleValidateFormItem('noPerformancePlan');
      handleValidateFormItem('prerequisites');
      handleValidateFormItem('skillRatings');
      handleValidateFormItem('businessCaseAttachment');

      setShowSkillRatingValidationIcons(true);

      return Object.values(formState.errors).every((v) => v === undefined);
    };

    const getValidationStatus = (item: FormItem): ValidationStatus => {
      if (Object.prototype.hasOwnProperty.call(formState.errors, item)) {
        return formState.errors[item] === undefined ? 'valid' : 'error';
      }
      return 'hidden';
    };

    const handleFormStateChange = useCallback(
      (state: IFormState, item?: FormItem | FormItem[]) => {
        item && setValidationQueue(validationQueue.concat(item));
        setFormState(state);
      },
      [validationQueue]
    );

    useEffect(() => {
      if (validationQueue.length) {
        const errors = formState.errors;
        validationQueue.forEach((item) => {
          errors[item] = getValidationError(item);
        });
        setFormState({ ...formState, errors });
        setValidationQueue([]);
      }
    }, [validationQueue, handleValidateFormItem, formState, getValidationError]);

    const isFormStateDirty = useCallback(() => {
      if (formState.targetRole !== initialFormState.targetRole) {
        return true;
      }
      if (formState.skipCurrentPromotionCycle !== initialFormState.skipCurrentPromotionCycle) {
        return true;
      }
      if (formState.hasCompletedRetros !== initialFormState.hasCompletedRetros) {
        return true;
      }
      if (
        formState.noPerformanceImprovementPlan !== initialFormState.noPerformanceImprovementPlan
      ) {
        return true;
      }
      if (formState.submitterAdditionalComments !== initialFormState.submitterAdditionalComments) {
        return true;
      }
      return (
        currentRoleSkillRatings.some(
          (r, i) => r.rating !== initialCurrentRoleSkillRatings[i]?.rating
        ) ||
        targetRoleSkillRatings.some((r, i) => r.rating !== initialTargetRoleSkillRatings[i]?.rating)
      );
    }, [
      formState,
      initialFormState,
      currentRoleSkillRatings,
      initialCurrentRoleSkillRatings,
      targetRoleSkillRatings,
      initialTargetRoleSkillRatings,
    ]);

    if (isLoading) {
      return (
        <div className={styles.root}>
          <LoadingIndicator />
        </div>
      );
    }

    return (
      <>
        <NavigationAlert for="role change" when={isFormStateDirty() && !isSubmitting} />
        <form className={styles.formContainer}>
          <div className={styles.titleContainer}>
            <h2>Role Change</h2>
            <RoleChangeStatusChip
              status={
                getStatus(
                  formState.isDraft,
                  roleChange?.isApproved,
                  roleChange?.isActive
                ) as RoleChangeStatus
              }
            />
          </div>
          {lastModifiedBy && (
            <span className={styles.lastModified}>
              Last modified by {lastModifiedBy.user} on {lastModifiedBy.date}
            </span>
          )}
          <RoleChangeCycleRadioGroup
            disabled={isReadOnly}
            selectedValue={formState.skipCurrentPromotionCycle}
            onChange={(value) =>
              handleFormStateChange({ ...formState, skipCurrentPromotionCycle: value })
            }
            roleChangeDate={formState.createdAt}
            dataTestId="role-change-cycle-radio-button"
          />

          <FormControl
            component="fieldset"
            className={cn(styles.formItem, {
              [styles.withValidation]: getValidationStatus('prerequisites') !== 'hidden',
            })}
            disabled={isReadOnly}>
            <label>Prerequisites</label>
            <FormGroup>
              <RoleChangeCheckbox
                disabled={true}
                selectedValue={!employee?.probation.isInProbation}
                label="Candidate has passed probation"
                dataTestId="passed-probation-checkbox"
              />
              <ValidationIcon
                status={getValidationStatus('isNotOnProbation')}
                className={styles.validationIcon}
              />
              <RoleChangeCheckbox
                disabled={isReadOnly}
                selectedValue={formState.hasCompletedRetros}
                onChange={(value) =>
                  handleFormStateChange(
                    {
                      ...formState,
                      hasCompletedRetros: value,
                    },
                    getValidationStatus('prerequisites') !== 'hidden'
                      ? ['hasCompletedRetros', 'prerequisites']
                      : undefined
                  )
                }
                label="Candidate has completed 2 retros within the last 12 months"
                dataTestId="completed-retros-checkbox"
              />
              <ValidationIcon
                status={getValidationStatus('hasCompletedRetros')}
                className={styles.validationIcon}
              />
              <RoleChangeCheckbox
                disabled={isReadOnly}
                selectedValue={formState.noPerformanceImprovementPlan}
                onChange={(value) =>
                  handleFormStateChange(
                    {
                      ...formState,
                      noPerformanceImprovementPlan: value,
                    },
                    getValidationStatus('prerequisites') !== 'hidden'
                      ? ['noPerformancePlan', 'prerequisites']
                      : undefined
                  )
                }
                label="Candidate has not been on a performance improvement plan for at least 12 months"
                dataTestId="performance-improvement-plan-checkbox"
              />
              <ValidationIcon
                status={getValidationStatus('noPerformancePlan')}
                className={styles.validationIcon}
              />
            </FormGroup>
            {formState.errors.prerequisites && (
              <div className={styles.errorText}>{formState.errors.prerequisites}</div>
            )}
          </FormControl>
          {isSkillsLoaded && targetRoleSkills ? (
            <RoleChangeSkills
              disabled={isReadOnly}
              currentRoleSkills={currentRoleSkills}
              targetRoleSkills={targetRoleSkills}
              dataSource={positionsArray}
              onSelected={(e) => {
                handleFormStateChange({
                  ...formState,
                  targetRole: e,
                });
                loadRoleSkills(employee!.position, e);
              }}
              selectedItem={formState.targetRole}
              currentRoleSkillRatings={currentRoleSkillRatings}
              targetRoleSkillRatings={targetRoleSkillRatings}
              handleCurrentRoleSkillChange={(e) =>
                handleRoleSkillChange(e, currentRoleSkillRatings, setCurrentRoleSkillRatings)
              }
              handleTargetRoleSkillChange={(e) =>
                handleRoleSkillChange(e, targetRoleSkillRatings, setTargetRoleSkillRatings)
              }
              formError={formState.errors.skillRatings}
              showValidationErrors={showSkillRatingValidationIcons}
            />
          ) : (
            <LoadingIndicator />
          )}
          <RoleChangeBusinessCaseAttachment
            onBusinessCaseFileModified={handleBusinessCaseFileModified}
            onDownload={handleFileDownload}
            onDelete={handleFileDelete}
            fileName={formState.businessCaseAttachmentFileName}
            disabled={isReadOnly}
            formError={formState.errors.businessCaseAttachment}
            showValidationErrors={true}
          />
          <FormControl component="fieldset" className={styles.formItem} disabled={isReadOnly}>
            <label>Additional Comments (optional)</label>
            {!isReadOnly ? (
              <TextField
                placeholder="Comments"
                className={styles.commentsContainer}
                variant="outlined"
                multiline
                fullWidth
                disabled={isReadOnly}
                inputProps={
                  {
                    'data-testid': 'comments-text-field',
                  } as SwitchBaseProps['inputProps'] & { 'data-testid': string }
                }
                value={formState.submitterAdditionalComments}
                onChange={(e) =>
                  setFormState({
                    ...formState,
                    submitterAdditionalComments: e.currentTarget.value,
                  })
                }
              />
            ) : (
              <div className={styles.formInputItem} data-testid="read-only-comment">
                {roleChange?.submitterAdditionalComments || 'No comments were made'}
              </div>
            )}
          </FormControl>
          {roleChange?.approverAdditionalComments && (
            <div className={styles.formItem}>
              <label>Approvers Comments</label>
              <div className={styles.formInputItem}>{roleChange.approverAdditionalComments}</div>
            </div>
          )}
          <div className={styles.buttonsContainer}>
            <Button
              variant="outlined"
              className={styles.backButton}
              color="primary"
              onClick={() => {
                setRoleChangeHasStarted(false);
                setSelectedRoleChange(undefined);
              }}>
              Back
            </Button>
            {canEdit(roleChanges) && isSubmitting ? (
              <div>
                <LoadingIndicator size="1.5em" className={styles.loadingIndicator} />
              </div>
            ) : formState.isDraft === false ? (
              roleChange?.isApproved === true ||
              roleChange?.isApproved === false ||
              !canEdit(roleChanges) ? null : (
                <Button
                  variant="contained"
                  color="secondary"
                  data-testid="edit-button"
                  onClick={handleRevertToDraft}
                  disabled={!isSkillsLoaded}>
                  Edit
                  <FontAwesomeIcon icon={faPaperPlane} size="sm" />
                </Button>
              )
            ) : (
              <>
                <Button
                  type="button"
                  variant="outlined"
                  color="primary"
                  data-testid="save-draft-button"
                  onClick={handleSaveAsDraft}
                  disabled={!isSkillsLoaded}>
                  Save
                  <FontAwesomeIcon icon={faSave} size="sm" />
                </Button>
                <Button
                  type="button"
                  variant="contained"
                  color="primary"
                  disabled={!isSkillsLoaded}
                  data-testid="submit-button"
                  onClick={() => handleValidation() && setConfirmationDialogOpen(true)}>
                  Submit
                  <FontAwesomeIcon icon={faPaperPlane} size="sm" />
                </Button>
                <AlertDialog
                  isOpen={confirmationDialogOpen}
                  title={'Submit Role Change'}
                  text={`You are about to submit ${employee?.displayName}'s role change for ${formState.targetRole}`}
                  onAgreeClick={handleSubmitRoleChange}
                  onDisagreeClick={() => setConfirmationDialogOpen(false)}
                  agreeButtonText="Submit"
                  disagreeButtonText="Cancel"
                />
              </>
            )}
          </div>
        </form>
      </>
    );
  }
);
