import React, { useState } from 'react';
import { MenuItem } from '@material-ui/core';
import { CustomSelection } from 'views/components/customSelection/CustomSelection';
import { LoadingIndicator } from 'views/components/loadingIndicator/LoadingIndicator';
import { AlertDialog } from 'views/components/alertDialog/AlertDialog';

export type TimeframeOption = { description: string; value: number };

export interface RoleChangePathwaySelectorProps {
  employeeDisplayName: string;
  selectedValue: number;
  onAgreeClick: (selectedValue: TimeframeOption) => void;
  isLoading: boolean;
}

export const RoleChangePathwaySelector: React.FunctionComponent<RoleChangePathwaySelectorProps> = (
  props
) => {
  const options: TimeframeOption[] = [
    { description: 'N/A', value: 0 },
    { description: '6 months', value: 6 },
    { description: '12 months', value: 12 },
    { description: '12+ months', value: 18 },
  ];

  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [timeframeToSelect, setTimeframeToSelect] = useState<number>(0);

  const onTimeframeChange = (timeframeValue: number) => {
    setTimeframeToSelect(timeframeValue);
    setIsDialogOpen(true);
  };

  const onCloseDialog = () => {
    setIsDialogOpen(false);
    setTimeframeToSelect(0);
  };

  const onAgreeUpdateClick = () => {
    // update logic here
    const newTimeframeOption = getTimeframeOption(timeframeToSelect);
    props.onAgreeClick(newTimeframeOption!);
    onCloseDialog();
  };

  const getTimeframeOption = (timeframeToSelect: number) => {
    return options.find((x) => x.value === timeframeToSelect);
  };

  return props.isLoading ? (
    <LoadingIndicator />
  ) : (
    <>
      <AlertDialog
        isOpen={isDialogOpen}
        title={'Update Role Change Timeframe'}
        text={`You are about to update ${props.employeeDisplayName}'s timeframe to: ${
          getTimeframeOption(timeframeToSelect)!.description
        }`}
        onAgreeClick={onAgreeUpdateClick}
        onDisagreeClick={onCloseDialog}
      />
      <CustomSelection
        label={'Role Change Timeframe'}
        value={props.selectedValue}
        onChange={onTimeframeChange}
        fullWidth={true}>
        {options.map(({ description, value }) => (
          <MenuItem key={`${value}-${description}`} value={value}>
            {description}
          </MenuItem>
        ))}
      </CustomSelection>
    </>
  );
};
