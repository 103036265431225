import { Typography, Box } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React from 'react';

interface ISidebarContentProps {
  heading: string;
  details?: string | undefined;
  hasChildren?: boolean;
  isLoaded?: boolean;
}

export const SidebarContent: React.FC<ISidebarContentProps> = (props: ISidebarContentProps) => {
  return (
    <>
      <Typography color={'textPrimary'} variant={'subtitle1'} component={'div'}>
        <Box fontWeight="fontWeightMedium" component={'span'}>
          {props.heading}
        </Box>
      </Typography>
      {props.hasChildren && (
        <Typography color={'textPrimary'} variant={'subtitle1'} component={'div'}>
          <Box fontWeight="fontWeightLight" component={'span'}>
            {props.isLoaded ? props.details : <Skeleton variant={'text'} />}
          </Box>
        </Typography>
      )}
    </>
  );
};
