import React from 'react';
import styles from './ChartHeader.module.scss';

export const ChartHeader: React.FC<{ text: string; subHeader?: string }> = ({
  text,
  subHeader,
}) => {
  return (
    <div>
      <span className={styles.root}>{text}</span>
      {subHeader ? <div className={styles.subHeader}>By Month</div> : <></>}
    </div>
  );
};
