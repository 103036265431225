import { faEdit, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  ClickAwayListener,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import cn from 'classnames';
import React, { ChangeEvent, useState } from 'react';
import { api } from 'shared/api/interfaces';
import { AlertDialog } from 'views/components/alertDialog/AlertDialog';
import { ValidationIcon } from 'views/components/validationIcon/ValidationIcon';
import styles from '../../RoleChange.module.scss';
import { SkillRating } from './components/skillRating/SkillRating';

interface IRoleChangeSkills {
  currentRoleSkills?: api.CapabilityRoleDto;
  targetRoleSkills: api.CapabilityRoleDto;
  disabled: boolean;
  dataSource: string[];
  onSelected?: (selectedItem: string) => void;
  selectedItem: string;
  currentRoleSkillRatings: api.SkillRatingDto[];
  targetRoleSkillRatings: api.SkillRatingDto[];
  handleCurrentRoleSkillChange: (item: api.SkillRatingDto) => void;
  handleTargetRoleSkillChange: (item: api.SkillRatingDto) => void;
  formError: string | undefined;
  showValidationErrors: boolean;
}

export const RoleChangeSkills: React.FC<IRoleChangeSkills> = (props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [positionToUpdate, setPositionToUpdate] = useState(props.selectedItem || '');
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isOpenTarget, setIsOpenTarget] = useState<boolean>(false);

  const onCloseDialog = () => {
    setIsDialogOpen(false);
    setAnchorEl(null);
  };

  const toggleSkillsMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleRoleChange = (e: ChangeEvent<{ value: unknown }>) => {
    const newValue = e.target.value as string;
    setPositionToUpdate(newValue);
    setIsDialogOpen(true);
  };

  const onAgree = () => {
    props.onSelected!(positionToUpdate);
    setIsDialogOpen(false);
  };

  return (
    <FormControl
      component="fieldset"
      className={cn(styles.formItem, styles.roleChangeSkills, {
        [styles.withValidation]: props.showValidationErrors,
      })}>
      {props.currentRoleSkills && (
        <>
          <ClickAwayListener onClickAway={() => setIsOpen(false)}>
            <label>
              {props.currentRoleSkills.name} Skills &amp; Behaviours
              <RatingDescription open={isOpen} onClick={() => setIsOpen((prev) => !prev)} />
            </label>
          </ClickAwayListener>
          <FormGroup className={styles.currentRole}>
            {props.currentRoleSkills.coreValues.map((skill, index) => {
              const rating =
                props.currentRoleSkillRatings.find((r) => r.coreValueId === skill.id)?.rating || 0;
              return (
                <React.Fragment key={index}>
                  <SkillRating
                    coreValueId={skill.id}
                    skill={skill.name}
                    currentRating={rating}
                    index={index}
                    handleSkillChange={props.handleCurrentRoleSkillChange}
                    disabled={props.disabled}
                    dataTestId={'skill-rating-dropdown'}
                    showValidationError={!!props.formError}
                  />
                  {props.showValidationErrors && (
                    <ValidationIcon
                      status={rating < 3 ? 'error' : 'valid'}
                      className={styles.validationIcon}
                    />
                  )}
                </React.Fragment>
              );
            })}
          </FormGroup>
        </>
      )}
      <ClickAwayListener onClickAway={() => setIsOpenTarget(false)}>
        <label className={styles.targetRole}>
          <div className={styles.targetRoleTitle}>
            {props.targetRoleSkills.name} Skills &amp; Behaviours
            {!props.disabled && (
              <>
                <IconButton
                  color="default"
                  onClick={toggleSkillsMenu}
                  data-testid="change-target-role-button"
                  className={styles.editButton}>
                  <FontAwesomeIcon icon={faEdit} size={'xs'} />
                </IconButton>
                <AlertDialog
                  isOpen={isDialogOpen}
                  title={'Update Target Role Change'}
                  text={`You are about to update their target role: ${props.targetRoleSkills.name} to ${positionToUpdate}`}
                  onAgreeClick={onAgree}
                  onDisagreeClick={onCloseDialog}
                />
                <Menu
                  id="position-menu"
                  anchorEl={anchorEl}
                  getContentAnchorEl={null}
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                  transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}>
                  <FormControl component="fieldset">
                    <RadioGroup
                      aria-label="roles"
                      value={props.selectedItem}
                      name="roles"
                      onChange={handleRoleChange}>
                      {props.dataSource.map((role, index) => (
                        <MenuItem key={index} value={role}>
                          <FormControlLabel
                            labelPlacement="start"
                            value={role}
                            control={<Radio />}
                            label={role}
                          />
                        </MenuItem>
                      ))}
                    </RadioGroup>
                  </FormControl>
                </Menu>
              </>
            )}
          </div>
          {!props.currentRoleSkills && (
            <RatingDescription
              open={isOpenTarget}
              onClick={() => setIsOpenTarget((prev) => !prev)}
            />
          )}
        </label>
      </ClickAwayListener>
      <FormGroup>
        {props.targetRoleSkills.coreValues.map((skill, index) => {
          const rating =
            props.targetRoleSkillRatings.find((r) => r.coreValueId === skill.id)?.rating || 0;
          return (
            <React.Fragment key={index}>
              <SkillRating
                coreValueId={skill.id}
                skill={skill.name}
                currentRating={rating}
                index={index}
                handleSkillChange={props.handleTargetRoleSkillChange}
                disabled={props.disabled}
                dataTestId={'skill-rating-dropdown'}
                showValidationError={!!props.formError}
              />
              {props.showValidationErrors && (
                <ValidationIcon
                  status={rating < 3 ? 'error' : 'valid'}
                  className={styles.validationIcon}
                />
              )}
            </React.Fragment>
          );
        })}
      </FormGroup>
      {props.formError && props.showValidationErrors && (
        <div className={styles.errorText}>{props.formError}</div>
      )}
    </FormControl>
  );
};

interface IRatingDescriptionProps {
  open: boolean;
  onClick: () => void;
}

const RatingDescription = (props: IRatingDescriptionProps) => {
  return (
    <>
      <IconButton
        className={styles.skillsRatingDescription}
        aria-label="rating-descriptions"
        onClick={() => props.onClick && props.onClick()}>
        <FontAwesomeIcon icon={faQuestionCircle} size="sm" />
      </IconButton>
      {props.open ? (
        <Paper className={styles.ratingDescription}>
          <p>
            <span>Rating Descriptions</span>
          </p>
          <p>
            <span>Not Applicable</span> - There hasn&#39;t been an opportunity to demonstrate this,
            or your contributor hasn&#39;t had the opportunity to witness this area.
          </p>
          <p>
            <span>Needs Coaching</span> - This area needs improvement. If you haven’t already, focus
            your PD plan on it.
          </p>
          <p>
            <span>Maturing</span> - You’re developing well in this area. Continue to work on it as a
            growth area.
          </p>
          <p>
            <span>No Brainer</span> - You’re consistently demonstrating solid ability at this. Keep
            it up!
          </p>
          <p>
            <span>Outstanding</span> - You’re doing this exceptionally well, demonstrating
            leadership in this space.
          </p>
        </Paper>
      ) : null}
    </>
  );
};
