import { Instance, types } from 'mobx-state-tree';

export const PagingCacheModel = types
  .model('PagingCacheModel', {
    currentPageCache: types.integer,
    rowsPerPageCache: types.integer,
  })
  .actions((self) => ({
    setCurrentPageCache: (page: number) => (self.currentPageCache = page),
    setRowsPerPageCache: (rowsPerPage: number) => (self.rowsPerPageCache = rowsPerPage),
  }));

export interface IPagingCache extends Instance<typeof PagingCacheModel> {}
