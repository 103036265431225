import { Box, Grid } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { useFlags } from 'hooks/useFlags';
import { usePermissions } from 'hooks/usePermissions';
import { useTalentAnalysis } from 'hooks/useTalentAnalysis';
import { useUser } from 'hooks/useUser';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { LoadingIndicator } from 'views/components/loadingIndicator/LoadingIndicator';
import { TalentAnalysisToolTable } from 'views/routes/squad/components/talentAnalysisTool/components/talentAnalysisToolTable/TalentAnalysisToolTable';
import { EmployeeStatusSelect } from './components/employeeStatusSelect/EmployeeStatusSelect';
import { MultiSelect } from './components/multiSelect/multiSelect';
import { SingleSelect } from './components/singleSelect/singleSelect';
import styles from './talentAnalysisTool.module.scss';

export const TalentAnalysisTool: React.FC = observer(() => {
  const { selectedSquad, getAllSquads } = useUser();

  const {
    // canViewAllSquads,
    squads: { canViewTalentAnalysis },
  } = usePermissions();

  if (!canViewTalentAnalysis) return null;
  const canViewAllSquads = false; // TODO: Get CanViewAllSquads from permissions when multi squad support is in.

  const { loadEmployees, state, employees } = useTalentAnalysis();
  const { all: talentCodes } = useFlags();

  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [squads, setSquads] = useState<string[]>([]);
  const [, setSelectedSquads] = useState<string[]>([]);
  const [selectedPositions, setSelectedPositions] = useState<string[]>([]);
  // const [selectedDepartments, setSelectedDepartments] = useState<string[]>([]);
  const [selectedEmployeeStatus, setSelectedEmployeeStatus] = useState<string>('');
  const [selectedTalentCodes, setSelectedTalentCodes] = useState<string[]>([]);
  const [selectedRoleChangePathWay, setSelectedRoleChangePathWay] = useState<string>('');
  const [selectedFlightRisk, setSelectedFlightRisk] = useState<string>('');
  const [selectedProbation, setSelectedProbation] = useState<string>('');
  const [selectedLongTermLeave, setSelectedLongTermLeave] = useState<string>('');

  const yesNoOptions = ['', 'Yes', 'No'];
  const yes = 'Yes';

  if (canViewAllSquads) {
    useEffect(() => {
      getAllSquads().then((s) => {
        setSquads(
          Array.from(
            new Set<string>([...s])
          )
        );
      });
    }, [getAllSquads, canViewAllSquads]);
  }

  const requiresReloadEmployees = async () => {
    if (isDirty) await loadEmployees(selectedSquad);
    setIsDirty(false);
  };

  useEffect(() => {
    if (selectedSquad) {
      loadEmployees(selectedSquad);
    }
  }, [loadEmployees, selectedSquad]);

  const filteredEmployees = employees.filter(
    (e) =>
      (selectedPositions.length === 0 || selectedPositions.indexOf(e.role) > -1) &&
      // (selectedDepartments.length === 0 || selectedDepartments.indexOf(e.department) > -1) &&
      (selectedEmployeeStatus === '' || e.statusType === Number(selectedEmployeeStatus)) &&
      (selectedTalentCodes.length === 0 || selectedTalentCodes.indexOf(e.talentCode) > -1) &&
      (selectedRoleChangePathWay === '' ||
        e.isRoleChangePathway === (selectedRoleChangePathWay === yes)) &&
      (selectedFlightRisk === '' || e.isFlightRisk === (selectedFlightRisk === yes)) &&
      (selectedProbation === '' || e.isOnProbation === (selectedProbation === yes)) &&
      (selectedLongTermLeave === '' || e.isOnLongTermLeave === (selectedLongTermLeave === yes))
  );

  return (
    <>
      {state === 'error' ? undefined : (
        <>
          <Grid container direction={'row'} spacing={2} className={styles.filterWrapper}>
            {canViewAllSquads && (
              <Grid item xs>
                {state !== 'done' ? (
                  <Skeleton />
                ) : (
                  <MultiSelect
                    id="talent-analysis-filter-squads"
                    options={squads}
                    onChanged={setSelectedSquads}
                    label="Squads"
                    values={selectedSquad ? [selectedSquad] : []}
                  />
                )}
              </Grid>
            )}
            <Grid item xs>
              {state !== 'done' ? (
                <Skeleton />
              ) : (
                <MultiSelect
                  id="talent-analysis-filter-positions"
                  options={Array.from(
                    new Set<string>([...employees.map((e) => e.role)])
                  )}
                  onChanged={setSelectedPositions}
                  label="Positions"
                  values={selectedPositions}
                />
              )}
            </Grid>
            <Grid item xs>
              {state !== 'done' ? (
                <Skeleton />
              ) : (
                <EmployeeStatusSelect
                  id="talent-analysis-filter-employee-status"
                  label="Status"
                  onChanged={async (so) => {
                    setSelectedEmployeeStatus(so);
                    await requiresReloadEmployees();
                  }}
                  options={['', '0', '1', '2']}
                  value={selectedEmployeeStatus?.toString() ?? ''}
                />
              )}
            </Grid>
          </Grid>
          <Grid container direction={'row'} spacing={2} className={styles.filterWrapper}>
            {/* <Grid item xs>
              {state !== 'done' ? (
                <Skeleton />
              ) : (
                <MultiSelect
                  id="talent-analysis-filter-capabilities"
                  options={Array.from(
                    new Set<string>([...employees.map((e) => e.department)])
                  )}
                  onChanged={setSelectedDepartments}
                  label="Capabilities"
                  values={selectedDepartments}
                />
              )}
            </Grid> */}
            <Grid item xs>
              {state !== 'done' ? (
                <Skeleton />
              ) : (
                <MultiSelect
                  id="talent-analysis-filter-talent-codes"
                  options={talentCodes.map((t) => t.name)}
                  onChanged={async (so) => {
                    setSelectedTalentCodes(so);
                    await requiresReloadEmployees();
                  }}
                  label="Talent Codes"
                  values={selectedTalentCodes}
                />
              )}
            </Grid>
            <Grid item xs>
              {state !== 'done' ? (
                <Skeleton />
              ) : (
                <SingleSelect
                  id="talent-analysis-filter-role-change-pathway"
                  label="Role Change Pathway"
                  onChanged={async (so) => {
                    setSelectedRoleChangePathWay(so);
                    await requiresReloadEmployees();
                  }}
                  options={yesNoOptions}
                  value={selectedRoleChangePathWay ?? ''}
                />
              )}
            </Grid>
            <Grid item xs>
              {state !== 'done' ? (
                <Skeleton />
              ) : (
                <SingleSelect
                  id="talent-analysis-filter-flight-risk"
                  label="Flight Risk"
                  onChanged={async (so) => {
                    setSelectedFlightRisk(so);
                    await requiresReloadEmployees();
                  }}
                  options={yesNoOptions}
                  value={selectedFlightRisk ?? ''}
                />
              )}
            </Grid>
            <Grid item xs>
              {state !== 'done' ? (
                <Skeleton />
              ) : (
                <SingleSelect
                  id="talent-analysis-filter-long-term-leave"
                  label="Long Term Leave"
                  onChanged={setSelectedLongTermLeave}
                  options={yesNoOptions}
                  value={selectedLongTermLeave ?? ''}
                />
              )}
            </Grid>
            <Grid item xs>
              {state !== 'done' ? (
                <Skeleton />
              ) : (
                <SingleSelect
                  id="talent-analysis-filter-probation"
                  label="Probation"
                  onChanged={setSelectedProbation}
                  options={yesNoOptions}
                  value={selectedProbation ?? ''}
                />
              )}
            </Grid>
          </Grid>
        </>
      )}
      {state === 'done' && (
        <TalentAnalysisToolTable
          employees={filteredEmployees}
          onDataChanged={() => setIsDirty(true)}
        />
      )}
      {state === 'loading' && <LoadingIndicator text={'Loading employees'} />}
      {state === 'error' && (
        <Box textAlign={'center'}>This is awkward - Failed to load Talent Analysis Tool.</Box>
      )}
    </>
  );
});
