import React, { useEffect, useState } from 'react';
import { MenuItem } from '@material-ui/core';
import { useFlags } from 'hooks/useFlags';
import { CustomSelection } from 'views/components/customSelection/CustomSelection';
import { useTalentAnalysis } from 'hooks/useTalentAnalysis';
import { LoadingIndicator } from 'views/components/loadingIndicator/LoadingIndicator';
import { AlertDialog } from 'views/components/alertDialog/AlertDialog';

export const TalentCodeSelector: React.FC<{
  employeeIdpId: string;
  talentCode: string;
  employeeName: string;
  onChanged: () => void;
}> = ({ employeeIdpId, talentCode, employeeName, onChanged }) => {
  const { all, getFlag } = useFlags();
  const { updateTalentCode } = useTalentAnalysis();
  const [loadingTalentCode, setLoadingTalentCode] = useState<boolean>(true);
  const [currentTalentCode, setCurrentTalentCode] = useState<string>(talentCode);
  const [selectedTalentCode, setSelectedTalentCode] = useState<number>(-1);

  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [talentCodeToSelect, setTalentCodeToSelect] = useState<number>(-1);

  const updateTC = (talentCode: number) => {
    setTalentCodeToSelect(talentCode);
    setIsDialogOpen(true);
  };

  const onCloseDialog = () => {
    setIsDialogOpen(false);
    setTalentCodeToSelect(-1);
  };

  const onAgreeUpdate = () => {
    setLoadingTalentCode(true);
    updateTalentCode(employeeIdpId, talentCodeToSelect)
      .then(() => {
        setCurrentTalentCode(getFlag(talentCodeToSelect)?.name ?? '');
        setSelectedTalentCode(talentCodeToSelect);
        onChanged();
      })
      .finally(() => {
        setLoadingTalentCode(false);
        onCloseDialog();
      });
  };

  useEffect(() => {
    setSelectedTalentCode(all.find((flag) => flag.name === currentTalentCode)?.flagId ?? -1);
    setLoadingTalentCode(false);
  }, [all, currentTalentCode]);

  return loadingTalentCode ? (
    <LoadingIndicator />
  ) : (
    <>
      <AlertDialog
        isOpen={isDialogOpen}
        title={'Update Talent Code'}
        text={`You are about to update ${employeeName}'s talent code to: ${
          getFlag(talentCodeToSelect)?.name
        }`}
        onAgreeClick={onAgreeUpdate}
        onDisagreeClick={onCloseDialog}
      />
      <CustomSelection
        label={'Talent Code'}
        value={selectedTalentCode ?? -1}
        onChange={updateTC}
        fullWidth={true}>
        {currentTalentCode || <MenuItem value={-1}>N/A</MenuItem>}
        {all.map(({ name, flagId }) => (
          <MenuItem key={flagId} value={flagId}>
            {name}
          </MenuItem>
        ))}
      </CustomSelection>
    </>
  );
};
