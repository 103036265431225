import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControlLabel,
  Checkbox,
  Grid,
  Box,
  DialogContentText,
} from '@material-ui/core';
import { IEmployeeItem } from 'domain/store/admin/EmployeeListModel';
import { useAdmin } from 'hooks/useAdmin';
import { useStore } from 'hooks/useStore';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { LoadingIndicator } from 'views/components/loadingIndicator/LoadingIndicator';
import _ from 'lodash';

export interface AssignSquadDialogProps {
  isOpen: boolean;
  squads: string[];
  allSquads: string[];
  employee: IEmployeeItem;
  closeDialog: () => void;
  saveDialog: () => void;
}

export const AssignSquadDialog: React.FC<AssignSquadDialogProps> = observer(
  ({ isOpen, squads, allSquads, employee, closeDialog, saveDialog }) => {
    const initialAssignedSquads = employee.assignedSquads ? employee.assignedSquads : [];

    const squadSelectionChanged = () =>
      _.isEqual(_.sortBy(selectedSquads), _.sortBy(initialAssignedSquads));

    const allPossibleSquadsAreDeselected = () => !selectedSquads.some((s) => squads.includes(s));

    const {
      SquadAssignment: { assignSquads },
    } = useAdmin();

    const store = useStore();

    const [selectedSquads, setSelectedSquads] = useState<string[]>(initialAssignedSquads);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [confirmation, setConfirmation] = useState<boolean>(false);

    const handleOkClick = () => {
      setIsLoading(true);
      assignSquads({ employeeIdpId: employee.idpId, squads: selectedSquads })
        .then(() => {
          if (allPossibleSquadsAreDeselected()) {
            closeDialog();
          } else {
            saveDialog();
          }
        })
        .catch((e) => {
          setSelectedSquads(employee.assignedSquads ?? []);
          if (e?.response?.status === 409) {
            store.notifications.addError(
              'Conflict occurred saving changes, please refresh the page, and try again after.'
            );
          } else if (e?.response?.status === 403) {
            store.notifications.addError('Not authorised to save changes.');
          } else {
            store.notifications.addError('Unable to assign squads, please try again.');
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    };

    const handleCancelClick = () => {
      setSelectedSquads(employee.assignedSquads ?? []);
      closeDialog();
    };

    const addRemoveSquad = (e: React.ChangeEvent<HTMLInputElement>, squad: string) => {
      if (e.target.checked) {
        setSelectedSquads([...selectedSquads, squad]);
      } else {
        setSelectedSquads(selectedSquads.filter((s) => s !== squad));
      }
    };

    return isOpen ? (
      <Dialog
        fullWidth={true}
        maxWidth={'xs'}
        open={isOpen}
        aria-labelledby="assign-squad-dialog-title"
        aria-describedby="assign-squad-dialog-description">
        <DialogTitle id="assign-squad-dialog-title">Assign Squads</DialogTitle>
        <DialogContent>
          {!confirmation ? (
            <Grid direction="column" container>
              {allSquads.map((squad) => (
                <Grid item key={squad}>
                  <FormControlLabel
                    control={<Checkbox checked={selectedSquads.includes(squad)} />}
                    disabled={!squads.includes(squad)}
                    onChange={(event) =>
                      addRemoveSquad(event as React.ChangeEvent<HTMLInputElement>, squad)
                    }
                    label={squad}
                  />
                </Grid>
              ))}
            </Grid>
          ) : (
            <DialogContentText>
              {selectedSquads.length > 0 ? (
                <>
                  You&apos;re about to update assigned squads for &quot;{employee.name}&quot; to
                  below squads. Do you wish to continue?
                  <ul>
                    {selectedSquads.map((squad) => (
                      <li key={squad}>
                        <span>- {squad}</span>
                      </li>
                    ))}
                  </ul>
                </>
              ) : (
                <>
                  You&apos;re about to unassign all squads for &quot;{employee.name}&quot;. Do you
                  wish to continue?
                </>
              )}
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          {isLoading ? (
            <Box>
              <LoadingIndicator size={'1rem'} />
            </Box>
          ) : (
            <>
              {!confirmation ? (
                <Button
                  onClick={() => setConfirmation(true)}
                  color="primary"
                  disabled={squadSelectionChanged()}>
                  Next
                </Button>
              ) : (
                <Button onClick={handleOkClick} color="primary" disabled={squadSelectionChanged()}>
                  {allPossibleSquadsAreDeselected() ? 'Confirm' : 'Next'}
                </Button>
              )}

              <Button onClick={handleCancelClick} color="secondary">
                Cancel
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
    ) : null;
  }
);
