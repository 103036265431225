import React from 'react';
import { INote } from 'domain/store/NoteModel';
import { Link as RouterLink } from 'react-router-dom';
import {
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from '@material-ui/lab';
import {
  Card,
  CardContent,
  CardHeader,
  Chip,
  Typography,
  Link,
  Grid,
  Box,
} from '@material-ui/core';
import { Avatar } from 'views/components/avatar/Avatar';
import styles from './SquadFeedTimelineItem.module.scss';
import { SquadFeedTimelineItemContent } from 'views/routes/squad/components/squadFeed/components/SquadFeedTimelineItemContent';
import { Tag } from 'views/components/tag/Tag';
import { SquadFeedTimelineItemAction } from 'views/routes/squad/components/squadFeed/components/SquadFeedTimelineItemAction';
import { EMPLOYEE_JOURNEYS_ROUTE_PATH } from 'views/routes/Routes';
import { SensitiveAttribute } from 'views/components/sensitiveAttribute/SensitiveAttribute';
import { FaceToFaceDiscussionAttribute } from 'views/components/faceToFaceDiscussionAttribute/FaceToFaceDiscussionAttribute';

interface ISquadFeedTimelineItemProps {
  note: INote;
  connected: boolean;
  isFirstOfDate: boolean;
}

export const SquadFeedTimelineItem: React.FC<ISquadFeedTimelineItemProps> = ({
  note,
  connected,
  isFirstOfDate,
}) => {
  return (
    <TimelineItem data-testid={'note-' + note.noteId}>
      <TimelineOppositeContent className={styles.dateStamp}>
        <Typography variant={'body2'} color={'textPrimary'}>
          {isFirstOfDate &&
            (note.modifiedDateMoment ?? note.createdDateMoment).local().format('dddd')}
        </Typography>
        <Typography variant={'body2'} color={'textPrimary'} data-testid="timeline-date">
          {isFirstOfDate &&
            (note.modifiedDateMoment ?? note.createdDateMoment).local().format('DD MMMM')}
        </Typography>
      </TimelineOppositeContent>
      <TimelineSeparator>
        <TimelineDot color={'primary'} />
        {connected && <TimelineConnector />}
      </TimelineSeparator>
      <TimelineContent>
        <Card>
          <Box mx="auto" width="95%">
            <CardHeader
              avatar={
                <Avatar
                  className={styles.avatar}
                  givenName={note.employee.givenName}
                  familyName={note.employee.familyName}
                  idpId={note.employee.idpId}
                />
              }
              title={
                <Grid
                  container
                  direction={'row'}
                  justify={'space-between'}
                  alignItems={'flex-start'}>
                  <Grid item xs>
                    <Grid container direction={'column'}>
                      <Grid item container alignItems={'center'}>
                        <Typography variant={'h5'} component={'span'} className={styles.subject}>
                          {note.subject}
                          {note.isPeopleLeaderConversation === true && (
                            <Chip
                              className={styles.plChat}
                              color="secondary"
                              variant="default"
                              label={'PL CHAT'}
                            />
                          )}
                        </Typography>
                      </Grid>
                      <Grid item className={styles.nameContainer}>
                        <Grid container spacing={2} alignItems={'baseline'}>
                          <Grid item>
                            <Typography component={'span'}>
                              <Link
                                variant="inherit"
                                color="textPrimary"
                                component={RouterLink}
                                to={`${EMPLOYEE_JOURNEYS_ROUTE_PATH}/${note.employee.idpId}`}>
                                {note.employee.displayName}
                              </Link>
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography className="date" color={'textSecondary'} component={'span'}>
                              {note.eventDateMoment!.local().format('DD/MM/YYYY')}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs className={styles.tagContainer}>
                        {note.tags.map((tag, index) => (
                          <Tag
                            key={tag.tagId}
                            name={tag.name}
                            isSensitive={tag.isSensitive}
                            reviewRequired={tag.reviewRequired}
                          />
                        ))}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid container direction={'column'}>
                      <Grid item>
                        <Box textAlign="right">
                          <Typography
                            variant={'subtitle2'}
                            color="textSecondary"
                            component={'span'}>
                            Author: {note.author.displayName}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item>
                        <Box textAlign="right">
                          <Typography
                            color="textSecondary"
                            variant={'subtitle2'}
                            component={'span'}>
                            Last modified: {note.modifiedDateMoment!.local().fromNow()}
                          </Typography>
                        </Box>
                      </Grid>
                      <FaceToFaceDiscussionAttribute
                        isFaceToFaceDiscussion={!!note.isFaceToFaceDiscussion}
                      />
                      {note.sensitivity === 'sensitive' && (
                        <SensitiveAttribute isSensitive={true} />
                      )}
                      {note.sensitivity === 'non-sensitive' && (
                        <SensitiveAttribute isSensitive={false} />
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              }
              action={<SquadFeedTimelineItemAction note={note} />}
            />
            <CardContent>
              <Typography variant="body1" color="textPrimary" component="div">
                <SquadFeedTimelineItemContent content={note.content} />
              </Typography>
            </CardContent>
          </Box>
        </Card>
      </TimelineContent>
    </TimelineItem>
  );
};
