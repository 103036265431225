import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useUser } from 'hooks/useUser';
import { useTrends } from 'hooks/useTrends';
import { Bar } from 'react-chartjs-2';
import { Skeleton } from '@material-ui/lab';
import { ColourSet, generateColours } from '../../ColourGenerator';
import { CustomColorCheckBox } from '../customColorCheckBox/CustomColorCheckBox';
import styles from './TalentCodesByMonthChart.module.scss';
import { ChartData } from 'chart.js';

const CHART_OPTIONS = {
  responsive: true,
  maintainAspectRatio: false,
  legend: {
    display: false,
  },
  scales: {
    xAxes: [
      {
        stacked: true,
        gridLines: {
          display: true,
          drawBorder: true,
          drawOnChartArea: false,
          drawTicks: false,
          lineWidth: 1,
        },
        ticks: {
          padding: 10,
        },
      },
    ],
    yAxes: [
      {
        stacked: true,
        display: true,
      },
    ],
  },
};

export const TalentCodesByMonthChart: React.FC = observer(() => {
  const { selectedSquad } = useUser();
  const {
    talentCodeTrendsByMonth: { state, value, loadTalentCodeTrendsByMonth },
  } = useTrends();

  // eslint-disable-next-line
  const [talentCodes, setTalentCodes] = useState([] as any[]);
  const [chartData, setChartData] = useState({} as ChartData);

  useEffect(() => {
    loadTalentCodeTrendsByMonth(selectedSquad).then((talentCodeTrends) => {
      const colours = generateColours(talentCodeTrends.labels.length, ColourSet.Blue);
      setTalentCodes(
        talentCodeTrends.labels.map((label, index) => {
          const talentCodeCounts = [] as number[];
          talentCodeTrends.data.forEach((d) => {
            const counts = d.counts.find((c) => c.name === label);
            talentCodeCounts.push(counts ? counts.count : 0);
          });

          return {
            name: label,
            checked: false,
            dataSet: {
              label: label,
              backgroundColor: colours[index],
              data: talentCodeCounts,
            },
          };
        })
      );
    });
  }, [loadTalentCodeTrendsByMonth, selectedSquad]);

  useEffect(() => {
    setChartData({
      labels: value.data.map((d) => d.month),
      datasets: talentCodes.map((talentCode) => talentCode.dataSet),
    });
  }, [talentCodes, value]);

  const handleOnchange = (index: number) => {
    setTalentCodes((talentCodes) => {
      talentCodes[index].checked = !talentCodes[index].checked;
      return talentCodes;
    });

    setChartData((chartData: ChartData) => {
      let dataSets = talentCodes
        .filter((talentCode) => talentCode.checked)
        .map((code) => code.dataSet);
      if (dataSets.length === 0) {
        dataSets = talentCodes.map((code) => code.dataSet);
      }
      return {
        ...chartData,
        datasets: dataSets,
      };
    });
  };

  return (
    <div className={styles.root}>
      {state === 'done' ? (
        <>
          <div>
            <Bar data={chartData} options={CHART_OPTIONS} />
          </div>
          <div>
            {talentCodes.map((talentCode, index) => {
              return (
                <CustomColorCheckBox
                  key={'talentCodeCheckBox_' + index.toString()}
                  index={index}
                  color={talentCode.dataSet.backgroundColor}
                  label={talentCode.name}
                  checked={talentCode.checked}
                  onChange={handleOnchange}
                />
              );
            })}
          </div>
        </>
      ) : (
        <Skeleton variant={'rect'} classes={{ root: styles.skeleton }} />
      )}
    </div>
  );
});
