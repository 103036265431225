import { FormControl, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import { IEmployeeJourney } from 'domain/store/employeeJourney/EmployeeJourneyModel';
import {
  IEmployeeStatusModel,
  IUpdateStatusRequest,
} from 'domain/store/employeeJourney/EmployeeStatusModel';
import React, { useEffect, useState } from 'react';
import { AlertDialog } from 'views/components/alertDialog/AlertDialog';
import { EmployeeStatus } from 'views/components/EmployeeStatus/EmployeeStatus';
export interface IStatusFlagProps {
  employeeId: string;
  employeeDisplayName: string;
  employeeStatusModel: IEmployeeStatusModel | null;
  journey: IEmployeeJourney;
  disabled: boolean;
}

export const StatusFlag: React.FC<IStatusFlagProps> = ({
  employeeStatusModel,
  employeeId,
  employeeDisplayName,
  journey,
  disabled,
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [status, setStatus] = useState<string>('0');

  const { employee } = journey;

  const employeeStatus = employeeStatusModel?.value?.status ?? 0;

  useEffect(() => {
    if (!isLoading) {
      setStatus(employeeStatus.toString());
    }
  }, [isLoading, employeeStatus]);

  const toggleStatus = () => {
    setIsDialogOpen(true);
  };

  const resetStatusDialogStates = () => {
    setIsDialogOpen(false);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStatus(event.target.value);
  };

  const onStatusAgree = () => {
    const statusRequest: IUpdateStatusRequest = {
      employeeIdpId: employeeId,
      status: parseInt(status),
    };

    setIsLoading(true);
    employee!.flags.status?.updateEmployeeStatus(statusRequest).finally(() => setIsLoading(false));
    resetStatusDialogStates();
  };

  const onStatusDisagree = () => {
    resetStatusDialogStates();
  };

  return (
    <>
      <EmployeeStatus
        employeeStatusModelValue={employeeStatusModel?.value || null}
        onToggle={toggleStatus}
        loading={isLoading}
        disabled={disabled}
      />
      <AlertDialog
        isOpen={isDialogOpen}
        title={`Change status for ${employeeDisplayName}`}
        content={
          <>
            <FormControl component="fieldset">
              <RadioGroup aria-label="status" name="status" value={status} onChange={handleChange}>
                <FormControlLabel value={'0'} control={<Radio />} label="No concern" />
                <FormControlLabel
                  value={'1'}
                  control={<Radio />}
                  label="Few things to keep an eye on"
                />
                <FormControlLabel value={'2'} control={<Radio />} label="Needs close attention" />
              </RadioGroup>
            </FormControl>
          </>
        }
        onAgreeClick={onStatusAgree}
        agreeButtonText={'Save'}
        agreeDisabled={parseInt(status) === employeeStatusModel?.value?.status}
        onDisagreeClick={onStatusDisagree}
        disagreeButtonText={'Cancel'}
      />
    </>
  );
};
