import React, { useState } from 'react';
import { ISquadUser } from 'domain/store/UserModel';
import { IconButton, Link, Menu, MenuItem, TableCell, TableRow } from '@material-ui/core';
import { Avatar } from 'views/components/avatar/Avatar';
import { Link as RouterLink } from 'react-router-dom';
import { CREATE_NOTE_ROUTE_PATH, EMPLOYEE_JOURNEYS_ROUTE_PATH } from 'views/routes/Routes';
import { Skeleton } from '@material-ui/lab';
import styles from './EmployeeJourneysRow.module.scss';
import { Create, MoreVert, Person } from '@material-ui/icons';
import { useNavigate } from 'hooks/useNavigate';

interface EmployeeJourneysRowProps {
  employee: ISquadUser | null;
}

export const EmployeeJourneysRow: React.FC<EmployeeJourneysRowProps> = ({ employee }) => {
  const navigate = useNavigate();
  const [menuEl, setMenuEl] = useState<Element | null>(null);
  const handleClose = () => setMenuEl(null);

  return (
    <TableRow className={styles.root}>
      <TableCell className={styles.nameCell}>
        <div className={styles.nameCellRoot}>
          <div className={styles.avatarContainer}>
            <Avatar
              className={styles.avatar}
              idpId={employee?.idpId}
              givenName={employee?.displayName.split(' ')[0]}
              familyName={employee?.displayName.split(' ')[1]}
            />
          </div>
          <div style={{ width: '100%' }}>
            {employee ? (
              <Link
                component={RouterLink}
                to={`${EMPLOYEE_JOURNEYS_ROUTE_PATH}/${employee?.idpId}`}>
                {employee.displayName}
              </Link>
            ) : (
              <Skeleton width={'50%'} />
            )}
          </div>
        </div>
      </TableCell>
      <TableCell className={styles.positionCell}>
        {employee ? <>{employee?.position}</> : <Skeleton width={'50%'} />}
      </TableCell>
      {/* <TableCell className={styles.positionCell}>
        {employee ? <>{employee?.capability}</> : <Skeleton width={'50%'} />}
      </TableCell> */}
      <TableCell>
        {employee ? (
          <>
            <IconButton
              aria-controls={`more-${employee.idpId}-menu`}
              aria-haspopup={'true'}
              onClick={(e) => {
                setMenuEl(e.currentTarget);
              }}>
              <MoreVert />
            </IconButton>

            <Menu
              id={`more-${employee.idpId}-menu`}
              anchorEl={menuEl}
              anchorOrigin={{ vertical: 'center', horizontal: 'left' }}
              keepMounted
              open={!!menuEl}
              onClose={handleClose}>
              <MenuItem
                onClick={() => {
                  handleClose();
                  navigate(`${EMPLOYEE_JOURNEYS_ROUTE_PATH}/${employee.idpId}`);
                }}>
                <Person />
                View Profile
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleClose();
                  navigate(CREATE_NOTE_ROUTE_PATH, { employeeId: employee.idpId });
                }}>
                <Create />
                Add Note
              </MenuItem>
            </Menu>
          </>
        ) : (
          <Skeleton variant={'rect'} />
        )}
      </TableCell>
    </TableRow>
  );
};
