import { detach, Instance, types } from 'mobx-state-tree';

import { flow, getEnv, LoadingStateModel } from 'domain/store/util';
import { AuthenticationProvider, Client, ResponseType } from '@microsoft/microsoft-graph-client';
import { AuthenticationProviderOptions } from '@microsoft/microsoft-graph-client/src/IAuthenticationProviderOptions';
import {
  createEmployee,
  EmployeeModel,
  IEmployee,
} from 'domain/store/employeeJourney/EmployeeModel';
import { api } from 'shared/api/interfaces';
import { errorLog } from 'index';

export interface EmployeeSearchResult {
  idpId: string;
  displayName: string;
  isFormerEmployee?: boolean;
}

const LoadEmployeeModel = types
  .model('LoadEmployeeModel', {
    employee: types.maybeNull(EmployeeModel),
    state: LoadingStateModel,
  })
  .actions((self) => ({
    reset: function () {
      self.employee = null;
      self.state = 'loading';
    },
    searchForEmployee: flow(function* (
      employeeIdpId?: string
    ): Generator<Promise<IEmployee>, IEmployee | null, IEmployee> {
      const { ajax } = getEnv(self);

      if (self.state === 'done' && self.employee?.idpId === employeeIdpId) {
        return self.employee;
      }

      self.state = 'loading';
      if (self.employee) {
        detach<IEmployee>(self.employee);
      }
      self.employee = null;

      if (employeeIdpId) {
        try {
          const result = yield ajax
            .get('Employee', { searchParams: { employeeIdpId } })
            .json<api.EmployeeDetailsDto>()
            .then((r) => createEmployee(r));

          self.employee = result;
          self.state = 'done';
          return result;
        } catch (e) {
          if (e.response && e.response.status === 403) {
            self.state = 'unauthorized';
          } else {
            errorLog('Failed to get employee.', e);
            self.state = 'error';
          }
        }
      } else {
        self.employee = null;
      }
      return null;
    }),
  }));

const PhotoModel = types.model({
  id: types.identifier,
  uri: types.maybeNull(types.string),
});

export const GraphModel = types
  .model('GraphModel', {
    loadEmployee: types.optional(LoadEmployeeModel, {}),
    photos: types.map(PhotoModel),
  })
  .actions((self) => {
    function* searchForEmployees(
      query: string,
      includeFormerEmployees = false
    ): Generator<Promise<EmployeeSearchResult[]>> {
      const { ajax } = getEnv(self);

      return yield ajax
        .get(`Employee/${'search'}`, {
          searchParams: { query, includeFormerEmployees },
        })
        .json<EmployeeSearchResult[]>();
    }

    function* searchForEmployeePhoto(idpId: string): Generator<Promise<string | null>> {
      const { auth } = getEnv(self);
      const graph = Client.initWithMiddleware({
        authProvider: new (class implements AuthenticationProvider {
          async getAccessToken(options?: AuthenticationProviderOptions): Promise<string> {
            return await auth.getGraphAccessToken(options?.scopes);
          }
        })(),
        debugLogging: false,
      });

      if (self.photos.get(idpId)) return self.photos.get(idpId)!.uri;

      try {
        const img = yield graph
          .api(`/users/${idpId}/photos/120x120/$value`)
          .version('beta')
          .responseType(ResponseType.BLOB)
          .get();

        const blobUrl = (window.URL || window.webkitURL).createObjectURL(img);
        self.photos.put({ id: idpId, uri: blobUrl });
        return blobUrl;
      } catch {
        self.photos.put({ id: idpId, uri: null });
        return null;
      }
    }

    return {
      searchForEmployees: flow(searchForEmployees),
      searchForEmployeePhoto: flow(searchForEmployeePhoto),
    };
  });

export interface IGraphModel extends Instance<typeof GraphModel> {}
