import { TableCell, TableHead, TableRow, TableSortLabel } from '@material-ui/core';
import React from 'react';
import { Order } from './SortHelper';

export interface HeadCell {
  id: string;
  label: string;
  isSortable: boolean;
}

interface ISortTableHeaderProps {
  // eslint-disable-next-line
  onRequestSort: (event: React.MouseEvent<unknown>, property: any) => void;
  order: Order;
  orderBy: string;
  labels: HeadCell[];
}

export const SortTableHeader: React.FC<ISortTableHeaderProps> = ({
  onRequestSort,
  order,
  orderBy,
  labels,
}) => {
  // eslint-disable-next-line
  const createSortHandler = (property: any) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {labels.map((l) =>
          l.isSortable ? (
            <TableCell key={l.id} sortDirection={orderBy === l.id ? order : false}>
              <TableSortLabel
                active={orderBy === l.id}
                direction={orderBy === l.id ? order : 'asc'}
                onClick={createSortHandler(l.id)}>
                {l.label}
              </TableSortLabel>
            </TableCell>
          ) : (
            <TableCell key={l.id}>{l.label}</TableCell>
          )
        )}
      </TableRow>
    </TableHead>
  );
};
