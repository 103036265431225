import React from 'react';
import styles from './SquadCatchUpItemSkeleton.module.scss';
import { Box, TableCell, TableRow } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { Avatar } from 'views/components/avatar/Avatar';

export const SquadCatchUpItemSkeleton: React.FC = () => {
  return (
    <TableRow>
      <TableCell>
        <Box display="flex" flexDirection="row" alignItems="center">
          <Avatar className={styles.avatar} />
          <Skeleton width={'50%'} />
        </Box>
      </TableCell>
      <TableCell>
        <Skeleton width={'50%'} />
      </TableCell>
      <TableCell>
        <Box display="flex" flexDirection="row" alignItems="center">
          <Avatar className={styles.avatar} />
          <Skeleton width={'50%'} />
        </Box>
      </TableCell>
      <TableCell>
        <Skeleton className={styles.skeleton} width={'50%'} />
      </TableCell>
    </TableRow>
  );
};
