import React from 'react';
import styles from './FlagChart.module.scss';
import { EmployeeListPopper } from '../employeeListPopper/EmployeeListPopper';
import { useEmployeeListPopper } from 'hooks/useEmployeeListPopper';
import { ITrendsEmployeesItem } from 'domain/store/trends/TrendsEmployeesModel';

interface IFlagItemProps {
  color: string;
  flag: string;
  employees: ITrendsEmployeesItem[];
  isRoleChangePathway?: boolean;
}

export const FlagItem: React.FC<IFlagItemProps> = (props) => {
  const popper = useEmployeeListPopper();

  return (
    <>
      <button
        className={styles.square}
        style={{ background: props.color }}
        onClick={popper.handleClick}>
        <div className={styles.flagItemContainer}>
          <span className={styles.number}>{props.employees.length}</span>
          <span>{props.flag}</span>
        </div>
      </button>
      <EmployeeListPopper
        anchorEl={popper.anchorEl}
        handleClose={popper.handleClose}
        color={props.color}
        title={props.flag}
        employees={props.employees}
        isRoleChangePathway={props.isRoleChangePathway}
      />
    </>
  );
};
