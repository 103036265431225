import React from 'react';
import styles from './FaceToFaceDiscussionAttribute.module.scss';
import { Typography, Grid, Box } from '@material-ui/core';
import ForumIcon from '@material-ui/icons/Forum';

interface IFaceToFaceDiscussionAttributeProps {
  isFaceToFaceDiscussion: boolean;
}

export const FaceToFaceDiscussionAttribute: React.FC<IFaceToFaceDiscussionAttributeProps> = ({
  isFaceToFaceDiscussion,
}) => {
  return (
    <Grid item className={styles.root}>
      <Box textAlign="right">
        <span className={styles.attribute}>
          <ForumIcon color="primary" fontSize="inherit" />
          <Typography color="primary">
            {isFaceToFaceDiscussion
              ? 'Direct conversation with the employee'
              : 'Feedback/observations about the employee'}
          </Typography>
        </span>
      </Box>
    </Grid>
  );
};
