import { Instance, types } from 'mobx-state-tree';
import { api } from 'shared/api/interfaces';
import { flow, getEnv, LoadingStateModel } from '../util';
import { MonthlyTrendModel } from './MonthlyTrendModel';
import { makeTrendsUrl } from './TrendsModel';
import moment from 'moment';
import { errorLog } from 'index';

interface ISupportingOpportunityByMonth extends Instance<typeof MonthlyTrendModel> {}

export const SupportingOpportunitiesByMonthModel = types
  .model('SupportingOpportunitiesByMonth', {
    value: types.optional(MonthlyTrendModel, {}),
    state: LoadingStateModel,
  })
  .actions((self) => ({
    loadSupportingOpportunitiesByMonth: flow(function* (
      selectedSquad: string | null,
      year?: number
    ): Generator<
      Promise<ISupportingOpportunityByMonth>,
      ISupportingOpportunityByMonth,
      ISupportingOpportunityByMonth
    > {
      if (selectedSquad) {
        const { ajax } = getEnv(self);

        const endDate = moment();
        if (year) endDate.set({ year: year });

        self.state = 'loading';
        try {
          self.value = yield ajax
            .get(makeTrendsUrl('SupportingOpportunitiesByMonth'), {
              searchParams: { squadName: selectedSquad, endDate: endDate.toDate().toDateString() },
            })
            .json<api.MonthlyTrendsDto>()
            .then((trend) => MonthlyTrendModel.create(trend));
          self.state = 'done';
        } catch (e) {
          errorLog('Failed to load supporting opportunities by month data', e);
          self.state = 'error';
        }
      }
      return self.value;
    }),
  }));
