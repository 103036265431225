import React from 'react';
import styles from './SensitiveAttribute.module.scss';
import { Typography, Grid, Box } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

interface ISensitiveAttributeProps {
  isSensitive: boolean;
}

export const SensitiveAttribute: React.FC<ISensitiveAttributeProps> = ({ isSensitive }) => {
  return (
    <Grid item className={styles.root}>
      <Box textAlign="right">
        <span className={styles.attribute}>
          {isSensitive ? (
            <Visibility color="primary" fontSize="inherit" />
          ) : (
            <VisibilityOff color="primary" fontSize="inherit" />
          )}
          <Typography color="primary">{isSensitive ? 'Sensitive' : 'Non-Sensitive'}</Typography>
        </span>
      </Box>
    </Grid>
  );
};
