import { IAuthenticationProvider } from 'auth/AuthenticationTypes';
import ky from 'ky';
import { Instance, types } from 'mobx-state-tree';
import { EmployeeJourneyModel } from './employeeJourney/EmployeeJourneyModel';
import { FlagsModel } from './FlagsModel';
import { GraphModel } from './singletons/GraphModel';
import { NotificationModel } from './singletons/NotificationModel';
import { SecurityModel } from './singletons/SecurityModel';
import { TagsModel } from './TagsModel';
import { TrendsModel } from './trends/TrendsModel';
import { createUser, IUser, UserModel } from './UserModel';
import { flow, getDefaultAjaxInstance, getEnv, LoadingStateModel } from './util';
import { TalentAnalysisToolModel } from './talentAnalysisTool/TalentAnalysisToolModel';
import { AdminModel } from './admin/AdminModel';
import { CatchUpsModel } from './catchUps/CatchUpsModel';
import { PagingCacheModel } from './PagingCacheModel';
import { DEFAULT_PAGE_NUMBER, DEFAULT_ROWS_PER_PAGE } from 'views/ViewConstants';
import { SquadCatchUpsModel } from './catchUps/SquadCatchUpsModel';
import { api } from 'shared/api/interfaces';
import { errorLog } from 'index';
import { RoleChangeModel } from './employeeJourney/RoleChangeModel';

const RootStoreModel = types
  .model(`RootStoreModel`, {
    graph: types.optional(GraphModel, {}),
    security: types.optional(SecurityModel, {}),
    notifications: types.optional(NotificationModel, {}),
    tags: types.optional(TagsModel, {}),
    flags: types.optional(FlagsModel, {}),
    user: types.optional(UserModel, {}),
    employeeJourney: types.optional(EmployeeJourneyModel, {}),
    state: LoadingStateModel,
    trends: types.optional(TrendsModel, {}),
    talentAnalysis: types.optional(TalentAnalysisToolModel, {}),
    admin: types.optional(AdminModel, {}),
    catchUpGroups: types.optional(CatchUpsModel, {}),
    squadCatchUps: types.optional(SquadCatchUpsModel, {}),
    pagingCache: types.optional(PagingCacheModel, {
      currentPageCache: DEFAULT_PAGE_NUMBER,
      rowsPerPageCache: DEFAULT_ROWS_PER_PAGE,
    }),
    roleChange: types.optional(RoleChangeModel, {}),
  })
  .actions((self) => ({
    afterCreate: flow(function* (): Generator<Promise<IUser>, void, IUser> {
      const { ajax } = getEnv(self);

      try {
        self.user = yield ajax
          .get('User')
          .json<api.LoggedInUserDto>()
          .then((user) => createUser(user));
        self.state = 'done';
      } catch (e) {
        errorLog('GET user failed.', e);
        const k = e as ky.HTTPError;
        if (k.response && k.response.status === 403) {
          self.state = 'unauthorized';
        } else {
          self.state = 'error';
        }
      }
    }),
  }))
  .views((self) => ({
    get isLoading() {
      return self.state === 'loading';
    },
  }));

export interface IRootStoreModel extends Instance<typeof RootStoreModel> {}

export interface IRootStoreEnvironment {
  ajax: typeof ky;
  auth: IAuthenticationProvider;
}

export function getDefaultStore(auth: IAuthenticationProvider): IRootStoreModel {
  const ajax = getDefaultAjaxInstance(auth);

  return RootStoreModel.create({ state: 'loading' }, { auth, ajax });
}
