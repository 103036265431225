import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CircularProgress, MenuItem, Select } from '@material-ui/core';
import React, { useState } from 'react';
import styles from './FrequencySelector.module.scss';
import { faChevronCircleDown } from '@fortawesome/free-solid-svg-icons';

interface Frequency {
  value: number;
  text: string;
}

export interface FrequencySelectorProps {
  showSelectOption?: boolean;
  value: number;
  isUpdatingFrequency?: boolean;
  onFrequencyChange: (frequencyId: number) => void;
}

export const FrequencySelector: React.FC<FrequencySelectorProps> = ({
  showSelectOption,
  value,
  isUpdatingFrequency,
  onFrequencyChange,
}) => {
  const [selectedFrequency, setSelectedFrequency] = useState<number>(value);

  const frequencies: Frequency[] = Array.from({ length: 8 }, (_, k) => k + 1).map((i) => ({
    value: i,
    text: `${i} ${i === 1 ? 'Week' : 'Weeks'}`,
  }));

  const handleFrequencyChange = (frequency: number) => {
    setSelectedFrequency(frequency);
    onFrequencyChange(frequency);
  };

  return (
    <Select
      variant="outlined"
      value={selectedFrequency}
      onChange={(event) => handleFrequencyChange(event.target.value as number)}
      disabled={isUpdatingFrequency}
      className={styles.select}
      IconComponent={(props) => {
        const propsWithDropdownIcon = {
          ...props,
          className: `${props.className} ${styles.dropdownIcon} `,
        };
        return (
          <div className={styles.dropdownIconContainer}>
            {isUpdatingFrequency ? (
              <CircularProgress className={styles.loadingIndicator} size={'1rem'} />
            ) : (
              <FontAwesomeIcon {...propsWithDropdownIcon} icon={faChevronCircleDown} />
            )}
          </div>
        );
      }}>
      {showSelectOption && <MenuItem value={0}>Select</MenuItem>}
      {frequencies.map((f) => (
        <MenuItem value={f.value} key={f.value}>
          {f.text}
        </MenuItem>
      ))}
    </Select>
  );
};
