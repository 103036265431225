import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, IconButton, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import {
  EmployeeStatusLabels,
  IEmployeeStatusModelValue,
} from 'domain/store/employeeJourney/EmployeeStatusModel';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { UpdatedByInfoIcon } from 'views/routes/employeeJourney/components/employeeSidebar/components/updatedByInfoIcon/UpdatedByInfoIcon';
import styles from './EmployeeStatus.module.scss';
import { StatusIcon } from './StatusIcon/StatusIcon';

export interface IEmployeeStatusProps {
  employeeStatusModelValue: IEmployeeStatusModelValue | null;
  loading: boolean;
  onToggle?: () => void;
  disabled: boolean;
}

export const EmployeeStatus = observer((props: IEmployeeStatusProps) => {
  return (
    <Box className={styles.employeeStatus} display={'flex'} flexDirection={'column'}>
      <Box
        display={'flex'}
        alignItems={'center'}
        className={styles.heading}
        fontWeight="fontWeightMedium"
        component={'span'}>
        <Typography color={'textPrimary'} variant={'subtitle1'} component={'div'}>
          Status
        </Typography>
        <IconButton
          color="default"
          onClick={() => props.onToggle && props.onToggle()}
          data-testid="edit-status"
          className={styles.editButton}
          disabled={props.disabled}>
          <FontAwesomeIcon icon={faEdit} size={'xs'} />
        </IconButton>
        {!props.loading && props.employeeStatusModelValue && (
          <>
            <UpdatedByInfoIcon
              updatedByDisplayName={props.employeeStatusModelValue.modifiedBy.displayName}
              modifiedDateTime={props.employeeStatusModelValue.modifiedDateTime}
            />
          </>
        )}
      </Box>
      <Box fontWeight="fontWeightLight" component={'span'}>
        {!props.loading ? (
          <div className={styles.ratingContainer}>
            <StatusIcon
              color={props.employeeStatusModelValue?.status ?? 0}
              status={EmployeeStatusLabels[props.employeeStatusModelValue?.status ?? 0]}
            />
          </div>
        ) : (
          <Skeleton variant={'text'} />
        )}
      </Box>
    </Box>
  );
});
