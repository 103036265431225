import React, { useState, useEffect } from 'react';
import { RoleChangePathwayControl } from 'views/routes/employeeJourney/components/employeeSidebar/components/roleChangePathway/RoleChangePathwayControl';
import { DialogWithDropdown } from './DialogWithDropdown';
import { IRoleChangePathwayModel } from 'domain/store/employeeJourney/RoleChangePathwayModel';

export interface RoleChangePathwayFlagProps {
  employeeId: string;
  employeeDisplayName: string;
  roleChangePathwayModel: IRoleChangePathwayModel | null;
  disabled: boolean;
}

export const RoleChangePathwayFlag: React.FunctionComponent<RoleChangePathwayFlagProps> = ({
  roleChangePathwayModel,
  employeeId,
  employeeDisplayName,
  disabled,
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const timeframes = [6, 12, 18];
  const employeeTimeframe = roleChangePathwayModel?.value?.timeframe ?? 0;
  const [currentTimeframe, setCurrentTimeframe] = useState<number>(employeeTimeframe);
  const filterZero = (source: number) => {
    return source === 0 ? timeframes[0] : source;
  };
  const [newTimeframe, setNewNumberOfMonth] = useState<number>(filterZero(currentTimeframe));

  useEffect(() => {
    setCurrentTimeframe(employeeTimeframe);
    setNewNumberOfMonth(filterZero(employeeTimeframe));
  }, [employeeTimeframe]); // eslint-disable-line react-hooks/exhaustive-deps

  const toggle = () => {
    setIsDialogOpen(true);
  };

  const resetDialogStates = () => {
    setNewNumberOfMonth(filterZero(currentTimeframe));
    setIsDialogOpen(false);
  };

  const onSelectChangeInDialog = (event: React.ChangeEvent<{ value: unknown }>) => {
    setNewNumberOfMonth(event.target.value as number);
  };

  const onSelectChangeOnSidebar = (event: React.ChangeEvent<{ value: unknown }>) => {
    setNewNumberOfMonth(event.target.value as number);
    setIsDialogOpen(true);
  };

  const onAgreeClick = () => {
    setIsLoading(true);

    roleChangePathwayModel
      ?.toggleRoleChangePathwayStatus(
        employeeId,
        newTimeframe === currentTimeframe ? 0 : newTimeframe
      )
      .finally(() => setIsLoading(false));

    resetDialogStates();
  };

  const onDisagreeClick = () => {
    resetDialogStates();
  };

  return (
    <>
      <RoleChangePathwayControl
        roleChangePathwayModelValue={roleChangePathwayModel?.value || null}
        onToggle={toggle}
        loading={isLoading}
        timeframes={timeframes}
        onSelectChangeOnSidebar={onSelectChangeOnSidebar}
        disabled={disabled}
      />
      <DialogWithDropdown
        isOpen={isDialogOpen}
        title={'Role Change Pathway Change'}
        onAgreeClick={onAgreeClick}
        onDisagreeClick={onDisagreeClick}
        oldNewTimeframe={[currentTimeframe, newTimeframe]}
        onSelectChange={onSelectChangeInDialog}
        timeframes={timeframes}
        employeeDisplayName={employeeDisplayName}
      />
    </>
  );
};
