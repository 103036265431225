import React, { useState } from 'react';
import styles from './NoteCard.module.scss';
import { Avatar } from 'views/components/avatar/Avatar';
import { INote } from 'domain/store/NoteModel';
import {
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import { NoteStatusChip } from 'views/routes/myNotes/components/listNotes/components/NoteStatusChip';
import { AlertDialog } from 'views/components/alertDialog/AlertDialog';

interface INoteCardProps {
  note: INote;
  selected: boolean;
  onClick: () => void;
  onDelete?: (noteId: number) => void;
  divider: boolean;
}

export const NoteCard: React.FC<INoteCardProps> = ({
  note,
  selected,
  onClick,
  onDelete,
  divider,
}) => {
  const [showAlertDialog, setShowAlertDialog] = useState(false);

  const onDisagreeClick = () => {
    setShowAlertDialog(false);
  };

  const onDeleteButtonClick = () => {
    setShowAlertDialog(true);
  };

  const onAgreeClick = () => {
    setShowAlertDialog(false);
    onDelete && onDelete(note.noteId);
  };

  return (
    <>
      <ListItem
        ContainerProps={{ className: styles.card }}
        className={styles.card}
        data-testid={'list-notes-card'}
        selected={selected}
        onClick={(e) =>
          (e.target as Element).namespaceURI !== 'http://www.w3.org/2000/svg' && onClick()
        }
        alignItems={'flex-start'}
        divider={divider}
        button={true}>
        <ListItemAvatar>
          <Avatar
            className={styles.avatarContainer}
            idpId={note.employee.idpId}
            givenName={note.employee.givenName}
            familyName={note.employee.familyName}
          />
        </ListItemAvatar>
        <ListItemText<'span', 'span'>
          primary={
            <span className={styles.subjectContainer}>
              <Typography className={styles.subjectTitle} component={'span'}>
                {note.subject} <NoteStatusChip note={note} />
              </Typography>
              <Typography
                className={styles.modifiedDate}
                color={'textSecondary'}
                component={'span'}>
                {(note.modifiedDateMoment ?? note.createdDateMoment).fromNow()}
              </Typography>
            </span>
          }
          secondaryTypographyProps={{ component: 'span' }}
          secondary={
            <>
              <div>{note.employee.displayName}</div>
              <div>{note.eventDateMoment!.format('DD/MM/YY')}</div>
              {note.canDelete && (
                <ListItemSecondaryAction className={styles.deleteContainer}>
                  <IconButton edge={'end'} aria-label={'delete'} onClick={onDeleteButtonClick}>
                    <Delete />
                  </IconButton>
                </ListItemSecondaryAction>
              )}
            </>
          }
        />
      </ListItem>
      <AlertDialog
        isOpen={showAlertDialog}
        title={'Delete note'}
        text={
          "Deleting this note will remove the note from the employee's wall. Do you wish to continue?"
        }
        onAgreeClick={onAgreeClick}
        onDisagreeClick={onDisagreeClick}
      />
    </>
  );
};
