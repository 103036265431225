import { MenuItem, FormControl, InputLabel, Select } from '@material-ui/core';
import React from 'react';
import { observer } from 'mobx-react-lite';

interface SingleSelectProps {
  id: string;
  options: string[];
  onChanged: (selectedOptions: string) => void;
  label: string;
  value?: string;
}

export const SingleSelect: React.FC<SingleSelectProps> = observer((props: SingleSelectProps) => {
  return (
    <FormControl variant={'outlined'} fullWidth={true}>
      <InputLabel id={props.id}>{props.label}</InputLabel>
      <Select
        fullWidth={true}
        labelId={props.id}
        label={props.label}
        onChange={(event) => props.onChanged(event.target.value as string)}
        value={props.value}>
        {props.options.map((option) => (
          <MenuItem key={option} value={option}>
            {option || 'Any'}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
});
