import React from 'react';
import styles from './UserProfile.module.scss';
import cn from 'classnames';
import { Avatar } from 'views/components/avatar/Avatar';
import { useUser } from 'hooks/useUser';
import { useSecurity } from 'hooks/useSecurity';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, ListItem, Menu, MenuItem } from '@material-ui/core';
import { siteDefault } from 'views/themes';

interface UserProfileProps {
  className?: string;
}

export const UserProfile: React.FC<UserProfileProps> = ({ className }) => {
  const { idpId, givenName, familyName, squadName } = useUser();
  const { signOut } = useSecurity();
  const [menuAnchorElement, setMenuAnchorElement] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) =>
    setMenuAnchorElement(event.currentTarget);
  const handleClose = () => setMenuAnchorElement(null);
  const squadDisplayName =
    squadName?.toLowerCase().indexOf('squad') === -1 ? `${squadName} Squad` : squadName;

  return (
    <div className={cn(styles.root, className)}>
      <Button
        className={cn(
          styles.avatarButton,
          Boolean(menuAnchorElement) && styles.loggedInUserMenuOpen
        )}
        onClick={handleClick}
        aria-controls="user-sub-menu"
        aria-haspopup="true">
        <Avatar
          className={styles.avatar}
          idpId={idpId}
          givenName={givenName}
          familyName={familyName}
        />
      </Button>
      <Menu
        id="user-sub-menu"
        classes={{ paper: styles.loggedInUserMenu, list: styles.loggedInUserMenuList }}
        style={siteDefault}
        anchorEl={menuAnchorElement}
        getContentAnchorEl={null}
        keepMounted
        open={Boolean(menuAnchorElement)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}>
        <ListItem disabled className={styles.signedInUser}>
          <div>
            <span>Signed-In as</span>
            <span>{`${givenName} ${familyName}`}</span>
            <span>{squadDisplayName}</span>
          </div>
          <Avatar
            className={styles.menuAvatar}
            idpId={idpId}
            givenName={givenName}
            familyName={familyName}
          />
        </ListItem>
        {/* An Example of adding a new element (e.g. for Admin). */}
        {/* <MenuItem onClick={() => {console.warn('clicked!'); handleClose();}>
          <span>Admin</span> <FontAwesomeIcon icon={faUserCog} size={'sm'} />
        </MenuItem> */}
        <MenuItem onClick={signOut}>
          <span>Sign Out</span> <FontAwesomeIcon icon={faSignOutAlt} size={'sm'} />
        </MenuItem>
      </Menu>
    </div>
  );
};
