import { Paper, Table, TableBody, TableContainer, TablePagination } from '@material-ui/core';
import { getComparator, Order, stableSort } from 'views/components/table/SortHelper';
import { IEmployeeTalentModelDto } from 'domain/store/dataTranferObjects/IEmployeeTalentModelDto';
import { IEmployeeTalentModel } from 'domain/store/talentAnalysisTool/TalentAnalysisToolModel';
import { usePagingCache } from 'hooks/usePagingCache';
import React, { useState } from 'react';
import { HeadCell, SortTableHeader } from 'views/components/table/SortTableHeader';
import { EmployeeRow } from 'views/routes/squad/components/talentAnalysisTool/components/employeeRow/EmployeeRow';
import styles from './TalentAnalysisToolTable.module.scss';
import { TablePaginationActions } from 'views/components/table/TablePaginationActions';

const tableCells: HeadCell[] = [
  { id: 'givenName', label: 'Name', isSortable: true },
  { id: 'role', label: 'Position', isSortable: true },
  // { id: 'department', label: 'Capability', isSortable: true },
  { id: 'status', label: 'Status', isSortable: false },
  { id: 'talentCode', label: 'Talent Code', isSortable: false },
  { id: 'rcp', label: 'Role Change Pathway', isSortable: false },
  { id: 'ft', label: 'Flight Risk ', isSortable: false },
  { id: 'ltl', label: 'Long Term Leave', isSortable: false },
  { id: 'p', label: 'Probation', isSortable: false },
];

export const TalentAnalysisToolTable: React.FC<{
  employees: IEmployeeTalentModel[];
  onDataChanged: () => void;
}> = ({ employees, onDataChanged }) => {
  const { setCurrentPageCache, setRowsPerPageCache } = usePagingCache();

  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof IEmployeeTalentModelDto>('givenName');

  const updatePage = (page: number) => {
    setPage(page);
    setCurrentPageCache(page);
  };

  const updateRowsPerPage = (rowsPerPage: number) => {
    setRowsPerPage(rowsPerPage);
    setRowsPerPageCache(rowsPerPage);
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof IEmployeeTalentModelDto
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <Paper className={styles.root}>
      <TableContainer className={styles.toolTableContainer}>
        <Table stickyHeader>
          <SortTableHeader
            order={order}
            orderBy={orderBy}
            labels={tableCells}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {stableSort<IEmployeeTalentModel>(employees, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((employee) => (
                <EmployeeRow
                  key={`${employee.idpId}-${employee.familyName}-${employee.givenName}`}
                  employee={employee}
                  onDataChanged={onDataChanged}
                />
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        page={page}
        count={employees.length}
        rowsPerPage={rowsPerPage}
        onChangePage={(e, p) => updatePage(p)}
        ActionsComponent={TablePaginationActions}
        onChangeRowsPerPage={(e) => {
          updateRowsPerPage(+e.target.value);
          updatePage(0);
        }}
      />
    </Paper>
  );
};
