import { Chip } from '@material-ui/core';
import { INote, NoteSensitivity, NoteStatus } from 'domain/store/NoteModel';
import React from 'react';
import styles from './NoteCard.module.scss';

const NOTE_STATUS_TO_LABEL: Record<NoteStatus | NoteSensitivity, string> = {
  published: '',
  pending: 'Requires Review',
  draft: '',
  sensitive: 'Sensitive',
  'non-sensitive': '',
  none: '',
};
export const NoteStatusChip: React.FC<{ note: INote }> = ({ note }) => {
  const key = note.status === 'published' ? note.sensitivity : note.status;
  const label = NOTE_STATUS_TO_LABEL[key];
  return label ? (
    <Chip
      classes={{ root: styles.chipOverride }}
      size={'small'}
      color={'primary'}
      variant={'outlined'}
      label={label}
    />
  ) : null;
};
