import React, { useEffect, useState } from 'react';
import { Container } from '@material-ui/core';
import { INote } from 'domain/store/NoteModel';
import { useUser } from 'hooks/useUser';
import { LoadingIndicator } from 'views/components/loadingIndicator/LoadingIndicator';
import { SquadFeedTimelineGroups } from './components/SquadFeedTimelineGroups';
import InfiniteScroll from 'react-infinite-scroller';
import { DEFAULT_PAGE_NUMBER, DEFAULT_ROWS_PER_PAGE } from 'views/ViewConstants';
import { observer } from 'mobx-react-lite';

export const SquadFeed: React.FC = observer(() => {
  const { loadSquadNotes, selectedSquad } = useUser();
  const [notes, setNotes] = useState<INote[]>([]);
  const [page, setPage] = useState(DEFAULT_PAGE_NUMBER);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [initialLoadHasCompleted, setInitialLoadHasCompleted] = useState(false);

  useEffect(() => {
    setNotes([]);
    setPage(0);
    setHasMore(true);
  }, [selectedSquad]);

  const loadNextPage = () => {
    if (isLoading) return;
    setIsLoading(true);
    loadSquadNotes(selectedSquad, page, DEFAULT_ROWS_PER_PAGE)
      .then((result) => {
        const combinedNotes = [...notes, ...result.notes];
        setNotes(combinedNotes);
        setPage(page + 1);
        setHasMore(combinedNotes.length < result.totalCount);
      })
      .finally(() => {
        setInitialLoadHasCompleted(true);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    loadNextPage();
    // This is only here to fire off the initial request, so we don't want to give it any dependencies that might cause it to run again
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <InfiniteScroll
      loadMore={loadNextPage}
      hasMore={initialLoadHasCompleted && hasMore}
      // 'key={0}' provided to prevent warning (https://github.com/oVirt/ovirt-web-ui/issues/562)
      loader={<LoadingIndicator key={0} />}
      useWindow={false}>
      {!!notes.length && (
        <Container>
          <SquadFeedTimelineGroups
            groups={notes.reduce((acc: { [key: string]: INote[] }, cur: INote) => {
              const key = (cur.modifiedDateMoment ?? cur.createdDateMoment).format('YYYY-MM');
              if (Object.prototype.hasOwnProperty.call(acc, key)) {
                acc[key].push(cur);
              } else {
                acc[key] = [cur];
              }
              return acc;
            }, {})}
          />
        </Container>
      )}
    </InfiniteScroll>
  );
});
