import React from 'react';
import NavigationPrompt from 'react-router-navigation-prompt';
import { AlertDialog } from 'views/components/alertDialog/AlertDialog';

export interface NavigationAlertProps {
  for: string;
  when: boolean;
}

export const NavigationAlert: React.FunctionComponent<NavigationAlertProps> = (props) => (
  <NavigationPrompt when={props.when}>
    {({ onCancel, onConfirm }) => (
      <AlertDialog
        isOpen={true}
        title={'Confirm Navigation'}
        text={'Your ' + props.for + ' changes will not be saved, do you wish to continue?'}
        onAgreeClick={onConfirm}
        onDisagreeClick={onCancel}
      />
    )}
  </NavigationPrompt>
);
