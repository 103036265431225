import React from 'react';
import { Box, Checkbox, FormControlLabel, Tooltip } from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons';
import { Probation as EmployeeProbation } from 'domain/store/employeeJourney/EmployeeModel';
import styles from './Probation.module.scss';
import { SwitchBaseProps } from '@material-ui/core/internal/SwitchBase';

interface ProbationProps {
  probation: EmployeeProbation;
  overrideDisabledStyle?: boolean;
  disabled: boolean;
}

export const Probation: React.FC<ProbationProps> = ({
  probation,
  overrideDisabledStyle,
  disabled,
}) => {
  return (
    <Box display={'flex'} alignItems={'center'}>
      <FormControlLabel
        disabled={disabled}
        classes={overrideDisabledStyle ? { disabled: styles.disabledLabel } : {}}
        control={
          <Checkbox
            classes={overrideDisabledStyle ? { disabled: styles.disabledCheckbox } : {}}
            checked={probation.isInProbation}
            name="probation"
            disabled={true}
            readOnly={true}
            inputProps={
              {
                'data-testid': 'probation-checkbox',
              } as SwitchBaseProps['inputProps'] & { 'data-testid': string }
            }
          />
        }
        label={'Probation'}
      />
      <Tooltip title={<>End of probation on {probation.endDate}</>}>
        <InfoOutlined />
      </Tooltip>
    </Box>
  );
};
