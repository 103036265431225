import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Autocomplete } from '@material-ui/lab';
import { Chip, CircularProgress, TextField } from '@material-ui/core';
import { useTags } from 'hooks/useTags';
import { ITag } from 'domain/store/TagsModel';
import styles from './TagDropdown.module.scss';
import cn from 'classnames';
import { Lock } from '@material-ui/icons';

export interface Props {
  defaultValue?: ITag[];
  onChange?: (tags: ITag[]) => void;
  onBlur?: () => void;
  className?: string;
  textBoxClassName?: string;
}

export const TagDropdown: React.FC<Props> = observer((props: Props) => {
  const tagStore = useTags();
  const [options, setOptions] = React.useState<ITag[]>([]);
  const [loading] = React.useState(false);
  const [values, setValues] = React.useState<ITag[] | undefined>([]);

  useEffect(() => {
    const tags = tagStore.all;
    setOptions(tags);
  }, [setOptions, tagStore.state, tagStore.all]);

  useEffect(() => {
    if (props.defaultValue) {
      setValues(props.defaultValue);
    }
  }, [props.defaultValue]);

  const handleDelete = (chipToDelete: ITag) => () => {
    if (props.onChange) {
      props.onChange(values?.filter((chip) => chip.tagId !== chipToDelete.tagId) || []);
    }
    setValues(values?.filter((chip) => chip.tagId !== chipToDelete.tagId) || undefined);
  };

  const handleChange = (values: ITag[] | null) => {
    if (props.onChange) {
      props.onChange(values || []);
    }
    setValues(values || undefined);
  };

  return (
    <Autocomplete
      getOptionLabel={(option) => option.name}
      className={props.className}
      options={options}
      autoComplete
      filterSelectedOptions
      loading={loading}
      multiple
      value={values}
      getOptionSelected={(option: ITag, value: ITag) => option.tagId === value.tagId}
      onChange={(_, values) => {
        handleChange(values);
      }}
      onBlur={() => {
        props.onBlur && props.onBlur();
      }}
      renderTags={(params) =>
        params.map((p, i) => (
          <Chip
            className={cn(styles.chip, {
              [styles.isSensitiveChip]: p.isSensitive || p.reviewRequired,
            })}
            key={i}
            onDelete={handleDelete(p)}
            label={p.name}
          />
        ))
      }
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={values && values.length > 0 ? '' : 'Select Tag(s)'}
          className={props.textBoxClassName}
          variant="outlined"
          fullWidth
          InputLabelProps={{ shrink: false }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
      renderOption={(option: ITag) => {
        return (
          <div className={styles.option}>
            {option.name}
            {(option.isSensitive || option.reviewRequired) && <Lock fontSize="inherit" />}
          </div>
        );
      }}
    />
  );
});
