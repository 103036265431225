import { flow, getEnv } from 'domain/store/util';
import { Instance, types } from 'mobx-state-tree';
import { IRoleChangePathwayDto } from 'domain/store/dataTranferObjects/IRoleChangePathwayDto';
import { api } from 'shared/api/interfaces';

export const RoleChangePathwayModifier = types.model('RoleChangePathwayModifier', {
  idpId: types.identifier,
  displayName: types.string,
});

export interface IRoleChangePathwayModel extends Instance<typeof RoleChangePathwayModel> {}

export interface IRoleChangePathwayWithMobx extends IRoleChangePathwayDto {
  modifiedBy: Instance<typeof RoleChangePathwayModifier>;
}

export interface IRoleChangePathwayModelValue
  extends Instance<typeof RoleChangePathwayModelValue> {}

export const RoleChangePathwayModelValue = types.model('RoleChangePathwayModelValue', {
  hasRoleChangePathway: types.boolean,
  modifiedBy: RoleChangePathwayModifier,
  modifiedDateTime: types.string,
  timeframe: types.number,
});

export const RoleChangePathwayModel = types
  .model('RoleChangePathwayModel', {
    value: types.maybeNull(RoleChangePathwayModelValue),
  })
  .actions((self) => {
    const toggleRoleChangePathwayStatus = function* (
      employeeIdpId: string,
      timeframe: number
    ): Generator<
      Promise<IRoleChangePathwayWithMobx>,
      IRoleChangePathwayWithMobx,
      IRoleChangePathwayWithMobx
    > {
      const { ajax } = getEnv(self);

      const result = yield ajax
        .post('Employee/rolechangepathway', {
          json: {
            employeeIdpId,
            flagValue: !self.value || timeframe !== 0 ? true : false,
            timeframe: timeframe,
          },
        })
        .json<api.RoleChangePathwayDto>();

      self.value = RoleChangePathwayModelValue.create(result);
      return result;
    };

    return {
      toggleRoleChangePathwayStatus: flow(toggleRoleChangePathwayStatus),
    };
  });

export interface IRoleChangePathway extends Instance<typeof RoleChangePathwayModel> {}

export function createRoleChangePathwayModel(
  rpp: api.RoleChangePathwayDtoValueDto
): IRoleChangePathway | null {
  if (!rpp) {
    return null;
  }

  return RoleChangePathwayModel.create(rpp);
}
