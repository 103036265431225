import React from 'react';
import styles from './D3BubbleChart.module.scss';
import { Paper, Popper, Typography } from '@material-ui/core';

interface ID3BubbleChartTooltipProps {
  anchorEl: SVGElement | null;
  title: string;
  value: number;
}

export const D3BubbleChartTooltip: React.FC<ID3BubbleChartTooltipProps> = (props) => {
  const [arrowRef, setArrowRef] = React.useState<HTMLSpanElement | null>(null);

  return (
    <Popper
      className={styles.popper}
      anchorEl={props.anchorEl}
      open={!!props.anchorEl}
      placement="top"
      disablePortal={false}
      modifiers={{
        flip: {
          enabled: true,
        },
        preventOverflow: {
          enabled: true,
          boundariesElement: 'scrollParent',
        },
        arrow: {
          enabled: true,
          element: arrowRef,
        },
      }}>
      <span ref={setArrowRef} className={styles.arrow} />
      <Paper className={styles.content}>
        <Typography variant={'subtitle1'}>{props.title}</Typography>
        <Typography variant={'subtitle2'}>{props.value}</Typography>
      </Paper>
    </Popper>
  );
};
