import { Grid, Paper } from '@material-ui/core';
import { useUser } from 'hooks/useUser';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { ChartHeader } from 'views/routes/squad/components/squadTrends/components/chartHeader/ChartHeader';
import { RoleDistributionChart } from 'views/routes/squad/components/squadTrends/components/roleDistribution/RoleDistributionChart';
import { TalentCodesChart } from 'views/routes/squad/components/squadTrends/components/talentCodes/TalentCodesChart';
import { FlagChart } from './components/flagChart/FlagChart';
import { SupportingOpportunitiesChart } from './components/supportingOpportunities/SupportingOpportunitiesChart';
import styles from './SquadTrends.module.scss';
import { TalentCodesByMonthChart } from './components/talentCodesByMonth/TalentCodesByMonthChart';
import { SupportingOpportunitiesByMonthChart } from './components/supportingOpportunitiesByMonth/SupportingOpportunitiesByMonthChart';
import { useTrends } from 'hooks/useTrends';

export const SquadTrends: React.FC = observer(() => {
  const {
    permissions: {
      squads: { canViewTrends },
    },
    selectedSquad,
  } = useUser();

  const {
    trendsEmployees: { loadEmployees },
  } = useTrends();

  React.useEffect(() => {
    loadEmployees(selectedSquad);
  }, [loadEmployees, selectedSquad]);

  if (!canViewTrends) return null;

  return (
    <Grid container direction={'column'} component={Paper}>
      <Grid item>
        <Grid container item direction={'row'} className={styles.chartRow}>
          <Grid item sm={12} md={6} className={styles.chartContainer}>
            <ChartHeader text={'Flags'} />
            <FlagChart />
          </Grid>
          <Grid item sm={12} md={6} className={styles.chartContainer}>
            <ChartHeader text={'Role Distribution'} />
            <RoleDistributionChart />
          </Grid>
        </Grid>
        <Grid container item direction={'row'} className={styles.chartRow}>
          <Grid item sm={12} md={6} className={styles.chartContainer}>
            <ChartHeader text={'Supporting Opportunities'} />
            <SupportingOpportunitiesChart />
          </Grid>
          <Grid item sm={12} md={6} className={styles.chartContainer}>
            <ChartHeader text={'Supporting Opportunities'} subHeader={'By Month'} />
            <SupportingOpportunitiesByMonthChart />
          </Grid>
        </Grid>
        <Grid container item direction={'row'} className={styles.chartRow}>
          <Grid item sm={12} md={6} className={styles.chartContainer}>
            <ChartHeader text={'Talent Codes'} />
            <TalentCodesChart />
          </Grid>
          <Grid item sm={12} md={6} className={styles.chartContainer}>
            <ChartHeader text={'Talent Codes'} subHeader={'By Month'} />
            <TalentCodesByMonthChart />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
});
