import { auth, errorLog } from 'index';
import { getDefaultAjaxInstance } from 'domain/store/util';
import { useState, useCallback } from 'react';
import { api } from 'shared/api/interfaces';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useFlightRiskService = (initFlightRiskValue: boolean) => {
  const [isFlightRisk, setIsFlightRisk] = useState(initFlightRiskValue);
  const [isLoading, setIsLoading] = useState(false);

  const ajax = getDefaultAjaxInstance(auth);
  const toggleFlightRisk = useCallback(
    async (employeeId: string) => {
      setIsLoading(true);
      try {
        const result = await ajax
          .post('Employee/FlightRisk', {
            json: { employeeIdpId: employeeId, flagValue: !isFlightRisk },
          })
          .json<api.FlightRiskDto>();

        setIsFlightRisk(result.isFlightRisk);
        return result;
      } catch (error) {
        errorLog('Failed to update employee flight risk.', error);
        throw error;
      } finally {
        setIsLoading(false);
      }
    },
    [ajax, isFlightRisk]
  );

  return { isFlightRisk, isLoading, toggleFlightRisk };
};
