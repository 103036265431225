import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { IEmployeeItem } from 'domain/store/admin/EmployeeListModel';
import React, { useState, useEffect, useCallback } from 'react';
import { Avatar } from 'views/components/avatar/Avatar';
import styles from './ChangeAccessDialog.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKey, faArrowCircleRight } from '@fortawesome/free-solid-svg-icons';
import { useAdmin } from 'hooks/useAdmin';
import { LoadingIndicator } from 'views/components/loadingIndicator/LoadingIndicator';
import { observer } from 'mobx-react-lite';
import { useStore } from 'hooks/useStore';
import { api } from 'shared/api/interfaces';
import { utc } from 'moment';

interface IChangeAccessDialogProps {
  isOpen: boolean;
  employee: IEmployeeItem;
  closeDialog: () => void;
}

const getAccessLevelValue = (level: string) => {
  switch (level) {
    case 'Unknown':
      return 0;
    case 'C':
      return 1;
    case 'B2':
      return 2;
    case 'B1':
      return 3;
    case 'A':
      return 4;
    case 'M':
      return 5;
    default:
      return 0;
  }
};

export const ChangeAccessDialog: React.FC<IChangeAccessDialogProps> = observer((props) => {
  const {
    notifications,
    user: { selectedSquad },
  } = useStore();
  const {
    employees: {
      sendChangeAccessLevelInvitation,
      sendingRoleInvitationState,
      getLastChangeAccessLevelInvitationForEmployee,
      squadEmployees,
      loadSquadEmployees,
      squadEmployeesState,
      loadEmployees,
    },
  } = useAdmin();

  const [squadFilter, setSquadFilter] = useState<string[] | null>();
  const [value, setValue] = useState(props.employee.accessLevel);
  const [filteredEmployees, setFilteredEmployees] = useState<IEmployeeItem[]>([]);
  const [lastInvitationForRole, setLastInvitationForRole] = useState<
    api.ChangeAccessLevelMessageDto | undefined
  >(undefined);

  useEffect(() => {
    if (props.isOpen) {
      setValue(props.employee.accessLevel);
      setLastInvitationForRole(undefined);
      getLastChangeAccessLevelInvitationForEmployee(props.employee.idpId).then((response) => {
        if (response.requestedRole !== props.employee.accessLevel) {
          setLastInvitationForRole(response);
        }
      });
    }
  }, [
    props.employee.accessLevel,
    props.isOpen,
    getLastChangeAccessLevelInvitationForEmployee,
    props.employee.idpId,
  ]);

  const filterEmployees = useCallback(() => {
    return squadEmployees.filter(
      (e) =>
        getAccessLevelValue(e.accessLevel) < getAccessLevelValue(value) &&
        e.idpId !== props.employee.idpId
    );
  }, [props.employee.idpId, squadEmployees, value]);

  useEffect(
    () => {
      if (props.isOpen) {
        const squads = props.employee.assignedSquads ?? [selectedSquad];
        if (squads !== squadFilter) {
          setFilteredEmployees([]);
          setSquadFilter(squads);
          loadSquadEmployees(squads, true).then(() => setFilteredEmployees(filterEmployees()));
        } else {
          setFilteredEmployees(filterEmployees());
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      filterEmployees,
      loadSquadEmployees,
      props.employee.assignedSquads,
      props.isOpen,
      selectedSquad,
    ]
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setValue((event.target as HTMLInputElement).value);

  const handleSubmit = (role?: string) =>
    sendChangeAccessLevelInvitation(props.employee.idpId, props.employee.accessLevel, role || value)
      .then((response) => {
        setLastInvitationForRole(response);
        props.closeDialog();
        loadEmployees(selectedSquad);
      })
      .catch((e) => {
        if (e?.response?.status === 409) {
          notifications.addError(
            'Conflict occurred saving changes, please refresh the page, and try again after.'
          );
        } else if (e?.response?.status === 403) {
          notifications.addError('Not authorised to save changes.');
        } else {
          notifications.addError('Changes could not be saved, please try again.');
        }
      });

  return (
    <Dialog fullWidth={true} maxWidth={'md'} open={props.isOpen}>
      <DialogTitle className={styles.title}>
        <Typography component="span">Change Access</Typography>
        <IconButton aria-label="close" className={styles.closeButton} onClick={props.closeDialog}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={styles.content}>
        <div>
          <div className={styles.employeeDetailsContainer}>
            <Avatar
              idpId={props.employee.idpId}
              givenName={props.employee.givenName}
              familyName={props.employee.familyName}
              className={styles.avatar}
            />
            <div className={styles.employeeDetails}>
              <span className={styles.bold}>{props.employee.name}</span>
              <span>{props.employee.position}</span>
              {/* <span>{props.employee.capability}</span> */}
              <span>{selectedSquad} Squad</span>
            </div>
            <FontAwesomeIcon icon={faKey} className={styles.keyIcon} />
            <span className={styles.bold}>
              Current access: {props.employee.accessLevelDisplayName}{' '}
              {props.employee.noAccessRequested ||
              lastInvitationForRole?.requestedRole === 'Unknown'
                ? '(Blocked)'
                : null}
            </span>
          </div>
          <div>
            <FormControl component="fieldset">
              <FormLabel component="legend" focused={false}>
                Select access type:
              </FormLabel>
              <RadioGroup className={styles.accessSelection} value={value} onChange={handleChange}>
                <FormControlLabel value="M" control={<Radio />} label="Portfolio Leader" />
                <FormControlLabel value="A" control={<Radio />} label="Squad Leader" />
                <FormControlLabel value="B1" control={<Radio />} label="Principal Consultant" />
                <FormControlLabel value="B2" control={<Radio />} label="Managing Consultant" />
                <FormControlLabel value="C" control={<Radio />} label="Contributor" />
                <FormControlLabel value="Unknown" control={<Radio />} label="No Access" />
              </RadioGroup>
            </FormControl>
          </div>
        </div>
        <div className={styles.rightPanel}>
          {squadEmployeesState === 'loading' ? (
            <LoadingIndicator size="1.25em" className={styles.loadingIndicator} />
          ) : value === 'Unknown' ? (
            <span>
              You are removing {props.employee.name}&apos;s &quot;YOUtopia Access&quot; which will
              prevent them from accessing the YOUtopia app.
            </span>
          ) : (
            <div className={styles.employeeList}>
              <span>
                Granting {props.employee.name} the &quot;{value}&quot; access will allow them to
                view:
              </span>
              <div className={styles.employeeNamesContainer}>
                <ol>
                  {filteredEmployees.map((e) => (
                    <li key={e.idpId}>
                      <span className={styles.accessLevel}>{e.accessLevelDisplayName}</span>
                      <span>- {e.name}</span>
                      <span> - ({e.assignedSquads?.join(', ')})</span>
                    </li>
                  ))}
                </ol>
              </div>
            </div>
          )}
          <div className={styles.buttonContainer}>
            {sendingRoleInvitationState === 'loading' ? (
              <LoadingIndicator size="1.25em" className={styles.loadingIndicator} />
            ) : (
              <Button
                onClick={() => handleSubmit()}
                disabled={props.employee.accessLevel === value}
                variant="contained"
                disableElevation
                color="primary"
                data-testid="invite-button">
                {value !== 'Unknown' ? 'Invite' : 'Continue'}
                <FontAwesomeIcon icon={faArrowCircleRight} className={styles.buttonIcon} />
              </Button>
            )}
          </div>
        </div>
        {lastInvitationForRole && (
          <span className={styles.lastInvitationContainer}>
            <span className={styles.lastInvitationText} data-testid="last-invitation-text">
              {`${
                lastInvitationForRole.requestedRole !== 'Unknown'
                  ? `Invitation to access ${lastInvitationForRole.requestedRoleDisplayName}`
                  : 'Notification to remove access'
              }
            sent by ${lastInvitationForRole.requesterDisplayName}
            on ${utc(lastInvitationForRole.requestDate).local().format('DD MMMM YYYY')}`}
            </span>
            <Button
              component="span"
              variant="text"
              color="primary"
              className={styles.resendLink}
              onClick={() => handleSubmit(lastInvitationForRole.requestedRole)}
              data-testid="resend-button">
              Resend?
            </Button>
          </span>
        )}
      </DialogContent>
    </Dialog>
  );
});
