import {
  Button,
  Drawer,
  IconButton,
  Link,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';
import CloseIcon from '@material-ui/icons/Close';
import { Skeleton } from '@material-ui/lab';
import cn from 'classnames';
import { usePermissions } from 'hooks/usePermissions';
import { useRoleChange } from 'hooks/useRoleChange';
import { useStore } from 'hooks/useStore';
import React, { useEffect, useState } from 'react';
import { api } from 'shared/api/interfaces';
import { AlertDialog } from 'views/components/alertDialog/AlertDialog';
import { LoadingIndicator } from 'views/components/loadingIndicator/LoadingIndicator';
import { RoleChangeStatusChip } from 'views/components/roleChangeStatusChip/RoleChangeStatusChip';
import styles from './RoleChangeSidePanel.module.scss';

interface IRoleChangeSidePanelProps {
  employee: api.EmployeeWithRoleChangeDto | undefined;
  setSelectedEmployee: (value: undefined) => void;
}

export const RoleChangeSidePanel: React.FC<IRoleChangeSidePanelProps> = ({
  employee,
  setSelectedEmployee,
}) => {
  const {
    loadSkills,
    getRatingDescriptionFromValue,
    approveRoleChangeForEmployee,
    downloadRoleChangeBusinessCaseAttachment,
  } = useRoleChange();
  const {
    notifications: { addError },
  } = useStore();
  const {
    canEdit,
    squads: { roleChanges },
  } = usePermissions();

  const [isLoadingSkills, setIsLoadingSkills] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [skills, setSkills] = useState<api.CoreValueDto[]>([]);
  const [approverComment, setApproverComment] = useState<string>('');
  const [confirmationState, setConfirmationState] = useState<'approving' | 'rejecting' | undefined>(
    undefined
  );

  const handleFileDownload = (fileName: string | undefined): void => {
    if (!employee || !fileName) {
      addError('To download the file, both the employee and file name should be present');
      return;
    }

    if (employee.roleChange) {
      downloadRoleChangeBusinessCaseAttachment(
        employee.idpId,
        employee.roleChange.roleChangeId,
        fileName
      )
        .then((blob: Blob) => {
          const url = window.URL.createObjectURL(new Blob([blob]));
          const a = document.createElement('a');
          a.setAttribute('style', 'display:none;');
          document.body.appendChild(a);
          a.download = fileName;
          a.href = url;
          a.target = '_blank';
          a.click();
          document.body.removeChild(a);
        })
        .catch((err) => {
          if (err?.response?.status === 404) addError('File not found');
        });
    }
  };

  useEffect(() => {
    if (!employee) return;

    setIsLoadingSkills(true);
    loadSkills(employee.department, [employee.roleChange.targetRole])
      .then((result) => setSkills(result[0].coreValues))
      .catch((err) => {
        if (err?.response?.status === 403) {
          addError('Not authorised to get role change skills.');
        } else {
          addError('Failed to retrieve role change skills');
        }
      })
      .finally(() => setIsLoadingSkills(false));
  }, [employee, loadSkills, addError]);

  const finalizeRoleChange = (shouldApprove: boolean) => {
    if (!employee) return;
    setConfirmationState(undefined);
    setIsSubmitting(true);
    approveRoleChangeForEmployee(employee.roleChange.roleChangeId, shouldApprove, approverComment)
      .then(() => {
        employee.roleChange.isApproved = shouldApprove;
        employee.roleChange.approverAdditionalComments = approverComment;
      })
      .catch((err) => {
        if (err?.response?.status === 409) {
          addError(
            'Conflict occurred saving changes, please refresh the page, and try again after.'
          );
        } else if (err?.response?.status === 403) {
          addError('Not authorised to save changes.');
        } else {
          addError(`Failed to ${shouldApprove ? 'approve' : 'reject'} role change.`);
        }
      })
      .finally(() => setIsSubmitting(false));
  };

  return (
    <Drawer
      open={!!employee}
      anchor="right"
      onClose={() => {
        setSelectedEmployee(undefined);
        setApproverComment('');
      }}>
      <div className={styles.root}>
        <IconButton className={styles.closeButton} onClick={() => setSelectedEmployee(undefined)}>
          <CloseIcon />
        </IconButton>
        <div className={styles.section}>
          <h1>{employee?.displayName}&#39;s Role Change</h1>
        </div>
        <div className={styles.section}>
          <h2>Prerequisites</h2>
          <ul>
            <li>
              <span>Passed probation</span>
              <CheckBoxOutlinedIcon color="primary" fontSize="small" />
            </li>
            <li>
              <span>Completed 2 retros in past 12 months</span>
              <CheckBoxOutlinedIcon color="primary" fontSize="small" />
            </li>
            <li>
              <span>No performance plan in past 12 months</span>
              <CheckBoxOutlinedIcon color="primary" fontSize="small" />
            </li>
          </ul>
        </div>
        <div className={styles.section}>
          <h2>{employee?.roleChange.targetRole} Skills &#38; Behaviours</h2>
          <ul className={styles.behaviors}>
            {isLoadingSkills
              ? Array.from({ length: 8 }, (_, k) => k).map((i) => (
                  <li key={i}>
                    <Skeleton width="90%" />
                    <Skeleton width="100%" />
                  </li>
                ))
              : skills.map((s, i) => (
                  <li key={i}>
                    <span>{s.name}</span>
                    <Typography component="span" color="primary">
                      {getRatingDescriptionFromValue(
                        employee?.roleChange.skillRatings
                          .find((r) => r.coreValueId === s.id)
                          ?.rating.toString() || '0'
                      ) || 'Not Rated'}
                    </Typography>
                  </li>
                ))}
          </ul>
        </div>
        <div className={styles.section}>
          <h2>Business Case Attachment</h2>
          {employee?.roleChange.businessCaseAttachmentFileName ? (
            <Tooltip title="Download File">
              <Link
                onClick={() =>
                  handleFileDownload(employee?.roleChange.businessCaseAttachmentFileName)
                }
                target="_blank">
                <span className={styles.businessCaseAttachment}>
                  {employee?.roleChange.businessCaseAttachmentFileName}
                </span>
              </Link>
            </Tooltip>
          ) : (
            'No attachment was uploaded'
          )}
        </div>
        <div className={styles.section}>
          <h2>Submitters Additional Comments</h2>
          {employee?.roleChange.submitterAdditionalComments
            ? employee?.roleChange.submitterAdditionalComments
            : 'No comments were made'}
        </div>
        {canEdit(roleChanges) && (
          <form>
            <div className={styles.section}>
              <h2>Approvers Comment</h2>
              {employee && employee.roleChange.isApproved !== null ? (
                employee.roleChange.approverAdditionalComments || 'No comments were made'
              ) : (
                <TextField
                  placeholder="Comments"
                  className={styles.commentsContainer}
                  variant="outlined"
                  multiline
                  fullWidth
                  disabled={employee?.roleChange.isApproved !== null}
                  value={approverComment}
                  onChange={(e) => setApproverComment(e.currentTarget.value)}
                />
              )}
            </div>
            {employee && employee.roleChange.isApproved !== null ? (
              <div className={cn(styles.section, styles.statusContainer)}>
                <RoleChangeStatusChip
                  status={employee?.roleChange.isApproved ? 'approved' : 'rejected'}
                />
              </div>
            ) : (
              employee &&
              !isLoadingSkills && (
                <div className={cn(styles.section, styles.buttonContainer)}>
                  {isSubmitting ? (
                    <div className={styles.loadingIndicator}>
                      <LoadingIndicator size="1.5em" />
                    </div>
                  ) : (
                    <>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setConfirmationState('approving')}>
                        Approve
                      </Button>
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => setConfirmationState('rejecting')}>
                        Reject
                      </Button>
                    </>
                  )}
                </div>
              )
            )}
          </form>
        )}
      </div>
      <AlertDialog
        isOpen={confirmationState === 'approving'}
        title={'Approve Role Change'}
        text={`You are about to approve ${employee?.displayName}'s role change to ${employee?.roleChange.targetRole}`}
        onAgreeClick={() => finalizeRoleChange(true)}
        onDisagreeClick={() => {
          setConfirmationState(undefined);
          setApproverComment('');
        }}
        agreeButtonText="Approve"
        disagreeButtonText="Cancel"
      />
      <AlertDialog
        isOpen={confirmationState === 'rejecting'}
        title={'Reject Role Change'}
        text={`You are about to reject ${employee?.displayName}'s role change to ${employee?.roleChange.targetRole}`}
        onAgreeClick={() => finalizeRoleChange(false)}
        onDisagreeClick={() => {
          setConfirmationState(undefined);
          setApproverComment('');
        }}
        agreeButtonText="Reject"
        disagreeButtonText="Cancel"
      />
    </Drawer>
  );
};
