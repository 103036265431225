import React, { useEffect, useState } from 'react';
import { MenuItem, Select, Box } from '@material-ui/core';
import { useFlags } from 'hooks/useFlags';
import { IFlag } from 'domain/store/FlagsModel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronCircleDown } from '@fortawesome/free-solid-svg-icons/faChevronCircleDown';
import styles from './PrimaryFlagDropdown.module.scss';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useStore } from 'hooks/useStore';
import { IUpdatePrimaryFlagRequest } from 'domain/store/employeeJourney/PrimaryFlagModel';
import { observer } from 'mobx-react-lite';
import { IEmployeeJourney } from 'domain/store/employeeJourney/EmployeeJourneyModel';
import { Skeleton } from '@material-ui/lab';
import { AlertDialog } from '../alertDialog/AlertDialog';
import { UpdatedByInfoIcon } from 'views/routes/employeeJourney/components/employeeSidebar/components/updatedByInfoIcon/UpdatedByInfoIcon';
import { api } from '../../../shared/api/interfaces';

interface IPrimaryFlagDropdownProps {
  journey: IEmployeeJourney;
  disabled: boolean;
}
interface IFlagModificationInfo {
  modifiedDateTime: string;
  modifiedBy: string;
}

export const PrimaryFlagDropdown: React.FC<IPrimaryFlagDropdownProps> = observer(
  ({ journey, disabled }) => {
    const store = useStore();
    const flagStore = useFlags();
    const { employee } = journey;
    const [options, setOptions] = useState<IFlag[]>([]);
    const [value, setValue] = useState<number>(-1);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [showAlertDialog, setShowAlertDialog] = useState<boolean>(false);
    const [flagIdToUpdate, setFlagIdToUpdate] = useState<number | null>(null);
    const [showTalentCodeInfo, setShowTalentCodeInfo] = useState<boolean>(false);
    const [flagModificationInfo, setFlagModificationInfo] = useState<IFlagModificationInfo | null>(
      null
    );

    const flagId = employee?.flags.primaryFlag?.value?.flagId || -1;
    const isLoaded = journey.isLoaded && flagStore.state === 'done';

    useEffect(() => {
      const flags = flagStore.all;
      setOptions(flags);
    }, [setOptions, flagStore.all]);

    useEffect(() => {
      if (isLoaded) {
        setValue(flagId);
        setShowTalentCodeInfo(flagId !== -1);
        setFlagModificationInfo({
          modifiedBy: employee?.flags.primaryFlag?.value?.modifiedBy.displayName || '',
          modifiedDateTime: employee?.flags.primaryFlag?.value?.modifiedDateTime || '',
        });
      }
    }, [isLoaded, flagId, employee]);

    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
      const flagId = event.target.value as number;
      setFlagIdToUpdate(flagId);
      setShowAlertDialog(true);
    };

    const resetPrimaryFlagUpdateStates = () => {
      setFlagIdToUpdate(null);
      setShowAlertDialog(false);
    };

    const onAgreeClickChangePrimaryFlag = () => {
      const flagRequest: IUpdatePrimaryFlagRequest = {
        employeeIdpId: employee!.idpId,
        flagId: flagIdToUpdate as number,
      };
      setIsSubmitting(true);

      employee!.flags.primaryFlag
        ?.update(flagRequest)
        .then((response: api.FlagDto) => {
          setValue(flagIdToUpdate as number);
          setShowTalentCodeInfo(true);
          setFlagModificationInfo({
            modifiedBy: response.modifiedBy.displayName,
            modifiedDateTime: response.modifiedDateTime,
          });
        })
        .catch((e) => {
          if (e.response.status) {
            if (e.response.status === 409) {
              store.notifications.addError(
                'Conflict occurred saving changes, please refresh the page, and try again after.'
              );
            } else if (e.response.status === 403) {
              store.notifications.addError('Not authorised to change primary flag.');
            } else if (e.response.status === 404) {
              store.notifications.addError('Primary flag could not be changed, please try again.');
            }
          }
        })
        .finally(() => setIsSubmitting(false));

      resetPrimaryFlagUpdateStates();
    };

    const onDisagreeClick = () => resetPrimaryFlagUpdateStates();

    return (
      <>
        {isLoaded ? (
          <Box>
            <Select
              labelId="primary-flag-dropdown-label"
              id="primary-flag-dropdown-label"
              value={value}
              disableUnderline
              onChange={(event) => handleChange(event)}
              IconComponent={(props) => {
                const propsWithDropdownIcon = {
                  ...props,
                  className: `${props.className} ${styles.dropdownIcon} `,
                };
                return (
                  <div className={styles.dropdownIconContainer}>
                    {isSubmitting ? (
                      <CircularProgress size={'1.2rem'} />
                    ) : (
                      <FontAwesomeIcon {...propsWithDropdownIcon} icon={faChevronCircleDown} />
                    )}
                  </div>
                );
              }}
              className={styles.selectInput}
              disabled={disabled}
              data-testid="edit-primary-flag-dropdown">
              {value === -1 && (
                <MenuItem value={-1} key={`dropdown-item--1`}>
                  <em>No Primary Flag Selected</em>
                </MenuItem>
              )}
              {options.length &&
                options.map((flag) => {
                  return (
                    <MenuItem value={flag.flagId} key={`dropdown-item-${flag.flagId}`}>
                      {flag.name}
                    </MenuItem>
                  );
                })}
            </Select>
            {showTalentCodeInfo && (
              <Box component={'span'} className={styles.updateByInfo}>
                <UpdatedByInfoIcon
                  updatedByDisplayName={flagModificationInfo?.modifiedBy || ''}
                  modifiedDateTime={flagModificationInfo?.modifiedDateTime || ''}
                />
              </Box>
            )}
          </Box>
        ) : (
          <Skeleton variant={'rect'} height={50} />
        )}
        <AlertDialog
          isOpen={showAlertDialog}
          title={'Talent Code change'}
          text={`Are you sure you wish to update the talent code flag for ${employee?.displayName}?`}
          onAgreeClick={onAgreeClickChangePrimaryFlag}
          onDisagreeClick={onDisagreeClick}
        />
      </>
    );
  }
);
