import React from 'react';
import styles from './EmployeeListPopper.module.scss';
import { IconButton, Popper, ClickAwayListener, Tabs, Tab } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import { ITrendsEmployeesItem } from 'domain/store/trends/TrendsEmployeesModel';
import { ISupportingOpportunityEmployee } from 'domain/store/trends/SupportingOpportunitiesWithEmployeesModel';
import { EmployeeList } from './EmployeeList';

interface IEmployeeListPopperProps {
  anchorEl: HTMLElement | SVGElement | null;
  handleClose: () => void;
  title: string;
  employees: Array<ITrendsEmployeesItem | ISupportingOpportunityEmployee>;
  color: string;
  offset?: [number, number];
  flip?: boolean;
  isRoleChangePathway?: boolean;
}

export const EmployeeListPopper: React.FC<IEmployeeListPopperProps> = function (props) {
  const calculateOverflow = () => {
    if (!props.anchorEl) return 0;

    // Having to use a magic number for pageMargin due to using <Popper ...keepMounted={true} /> causes it to not be rendered correctly
    // (position is wrong and has display: none). If keepMounted worked it would give us the actual popper element to grab dimensions from.
    // The number should be relative to width of this popper when rendered
    const pageMargin = 350;
    const rect = props.anchorEl.getBoundingClientRect();
    const horizontalOffset = props.offset ? props.offset[0] : 0;
    const center = rect.left + rect.width / 2;
    const left = center + horizontalOffset - pageMargin;
    const right = center + horizontalOffset + pageMargin;
    const windowLimit = document.documentElement.clientWidth;

    if (left < 0) {
      return left * -1;
    }

    if (right > windowLimit) {
      return windowLimit - right;
    }

    return 0;
  };

  const horizontalOffset = props.offset ? props.offset[0] : 0;
  const verticalOffset = props.offset ? props.offset[1] : 0;
  const overflow = calculateOverflow();
  const popperOffset = `${horizontalOffset + overflow},${verticalOffset}`;
  const arrowOffset = overflow * -1;

  const [activeTab, setActiveTab] = React.useState(0);
  const changeTab = (_: React.ChangeEvent<unknown>, value: number) => setActiveTab(value);

  const employeesFilteredByTimeFrame = (timeframe: 0 | 6 | 12 | 18) => {
    if (timeframe === 0) return props.employees;

    return props.employees.filter(
      (e) =>
        'roleChangeTimeFrame' in e && e.roleChangeTimeFrame && e.roleChangeTimeFrame === timeframe
    ) as ITrendsEmployeesItem[];
  };

  return (
    <Popper
      open={!!props.anchorEl}
      anchorEl={props.anchorEl}
      className={styles.root}
      placement="bottom"
      disablePortal={false}
      modifiers={{
        preventOverflow: {
          enabled: false,
        },
        hide: {
          enabled: false,
        },
        flip: {
          enabled: !!props.flip,
        },
        offset: {
          offset: popperOffset,
        },
      }}>
      <span className={styles.arrow} style={{ marginLeft: arrowOffset }} />
      <ClickAwayListener onClickAway={props.handleClose}>
        <div>
          <div className={`${styles.header} ${styles[props.color]}`}>
            <div className={styles.block} style={{ background: props.color }} />
            <span className={styles.title}>{props.title}</span>
            <span>Total: {props.employees.length}</span>
          </div>
          <IconButton
            color="secondary"
            onClick={props.handleClose}
            style={{ position: 'absolute', top: '0', right: '0' }}>
            <FontAwesomeIcon icon={faTimesCircle} size={'xs'} />
          </IconButton>
          {props.isRoleChangePathway ? (
            <>
              <Tabs
                value={activeTab}
                onChange={changeTab}
                classes={{ root: styles.tab, indicator: styles.indicator }}>
                <Tab
                  label={`All (${props.employees.length})`}
                  classes={{ selected: styles.selected }}
                />
                <Tab
                  label={`6 months (${employeesFilteredByTimeFrame(6).length})`}
                  classes={{ selected: styles.selected }}
                />
                <Tab
                  label={`12 months (${employeesFilteredByTimeFrame(12).length})`}
                  classes={{ selected: styles.selected }}
                />
                <Tab
                  label={`12+ months (${employeesFilteredByTimeFrame(18).length})`}
                  classes={{ selected: styles.selected }}
                />
              </Tabs>
              <EmployeeList
                employees={employeesFilteredByTimeFrame((activeTab * 6) as 0 | 6 | 12 | 18)}
              />
            </>
          ) : (
            <EmployeeList employees={props.employees} />
          )}
        </div>
      </ClickAwayListener>
    </Popper>
  );
};
