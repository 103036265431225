import { flow, getEnv, LoadingStateModel } from 'domain/store/util';
import { Instance, types } from 'mobx-state-tree';
import { api } from 'shared/api/interfaces';
import { errorLog } from 'index';
import moment from 'moment';

export const ratings = [
  { value: '0', description: 'Not Applicable' },
  { value: '1', description: 'Needs Coaching' },
  { value: '2', description: 'Maturing' },
  { value: '3', description: 'No Brainer' },
  { value: '4', description: 'Outstanding' },
];

export type RoleChangeStatus = 'draft' | 'submitted' | 'approved' | 'rejected' | 'new';
export interface RoleChangeAttachmentFile {
  name: string;
  base64: string;
  contentType: string;
}

export const RoleChangeModel = types
  .model('RoleChange', {
    loadingState: LoadingStateModel,
  })
  .actions((self) => ({
    getCurrentRoleChange: flow(function* (
      roleChangeId: number
    ): Generator<Promise<api.RoleChangeDto>, api.RoleChangeDto | null, api.RoleChangeDto> {
      const { ajax } = getEnv(self);
      try {
        self.loadingState = 'loading';
        const result = yield ajax
          .get('RoleChanges/rolechange', { searchParams: { roleChangeId } })
          .json<api.RoleChangeDto>();

        self.loadingState = 'done';
        return result;
      } catch (error) {
        self.loadingState = 'error';
        errorLog('Failed to fetch current role change', error);
        return null;
      }
    }),
    getAllRoleChangeSubmissions: flow(function* (
      employeeIdpId: string
    ): Generator<Promise<api.RoleChangeDto[]>, api.RoleChangeDto[] | null, api.RoleChangeDto[]> {
      const { ajax } = getEnv(self);
      try {
        self.loadingState = 'loading';
        const result = yield ajax
          .get('RoleChanges/rolechangesforemployee', { searchParams: { employeeIdpId } })
          .json<api.RoleChangeDto[]>();

        self.loadingState = 'done';
        return result;
      } catch (error) {
        self.loadingState = 'error';
        errorLog('Failed to fetch current role change', error);
        return null;
      }
    }),
    getRoleChangeSubmissionsForSquad: flow(function* (
      squadName: string
    ): Generator<
      Promise<api.EmployeeWithRoleChangeDto[]>,
      api.EmployeeWithRoleChangeDto[],
      api.EmployeeWithRoleChangeDto[]
    > {
      const { ajax } = getEnv(self);
      try {
        self.loadingState = 'loading';
        const result = yield ajax
          .get('RoleChanges/rolechangesforsquad', { searchParams: { squadName } })
          .json<api.EmployeeWithRoleChangeDto[]>();

        self.loadingState = 'done';
        return result;
      } catch (error) {
        self.loadingState = 'error';
        errorLog(`Failed to fetch role change submissions for ${squadName}`, error);
        return [];
      }
    }),
    upsertRoleChange: flow(function* (
      request: api.UpsertRoleChangeSubmissionRequest
    ): Generator<Promise<void>, void, void> {
      const { ajax } = getEnv(self);

      try {
        yield ajax
          .post('RoleChanges/upsertrolechange', {
            json: request,
          })
          .json<void>();
      } catch (error) {
        errorLog('Failed to submit role change', error);
        throw error;
      }
    }),
    revertCurrentRoleChangeToDraft: flow(function* (
      roleChangeId: number
    ): Generator<Promise<void>, void, void> {
      const { ajax } = getEnv(self);
      try {
        yield ajax
          .post('RoleChanges/revertrolechangetodraft', { searchParams: { roleChangeId } })
          .json<void>();
      } catch (error) {
        errorLog('Failed to revert current role change to draft', error);
      }
    }),
    approveRoleChangeForEmployee: flow(function* (
      roleChangeId: number,
      shouldApprove: boolean,
      approverAdditionalComments: string
    ): Generator<Promise<void>, void, void> {
      const { ajax } = getEnv(self);
      try {
        yield ajax
          .post('RoleChanges/finalizerolechange', {
            json: { roleChangeId, approveRoleChange: shouldApprove, approverAdditionalComments },
          })
          .json<void>();
      } catch (error) {
        errorLog(`Failed to ${shouldApprove ? 'approve' : 'reject'} role change`, error);
      }
    }),
    downloadRoleChangeBusinessCaseAttachment: flow(function* (
      employeeIdpId: string,
      roleChangeId: number,
      fileName: string
    ): Generator<Promise<Blob>, Blob, Blob> {
      const { ajax } = getEnv(self);

      try {
        return yield ajax
          .get('RoleChanges/downloadBusinessCaseAttachment', {
            searchParams: { employeeIdpId, roleChangeId, fileName },
          })
          .blob();
      } catch (error) {
        errorLog('Failed to download business case for Role Change', error);
        throw error;
      }
    }),
  }))
  .actions((self) => ({
    loadSkills: flow(function* (
      capabilityId: string,
      roleNames: string[]
    ): Generator<
      Promise<api.CapabilityRoleDto[]>,
      api.CapabilityRoleDto[],
      api.CapabilityRoleDto[]
    > {
      const { ajax } = getEnv(self);

      const searchRoleNames = new URLSearchParams(roleNames.map((s) => ['roleName', s]));
      const roleName = searchRoleNames.toString();

      return yield ajax
        .get('RoleChanges/details?capabilityId=' + capabilityId + '&' + roleName)
        .json<api.CapabilityRoleDto[]>();
    }),
  }))
  .views((self) => ({
    get isLoading() {
      return self.loadingState === 'loading';
    },
    get currentRoleChangeCycle() {
      const currentYear = moment().get('year');
      return moment().get('month') < 6 ? `Jan-Jun ${currentYear}` : `Jul-Dec ${currentYear}`;
    },
    getRoleChangeCycle(roleChange: api.RoleChangeDto) {
      const currentYear = moment(roleChange.createdAt).get('year');
      const isCurrentMonthInFirstCycle = moment(roleChange.createdAt).get('month') < 6;
      const cycles = [
        `Jan-Jun ${currentYear}`,
        `Jul-Dec ${currentYear}`,
        `Jan-Jun ${currentYear + 1}`,
      ].slice(isCurrentMonthInFirstCycle ? 0 : 1, isCurrentMonthInFirstCycle ? 2 : 3);

      return cycles[roleChange.skipCurrentPromotionCycle ? 1 : 0];
    },
    getRatingDescriptionFromValue(value: string) {
      return ratings.find((r) => r.value === value)?.description || undefined;
    },
  }));

export const getStatus = (
  isDraft: boolean | undefined,
  isApproved: boolean | undefined,
  isActive: boolean | undefined
): RoleChangeStatus => {
  if (isDraft) return 'draft';
  if (isDraft === undefined) return 'new';
  if (isApproved === null && isActive) return 'submitted';
  if (isApproved && !isActive) {
    return 'approved';
  } else {
    return 'rejected';
  }
};

export interface IRoleChangeModel extends Instance<typeof RoleChangeModel> {}
