export enum Positions {
  LeadConsultant = 'Lead Consultant',
  SeniorDeveloper = 'Senior Developer',
  TechnicalLead = 'Technical Lead',
  ManagingConsultant = 'Managing Consultant',
  Consultant = 'Consultant',
  SquadLeader = 'Squad Leader',
  PrincipalConsultant = 'Principal Consultant',
  Associate = 'Associate',
  AssociateSquadLeader = 'Associate Squad Leader',
}
