import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { TextField } from '@material-ui/core';
import styles from './MarkdownTextArea.module.scss';
import 'github-markdown-css';

type ITextAreaProps = {
  content: string;
  onChange?: (text: string) => void;
  onBlur?: () => void;
};

export const MarkdownTextArea: React.FC<ITextAreaProps> = function ({ content, onChange, onBlur }) {
  const [isFocused, setIsFocused] = useState(false);

  const onBlurHandler = () => {
    setIsFocused(false);
    onBlur && onBlur();
  };

  const onClickHandler = () => {
    setIsFocused(true);
  };

  const onChangeHandler = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    onChange && onChange(event.target.value);
  };

  return (
    <div
      data-testid="markdown-container"
      className={styles.root}
      onClick={onClickHandler}
      tabIndex={0}
      onFocus={onClickHandler}
      onBlur={onBlurHandler}>
      {!isFocused && content.length ? (
        <div data-testid="markdown-preview" className="markdown-body">
          <ReactMarkdown className={styles.markdownPreview} source={content} skipHtml={true} />
        </div>
      ) : (
        <TextField
          placeholder="Enter details here"
          variant="outlined"
          fullWidth
          multiline
          InputLabelProps={{ shrink: false }}
          value={content}
          onChange={onChangeHandler}
          inputProps={{
            'data-testid': 'markdown-textarea',
            autoFocus: true,
          }}
          InputProps={{
            classes: { root: styles.markdownInput },
          }}
        />
      )}
    </div>
  );
};
