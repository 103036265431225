import { useStore } from 'hooks/useStore';

interface IUseSecurity {
  signOut: () => void;
}

export function useSecurity(): IUseSecurity {
  const { security } = useStore();

  return {
    signOut: security.signOut,
  };
}
