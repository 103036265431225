import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTrends } from 'hooks/useTrends';
import { D3BubbleChart } from 'views/components/charts/d3BubbleChart/D3BubbleChart';
import { Skeleton } from '@material-ui/lab';
import { Typography } from '@material-ui/core';
import { ColourSet, generateColours } from '../../ColourGenerator';
import { ChartData } from '../../../../../../components/charts/d3BubbleChart/types';

export const TalentCodesChart: React.FC = observer(() => {
  const {
    trendsEmployees: { state, talentCodeCounts },
  } = useTrends();

  const [colours, setColours] = useState([] as string[]);
  const [chartData, setChartData] = useState([] as ChartData[]);

  useEffect(() => {
    setColours(generateColours(talentCodeCounts.length, ColourSet.Blue));
  }, [talentCodeCounts]);

  useEffect(() => {
    setChartData(
      talentCodeCounts.map((item, index) => ({
        name: item.talentCode,
        value: item.count,
        colour: colours[index],
      }))
    );
  }, [colours, talentCodeCounts]);

  return (
    <Typography component={'span'} variant={'body1'}>
      {state === 'done' ? (
        <D3BubbleChart
          data={chartData}
          shouldEmployeeListFlip={true}
          chartType="talentCodes"
          size={[512, 512]}
        />
      ) : (
        <Skeleton variant={'rect'} height={512} />
      )}
    </Typography>
  );
});
