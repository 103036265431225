import React, { ReactNode } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';

export interface AlertDialogProps {
  isOpen: boolean;
  title: string;
  text?: string;
  content?: ReactNode;
  agreeButtonText?: string;
  disagreeButtonText?: string;
  onAgreeClick?: () => void;
  onDisagreeClick?: () => void;
  agreeDisabled?: boolean;
}

export const AlertDialog: React.FunctionComponent<AlertDialogProps> = (props) => {
  const onAgreeClick = () => {
    props.onAgreeClick && props.onAgreeClick();
  };

  const onDisagreeClick = () => {
    props.onDisagreeClick && props.onDisagreeClick();
  };

  return (
    <Dialog
      data-testid={'dialog-test-id'}
      open={props.isOpen}
      onClose={onDisagreeClick}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title" data-testid={'dialog-title'}>
        {props.title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{props.text}</DialogContentText>
        {props.content}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onAgreeClick}
          color="primary"
          autoFocus
          data-testid={'dialog-button-agree'}
          disabled={props.agreeDisabled}>
          {props.agreeButtonText || 'Yes'}
        </Button>
        {props.onDisagreeClick && (
          <Button
            onClick={onDisagreeClick}
            color="secondary"
            data-testid={'dialog-button-disagree'}>
            {props.disagreeButtonText || 'No'}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
