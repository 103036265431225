import { errorLog } from 'index';
import { flow, Instance, types } from 'mobx-state-tree';
import { api } from 'shared/api/interfaces';
import { LoadingStateModel, getEnv, ModelLoadingState } from '../util';
import { makeAdminUrl } from './AdminModel';

export const EmployeeModel = types.model('EmployeeModel', {
  idpId: types.string,
  name: types.string,
  givenName: types.string,
  familyName: types.string,
  position: types.string,
  capability: types.string,
  accessLevel: types.string,
  accessLevelDisplayName: types.string,
  noAccessRequested: types.boolean,
  assignedSquads: types.maybeNull(types.array(types.string)),
});

export interface IEmployeeItem extends Instance<typeof EmployeeModel> {}

export const EmployeeListModel = types
  .model('EmployeeListModel', {
    employees: types.optional(types.array(EmployeeModel), []),
    squadEmployees: types.optional(types.array(EmployeeModel), []),
    state: LoadingStateModel,
    squadEmployeesState: LoadingStateModel,
    sendingRoleInvitationState: types.optional(
      types.enumeration<ModelLoadingState>(['loading', 'done', 'error', 'unauthorized']),
      'done'
    ),
  })
  .actions((self) => ({
    loadSquadEmployees: flow(function* (
      squadNames: string[],
      includeUnknownRoles = false
    ): Generator<Promise<IEmployeeItem[]>, void, IEmployeeItem[]> {
      if (squadNames) {
        const { ajax } = getEnv(self);
        const searchParams = new URLSearchParams({ includeUnknownRoles: includeUnknownRoles });
        squadNames.forEach((squad) => {
          searchParams.append('squadName', squad);
        });
        self.squadEmployeesState = 'loading';

        try {
          self.squadEmployees.clear();
          const value = yield ajax
            .get(makeAdminUrl('employees'), {
              searchParams: searchParams,
            })
            .json<api.UserDto[]>()
            .then((values) => values.map((item) => EmployeeModel.create(item)));
          value.forEach((i) => self.squadEmployees.push(i));

          self.squadEmployeesState = 'done';
        } catch (e) {
          errorLog('Failed to load employees', e);
          self.state = 'error';
        }
      }
    }),
    loadEmployees: flow(function* (
      selectedSquad: string | null,
      includeUnknownRoles = false
    ): Generator<Promise<IEmployeeItem[]>, void, IEmployeeItem[]> {
      if (selectedSquad) {
        const { ajax } = getEnv(self);

        self.state = 'loading';

        try {
          self.employees.clear();
          const value = yield ajax
            .get(makeAdminUrl('employees'), {
              searchParams: { squadName: selectedSquad, includeUnknownRoles },
            })
            .json<api.UserDto[]>()
            .then((values) => values.map((item) => EmployeeModel.create(item)));
          value.forEach((i) => self.employees.push(i));

          self.state = 'done';
        } catch (e) {
          errorLog('Failed to load employees', e);
          self.state = 'error';
        }
      }
    }),
    sendChangeAccessLevelInvitation: flow(function* (
      requestForIdpId: string,
      currentRole: string,
      requestedRole: string
    ): Generator<
      Promise<api.ChangeAccessLevelMessageDto>,
      api.ChangeAccessLevelMessageDto,
      api.ChangeAccessLevelMessageDto
    > {
      const { ajax } = getEnv(self);
      try {
        self.sendingRoleInvitationState = 'loading';
        return yield ajax
          .post(makeAdminUrl('ChangeAccessLevelRequest'), {
            json: { requestForIdpId, currentRole, requestedRole },
          })
          .json<api.ChangeAccessLevelMessageDto>();
      } finally {
        self.sendingRoleInvitationState = 'done';
      }
    }),
    getLastChangeAccessLevelInvitationForEmployee: flow(function* (
      employeeIdpId: string
    ): Generator<
      Promise<api.ChangeAccessLevelMessageDto>,
      api.ChangeAccessLevelMessageDto,
      api.ChangeAccessLevelMessageDto
    > {
      const { ajax } = getEnv(self);
      return yield ajax
        .get(makeAdminUrl('ChangeAccessLevelRequest'), {
          searchParams: { requestForIdpId: employeeIdpId },
        })
        .json<api.ChangeAccessLevelMessageDto>();
    }),
  }));
