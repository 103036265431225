import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
} from '@material-ui/core';
import { TimeframeDropdown } from '../roleChangePathwayTimeframe/TimeframeDropdown';

export interface DialogWithDropdownProps {
  isOpen: boolean;
  title: string;
  agreeButtonText?: string;
  disagreeButtonText?: string;
  timeframes: number[];
  employeeDisplayName: string;
  oldNewTimeframe: [number, number];
  onSelectChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
  onAgreeClick?: () => void;
  onDisagreeClick?: () => void;
}

export const DialogWithDropdown: React.FunctionComponent<DialogWithDropdownProps> = (props) => {
  const [oldValue, newValue] = props.oldNewTimeframe;
  const onAgreeClick = () => {
    props.onAgreeClick && props.onAgreeClick();
  };

  const onDisagreeClick = () => {
    props.onDisagreeClick && props.onDisagreeClick();
  };

  const filterMoreThanOneYear = (numberOfMonths: number) => {
    return numberOfMonths > 12 ? '12+' : numberOfMonths;
  };

  const bold: React.CSSProperties = { fontWeight: 'bold' };

  return (
    <Dialog
      open={props.isOpen}
      onClose={onDisagreeClick}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title" data-testid={'dialog-title'}>
        {props.title}
      </DialogTitle>
      <DialogContent>
        <>
          {oldValue === 0 ? (
            <>
              <DialogContentText>
                You are editing {props.employeeDisplayName}&apos;s Role Change Pathway to
              </DialogContentText>
              <br />
              <TimeframeDropdown
                value={props.timeframes[0]}
                timeframes={props.timeframes}
                isUpdatingTimeframe={false}
                onSelectChange={props.onSelectChange}
              />
            </>
          ) : (
            <DialogContentText>
              {newValue === oldValue ? (
                <>You are clearing {props.employeeDisplayName}&apos;s Role Change Pathway</>
              ) : (
                <>
                  You are editing {props.employeeDisplayName}&apos;s Role Change Pathway from{' '}
                  <span style={bold}>{filterMoreThanOneYear(oldValue)} months</span> to{' '}
                  <span style={bold}>{filterMoreThanOneYear(newValue)} months</span>
                </>
              )}
            </DialogContentText>
          )}
        </>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onAgreeClick}
          color="primary"
          autoFocus
          data-testid={'dialog-button-agree'}>
          {props.agreeButtonText || 'Submit'}
        </Button>
        {props.onDisagreeClick && (
          <Button
            onClick={onDisagreeClick}
            color="secondary"
            data-testid={'dialog-button-disagree'}>
            {props.disagreeButtonText || 'Cancel'}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
