import { Instance, types } from 'mobx-state-tree';
import { flow, getEnv, getRoot, LoadingStateModel } from 'domain/store/util';
import { TalentCodeTrendsByMonthModel } from './TalentCodeTrendsByMonthModel';
import { SupportingOpportunitiesByMonthModel } from './SupportingOpportunitiesByMonthModel';
import { TrendsEmployeesModel } from './TrendsEmployeesModel';
import { SupportingOpportunitiesWithEmployeesModel } from './SupportingOpportunitiesWithEmployeesModel';
import { api } from 'shared/api/interfaces';
import { errorLog } from 'index';

export function makeTrendsUrl(name: string): string {
  return `Trends/${name}`;
}

export const TrendsModel = types
  .model('Trends', {
    squadsStatus: LoadingStateModel,
    selectedSquad: types.optional(types.string, ''),
    talentCodeTrendsByMonth: types.optional(TalentCodeTrendsByMonthModel, {}),
    supportingOpportunitiesByMonth: types.optional(SupportingOpportunitiesByMonthModel, {}),
    trendsEmployees: types.optional(TrendsEmployeesModel, {}),
    supportingOpportunitiesWithEmployees: types.optional(
      SupportingOpportunitiesWithEmployeesModel,
      {}
    ),
    roleDistributionOrder: types.array(types.string),
  })
  .actions((self) => ({
    afterAttach: flow(function* (): Generator<
      Promise<api.ChartMetaDataDto>,
      void,
      api.ChartMetaDataDto
    > {
      const { user } = getRoot(self);
      self.selectedSquad = user.squadName ?? '';

      const { ajax } = getEnv(self);
      try {
        const result = yield ajax.get(makeTrendsUrl('Meta'), {}).json<api.ChartMetaDataDto>();
        self.roleDistributionOrder.push(...result.roleDistributionOrderedSet);
      } catch (e) {
        errorLog('Failed to get chart metadata.', e);
      }
    }),
  }));

export interface ITrends extends Instance<typeof TrendsModel> {}
