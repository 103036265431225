import React, { useEffect, useState } from 'react';
import styles from './ListNotes.module.scss';
import { TabComponent, TabItem } from 'views/components/tab/Tab';
import { RecentList } from './RecentList';
import { DraftList } from './DraftList';
import { Button, Grid } from '@material-ui/core';
import { useNavigate } from 'hooks/useNavigate';
import { CREATE_NOTE_ROUTE_PATH } from 'views/routes/Routes';

interface IListNotesProps {
  selectedNoteId?: number;
  showDrafts: boolean;
  resetForm: () => void;
}

export const ListNotes: React.FC<IListNotesProps> = ({ selectedNoteId, showDrafts, resetForm }) => {
  const [activeTab, setActiveTab] = useState<number>(showDrafts ? 0 : 1);
  const navigate = useNavigate();

  useEffect(() => setActiveTab(showDrafts ? 0 : 1), [showDrafts]);

  const tabItems: TabItem[] = [
    {
      title: 'Drafts',
      testId: 'list-notes-header-drafts',
      component: <DraftList selectedNoteId={selectedNoteId!} resetForm={resetForm} />,
    },
    {
      title: 'Previous',
      testId: 'list-notes-header-previous',
      component: <RecentList selectedNoteId={selectedNoteId!} resetForm={resetForm} />,
    },
  ];

  const handleTabChange = (selectedIndex: number) => {
    setActiveTab(selectedIndex);
  };

  return (
    <div className={styles.noteListComponent}>
      <Grid container direction={'row'}>
        <Grid item xs={6}>
          <h2>Notes</h2>
        </Grid>
        <Grid item xs={6} className={styles.newNoteButtonContainer}>
          <Button
            variant="contained"
            color="primary"
            classes={{ containedPrimary: styles.materialOverride }}
            onClick={() => navigate(CREATE_NOTE_ROUTE_PATH)}>
            New Note
          </Button>
        </Grid>
      </Grid>
      <TabComponent
        className={styles.tabHeaderContainer}
        items={tabItems}
        onChange={handleTabChange}
        value={activeTab}
      />
      <div className={styles.tabBodyContainer}>{tabItems[activeTab].component}</div>
    </div>
  );
};
