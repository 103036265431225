import React, { ReactNode } from 'react';
import { Link, Redirect, Route, Switch, useLocation, useParams } from 'react-router-dom';
import styles from 'views/routes/employeeJourney/EmployeeJourney.module.scss';
import { EmployeeSidebar } from 'views/routes/employeeJourney/components/employeeSidebar/EmployeeSidebar';
import { EmployeeTimeline } from 'views/routes/employeeJourney/components/employeeTimeline/EmployeeTimeline';
import { RoleChangesList } from 'views/routes/employeeJourney/components/roleChange/RoleChangesList';
import { AppBar, Tab, Tabs } from '@material-ui/core';
import { usePermissions } from 'hooks/usePermissions';
import { observer } from 'mobx-react-lite';
import { useEmployeeJourney } from 'hooks/useEmployeeJourney';
import { LoadingIndicator } from 'views/components/loadingIndicator/LoadingIndicator';

interface TabItem {
  link: string;
  title: ReactNode;
  component: ReactNode;
  disabled: boolean;
  visible: boolean;
}

export const EmployeeJourney: React.FC = observer(() => {
  const { idpId } = useParams<{ idpId?: string }>();
  const {
    canView,
    canViewEmployeeTab,
    employees: { roleChanges },
  } = usePermissions();
  const location = useLocation();

  const canViewRoleChanges = canView(roleChanges);
  const { isLoaded, employee } = useEmployeeJourney();

  const tabItems: TabItem[] = [
    {
      title: 'Journey',
      component: <EmployeeTimeline idpId={idpId} className={styles.employeeTimeline} />,
      link: 'journey',
      disabled: !canViewEmployeeTab,
      visible: true,
    },
    {
      title: 'Role Change',
      component: isLoaded ? <RoleChangesList /> : <LoadingIndicator />,
      link: 'role-change',
      disabled: !canViewRoleChanges || !employee?.isInCareerFramework,
      visible: canViewRoleChanges,
    },
  ].filter((t) => t.visible);

  const activeTab: number = tabItems.findIndex(
    (item) => location.pathname === `/employees/${idpId}/${item.link}`
  );

  return (
    <div className={styles.employeeJourneyComponent}>
      <AppBar position={'static'} color={'default'} className={styles.squadAppBar}>
        <Tabs
          value={activeTab === -1 ? 0 : activeTab}
          centered={true}
          classes={{ indicator: styles.indicator }}>
          {tabItems.map((item, index) => (
            <Tab
              component={Link}
              to={`/employees/${idpId}/${item.link}`}
              key={index}
              label={item.title}
              disabled={item.disabled}
              className={styles.appBarTab}
            />
          ))}
        </Tabs>
      </AppBar>
      <div className={styles.tabBodyContainer}>
        <EmployeeSidebar />
        <Switch>
          {tabItems.map(
            (item) =>
              !item.disabled && ( // prevent the user from just using updating the url
                <Route path={`/employees/${idpId}/${item.link}`} key={item.link}>
                  {item.component}
                </Route>
              )
          )}
          {isLoaded && (
            <Redirect to={`/employees/${idpId}/${tabItems.length === 0 ? '' : tabItems[0].link}`} />
          )}
        </Switch>
      </div>
    </div>
  );
});
