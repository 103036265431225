import React from 'react';
import { Divider, Box } from '@material-ui/core';

export const EmployeeSidebarDivider: React.FC = () => {
  return (
    <Box marginTop={'0.75em'} marginBottom={'0.75em'}>
      <Divider />
    </Box>
  );
};
