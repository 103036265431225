import React from 'react';
import styles from './SquadCatchUps.module.scss';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { useSquadCatchUps } from 'hooks/useSquadCatchUps';
import { useUser } from 'hooks/useUser';
import { observer } from 'mobx-react-lite';
import { SquadCatchUpItem } from './components/squadCatchUpItem/SquadCatchUpItem';
import { SquadCatchUpItemSkeleton } from './components/squadCatchUpItemSkeleton/SquadCatchUpItemSkeleton';
import { usePermissions } from 'hooks/usePermissions';

export const SquadCatchUps: React.FC = observer(() => {
  const { state, squadCatchUps, loadCatchupsForSquad } = useSquadCatchUps();
  const { selectedSquad } = useUser();

  const {
    catchUps: { canViewSquadCatchUps },
  } = usePermissions();

  if (!canViewSquadCatchUps) return null;

  React.useEffect(() => {
    loadCatchupsForSquad(selectedSquad);
  }, [loadCatchupsForSquad, selectedSquad]);

  return (
    <Box maxWidth="1274px" margin="auto" my={10}>
      <Paper className={styles.paper}>
        <h1>{selectedSquad}</h1>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Last Catch-up</TableCell>
                <TableCell>Catch-up with</TableCell>
                <TableCell>Days since last Catch-up</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {state === 'done'
                ? squadCatchUps.map((c, i) => <SquadCatchUpItem catchup={c} key={i} />)
                : Array.from({ length: 5 }, (v, k) => k).map((i) => (
                    <SquadCatchUpItemSkeleton key={i} />
                  ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
});
