import React from 'react';
import styles from 'views/components/tag/Tag.module.scss';
import { Chip } from '@material-ui/core';

export interface TagProps {
  name: string;
  isSensitive: boolean;
  reviewRequired: boolean;
}

export const Tag: React.FC<TagProps> = ({ isSensitive, name }) => {
  return (
    <Chip
      size="small"
      color="primary"
      variant="outlined"
      label={`#${name}`}
      classes={{ label: styles.materialOverride }}
      className={styles.tagComponent}
    />
  );
};
