import { CSSProperties } from 'react';

export enum color {
  black = '#000000',
  white = '#ffffff',
  vibrantBlue = '#0e39fb',
  pinkishRed = '#ed1f4f',
  brick = '#d32323',
  tangerine = '#ff9300',
  sapGreen = '#58a41e',
  veryLightPink = '#d1d1d1',
  brownGrey = '#8b8b8b',
  brownishGrey = '#707070',
  mineShaft = '#343434',
  cerulean = '#00aeef',
  hollywoodCerise = '#ff029a',
  bigStone = '#141b33',
  lemon = '#f9ea1d',
  puertoRico = '#49BAAA',
  electricLime = '#CCFF00',
  treePoppy = '#F7941D',
  tePapaGreen = '#1F313E',
  blueDianne = '#223F54',
  fiord = '#3D5567',
  athensGray = '#F0F0F4',
  alabaster = '#F8F8F8',
  codgray = '#111111',
  sunglo = '#E57373',
  texasRose = '#FFB74D',
  pictonBlue = '#4FC3F7',
  deYork = '#81C784',
  boulder = '#777777',
  catskillWhite = '#F9FAFC',
  aliceBlue = '#F9FAFC',
}

interface IAppCssProperties extends CSSProperties {
  '--background': color;
  '--primary-one': color;
  '--on-primary-one': color;
  '--primary-two': color;
  '--on-primary-two': color;
  '--primary-three': color;
  '--on-primary-three': color;
  '--secondary-one': color;
  '--secondary-two': color;
  '--secondary-three': color;
  '--secondary-four': color;
  '--shade-darkest': color;
  '--shade-darker': color;
  '--shade-dark': color;
  '--shade-light': color;
  '--shade-lighter': color;
  '--on-notification': color;
  '--error': color;
  '--warning': color;
  '--information': color;
  '--success': color;
  '--disabled': color;
  '--header-bg': color;
  '--nav-link': color;
  '--active-nav': color;
  '--input-border': color;
  '--validation-success': color;
  '--active-nav-link': color;
  '--journey-background': color;
}

export const siteDefault: IAppCssProperties = {
  '--background': color.catskillWhite,
  '--primary-one': color.vibrantBlue,
  '--on-primary-one': color.white,
  '--primary-two': color.hollywoodCerise,
  '--on-primary-two': color.white,
  '--primary-three': color.bigStone,
  '--on-primary-three': color.white,
  '--secondary-one': color.lemon,
  '--secondary-two': color.puertoRico,
  '--secondary-three': color.electricLime,
  '--secondary-four': color.treePoppy,
  '--shade-darkest': color.tePapaGreen,
  '--shade-darker': color.blueDianne,
  '--shade-dark': color.fiord,
  '--shade-light': color.athensGray,
  '--shade-lighter': color.alabaster,
  '--on-notification': color.codgray,
  '--error': color.sunglo,
  '--warning': color.texasRose,
  '--information': color.pictonBlue,
  '--success': color.deYork,
  '--disabled': color.boulder,
  '--header-bg': color.mineShaft,
  '--nav-link': color.veryLightPink,
  '--active-nav': color.pinkishRed,
  '--active-nav-link': color.white,
  '--input-border': color.brownishGrey,
  '--validation-success': color.sapGreen,
  '--journey-background': color.aliceBlue,
};
