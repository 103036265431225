import { errorLog } from 'index';
import { Instance, types } from 'mobx-state-tree';
import { api } from 'shared/api/interfaces';
import { flow, getEnv, LoadingStateModel } from '../util';
import { makeTrendsUrl } from './TrendsModel';

const TrendsEmployeesItemModel = types.model('TrendsEmployeeItem', {
  employeeIdpId: types.string,
  displayName: types.string,
  jobTitle: types.string,
  talentCode: types.maybeNull(types.string),
  isFlightRisk: types.boolean,
  isRoleChangePathway: types.boolean,
  isOnProbation: types.boolean,
  isOnLongTermLeave: types.boolean,
  roleChangeTimeFrame: types.maybeNull(types.number),
});

export interface ITrendsEmployeesItem extends Instance<typeof TrendsEmployeesItemModel> {}

export type IRoleCount = { role: string; count: number };

export const TrendsEmployeesModel = types
  .model('TrendsEmployees', {
    value: types.optional(types.array(TrendsEmployeesItemModel), []),
    state: LoadingStateModel,
  })
  .actions((self) => ({
    loadEmployees: flow(function* (
      selectedSquad: string | null
    ): Generator<Promise<ITrendsEmployeesItem[]>, void, ITrendsEmployeesItem[]> {
      if (selectedSquad) {
        const { ajax } = getEnv(self);

        self.state = 'loading';
        try {
          self.value.clear();
          const value = yield ajax
            .get(makeTrendsUrl('Employees'), {
              searchParams: { squadName: selectedSquad },
            })
            .json<api.TrendsEmployeeDto[]>()
            .then((values) => values.map((item) => TrendsEmployeesItemModel.create(item)));
          value.forEach((i) => self.value.push(i));
          self.state = 'done';
        } catch (e) {
          errorLog('Failed to load trend employees', e);
          self.state = 'error';
        }
      }
    }),
  }))
  .views((self) => ({
    sortByName(employees: ITrendsEmployeesItem[]) {
      return employees.sort((a, b) => (a.displayName > b.displayName ? 1 : -1));
    },
    get flightRiskEmployees() {
      return this.sortByName(self.value.filter((e) => e.isFlightRisk));
    },
    get employeesOnLongTermLeave() {
      return this.sortByName(self.value.filter((e) => e.isOnLongTermLeave));
    },
    get employeesOnRoleChangePathways() {
      return this.sortByName(self.value.filter((e) => e.isRoleChangePathway));
    },
    get employeesOnProbation() {
      return this.sortByName(self.value.filter((e) => e.isOnProbation));
    },
    employeesWithMatchingJobTitle(jobTitle: string) {
      return this.sortByName(self.value.filter((e) => e.jobTitle === jobTitle));
    },
    employeesWithMatchingTalentCode(talentCode: string) {
      return this.sortByName(self.value.filter((e) => e.talentCode === talentCode));
    },
    get talentCodeCounts() {
      const counts = self.value.reduce<Record<string, number>>((result, item) => {
        if (item.talentCode) result[item.talentCode] = (result[item.talentCode] || 0) + 1;
        return result;
      }, {});

      return Object.keys(counts).map((key) => {
        return { talentCode: key, count: counts[key] };
      });
    },
    get roleCounts(): IRoleCount[] {
      const counts = self.value.reduce<Record<string, number>>((result, item) => {
        if (item.jobTitle) result[item.jobTitle] = (result[item.jobTitle] || 0) + 1;
        return result;
      }, {});

      return Object.keys(counts).map<IRoleCount>((key) => {
        return { role: key, count: counts[key] };
      });
    },
  }));
