import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useUser } from 'hooks/useUser';
import { useTrends } from 'hooks/useTrends';
import { ColourSet, generateColours } from '../../ColourGenerator';
import { Bar } from 'react-chartjs-2';
import { CustomColorCheckBox } from '../customColorCheckBox/CustomColorCheckBox';
import Skeleton from '@material-ui/lab/Skeleton';
import styles from './SupportingOpportunitiesByMonthChart.module.scss';
import { CustomSelection } from 'views/components/customSelection/CustomSelection';
import { MenuItem } from '@material-ui/core';
import moment from 'moment';
import { ChartData } from 'chart.js';

const startYear = Number(process.env.REACT_APP_SUPPORTING_OPPORTUNITIES_BY_MONTH_STARTING_YEAR);
const currentYear = moment().year();
const years = Array.from(
  { length: Math.max(currentYear - startYear + 1, 1) },
  (_, i) => currentYear - i
);

const CHART_OPTIONS = {
  responsive: true,
  maintainAspectRatio: false,
  legend: {
    display: false,
  },
  scales: {
    xAxes: [
      {
        stacked: true,
        gridLines: {
          display: true,
          drawBorder: true,
          drawOnChartArea: false,
          drawTicks: false,
          lineWidth: 1,
        },
        ticks: {
          padding: 10,
        },
      },
    ],
    yAxes: [
      {
        stacked: true,
        display: true,
      },
    ],
  },
};

export const SupportingOpportunitiesByMonthChart: React.FC = observer(() => {
  const { selectedSquad } = useUser();
  const {
    supportingOpportunitiesByMonth: { state, value, loadSupportingOpportunitiesByMonth },
  } = useTrends();

  const [selectedYear, setSelectedYear] = useState(currentYear.toString());
  // eslint-disable-next-line
  const [supportingOpportunities, setSupportingOpportunities] = useState([] as any[]);
  const [chartData, setChartData] = useState({} as ChartData);

  useEffect(() => {
    loadSupportingOpportunitiesByMonth(selectedSquad, Number(selectedYear)).then(
      (supportingOpps) => {
        const colours = generateColours(supportingOpps.labels.length, ColourSet.TealGreen);
        setSupportingOpportunities(
          supportingOpps.labels.map((label, index) => {
            const supportingOpportunityCounts = [] as number[];
            supportingOpps.data.forEach((d) => {
              const counts = d.counts.find((c) => c.name === label);
              supportingOpportunityCounts.push(counts ? counts.count : 0);
            });

            return {
              name: label,
              checked: false,
              dataSet: {
                label: label,
                backgroundColor: colours[index],
                data: supportingOpportunityCounts,
              },
            };
          })
        );
      }
    );
  }, [loadSupportingOpportunitiesByMonth, selectedSquad, selectedYear]);

  useEffect(() => {
    setChartData({
      labels: value.data.map((d) => d.month),
      datasets: supportingOpportunities.map(
        (supportingOpportunity) => supportingOpportunity.dataSet
      ),
    });
  }, [supportingOpportunities, value]);

  const handleOnchange = (index: number) => {
    setSupportingOpportunities((supportingOpportunities) => {
      supportingOpportunities[index].checked = !supportingOpportunities[index].checked;
      return supportingOpportunities;
    });

    setChartData((chartData: ChartData) => {
      let dataSets = supportingOpportunities
        .filter((supportingOpportunity) => supportingOpportunity.checked)
        .map((supportingOpportunity) => supportingOpportunity.dataSet);
      if (dataSets.length === 0) {
        dataSets = supportingOpportunities.map(
          (supportingOpportunity) => supportingOpportunity.dataSet
        );
      }
      return {
        ...chartData,
        datasets: dataSets,
      };
    });
  };

  return (
    <div className={styles.root}>
      <CustomSelection
        label={'Year'}
        value={selectedYear}
        onChange={(value) => setSelectedYear(value)}
        className={styles.yearSelector}>
        {years.map((t) => {
          const value = t.toString();
          return (
            <MenuItem value={value} key={value}>
              {value}
            </MenuItem>
          );
        })}
      </CustomSelection>
      {state === 'done' ? (
        <>
          <div>
            <Bar data={chartData} options={CHART_OPTIONS} />
          </div>
          <div>
            {supportingOpportunities.map((supportingOpportunity, index) => {
              return (
                <CustomColorCheckBox
                  key={'supportingOpportunityCheckBox_' + index.toString()}
                  index={index}
                  color={supportingOpportunity.dataSet.backgroundColor}
                  label={supportingOpportunity.name}
                  checked={supportingOpportunity.checked}
                  onChange={handleOnchange}
                />
              );
            })}
          </div>
        </>
      ) : (
        <Skeleton variant={'rect'} classes={{ root: styles.skeleton }} />
      )}
    </div>
  );
});
