import React from 'react';
import cn from 'classnames';
import styles from './LoadingIndicator.module.scss';
import { CircularProgress } from '@material-ui/core';

interface ILoadingIndicatorProps {
  text?: string;
  size?: number | string;
  className?: string;
}

export const LoadingIndicator: React.FC<ILoadingIndicatorProps> = ({ text, className, size }) => {
  return (
    <div className={cn(styles.container, className)}>
      <CircularProgress size={size} data-testid={'loading-indicator'} />
      <div className={styles.text}>{text}</div>
    </div>
  );
};
