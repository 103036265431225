import { IFlightRiskStatusModel } from 'domain/store/employeeJourney/FlightRiskModel';
import { useStore } from 'hooks/useStore';
import React, { useState } from 'react';
import { AlertDialog } from 'views/components/alertDialog/AlertDialog';
import { FlightRisk } from 'views/routes/employeeJourney/components/employeeSidebar/components/flightRisk/FlightRisk';
export interface FlightRiskFlagProps {
  employeeId: string;
  employeeDisplayName: string;
  flightRiskStatusModel: IFlightRiskStatusModel | null;
  disabled: boolean;
}

export const FlightRiskFlag: React.FC<FlightRiskFlagProps> = ({
  flightRiskStatusModel,
  employeeId,
  employeeDisplayName,
  disabled,
}) => {
  const [isFlightRiskDialogOpen, setIsFlightRiskDialogOpen] = useState<boolean>(false);
  const [isLoadingFlightRisk, setLoadingFlightRisk] = useState<boolean>(false);
  const store = useStore();
  const toggleFlightRisk = () => {
    setIsFlightRiskDialogOpen(true);
  };

  const resetFlightRiskDialogStates = () => {
    setIsFlightRiskDialogOpen(false);
  };

  const onFlightRiskAgree = () => {
    setLoadingFlightRisk(true);

    flightRiskStatusModel
      ?.toggleFlightRiskStatus(employeeId)
      .then((r) => r)
      .catch((e) => {
        if (e?.response?.status === 409) {
          store.notifications.addError(
            'Conflict occurred saving changes, please refresh the page, and try again after.'
          );
        } else if (e?.response?.status === 403) {
          store.notifications.addError('Not authorised to save changes.');
        } else {
          store.notifications.addError('Unable to change flight risk status, please try again.');
        }
      })
      .finally(() => setLoadingFlightRisk(false));

    resetFlightRiskDialogStates();
  };

  const onFlightRiskDisagree = () => {
    resetFlightRiskDialogStates();
  };

  return (
    <>
      <FlightRisk
        flightRiskStatusModelValue={flightRiskStatusModel?.value || null}
        onToggle={toggleFlightRisk}
        loading={isLoadingFlightRisk}
        disabled={disabled}
      />
      <AlertDialog
        isOpen={isFlightRiskDialogOpen}
        title={'Flight Risk Change'}
        text={
          flightRiskStatusModel?.value?.isFlightRisk
            ? `You are about to clear the Flight Risk flag for ${employeeDisplayName}`
            : `You are about to set the Flight Risk flag to ${employeeDisplayName}?`
        }
        onAgreeClick={onFlightRiskAgree}
        onDisagreeClick={onFlightRiskDisagree}
      />
    </>
  );
};
