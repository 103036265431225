import React from 'react';

import { Link } from 'react-router-dom';

import styles from './Nav.module.scss';

export interface NavItem {
  title: string;
  link: string;
  isActive: boolean;
}

interface Props {
  className?: string;
  items: NavItem[];
  currentNav: string;
}

export const Nav: React.FC<Props> = ({ className, items, currentNav }) => (
  <ul className={[styles.navContainer, className].join(' ')}>
    {items.map((item, index) => (
      <li key={index} className={item.isActive ? styles.active : undefined}>
        <Link
          to={item.link}
          onClick={(e) => {
            if (item.link === currentNav) e.preventDefault();
          }}>
          {item.title}
        </Link>
      </li>
    ))}
  </ul>
);
