import { ILongTermLeaveModel } from 'domain/store/employeeJourney/LongTermLeaveModel';
import { useStore } from 'hooks/useStore';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { LongTermLeaveFlagCheckbox } from 'views/routes/employeeJourney/components/employeeSidebar/components/longTermLeaveFlagCheckbox/LongTermLeaveFlagCheckbox';
import { DialogWithDatepicker } from './DialogWithDatepicker';

export interface LongTermLeaveFlagProps {
  employeeId: string;
  employeeDisplayName: string;
  longTermLeaveModel: ILongTermLeaveModel | null;
  disabled: boolean;
}

export const LongTermLeaveFlag: React.FC<LongTermLeaveFlagProps> = ({
  longTermLeaveModel,
  employeeId,
  employeeDisplayName,
  disabled,
}) => {
  const store = useStore();
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const employeeExpectedReturn = longTermLeaveModel?.value?.expectedReturn ?? null;
  const [expectedReturn, setExpectedReturn] = useState<string | null>(employeeExpectedReturn);
  const filterNull = useCallback(
    (source: string | null) => {
      return !source ? employeeExpectedReturn : source;
    },
    [employeeExpectedReturn]
  );
  const [newExpectedReturn, setNewExpectedReturn] = useState<string | null>(
    filterNull(expectedReturn)
  );

  useEffect(() => {
    setExpectedReturn(employeeExpectedReturn);
    setNewExpectedReturn(filterNull(employeeExpectedReturn));
  }, [employeeExpectedReturn, filterNull]);

  const toggle = () => {
    setIsDialogOpen(true);
  };

  const resetDialogStates = () => {
    setNewExpectedReturn(filterNull(expectedReturn));
    setIsDialogOpen(false);
    setIsDisabled(true);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setNewExpectedReturn(e.target.value as string);
    setIsDisabled(false);
  };

  const onAgreeClick = () => {
    if (longTermLeaveModel) {
      setIsLoading(true);
      longTermLeaveModel
        .toggleLongTermLeaveStatus(
          employeeId,
          newExpectedReturn === expectedReturn ? null : newExpectedReturn
        )
        .catch(() => {
          store.notifications.addError('Expected return must not be in the past');
        })
        .finally(() => setIsLoading(false));
    }

    resetDialogStates();
  };

  const onDisagreeClick = () => {
    resetDialogStates();
  };

  return (
    <>
      <LongTermLeaveFlagCheckbox
        longTermLeave={longTermLeaveModel?.value ?? null}
        onToggle={toggle}
        loading={isLoading}
        disabled={disabled}
      />
      <DialogWithDatepicker
        isOpen={isDialogOpen}
        title={'Long Term Leave'}
        onAgreeClick={onAgreeClick}
        onDisagreeClick={onDisagreeClick}
        currentExpectedReturn={expectedReturn}
        onDateChange={handleChange}
        employeeDisplayName={employeeDisplayName}
        isDisabled={isDisabled}
      />
    </>
  );
};
