import React, { useEffect, useRef, useState } from 'react';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import { observer } from 'mobx-react-lite';
import { useAdmin } from 'hooks/useAdmin';
import Box from '@material-ui/core/Box/Box';
import { SquadEmployeeRow } from './components/SquadEmployeeRow/SquadEmployeeRow';
import { useUser } from 'hooks/useUser';
import { useStore } from 'hooks/useStore';
import { Grid, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { IEmployeeItem } from 'domain/store/admin/EmployeeListModel';
import { getSnapshot } from 'mobx-state-tree';
import styles from './AssignSquads.module.scss';
import { debounce, isEmpty } from 'lodash';
import { AssignSquadDialog } from './components/AssignSquadDialog/AssignSquadDialog';
import { ChangeAccessDialog } from '../manageRoles/components/changeAccess/ChangeAccessDialog';
import { usePermissions } from '../../../../../hooks/usePermissions';

enum AssignmentState {
  Closed = 0,
  AssignSquad = 1,
  AssignRole = 2,
}

export const AssignSquads: React.FC = observer(() => {
  const {
    SquadAssignment: {
      squadAssignmentEmployees,
      loadEmployeesEligibleForSquadAssignment,
      squadAssignmentEmployeesSearch,
      searchEmployeesEligibleForSquadAssignment,
      selectedEmployee,
      setSquadAssignmentEmployees,
      searchState,
      state,
    },
  } = useAdmin();
  const { accessLevel, squadName, assignedSquads, getAllSquads } = useUser();
  const { canViewAllSquads } = usePermissions();
  const store = useStore();
  const [searchValue, setSearchValue] = useState<string | null>(null);
  const [squads, setSquads] = useState<string[]>([]);
  const [allSquads, setAllSquads] = useState<string[]>([]);
  const inputTextRef = useRef<HTMLInputElement>();
  const [employeeToAssign, setEmployeeToAssign] = useState<IEmployeeItem | null>(null);
  const [currentAssignmentState, setCurrentAssignmentState] = useState<AssignmentState>(
    AssignmentState.Closed
  );

  const searchEmployee = useRef(
    debounce((query: string) => {
      const search = isEmpty(query) ? undefined : query;
      searchEmployeesEligibleForSquadAssignment(search, false).catch(() => {
        store.notifications.addError('Unable to load employees, please try again.');
      });
    }, 600)
  ).current;

  const getSearchPlaceholderText = () => {
    if (searchState === 'error') return 'Could not load results, try again';
    if (searchValue === null) return 'Type name to search';
    return searchState === 'loading' ? 'Searching' : 'No results';
  };

  useEffect(() => {
    loadEmployeesEligibleForSquadAssignment().catch(() => {
      store.notifications.addError('Unable to load employees, please try again.');
    });
  }, [loadEmployeesEligibleForSquadAssignment, store.notifications]);

  useEffect(() => {
    getAllSquads().then((result) => {
      const allowedAccessSquads = canViewAllSquads ? result : [...assignedSquads, squadName];
      const newSquadList = result.filter((canView) => allowedAccessSquads.includes(canView));
      setSquads(newSquadList);
      setAllSquads(result);
    });
  }, [assignedSquads, getAllSquads, squadName, canViewAllSquads]);

  const isEmployeeAccessLevelBelowUser = (employeeAccessLevel: string) => {
    if (accessLevel === 'A' && employeeAccessLevel !== 'M' && employeeAccessLevel !== 'A')
      return true;
    return accessLevel === 'M' && employeeAccessLevel !== 'M';
  };

  const handleClose = () => {
    setEmployeeToAssign(null);
    setCurrentAssignmentState(AssignmentState.Closed);
  };

  return (
    <div className={styles.root}>
      <Grid>
        <Grid item xs={4}>
          <Autocomplete
            getOptionLabel={(option) => option?.name}
            options={getSnapshot(squadAssignmentEmployeesSearch) as IEmployeeItem[]}
            noOptionsText={getSearchPlaceholderText()}
            autoComplete
            filterSelectedOptions
            className={styles.search}
            value={selectedEmployee}
            onInputChange={(_, newInputValue) => {
              if (searchValue !== newInputValue) {
                setSearchValue(newInputValue);
                searchEmployee(newInputValue);
              }
            }}
            getOptionSelected={(option: IEmployeeItem, value: IEmployeeItem) =>
              option.idpId === value?.idpId
            }
            onChange={(_, value, reason) => {
              if (reason === 'clear') {
                loadEmployeesEligibleForSquadAssignment().catch(() => {
                  store.notifications.addError('Unable to load employees, please try again.');
                });
              }
              setSquadAssignmentEmployees(value);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                variant="outlined"
                placeholder="Search employees"
                InputLabelProps={{ shrink: false }}
                InputProps={{
                  ...params.InputProps,
                  inputRef: inputTextRef,
                }}
              />
            )}
            renderOption={(option: IEmployeeItem) => {
              return <div>{option.name}</div>;
            }}
          />
        </Grid>
        <Box margin="auto" mt={3}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Position</TableCell>
                  <TableCell>Access Level</TableCell>
                  <TableCell>Squads</TableCell>
                  <TableCell align="center">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {state === 'done'
                  ? Array.from(squadAssignmentEmployees).map((employee) => (
                      <SquadEmployeeRow
                        employee={employee}
                        key={employee.idpId}
                        canChangeAccess={isEmployeeAccessLevelBelowUser(employee.accessLevel)}
                        assignEmployee={(e) => {
                          setEmployeeToAssign(e);
                          setCurrentAssignmentState(AssignmentState.AssignSquad);
                        }}
                      />
                    ))
                  : Array.from({ length: 6 }, (v, k) => k).map((i) => (
                      <SquadEmployeeRow
                        key={i}
                        employee={null}
                        canChangeAccess={false}
                        assignEmployee={setEmployeeToAssign}
                      />
                    ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Grid>
      {employeeToAssign && (
        <>
          <AssignSquadDialog
            employee={employeeToAssign}
            squads={squads}
            allSquads={allSquads}
            isOpen={currentAssignmentState === AssignmentState.AssignSquad}
            closeDialog={handleClose}
            saveDialog={() => setCurrentAssignmentState(AssignmentState.AssignRole)}
          />
          <ChangeAccessDialog
            isOpen={currentAssignmentState === AssignmentState.AssignRole}
            employee={employeeToAssign}
            closeDialog={handleClose}
          />
        </>
      )}
    </div>
  );
});
