import React from 'react';
import styles from 'views/components/validationIcon/ValidationIcon.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';

export type ValidationStatus = 'hidden' | 'valid' | 'error';

interface IStatusIconProps {
  status: ValidationStatus;
  className?: string;
}

export const ValidationIcon: React.FC<IStatusIconProps> = ({ status, className }) => {
  return (
    <>
      {status !== 'hidden' && (
        <div className={[styles.validationIconContainer, className].filter((v) => v).join(' ')}>
          {status === 'error' && (
            <FontAwesomeIcon icon={faTimesCircle} className={styles.validationError} />
          )}
          {status === 'valid' && (
            <FontAwesomeIcon icon={faCheckCircle} className={styles.validationSuccess} />
          )}
        </div>
      )}
    </>
  );
};
