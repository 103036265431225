import { Box, FormControl, Link, Tooltip, Button, IconButton } from '@material-ui/core';
import { faTimes, faUpload } from '@fortawesome/free-solid-svg-icons';
import React, { useState } from 'react';
import styles from '../../RoleChange.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import { AlertDialog } from 'views/components/alertDialog/AlertDialog';
import { useStore } from '../../../../../../../hooks/useStore';
import { RoleChangeAttachmentFile } from 'domain/store/employeeJourney/RoleChangeModel';

interface IRoleChangeBusinessCaseAttachment {
  fileName: string | undefined;
  onBusinessCaseFileModified: (file: RoleChangeAttachmentFile, fileModified: boolean) => void;
  onDownload: (fileName: string | undefined) => void;
  onDelete: (fileName: string | undefined) => void;
  disabled: boolean;
  formError: string | undefined;
  showValidationErrors: boolean;
}

export const RoleChangeBusinessCaseAttachment: React.FC<IRoleChangeBusinessCaseAttachment> = (
  props: IRoleChangeBusinessCaseAttachment
) => {
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const {
    notifications: { addError },
  } = useStore();
  const [fileModified, setFileModified] = useState<boolean>(false);
  const allowed_types = [
    'application/pdf',
    'text/plain',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  ];

  const onAgree = () => {
    props.onDelete(props.fileName);
    setIsDialogOpen(false);
  };

  const preFileUploadCheck = (selectedFiles: FileList | null) => {
    if (!selectedFiles || selectedFiles.length === 0) {
      addError('Please select a file');
      return;
    }

    const selectedFile = selectedFiles[0];

    if (!allowed_types.includes(selectedFile.type)) {
      addError('Only Word, Text and PDF files are allowed ( Doc(x) | Txt | PDF)');
      return;
    }

    const getFileContent = new Promise(
      (resolve: (file: RoleChangeAttachmentFile) => void, reject) => {
        const reader = new FileReader();
        reader.onloadend = function () {
          resolve({
            name: selectedFile.name,
            base64: reader.result,
            contentType: selectedFile.type,
          } as RoleChangeAttachmentFile);
        };
        reader.readAsDataURL(selectedFile);
      }
    );
    getFileContent.then((attachment) => {
      setFileModified(true);
      props.onBusinessCaseFileModified(attachment, true);
    });
  };

  return (
    <FormControl
      component="fieldset"
      className={cn(styles.formItem, {
        [styles.withValidation]: props.showValidationErrors,
      })}
      disabled={props.disabled}>
      <label>
        Business Case Attachment
        {!props.disabled && (
          <Box component={'span'}>
            <input
              accept={allowed_types.join()}
              id="contained-button-file"
              type="file"
              onChange={(e) => {
                preFileUploadCheck(e.target.files);
                e.target.value = '';
              }}
              hidden
            />
            <label htmlFor="contained-button-file">
              <Button
                variant="contained"
                color="primary"
                component="span"
                className={styles.attachmentButton}>
                Upload
                <FontAwesomeIcon icon={faUpload} size="sm" />
              </Button>
            </label>
          </Box>
        )}
      </label>
      <Box component={'span'} className={styles.attachmentContainer}>
        {!!props.fileName && !fileModified && (
          <Tooltip title="Download File">
            <Link onClick={() => props.onDownload(props.fileName)} target="_blank">
              <span className={styles.fileName}>{props.fileName}</span>
            </Link>
          </Tooltip>
        )}
        {!!props.fileName && fileModified && <span>{props.fileName}</span>}
        {!props.disabled && !!props.fileName && (
          <Tooltip title="Remove File">
            <IconButton
              color="secondary"
              data-testid="delete-file"
              className={styles.deleteButton}
              component="label"
              onClick={() => setIsDialogOpen(true)}>
              <FontAwesomeIcon icon={faTimes} size={'sm'} />
            </IconButton>
          </Tooltip>
        )}
        {!props.fileName && <span>No files attached</span>}
      </Box>
      {props.formError && props.showValidationErrors && (
        <div className={styles.errorText}>{props.formError}</div>
      )}
      <AlertDialog
        isOpen={isDialogOpen}
        title={'Remove Business Case Attachment'}
        text={`You are about to remove business case attachment from Role Change`}
        onAgreeClick={onAgree}
        onDisagreeClick={() => setIsDialogOpen(false)}
      />
    </FormControl>
  );
};
