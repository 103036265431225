import { observer } from 'mobx-react-lite';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Shell } from 'views/components/shell/Shell';
import { EmployeeJourney } from 'views/routes/employeeJourney/EmployeeJourney';
import { MyNotes } from 'views/routes/myNotes/MyNotes';
import { Admin } from './admin/Admin';
import { NotFound } from './notFound/NotFound';
import { Squad } from './squad/Squad';
import { EmployeeJourneys } from 'views/routes/employeeJourneys/EmployeeJourneys';
import { Unauthorised } from './unauthorised/Unauthorised';
import { usePermissions } from 'hooks/usePermissions';
import { CatchUps } from './catchUps/CatchUps';

export const EMPLOYEE_JOURNEYS_ROUTE_PATH = '/employees';
export const CREATE_NOTE_ROUTE_PATH = '/notes/create';
export const EDIT_NOTE_ROUTE_PATH = '/notes/edit';
export const SQUAD_ROUTE_PATH = '/squad';
export const ADMIN_ROUTE_PATH = '/admin';
export const UNAUTHORISED_ROUTE_PATH = '/unauthorised';
export const CATCH_UPS_ROUTE_PATH = '/catch-ups';

export const Routes: React.FC = observer(() => {
  const {
    canViewSquadTab,
    canViewEmployeeTab,
    canViewNoteTab,
    canManageRoles,
    canViewCatchupGroupTab,
    canAssignSquads,
  } = usePermissions();

  return (
    <Shell>
      <Switch>
        <Route exact path="/">
          {canViewSquadTab ? (
            <Squad />
          ) : canViewEmployeeTab ? (
            <EmployeeJourneys />
          ) : (
            <Unauthorised />
          )}
        </Route>

        {canViewNoteTab && (
          <Route exact path={`${EDIT_NOTE_ROUTE_PATH}/:noteId`}>
            <MyNotes mode={'edit'} />
          </Route>
        )}

        {canViewNoteTab && (
          <Route exact path={CREATE_NOTE_ROUTE_PATH}>
            <MyNotes mode={'create'} />
          </Route>
        )}

        {canViewCatchupGroupTab && (
          <Route path={CATCH_UPS_ROUTE_PATH}>
            <CatchUps />
          </Route>
        )}

        {canViewEmployeeTab && (
          <Route exact path={`${EMPLOYEE_JOURNEYS_ROUTE_PATH}`}>
            <EmployeeJourneys />
          </Route>
        )}

        {canViewEmployeeTab && (
          <Route path={`${EMPLOYEE_JOURNEYS_ROUTE_PATH}/:idpId`}>
            <EmployeeJourney />
          </Route>
        )}

        {canViewSquadTab && (
          <Route path={SQUAD_ROUTE_PATH}>
            <Squad />
          </Route>
        )}

        {(canManageRoles || canAssignSquads) && (
          <Route path={ADMIN_ROUTE_PATH}>
            <Admin />
          </Route>
        )}

        <Route exact path={UNAUTHORISED_ROUTE_PATH}>
          <Unauthorised />
        </Route>
        <Route>
          <NotFound />
        </Route>
      </Switch>
    </Shell>
  );
});
