import React from 'react';
import styles from 'views/routes/notFound/NotFound.module.scss';

export const NotFound: React.FC = function () {
  return (
    <div className={styles.notFoundRoot}>
      <h2>Oh no!</h2>
      <p>The page you were looking for cannot be found. Try clicking on one of the menu links.</p>
    </div>
  );
};
