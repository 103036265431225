import { Chip } from '@material-ui/core';
import React from 'react';
import styles from './ArchivedChip.module.scss';

export const ArchivedChip: React.FC = () => {
  return (
    <Chip
      label="Archived"
      variant="outlined"
      size="small"
      color="primary"
      className={styles.archivedChip}
    />
  );
};
