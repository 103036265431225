import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  FormControl,
  Grid,
  Link,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import { ChevronRightRounded, Visibility, VisibilityOff } from '@material-ui/icons';
import { INote, NoteSensitivity } from 'domain/store/NoteModel';
import { useStore } from 'hooks/useStore';
import { useUser } from 'hooks/useUser';
import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Avatar } from 'views/components/avatar/Avatar';
import { SensitiveAttribute } from 'views/components/sensitiveAttribute/SensitiveAttribute';
import { FaceToFaceDiscussionAttribute } from 'views/components/faceToFaceDiscussionAttribute/FaceToFaceDiscussionAttribute';
import { Tag } from 'views/components/tag/Tag';
import { EMPLOYEE_JOURNEYS_ROUTE_PATH } from 'views/routes/Routes';
import { NoteItemContent } from 'views/routes/squad/components/noteReview/components/NoteItemContent';
import styles from './NoteItem.module.scss';

interface INoteItemProps {
  note: INote;
  onNoteApproved: () => void;
}

export const NoteItemHeader: React.FC<{ note: INote }> = ({ note }) => {
  return (
    <CardHeader
      avatar={
        <Avatar
          className={styles.avatar}
          givenName={note.employee.givenName}
          familyName={note.employee.familyName}
          idpId={note.employee.idpId}
        />
      }
      title={
        <Grid container direction={'row'} justify={'space-between'} alignItems={'flex-start'}>
          <Grid item xs>
            <Grid container direction={'column'}>
              <Grid item container alignItems={'center'}>
                <Typography variant={'h5'} component={'span'} className={styles.subject}>
                  {note.subject}
                  {note.isPeopleLeaderConversation === true && (
                    <Chip
                      className={styles.plChat}
                      color="secondary"
                      variant="default"
                      label={'PL CHAT'}
                    />
                  )}
                </Typography>
              </Grid>
              <Grid item className={styles.nameContainer}>
                <Grid container spacing={2} alignItems={'baseline'}>
                  <Grid item>
                    <Typography component={'span'}>
                      <Link
                        variant="inherit"
                        color="textPrimary"
                        component={RouterLink}
                        to={`${EMPLOYEE_JOURNEYS_ROUTE_PATH}/${note.employee.idpId}`}>
                        {note.employee.displayName}
                      </Link>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography className="date" color={'textSecondary'} component={'span'}>
                      {note.eventDateMoment!.format('DD/MM/YYYY')}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs className={styles.tagContainer}>
                {note.tags.map((tag, index) => (
                  <Tag
                    key={tag.tagId}
                    name={tag.name}
                    isSensitive={tag.isSensitive}
                    reviewRequired={tag.reviewRequired}
                  />
                ))}
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container direction={'column'}>
              <Grid item>
                <Box textAlign="right">
                  <Typography variant={'subtitle2'} color="textSecondary" component={'span'}>
                    Author: {note.author.displayName}
                  </Typography>
                </Box>
              </Grid>
              <Grid item>
                <Box textAlign="right">
                  <Typography color="textSecondary" variant={'subtitle2'} component={'span'}>
                    Last modified: {note.modifiedDateMoment!.fromNow()}
                  </Typography>
                </Box>
              </Grid>
              <FaceToFaceDiscussionAttribute
                isFaceToFaceDiscussion={!!note.isFaceToFaceDiscussion}
              />
              <Grid item className={note.status === 'pending' ? styles.reviewStatus : ''}>
                <Box textAlign="right">
                  {note.status === 'pending' && <span>To be reviewed</span>}
                  {note.sensitivity === 'sensitive' && <SensitiveAttribute isSensitive={true} />}
                  {note.sensitivity === 'non-sensitive' && (
                    <SensitiveAttribute isSensitive={false} />
                  )}
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      }
    />
  );
};

export const NoteItem: React.FC<INoteItemProps> = ({ note, onNoteApproved }) => {
  const store = useStore();
  const { review } = useUser();
  const [selection, setSelection] = useState<NoteSensitivity>('none');

  const isValidSelection = () =>
    (selection === 'sensitive' || selection === 'non-sensitive') && selection !== note.sensitivity;

  const updateNoteSensitivity = () => {
    if (isValidSelection()) {
      review
        .updateNoteSensitivity(selection, note.noteId)
        .then(() => onNoteApproved())
        .catch(() => {
          store.notifications.addError('Unable to approve the note, please try again.');
        });
    }
  };

  useEffect(() => {
    setSelection(note.sensitivity);
  }, [note]);

  return (
    <Card className={styles.noteItem}>
      <Box mx="auto" width="95%">
        <NoteItemHeader note={note} />
        <CardContent>
          <Typography variant="body1" color="textPrimary" component="div">
            <NoteItemContent content={note.content} />
          </Typography>
          <Box mt={4}>
            <Grid
              container
              spacing={4}
              direction={'row'}
              justify={'flex-end'}
              alignItems={'flex-end'}>
              <Grid item>
                <div>Visibility Settings</div>
                <FormControl variant="outlined" className={styles.visibilitySelector}>
                  <Select
                    value={selection}
                    onChange={(e) => setSelection(e.target.value as NoteSensitivity)}>
                    <MenuItem value={'none'} disabled>
                      <span className={styles.menuItem}>Choose settings...</span>
                    </MenuItem>
                    <MenuItem value={'sensitive'}>
                      <span className={styles.menuItem}>
                        <Visibility className={styles.menuItemIcon} />
                        Mark as Sensitive
                      </span>
                    </MenuItem>
                    <MenuItem value={'non-sensitive'}>
                      <span className={styles.menuItem}>
                        <VisibilityOff className={styles.menuItemIcon} />
                        Mark as Non-Sensitive
                      </span>
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item>
                <Button
                  variant={'contained'}
                  color={'primary'}
                  size={'large'}
                  className={styles.xlButton}
                  disabled={!isValidSelection()}
                  onClick={() => updateNoteSensitivity()}
                  endIcon={<ChevronRightRounded />}>
                  Submit
                </Button>
              </Grid>
              {isValidSelection() && selection === 'sensitive' && (
                <span className={styles.sensitiveInfoText}>
                  Marking this note as sensitive will make this note visible to only M & A users.
                </span>
              )}
              {isValidSelection() && selection === 'non-sensitive' && (
                <span className={styles.sensitiveInfoText}>
                  Marking this note as non-sensitive will make this note visible to M, A, B1 & B2
                  users. C users will not have visibility.
                </span>
              )}
            </Grid>
          </Box>
        </CardContent>
      </Box>
    </Card>
  );
};
