import React from 'react';
import { Grid } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

import styles from './SquadNotSelected.module.scss';

export const SquadNotSelected: React.FC = () => {
  return (
    <div className={styles.container}>
      <Grid
        container
        className={styles.container}
        direction="column"
        justify="center"
        alignItems="center"
        spacing={4}>
        <Grid item>
          <FontAwesomeIcon className={styles.iconColor} icon={faInfoCircle} size={'10x'} />
        </Grid>
        <Grid className={styles.bold} item>
          No Squad Selected
        </Grid>
        <Grid item>Select a squad from the dropdown menu in the header above.</Grid>
      </Grid>
    </div>
  );
};
