import React, { useEffect, useState } from 'react';
import { TextField } from '@material-ui/core';
import styles from './SquadSelect.module.scss';
import { IUser } from 'domain/store/UserModel';
import { observer } from 'mobx-react-lite';
import { Autocomplete } from '@material-ui/lab';
import cn from 'classnames';

export const SquadSelect: React.FC<{ user: IUser }> = observer(({ user }) => {
  const {
    permissions: { canViewAllSquads },
    selectedSquad,
    setSquad,
    getAllSquads,
    assignedSquads,
  } = user;

  const [squads, setSquads] = useState<string[]>(assignedSquads);

  useEffect(() => {
    if (canViewAllSquads) {
      getAllSquads().then((s) => {
        setSquads(
          Array.from(
            new Set<string>([...s])
          )
        );
      });
    }
  }, [getAllSquads, canViewAllSquads]);

  if (!canViewAllSquads && assignedSquads.length === 0) return null;

  return (
    <>
      <Autocomplete
        getOptionLabel={(option) => option}
        className={cn(styles.roundInputBox)}
        options={squads}
        value={selectedSquad ? selectedSquad : undefined}
        onChange={(e, v) => (v === null ? setSquad('') : setSquad(v as string))}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Select Squad"
            variant="outlined"
            fullWidth
            InputLabelProps={{ shrink: false }}
            InputProps={{
              ...params.InputProps,
            }}
          />
        )}
      />
    </>
  );
});
