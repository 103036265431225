import { useUser } from 'hooks/useUser';
import React, { ReactNode, useEffect } from 'react';
import { Link as RouterLink, Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { SquadFeed } from './components/squadFeed/SquadFeed';
import { AppBar, Badge, Tab, Tabs } from '@material-ui/core';
import { NoteReview } from 'views/routes/squad/components/noteReview/NoteReview';
import { SquadTrends } from './components/squadTrends/SquadTrends';
import styles from './Squad.module.scss';
import { useBackToTopButton } from 'hooks/useBackToTopButton';
import { BackToTopButton } from 'views/routes/employeeJourney/components/employeeTimeline/components/backToTopButton/BackToTopButton';
import { observer } from 'mobx-react-lite';
import { TalentAnalysisTool } from 'views/routes/squad/components/talentAnalysisTool/talentAnalysisTool';
import { usePermissions } from 'hooks/usePermissions';
import { RoleChangeTable } from './components/roleChangeTable/RoleChangeTable';
import { SquadNotSelected } from 'views/components/squadNotSelected/SquadNotSelected';

interface TabItem {
  link: string;
  title: ReactNode;
  component: ReactNode;
  disabled: boolean;
  testId?: string;
}

export const Squad = observer(() => {
  const {
    canViewSquadTab,
    squads,
    employees: { roleChanges },
    canView,
  } = usePermissions();

  if (!canViewSquadTab) return null;

  const location = useLocation();
  const { review, selectedSquad } = useUser();

  useEffect(() => {
    if (selectedSquad && squads.canViewNoteReview) {
      review.getNumberPendingNotes(selectedSquad);
    }
  }, [review, review.reviewState, squads.canViewNoteReview, selectedSquad]);

  const {
    showBackToTopButton,
    scrollToTopOfTimeline,
    scrollableContainerRef,
  } = useBackToTopButton();

  const tabItems: TabItem[] = [
    {
      title: 'Trends',
      testId: 'squad-trends-tab-item',
      component: selectedSquad ? <SquadTrends /> : <SquadNotSelected />,
      link: 'trends',
      disabled: !squads.canViewTrends,
    },
    {
      title: 'Squad Feed',
      testId: 'squad-feed-tab-item',
      component: selectedSquad ? <SquadFeed /> : <SquadNotSelected />,
      link: 'feed',
      disabled: !squads.canViewFeed,
    },
    {
      title: selectedSquad ? (
        <Badge color={'primary'} badgeContent={review.numberOfPendingNotes}>
          Review
        </Badge>
      ) : (
        'Review'
      ),
      component: <NoteReview />,
      link: 'review',
      disabled: !squads.canViewNoteReview,
    },
    {
      title: 'Talent Analysis Tool',
      link: 'talent',
      component: selectedSquad ? <TalentAnalysisTool /> : <SquadNotSelected />,
      disabled: !squads.canViewTalentAnalysis,
    },
    {
      title: 'Role Change',
      link: 'rolechange',
      component: selectedSquad ? <RoleChangeTable /> : <SquadNotSelected />,
      disabled: !canView(roleChanges),
    },
  ].filter((t) => !t.disabled);

  const activeTab: number = tabItems.findIndex(
    (item) => location.pathname === `/squad/${item.link}`
  );

  return (
    <div className={styles.squadRoot}>
      <AppBar position={'static'} color={'default'} className={styles.squadAppBar}>
        <Tabs
          value={activeTab === -1 ? 0 : activeTab}
          centered={true}
          classes={{ indicator: styles.indicator }}>
          {tabItems.map((item, index) => (
            <Tab
              component={RouterLink}
              to={`/squad/${item.link}`}
              key={index}
              label={item.title}
              data-testid={item.testId}
              disabled={item.disabled}
              className={styles.appBarTab}
            />
          ))}
        </Tabs>
      </AppBar>
      <div className={styles.tabBodyContainer} ref={scrollableContainerRef}>
        <Switch>
          {tabItems.map(
            (item) =>
              !item.disabled && ( // prevent the user from just using updating the url
                <Route path={`/squad/${item.link}`} key={item.link}>
                  {item.component}
                </Route>
              )
          )}
          <Redirect to={'/squad/' + (tabItems.length === 0 ? '' : tabItems[0].link)} />
        </Switch>
        {showBackToTopButton && (
          <div className={styles.backToTopContainer}>
            <BackToTopButton scrollToTop={scrollToTopOfTimeline} edge={'end'} />
          </div>
        )}
      </div>
    </div>
  );
});
