import { useEffect, useState } from 'react';

import { useGraph } from './useGraph';

interface IEmployeePhotoResult {
  photo?: string | null;
  isLoading: boolean;
}

export function useEmployeePhoto(employeeId?: string): IEmployeePhotoResult {
  const { searchForEmployeePhoto } = useGraph();
  const [photo, setPhoto] = useState<string | undefined | null>(undefined);
  const [isLoading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    let active = true;
    if (employeeId) {
      setLoading(true);
      searchForEmployeePhoto(employeeId)
        .then((photoUri) => {
          active && setPhoto(photoUri);
        })
        .catch(() => active && setPhoto(null))
        .finally(() => active && setLoading(false));
    } else {
      active && setPhoto(undefined);
    }
    return () => {
      active = false;
    };
  }, [employeeId, searchForEmployeePhoto]);

  return { photo, isLoading };
}
