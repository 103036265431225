import React, { useEffect, useRef, useState } from 'react';
import styles from './ManageRoles.module.scss';
import { useUser } from 'hooks/useUser';
import { useAdmin } from 'hooks/useAdmin';
import { SquadNotSelected } from 'views/components/squadNotSelected/SquadNotSelected';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TablePagination from '@material-ui/core/TablePagination';
import { observer } from 'mobx-react-lite';
import { Autocomplete } from '@material-ui/lab';
import { Checkbox, FormControlLabel, TextField } from '@material-ui/core';
import { IEmployeeItem } from 'domain/store/admin/EmployeeListModel';
import { EmployeeListRow } from './components/employeeListRow/EmployeeListRow';
import { ChangeAccessDialog } from './components/changeAccess/ChangeAccessDialog';
import { TablePaginationActions } from 'views/components/table/TablePaginationActions';

export const ManageRoles: React.FC = observer(() => {
  const { selectedSquad, idpId, accessLevel } = useUser();
  const {
    employees: { employees, loadEmployees, state },
  } = useAdmin();

  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [searchedEmployee, setSearchedEmployee] = useState<IEmployeeItem | null>(null);
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState<IEmployeeItem[]>([]);
  const [showAllUnknownRoles, setShowAllUnknownRoles] = useState(false);
  const inputTextRef = useRef<HTMLInputElement>();
  const [employeeToAssign, setEmployeeToAssign] = useState<IEmployeeItem | null>(null);
  const [changeAccessDialogIsOpen, setChangeAccessDialogIsOpen] = React.useState(false);

  useEffect(() => {
    loadEmployees(selectedSquad, true);
  }, [loadEmployees, selectedSquad]);

  useEffect(() => {
    setPage(0);
  }, [employees, searchedEmployee, showAllUnknownRoles]);

  useEffect(() => {
    if (!inputValue) {
      setOptions([]);
      return;
    }

    const pattern = new RegExp(`^${inputValue}`, 'i');

    const givenNameMatches = employees
      .filter((e) => e.givenName.match(pattern))
      .sort((a, b) => a.givenName.localeCompare(b.givenName));

    const familyNameMatches = employees
      .filter((e) => e.familyName.match(pattern) && !givenNameMatches.includes(e))
      .sort((a, b) => a.familyName.localeCompare(b.familyName));

    setOptions([...givenNameMatches, ...familyNameMatches]);
  }, [inputValue, employees]);

  const filteredEmployees = employees
    .filter((e) => searchedEmployee || showAllUnknownRoles || e.accessLevel !== 'Unknown')
    .filter((e) => !searchedEmployee || e.idpId === searchedEmployee.idpId);

  const isEmployeeAccessLevelBelowUser = (employeeAccessLevel: string) => {
    if (accessLevel === 'A' && employeeAccessLevel !== 'M' && employeeAccessLevel !== 'A')
      return true;
    return accessLevel === 'M' && employeeAccessLevel !== 'M';
  };

  const handleClose = () => {
    setEmployeeToAssign(null);
    setChangeAccessDialogIsOpen(false);
  };
  const handleEmployeeAssignment = (e: IEmployeeItem | null) => {
    setEmployeeToAssign(e);
    setChangeAccessDialogIsOpen(true);
  };

  return (
    <div className={styles.root}>
      {selectedSquad ? (
        <>
          <div className={styles.tableControls}>
            <Autocomplete
              getOptionLabel={(option) => option.name}
              className={styles.search}
              options={options}
              autoComplete
              filterSelectedOptions
              value={searchedEmployee}
              onInputChange={(_, newInputValue) => setInputValue(newInputValue)}
              getOptionSelected={(option: IEmployeeItem, value: IEmployeeItem) =>
                option.idpId === value.idpId
              }
              onChange={(_, value) => setSearchedEmployee(value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  variant="outlined"
                  placeholder="Search employees"
                  InputLabelProps={{ shrink: false }}
                  InputProps={{
                    ...params.InputProps,
                    inputRef: inputTextRef,
                  }}
                />
              )}
              renderOption={(option: IEmployeeItem) => {
                return <div>{option.name}</div>;
              }}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={showAllUnknownRoles}
                  onChange={(_, value) => setShowAllUnknownRoles(value)}
                />
              }
              label="Show users with no access"
              className={styles.checkbox}
            />
          </div>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Position</TableCell>
                  <TableCell>Access Level</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {state === 'done'
                  ? filteredEmployees
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((employee, index) => (
                        <EmployeeListRow
                          key={index}
                          employee={employee}
                          canView={idpId !== employee.idpId}
                          canChangeAccess={isEmployeeAccessLevelBelowUser(employee.accessLevel)}
                          onAssignEmployee={handleEmployeeAssignment}
                        />
                      ))
                  : Array.from({ length: 10 }, (v, k) => k).map((i) => (
                      <EmployeeListRow
                        key={i}
                        employee={null}
                        canView={false}
                        canChangeAccess={false}
                        onAssignEmployee={(_) => {}}
                      />
                    ))}
              </TableBody>
            </Table>
            <TablePagination
              component={'div'}
              count={searchedEmployee ? 1 : filteredEmployees.length}
              onChangePage={(e, p) => setPage(p)}
              page={page}
              rowsPerPage={rowsPerPage}
              ActionsComponent={TablePaginationActions}
              onChangeRowsPerPage={(e) => {
                setRowsPerPage(+e.target.value);
                setPage(0);
              }}
            />
          </TableContainer>
        </>
      ) : (
        <SquadNotSelected />
      )}
      {employeeToAssign && (
        <ChangeAccessDialog
          isOpen={changeAccessDialogIsOpen}
          employee={employeeToAssign}
          closeDialog={handleClose}
        />
      )}
    </div>
  );
});
