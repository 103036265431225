import { FormControl, InputLabel, Select } from '@material-ui/core';
import React, { JSXElementConstructor, PropsWithChildren, ReactElement } from 'react';
import cn from 'classnames';
import styles from './CustomSelection.module.scss';

interface ICustomSelectionProps<DataType> {
  label: string;
  value: DataType;
  onChange: (value: DataType) => void;
  className?: string;
  fullWidth?: boolean;
}

type Props<DataType> = PropsWithChildren<ICustomSelectionProps<DataType>>;

export function CustomSelection<DataType>({
  label,
  value,
  onChange,
  children,
  className,
  fullWidth,
}: Props<DataType>): ReactElement<Props<DataType>, JSXElementConstructor<Props<DataType>>> | null {
  return (
    <FormControl
      variant={'outlined'}
      className={cn(styles.customSelection, className)}
      fullWidth={fullWidth}>
      <InputLabel id={`custom-selection-${label}`}>{label}</InputLabel>
      <Select
        fullWidth={fullWidth}
        className={styles.customSelectionInput}
        labelId={`custom-selection-${label}`}
        label={label}
        value={value}
        onChange={(event) => onChange(event.target.value as DataType)}>
        {children}
      </Select>
    </FormControl>
  );
}
