import React from 'react';
import styles from './PageHeader.module.scss';

interface IPageHeaderProps {
  title: string;
}

export const PageHeader: React.FC<IPageHeaderProps> = (props: IPageHeaderProps) => {
  return <div className={styles.PageHeader}>{props.title}</div>;
};
