import { errorLog } from 'index';
import { Instance, types } from 'mobx-state-tree';
import { api } from 'shared/api/interfaces';

import { flow, getEnv, LoadingStateModel } from './util';

export const TagModel = types.model('Tag', {
  tagId: types.identifierNumber,
  name: types.string,
  description: types.maybeNull(types.string),
  isSensitive: types.boolean,
  reviewRequired: types.boolean,
});

export const TagsModel = types
  .model('TagsModel', {
    state: LoadingStateModel,
    tags: types.map(TagModel),
  })
  .views((self) => ({
    get all() {
      return Array.from(self.tags.values());
    },
  }))
  .actions((self) => ({
    afterAttach: flow(function* (): Generator<Promise<ITag[]>, void, ITag[]> {
      if (self.state === 'done') return;

      const { ajax } = getEnv(self);
      try {
        const data = yield ajax
          .get('Tags', {})
          .json<api.TagDto[]>()
          .then((tags) => tags.map((tag) => TagModel.create(tag)));
        data.forEach((tag) => self.tags.put(tag));
        self.state = 'done';
      } catch (e) {
        errorLog('GET tags failed.', e);
        self.state = 'error';
      }
    }),
  }));

export interface ITag extends Instance<typeof TagModel> {}

export interface ITags extends Instance<typeof TagsModel> {}
