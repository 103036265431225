import React, { useEffect, useState } from 'react';

import { autorun } from 'mobx';
import { observer } from 'mobx-react-lite';
import { Alert } from '@material-ui/lab';
import { Snackbar } from '@material-ui/core';

import { useStore } from 'hooks/useStore';
import { INotification } from 'domain/store/singletons/NotificationModel';

interface INotificationProps {
  isOpen: boolean;
  notification: INotification;
  onClose: () => void;
}

export const Notifications: React.FC = observer(() => {
  const store = useStore();
  const [notifications, setNotifications] = useState<INotification[]>([]);

  useEffect(() => {
    return autorun(() => {
      setNotifications(store.notifications.notificationsToShow);
    });
  }, [store, notifications, setNotifications]);

  return (
    <>
      {notifications.map((n, i) => (
        <Notification
          key={i}
          isOpen={true}
          notification={n}
          onClose={() => {
            store.notifications.closeNotification(n);
          }}
        />
      ))}
    </>
  );
});

const Notification: React.FC<INotificationProps> = observer(
  ({ isOpen, notification: { message, severity }, onClose }) => {
    return (
      <Snackbar
        open={isOpen}
        autoHideDuration={6000}
        onClose={onClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}>
        <Alert elevation={6} variant="filled" onClose={onClose} severity={severity}>
          {message}
        </Alert>
      </Snackbar>
    );
  }
);
