import { FormControl, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import React from 'react';
import styles from '../../RoleChange.module.scss';
import moment from 'moment';
import cn from 'classnames';
import { SwitchBaseProps } from '@material-ui/core/internal/SwitchBase';

interface IRoleChangeCycleRadioGroupProps {
  onChange: (value: boolean) => void;
  disabled: boolean;
  selectedValue: boolean | undefined;
  roleChangeDate: string | undefined;
  dataTestId: string;
}

export const RoleChangeCycleRadioGroup: React.FC<IRoleChangeCycleRadioGroupProps> = (props) => {
  const getCurrentCycles = () => {
    const currentYear = moment(props.roleChangeDate).get('year');
    const isCurrentMonthInFirstCycle = moment(props.roleChangeDate).get('month') < 6;

    if (isCurrentMonthInFirstCycle) {
      return [`January to June ${currentYear}`, `July to December ${currentYear}`];
    } else {
      return [`July to December ${currentYear}`, `January to June ${currentYear + 1}`];
    }
  };

  const cycles = getCurrentCycles();

  return (
    <FormControl component="fieldset" className={styles.formItem} disabled={props.disabled}>
      <label>Role Change Cycle</label>
      <RadioGroup
        row
        value={cycles[props.selectedValue ? 1 : 0]}
        onChange={(event) =>
          props.onChange(cycles.indexOf((event.target as HTMLInputElement).value) > 0)
        }>
        {cycles.map((c, i) => (
          <FormControlLabel
            key={i}
            value={c}
            control={
              <Radio
                inputProps={
                  props.dataTestId
                    ? ({
                        'data-testid': props.dataTestId,
                      } as SwitchBaseProps['inputProps'] & { 'data-testid': string })
                    : undefined
                }
              />
            }
            label={c}
            className={cn(styles.formInputItem, styles.reverse)}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};
