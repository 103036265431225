import { humanizer, Unit } from 'humanize-duration';
import { Instance, types } from 'mobx-state-tree';
import moment, { duration } from 'moment';
import { EmployeeStatusModel } from './EmployeeStatusModel';
import { api } from 'shared/api/interfaces';
import { createFlightRiskStatusModel, FlightRiskStatusModel } from './FlightRiskModel';
import { createLongTermLeaveModel, LongTermLeaveModel } from './LongTermLeaveModel';
import { createPrimaryFlagModel, PrimaryFlagModel } from './PrimaryFlagModel';
import { createRoleChangePathwayModel, RoleChangePathwayModel } from './RoleChangePathwayModel';

export const WORK_DAY_IN_HOURS = 8;

export const EmployeeFlagsModel = types.model('EmployeeFlagsModel', {
  primaryFlag: types.maybeNull(PrimaryFlagModel),
  flightRisk: types.maybeNull(FlightRiskStatusModel),
  roleChangePathway: types.maybeNull(RoleChangePathwayModel),
  longTermLeave: types.maybeNull(LongTermLeaveModel),
  status: types.maybeNull(EmployeeStatusModel),
});

export function createEmployeeFlags(
  empFlags: api.EmployeeFlagsDto
): Instance<typeof EmployeeFlagsModel> {
  return EmployeeFlagsModel.create({
    flightRisk: createFlightRiskStatusModel(empFlags.flightRisk),
    longTermLeave: createLongTermLeaveModel(empFlags.longTermLeave),
    primaryFlag: createPrimaryFlagModel(empFlags.primaryFlag),
    roleChangePathway: createRoleChangePathwayModel(empFlags.roleChangePathway),
    status: empFlags.status ? EmployeeStatusModel.create(empFlags.status) : null,
  });
}

export const EmployeeLeaveModel = types.model('EmployeeLeaveModel', {
  annualLeaveInHours: types.number,
  personalLeaveInHours: types.number,
  pdTakenInHours: types.number,
});

export const EmployeeModel = types
  .model('EmployeeModel', {
    idpId: types.optional(types.string, ''),
    telstraEmployeeNumber: types.optional(types.string, ''),
    givenName: types.optional(types.string, ''),
    familyName: types.optional(types.string, ''),
    displayName: types.optional(types.string, ''),
    squadName: types.optional(types.string, ''),
    position: types.optional(types.string, ''),
    capability: types.optional(types.string, ''),
    tenure: types.maybeNull(types.string),
    leaveDetails: types.maybeNull(EmployeeLeaveModel),
    isFormerEmployee: types.optional(types.boolean, false),
    probationEndDate: types.maybeNull(types.string),
    lastRetroDate: types.maybeNull(types.string),
    flags: EmployeeFlagsModel,
    emailAddress: types.maybeNull(types.string),
    showMinimalDetails: types.optional(types.boolean, false),
    isInCareerFramework: types.optional(types.boolean, false),
  })
  .views((self) => ({
    get annualLeaveDays(): string {
      return Math.round(self.leaveDetails!.annualLeaveInHours / WORK_DAY_IN_HOURS).toString();
    },
    get personalLeaveDays(): string {
      return Math.round(self.leaveDetails!.personalLeaveInHours! / WORK_DAY_IN_HOURS).toString();
    },
    get pdTakenDays(): string {
      return Math.round(self.leaveDetails!.pdTakenInHours! / WORK_DAY_IN_HOURS).toString();
    },
    get annualLeaveHours(): string {
      return self.leaveDetails!.annualLeaveInHours!.toFixed(2);
    },
    get personalLeaveHours(): string {
      return self.leaveDetails!.personalLeaveInHours!.toFixed(2);
    },
    get pdTakenHours(): string {
      return self.leaveDetails!.pdTakenInHours!.toFixed(2);
    },
    get tenureFormatted(): string {
      if (self.tenure === null) return 'Unknown';

      const difference = duration(
        moment().diff(moment(self.tenure), 'milliseconds', true),
        'milliseconds'
      );
      const units: Unit[] = ['y', 'mo'];
      if (difference.asDays() < 30) {
        units.push('d');
      }
      const yearMonthHumaniser = humanizer({
        language: 'en',
        round: true,
        units,
      });
      return yearMonthHumaniser(difference.asMilliseconds());
    },
    get probation(): Probation {
      const probationEndDate = moment(self.probationEndDate);
      return {
        isInProbation: moment().diff(probationEndDate, 'day') <= 0,
        endDate: probationEndDate.format('DD/MM/YYYY'),
      };
    },
    get lastRetroFormatted(): string {
      if (self.lastRetroDate === null) return 'Unknown';
      const lastRetroDate = moment(self.lastRetroDate);
      return lastRetroDate.format('DD/MM/YYYY');
    },
  }));

export interface IEmployee extends Instance<typeof EmployeeModel> {}

export function createEmployee(employee: api.EmployeeDetailsDto): IEmployee {
  return EmployeeModel.create({
    ...employee,
    flags: createEmployeeFlags(employee.flags),
    probationEndDate: employee.probationEndDate,
    tenure: employee.tenure,
  });
}

export interface Probation {
  isInProbation: boolean;
  endDate: string;
}
