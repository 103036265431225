import React, { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Nav, NavItem } from 'views/components/shell/components/nav/Nav';
import { Search } from 'views/components/shell/components/search/Search';
import {
  ADMIN_ROUTE_PATH,
  CREATE_NOTE_ROUTE_PATH,
  SQUAD_ROUTE_PATH,
  EMPLOYEE_JOURNEYS_ROUTE_PATH,
  CATCH_UPS_ROUTE_PATH,
} from 'views/routes/Routes';
import { Logo } from './components/logo/Logo';
import { UserProfile } from './components/UserProfile';
import styles from './Shell.module.scss';

import { useUser } from 'hooks/useUser';
import { usePermissions } from 'hooks/usePermissions';
import { SquadSelect } from './components/squadSelect/SquadSelect';

export const Shell: React.FC = ({ children }) => {
  const user = useUser();

  const {
    canViewSquadTab,
    canViewEmployeeTab,
    canViewNoteTab,
    canManageRoles,
    canViewCatchupGroupTab,
  } = usePermissions();
  const history = useHistory();
  const [currentNav, setCurrentNav] = useState('');
  const [activeNav, setActiveNav] = useState('');

  const navs: NavItem[] = [];
  const setNav = useCallback((route: string) => {
    setCurrentNav(route);
    setActiveNav(topLevelRoute(route));
  }, []);
  const topLevelRoute = (route: string) => route.split('/')[1];

  if (canViewSquadTab) {
    navs.push({
      title: 'Squad',
      link: SQUAD_ROUTE_PATH,
      isActive: false,
    });
  }

  if (canViewEmployeeTab) {
    navs.push({
      title: 'Employees',
      link: EMPLOYEE_JOURNEYS_ROUTE_PATH,
      isActive: false,
    });
  }

  if (canViewNoteTab) {
    navs.push({
      title: 'Notes',
      link: CREATE_NOTE_ROUTE_PATH,
      isActive: false,
    });
  }

  if (canViewCatchupGroupTab) {
    navs.push({
      title: 'Catch-ups',
      link: CATCH_UPS_ROUTE_PATH,
      isActive: false,
    });
  }

  if (canManageRoles) {
    navs.push({
      title: 'Admin',
      link: ADMIN_ROUTE_PATH,
      isActive: false,
    });
  }

  useEffect(() =>
    history.listen((location) => {
      setNav(location.pathname + location.search);
    })
  );

  useEffect(() => {
    if (history.location.pathname === '/') {
      if (canViewSquadTab) {
        setNav(SQUAD_ROUTE_PATH);
      } else if (canViewEmployeeTab) {
        setNav(EMPLOYEE_JOURNEYS_ROUTE_PATH);
      } else {
        setNav(history.location.pathname + history.location.search);
      }
    } else {
      setNav(history.location.pathname + history.location.search);
    }
  }, [setNav, history.location, canViewSquadTab, canViewEmployeeTab]);

  const getNavs = () =>
    navs.map((nav) => ({
      ...nav,
      isActive: topLevelRoute(nav.link) === activeNav,
    }));

  return (
    <div className={styles.root}>
      <header className={styles.header}>
        <Logo className={styles.logoContainer} />
        <Nav className={styles.navContainer} items={getNavs()} currentNav={currentNav} />
        <div className={styles.searchContainer}>
          <SquadSelect user={user} />
          <Search className={styles.searchInput} />
        </div>
        <UserProfile />
      </header>
      <main className={styles.main}>{children}</main>
    </div>
  );
};
