import React, { useEffect, useState } from 'react';
import { asapScheduler, fromEvent, merge } from 'rxjs';
import { throttleTime } from 'rxjs/operators';

interface IBackToTopButtonResult {
  scrollableContainerRef: React.Ref<HTMLDivElement>;
  scrollToTopOfTimeline: () => void;
  showBackToTopButton: boolean;
}

export function useBackToTopButton(): IBackToTopButtonResult {
  const scrollableContainerRef = React.createRef<HTMLDivElement>();
  const [showBackToTopButton, setShowBackToTopButton] = useState(false);
  const scrollToTopOfTimeline = () =>
    scrollableContainerRef.current?.scrollTo({ top: 0, behavior: 'smooth' });

  useEffect(() => {
    const checkIfBackToTopButtonIsNecessary = () => {
      const isNecessary =
        !!scrollableContainerRef.current &&
        scrollableContainerRef.current.scrollTop > 100 &&
        scrollableContainerRef.current.scrollHeight > scrollableContainerRef.current.clientHeight;

      if (showBackToTopButton !== isNecessary) {
        setShowBackToTopButton(isNecessary);
      }
    };

    const observableEvents = [fromEvent(window, 'resize'), fromEvent(window, 'orientationchange')];

    if (scrollableContainerRef.current) {
      observableEvents.push(fromEvent(scrollableContainerRef.current, 'scroll'));
    }

    const scrollEventSubscription = merge(...observableEvents)
      .pipe(throttleTime(250, asapScheduler, { leading: true, trailing: true }))
      .subscribe(checkIfBackToTopButtonIsNecessary);

    checkIfBackToTopButtonIsNecessary();
    return () => {
      scrollEventSubscription.unsubscribe();
    };
  }, [showBackToTopButton, scrollableContainerRef]);

  return { scrollableContainerRef, scrollToTopOfTimeline, showBackToTopButton };
}
