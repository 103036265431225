import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@material-ui/core';
import React, { useState } from 'react';

export interface AddGroupDialogProps {
  isOpen: boolean;
  onCancelClick: () => void;
  onOkClick: (groupName: string) => void;
}

export const AddGroupDialog: React.FunctionComponent<AddGroupDialogProps> = (props) => {
  const [newGroupName, setNewGroupName] = useState<string>('');

  const onCancelClick = () => {
    props.onCancelClick();
  };

  const onOkClick = () => {
    props.onOkClick(newGroupName);
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.keyCode === 13) {
      props.onOkClick(newGroupName);
    }

    if (e.keyCode === 27) {
      onCancelClick();
    }
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth={'xs'}
      data-testid={'dialog-test-id'}
      onClose={onCancelClick}
      onKeyDown={handleKeyDown}
      onEnter={() => setNewGroupName('')}
      open={props.isOpen}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title" data-testid={'dialog-title'}>
        Create new group
      </DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          fullWidth={true}
          label="Group Name"
          onChange={(event) => setNewGroupName(event.target.value)}
          value={newGroupName}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onOkClick} color="primary">
          OK
        </Button>
        <Button onClick={onCancelClick} color="secondary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};
