import { useContext } from 'react';

import { StoreContext } from 'views/App';
import { IRootStoreModel } from 'domain/store/RootStoreModel';

export function useStore(): IRootStoreModel {
  const store = useContext(StoreContext);

  if (!store) throw new Error('Store context has not been initialised');
  return store;
}
