import { Box, Button, TableCell, TableRow } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton/Skeleton';
import { IEmployeeItem } from 'domain/store/admin/EmployeeListModel';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Avatar } from 'views/components/avatar/Avatar';
import styles from './SquadEmployeeRow.module.scss';

export interface SquadEmployeeRowProps {
  employee: IEmployeeItem | null;
  canChangeAccess: boolean;
  assignEmployee: (employee: IEmployeeItem) => void;
}

export const SquadEmployeeRow: React.FC<SquadEmployeeRowProps> = observer(
  ({ employee, canChangeAccess, assignEmployee }) => {
    return (
      <TableRow>
        <TableCell>
          <Box display="flex" flexDirection="row" alignItems="center">
            <Avatar
              className={styles.avatar}
              idpId={employee?.idpId}
              givenName={employee?.givenName}
              familyName={employee?.familyName}
            />
            {employee?.name}
          </Box>
        </TableCell>
        <TableCell> {employee ? employee?.position : <Skeleton width={'25%'} />}</TableCell>
        <TableCell>
          {employee ? employee?.accessLevelDisplayName : <Skeleton width={'25%'} />}
        </TableCell>
        <TableCell>
          {employee ? employee?.assignedSquads?.join(' / ') : <Skeleton width={'25%'} />}
        </TableCell>
        <TableCell align="center">
          {employee ? (
            canChangeAccess ? (
              <Button
                onClick={() => {
                  assignEmployee(employee);
                }}
                color="primary">
                Assign
              </Button>
            ) : null
          ) : (
            <Skeleton width={'25%'} />
          )}
        </TableCell>
      </TableRow>
    );
  }
);
