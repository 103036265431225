import { faEdit, faPlusCircle, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  CircularProgress,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { IGroupModel } from 'domain/store/catchUps/CatchUpsModel';
import { useCatchUpGroups } from 'hooks/useCatchUpGroups';
import { useStore } from 'hooks/useStore';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { AlertDialog } from 'views/components/alertDialog/AlertDialog';
import { AddMemberForm } from '../AddMemberForm/AddMemberForm';
import { MemberRow } from '../MemberRow/MemberRow';
import { RenameGroupDialog } from '../RenameGroupDialog/RenameGroupDialog';
import styles from './GroupCard.module.scss';

export interface GroupCardProps {
  group: IGroupModel | null;
}

export const GroupCard: React.FC<GroupCardProps> = observer(({ group }) => {
  const { addMember, deleteGroup, renameGroup } = useCatchUpGroups();

  const store = useStore();

  const [isAddMemberFormOpen, setAddMemberFormOpen] = useState<boolean>(false);
  const [isDeleteGroupDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);
  const [isRenameGroupDialogOpen, setIsRenameDialogOpen] = useState<boolean>(false);
  const [isAddingMember, setIsAddingMember] = useState<boolean>(false);
  const [groupName, setGroupName] = useState<string | undefined>(group?.catchupGroupName);
  const [isUpdatingGroupName, setIsUpdatingGroupName] = useState(false);

  const handleGroupDelete = () => {
    if (group) {
      deleteGroup(group.catchupGroupId).catch((e) => {
        if (e?.response?.status === 409) {
          store.notifications.addError(
            'Conflict occurred saving changes, please refresh the page, and try again after.'
          );
        } else if (e?.response?.status === 403) {
          store.notifications.addError('Not authorised to save changes.');
        } else {
          store.notifications.addError('Unable to delete group, please try again.');
        }
      });
      setIsDeleteDialogOpen(false);
    }
  };

  const handleGroupRename = (groupName: string) => {
    setIsUpdatingGroupName(true);
    setGroupName(groupName);
    group &&
      renameGroup(group.catchupGroupId, groupName)
        .catch((e) => {
          if (e?.response?.status === 409) {
            store.notifications.addError(
              'Conflict occurred saving changes, please refresh the page, and try again after.'
            );
          } else if (e?.response?.status === 403) {
            store.notifications.addError('Not authorised to save changes.');
          } else {
            store.notifications.addError('Unable to rename group, please try again.');
          }
        })
        .finally(() => {
          setIsUpdatingGroupName(false);
          setIsRenameDialogOpen(false);
        });
  };

  const handleAddMember = (idpId: string, frequencyId: number) => {
    setIsAddingMember(true);
    group &&
      addMember(idpId, group.catchupGroupId, frequencyId)
        .then((_) => {
          setAddMemberFormOpen(false);
        })
        .catch((e) => {
          e.response.text().then((message: string) => {
            if (e.response.status === 409) {
              store.notifications.addError(
                'Conflict occurred saving changes, please refresh the page, and try again after.'
              );
            } else {
              store.notifications.addError('Unable to add user to the group, please try again.');
            }
          });
        })
        .finally(() => {
          setIsAddingMember(false);
        });
  };

  return (
    <Box mb={4}>
      <Paper className={styles.paper}>
        <Box display="flex" justifyContent="flex-end" p={2}>
          {group && (
            <div className={styles.actions}>
              <IconButton onClick={() => setIsRenameDialogOpen(true)}>
                <FontAwesomeIcon icon={faEdit} size={'xs'} />
              </IconButton>
              <IconButton onClick={() => setIsDeleteDialogOpen(true)}>
                <FontAwesomeIcon icon={faTrashAlt} size={'xs'} />
              </IconButton>
            </div>
          )}
        </Box>
        {group ? (
          <>
            {isUpdatingGroupName ? (
              <CircularProgress className={styles.loadingIndicator} size={'1rem'} />
            ) : (
              <h3>{groupName}</h3>
            )}
          </>
        ) : (
          <Skeleton component="h3" width={'10%'} />
        )}
        <TableContainer>
          <Table className={styles.table}>
            <TableHead>
              <TableRow>
                <TableCell align="left">Name</TableCell>
                <TableCell align="center" size="small">
                  Last Catch-up
                </TableCell>
                <TableCell align="center" size="small">
                  Next Catch-up
                </TableCell>
                <TableCell align="right" size="small">
                  Days until next
                </TableCell>
                <TableCell align="center">Frequency</TableCell>
                <TableCell align="center">Add note</TableCell>
                <TableCell align="center">Remove</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {group
                ? group.members.map((member) => <MemberRow member={member} key={member.idpId} />)
                : Array.from({ length: 5 }, (v, k) => k).map((i) => (
                    <MemberRow key={i} member={null} />
                  ))}
              {isAddMemberFormOpen && (
                <AddMemberForm
                  isAddingMember={isAddingMember}
                  onCancelClick={() => setAddMemberFormOpen(false)}
                  onOkClick={handleAddMember}
                />
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {group && (
          <>
            <Box mt={2} pl={2}>
              <IconButton onClick={() => setAddMemberFormOpen(true)}>
                <FontAwesomeIcon color="black" icon={faPlusCircle} size={'2x'} />
                <h6>Add New Member</h6>
              </IconButton>
            </Box>
            <AlertDialog
              title="Delete group"
              text={`Are you sure you want to delete the group "${group?.catchupGroupName}"`}
              isOpen={isDeleteGroupDialogOpen}
              onDisagreeClick={() => setIsDeleteDialogOpen(false)}
              onAgreeClick={handleGroupDelete}
            />
            <RenameGroupDialog
              isOpen={isRenameGroupDialogOpen}
              groupName={groupName ?? ''}
              onOkClick={(name: string) => handleGroupRename(name)}
              onCancelClick={() => setIsRenameDialogOpen(false)}
            />
          </>
        )}
      </Paper>
    </Box>
  );
});
