import { Instance, types } from 'mobx-state-tree';
import { EmployeeListModel } from './EmployeeListModel';
import { SquadAssignmentEmployeeListModel } from './SquadAssignmentEmployeeListModel';

export function makeAdminUrl(name: string): string {
  return `Admin/${name}`;
}

export const AdminModel = types.model('AdminModel', {
  employees: types.optional(EmployeeListModel, {}),
  SquadAssignment: types.optional(SquadAssignmentEmployeeListModel, {}),
});

export interface IAdmin extends Instance<typeof AdminModel> {}
