import {
  AuthenticationParameters,
  AuthError,
  AuthResponse,
  Configuration,
  UserAgentApplication,
} from 'msal';

import {
  AuthenticationAccount,
  AuthenticationError,
  AuthenticationProviderState,
  IAuthenticationProvider,
} from './AuthenticationTypes';

export class AuthenticationProvider implements IAuthenticationProvider {
  private readonly application: UserAgentApplication;

  constructor(config: Configuration, private readonly authParams: AuthenticationParameters) {
    this.application = new UserAgentApplication(config);
  }

  public get initialState(): AuthenticationProviderState {
    return 'loading';
  }

  public get account(): AuthenticationAccount | null {
    return this.application.getAccount();
  }

  public async signIn(): Promise<AuthenticationProviderState> {
    if (this.getIsSigningIn()) return 'signing-in';
    if (this.getIsLoginError()) return 'login-error';
    if (await this.getIsSignedIn()) return 'signed-in';

    this.signInRedirect();
    return 'redirecting';
  }

  public signOut(): void {
    sessionStorage.clear();
    this.application.logout();
  }

  public async getIsSignedIn(): Promise<boolean> {
    const local = !this.getIsSigningIn() && !!this.account;
    return local && (await this.canAcquireTokenSilent());
  }

  public async getApiAccessToken(): Promise<string> {
    if (await this.getIsSignedIn()) {
      const response = await this.application.acquireTokenSilent(this.authParams);
      return response.accessToken;
    }
    throw new AuthenticationError();
  }

  public async getGraphAccessToken(scopes?: string[]): Promise<string> {
    const accessTokenRequest: AuthenticationParameters = {
      scopes: scopes || ['user.read'],
    };
    if (await this.getIsSignedIn()) {
      const response = await this.application.acquireTokenSilent(accessTokenRequest);
      return response.accessToken;
    }
    throw new AuthenticationError();
  }

  private signInRedirect() {
    this.application.loginRedirect(this.authParams);
  }

  private getIsLoginError(): boolean {
    let isLoginError = false;

    //
    // The callback is invoked immediately by MSAL.js
    //
    this.application.handleRedirectCallback((authErr: AuthError, response?: AuthResponse) => {
      isLoginError = !!authErr;
    });

    return isLoginError;
  }

  private getIsSigningIn(): boolean {
    return (
      this.application.getLoginInProgress() ||
      this.application.urlContainsHash(window.location.hash)
    );
  }

  private async canAcquireTokenSilent(): Promise<boolean> {
    try {
      await this.application.acquireTokenSilent(this.authParams);
      return true;
    } catch {
      return false;
    }
  }
}
