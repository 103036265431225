import { flow, getEnv } from 'domain/store/util';
import { Instance, types } from 'mobx-state-tree';
import { createNote, INote, NoteModel } from 'domain/store/NoteModel';
import { createEmployee, EmployeeModel, IEmployee } from './EmployeeModel';
import { api } from 'shared/api/interfaces';
import { errorLog } from 'index';

const EmployeeJourneyNotesModel = types.array(NoteModel);

export const EmployeeJourneyModel = types
  .model('EmployeeJourneyModel', {
    employee: types.maybeNull(EmployeeModel),
    notes: types.maybeNull(EmployeeJourneyNotesModel),
    state: types.optional(
      types.enumeration<'pending' | 'loading' | 'done' | 'error' | 'unauthorised'>(
        'EmployeeJourneyState',
        ['pending', 'loading', 'done', 'error', 'unauthorised']
      ),
      'pending'
    ),
    positions: types.array(types.string),
  })
  .actions((self) => {
    const loadNotes = function* (): Generator<Promise<INote[]>, void, INote[]> {
      const { ajax } = getEnv(self);
      const employeeIdpId = self.employee?.idpId;
      if (employeeIdpId) {
        const response = yield ajax
          .get('Notes', { searchParams: { EmployeeIdpId: employeeIdpId } })
          .json<api.NoteDto[]>()
          .then((notes) => notes.map((note) => createNote(note)));
        self.notes = EmployeeJourneyNotesModel.create(response);
      }
    };

    return {
      loadNotes: flow(loadNotes),
    };
  })
  .actions((self) => {
    const shouldUpdate = (employeeIdpId: string): boolean => {
      return self.employee?.idpId !== employeeIdpId;
    };

    const unloadJourney = function (): void {
      self.state = 'pending';
      self.notes = null;
      self.employee = null;
    };

    const loadJourney = function* (
      employeeIdpId: string
    ): Generator<Promise<IEmployee | void>, void, IEmployee> {
      if (self.state === 'done' && shouldUpdate(employeeIdpId)) {
        unloadJourney();
      }

      if (self.state !== 'pending') return;

      self.state = 'loading';
      try {
        const { ajax } = getEnv(self);

        self.employee = yield ajax
          .get('Employee', { searchParams: { employeeIdpId } })
          .json<api.EmployeeDetailsDto>()
          .then((r) => createEmployee(r));

        yield self.loadNotes();

        self.state = 'done';
      } catch (e) {
        self.state = 'error';
        errorLog('Failed to get employee.', e);
        if (e.response && e.response.status === 403) {
          self.state = 'unauthorised';
        }
      }
    };

    return {
      unloadJourney,
      loadJourney: flow(loadJourney),
    };
  })
  .actions((self) => ({
    afterAttach: flow(function* (): Generator<
      Promise<api.ChartMetaDataDto>,
      void,
      api.ChartMetaDataDto
    > {
      const { ajax } = getEnv(self);
      try {
        const result = yield ajax.get('RoleChanges/positions', {}).json<api.ChartMetaDataDto>();
        self.positions.push(...result.roleDistributionOrderedSet);
      } catch (e) {
        errorLog('Failed to get positions metadata.', e);
      }
    }),
  }))
  .views((self) => ({
    get isLoaded() {
      return self.state === 'done';
    },
  }));

export interface IEmployeeJourney extends Instance<typeof EmployeeJourneyModel> {}
