import { useEffect } from 'react';
import { useStore } from './useStore';
import { IEmployeeJourney } from 'domain/store/employeeJourney/EmployeeJourneyModel';
import { UNAUTHORISED_ROUTE_PATH } from 'views/routes/Routes';
import { useHistory, useParams } from 'react-router-dom';

export function useEmployeeJourney(): IEmployeeJourney {
  const { employeeJourney } = useStore();
  const { idpId } = useParams<{ idpId?: string }>();
  const history = useHistory();

  useEffect(() => {
    if (idpId && idpId !== employeeJourney.employee?.idpId) {
      employeeJourney.unloadJourney();
      employeeJourney.loadJourney(idpId).then(() => {
        if (employeeJourney.state === 'unauthorised') {
          history.push(UNAUTHORISED_ROUTE_PATH);
        }
      });
    }
  }, [employeeJourney, history, idpId]);

  return employeeJourney;
}
