import { Skeleton } from '@material-ui/lab';
import { useTrends } from 'hooks/useTrends';
import { observer } from 'mobx-react-lite';
import React from 'react';
import styles from './FlagChart.module.scss';
import { FlagItem } from './FlagItem';

export const FlagChart = observer(() => {
  const { trendsEmployees } = useTrends();

  return (
    <>
      {trendsEmployees.state === 'done' ? (
        <div className={styles.flagChartContainer}>
          <div className={styles.row}>
            <FlagItem
              flag={'Flight risks'}
              color={'#3f51b5'}
              employees={trendsEmployees.flightRiskEmployees}
            />
            <FlagItem
              flag={'Long term leave'}
              color={'#f50057'}
              employees={trendsEmployees.employeesOnLongTermLeave}
            />
          </div>
          <div className={styles.row}>
            <FlagItem
              flag={'Role change pathway'}
              color={'#23b7dd'}
              employees={trendsEmployees.employeesOnRoleChangePathways}
              isRoleChangePathway={true}
            />
            <FlagItem
              flag={'Probation'}
              color={'#000'}
              employees={trendsEmployees.employeesOnProbation}
            />
          </div>
        </div>
      ) : (
        <Skeleton variant={'rect'} classes={{ root: styles.skeleton }} />
      )}
    </>
  );
});
