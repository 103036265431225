import React from 'react';
import {
  TableContainer,
  Table,
  TableRow,
  TableBody,
  TablePagination,
  TableCell,
} from '@material-ui/core';
import { EmployeeListPopperItem } from './EmployeeListPopperItem';
import { ITrendsEmployeesItem } from 'domain/store/trends/TrendsEmployeesModel';
import { ISupportingOpportunityEmployee } from 'domain/store/trends/SupportingOpportunitiesWithEmployeesModel';
import styles from './EmployeeListPopperItem.module.scss';
import cn from 'classnames';

interface IEmployeeListProps {
  employees: Array<ITrendsEmployeesItem | ISupportingOpportunityEmployee>;
}

export const EmployeeList: React.FC<IEmployeeListProps> = function (props) {
  const rowsPerPage = 6;
  const itemsPerRow = 3;
  const itemsPerPage = itemsPerRow * rowsPerPage;
  const [page, setPage] = React.useState(0);

  React.useEffect(() => {
    setPage(0);
  }, [props]);

  return (
    <TableContainer>
      <Table padding="none">
        <TableBody>
          {[...Array(rowsPerPage)].map((_, row) => (
            <TableRow key={row}>
              {[...Array(itemsPerRow)].map((_, i) => {
                const index = page * itemsPerPage + row * 3 + i;
                const employee = props.employees[index];
                return employee ? (
                  <EmployeeListPopperItem employee={employee} key={index} />
                ) : (
                  <TableCell
                    className={cn(styles.emptyCell, {
                      [styles.tableCellAsPadding]: props.employees.length > itemsPerPage,
                    })}
                    key={index}
                  />
                );
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        component="div"
        count={props.employees.length / itemsPerRow}
        onChangePage={(_, p) => setPage(p)}
        page={page}
        rowsPerPage={rowsPerPage}
        labelDisplayedRows={() =>
          `${page + 1} of ${Math.ceil(props.employees.length / itemsPerPage || 1)}`
        }
        rowsPerPageOptions={[rowsPerPage]}
      />
    </TableContainer>
  );
};
