import { Checkbox, Tooltip } from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons';
import moment from 'moment';
import React from 'react';
import { LoadingIndicator } from 'views/components/loadingIndicator/LoadingIndicator';
import styles from './EmployeeRow.module.scss';

interface ILongTermLeaveCheckboxProps {
  checked: boolean;
  employeeExpectedReturn: string | null;
  isLoading: boolean;
  onToggle?: () => void;
}

export const LongTermLeaveCheckbox: React.FC<ILongTermLeaveCheckboxProps> = (
  props: ILongTermLeaveCheckboxProps
) => {
  return props.isLoading ? (
    <LoadingIndicator size={'1.5em'} />
  ) : (
    <div className={styles.checkboxContainer}>
      <Checkbox onClick={() => props.onToggle && props.onToggle()} checked={props.checked} />
      {props.employeeExpectedReturn && (
        <Tooltip
          title={<>Expected Return: {moment(props.employeeExpectedReturn).format('DD/MM/YYYY')}</>}>
          <InfoOutlined />
        </Tooltip>
      )}
    </div>
  );
};
