import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Autocomplete } from '@material-ui/lab';
import { CircularProgress, TextField } from '@material-ui/core';
import { useGraph } from 'hooks/useGraph';
import { EmployeeSearchResult } from 'domain/store/singletons/GraphModel';
import styles from './EmployeeDropdown.module.scss';
import { ArchivedChip } from '../archivedChip/ArchivedChip';

interface EmployeeDropdownProps {
  defaultValue?: EmployeeSearchResult | null;
  onChange?: (employee: EmployeeSearchResult | undefined, clearCallback: () => void) => void;
  onBlur?: () => void;
  className?: string;
  textBoxClassName?: string;
  includeFormerEmployees?: boolean;
}

export const EmployeeDropdown: React.FC<EmployeeDropdownProps> = (props: EmployeeDropdownProps) => {
  const { searchForEmployees } = useGraph();
  const [value, setValue] = useState<EmployeeSearchResult | null>(null);
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState<EmployeeSearchResult[]>([]);
  const inputTextRef = useRef<HTMLInputElement>();

  const fetchUsers = useCallback(
    async (search: string) => await searchForEmployees(search, props.includeFormerEmployees),
    [searchForEmployees, props.includeFormerEmployees]
  );

  useEffect(() => {
    const fetchUsersDebounced = (search: string) => {
      const global = (window as unknown) as { timer: NodeJS.Timeout };
      clearTimeout(global.timer);
      if (inputValue) {
        setLoading(true);
      } else {
        setLoading(false);
        setOptions([]);
        return;
      }
      global.timer = setTimeout(async () => {
        const users = await fetchUsers(search);
        setOptions(users);
        setLoading(false);
      }, 500);
    };

    fetchUsersDebounced(inputValue);
  }, [inputValue, fetchUsers]);

  useEffect(() => {
    if (props.defaultValue || props.defaultValue === null) {
      setValue(props.defaultValue);
    }
  }, [props.defaultValue]);

  const handleChange = (value: EmployeeSearchResult | null) => {
    let clearCalled = false;
    props.onChange &&
      props.onChange(value || undefined, () => {
        clearCalled = true;
        setValue(null);
        setOptions([]);
        setInputValue('');
        inputTextRef.current?.blur();
      });
    !clearCalled && setValue(value || null);
  };

  return (
    <Autocomplete
      getOptionLabel={(option) => option.displayName}
      className={props.className}
      options={options}
      autoComplete
      filterSelectedOptions
      value={value}
      loading={loading}
      onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
      getOptionSelected={(option: EmployeeSearchResult, value: EmployeeSearchResult) =>
        option.idpId === value.idpId
      }
      onChange={(event, value) => handleChange(value)}
      onBlur={() => props.onBlur && props.onBlur()}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          variant="outlined"
          placeholder="Enter employee name"
          className={props.textBoxClassName}
          InputLabelProps={{ shrink: false }}
          InputProps={{
            ...params.InputProps,
            inputRef: inputTextRef,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
      renderOption={(option: EmployeeSearchResult) => {
        return (
          <div className={styles.searchResult}>
            <span>{option.displayName}</span>
            {option.isFormerEmployee && <ArchivedChip />}
          </div>
        );
      }}
    />
  );
};
