import React, { FormEvent } from 'react';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { MarkdownTextArea } from 'views/components/markdownTextArea/MarkdownTextArea';
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from '@material-ui/core';
import styles from 'views/routes/myNotes/components/editNote/EditNote.module.scss';
import { EmployeeDropdown } from 'views/components/employeeDropdown/EmployeeDropdown';
import { TagDropdown } from 'views/components/tagDropdown/TagDropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleRight, faSave, faUndo } from '@fortawesome/free-solid-svg-icons';
import { LoadingIndicator } from 'views/components/loadingIndicator/LoadingIndicator';
import { FormItem, IFormState, ModeType } from 'views/routes/myNotes/MyNotes';
import { ValidationIcon, ValidationStatus } from 'views/components/validationIcon/ValidationIcon';
import { NavigationAlert } from 'views/components/navigationAlert/NavigationAlert';
import { Tooltip } from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons';
import { NoteSensitivity } from 'domain/store/NoteModel';

interface IEditNoteProps {
  formState: IFormState;
  formTitle: string;
  mode: ModeType;
  showSensitivity: boolean;
  onChange: (state: IFormState, item?: FormItem) => void;
  onSubmitNote: () => void;
  onSubmitDraft: () => void;
  onValidateFormItem: (item: FormItem) => void;
  onUndoChanges: () => void;
  isFormStateDirty: boolean;
  isOkToSaveAsDraft: boolean;
  isSubmitting: false | 'draft' | 'published';
}

interface CheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  'data-testid'?: string;
}

const SUBMISSION_TEXT: { draft: string; published: string } = {
  draft: 'Saving Note',
  published: 'Submitting Note',
};

export const EditNote: React.FC<IEditNoteProps> = observer(
  ({
    formState,
    formTitle,
    mode,
    showSensitivity,
    onChange,
    onValidateFormItem,
    onSubmitNote,
    onSubmitDraft,
    onUndoChanges,
    isFormStateDirty,
    isOkToSaveAsDraft,
    isSubmitting,
  }) => {
    const onSubmitForm = (e: FormEvent) => {
      e.preventDefault();
      onSubmitNote();
    };

    const handleSaveAsDraft = (e: FormEvent) => {
      e.preventDefault();
      onSubmitDraft();
    };

    const getFormItemClass = (item: FormItem): string => {
      const classes = [styles.formItem];

      if (styles[item]) {
        classes.push(styles[item]);
      }

      const errors = formState.errors;
      if (errors[item]) {
        classes.push(styles.error);
      }

      if (!isFormStateDirty) {
        classes.push(styles.pristine);
      }

      return classes.join(' ');
    };

    const getValidationStatus = (item: FormItem): ValidationStatus => {
      if (Object.prototype.hasOwnProperty.call(formState.errors, item)) {
        return formState.errors[item] === undefined ? 'valid' : 'error';
      }
      return 'hidden';
    };

    return (
      <>
        <NavigationAlert for="note" when={isFormStateDirty && !isSubmitting} />
        <div className={styles.editNoteComponent}>
          <div className={styles.formSection}>
            <h2>{formTitle}</h2>
            <form
              className={styles.formContainer}
              onSubmit={onSubmitForm}
              id={'newNoteForm'}
              data-testid="edit-note-form">
              <div className={getFormItemClass('employee')}>
                <label>Employee</label>
                <div className={styles.inputContainer}>
                  <EmployeeDropdown
                    onChange={(employee) => onChange({ ...formState, employee }, 'employee')}
                    defaultValue={formState.employee}
                    onBlur={() => onValidateFormItem('employee')}
                  />
                </div>
                <ValidationIcon
                  status={getValidationStatus('employee')}
                  className={styles.validationIcon}
                />
                <div className={styles.errorText}>{formState.errors.employee}</div>
              </div>

              {formState.employee && (
                <div className={getFormItemClass('isFaceToFaceDiscussion')}>
                  <label></label>
                  <FormControl component="fieldset">
                    <RadioGroup
                      row
                      aria-label="face to face discussion"
                      name="face-to-face-discussion"
                      value={
                        formState.isFaceToFaceDiscussion === undefined
                          ? undefined
                          : formState.isFaceToFaceDiscussion.toString()
                      }
                      onChange={(event) =>
                        onChange({
                          ...formState,
                          isFaceToFaceDiscussion:
                            (event.target as HTMLInputElement).value === 'true',
                        })
                      }
                      onBlur={() => onValidateFormItem('isFaceToFaceDiscussion')}>
                      <FormControlLabel
                        value={'true'}
                        control={<Radio />}
                        label="Direct conversation with the employee"
                        data-testid="is-face-to-face"
                      />
                      <FormControlLabel
                        value={'false'}
                        control={<Radio />}
                        label="Feedback/observations about the employee"
                      />
                    </RadioGroup>
                  </FormControl>
                  <ValidationIcon
                    status={getValidationStatus('isFaceToFaceDiscussion')}
                    className={styles.validationIcon}
                  />
                  <div className={styles.errorText}>{formState.errors.isFaceToFaceDiscussion}</div>
                </div>
              )}

              <div className={getFormItemClass('date')}>
                <label>Date</label>
                <div className={styles.inputContainer}>
                  <TextField
                    className={styles.datePicker}
                    type="date"
                    placeholder="Enter event date"
                    variant="outlined"
                    fullWidth
                    InputLabelProps={{ shrink: false }}
                    value={formState.date || ''}
                    onChange={(event) =>
                      onChange({ ...formState, date: event.currentTarget.value }, 'date')
                    }
                    onBlur={() => onValidateFormItem('date')}
                    inputProps={{
                      'data-testid': 'date',
                      max: moment().format('YYYY-MM-DD'),
                      min: moment().add(-1, 'year').format('YYYY-MM-DD'),
                    }}
                  />
                </div>
                <ValidationIcon
                  status={getValidationStatus('date')}
                  className={styles.validationIcon}
                />
                <div className={styles.errorText}>{formState.errors.date}</div>
              </div>

              <div className={getFormItemClass('tags')}>
                <label>Tags</label>
                <div className={styles.inputContainer}>
                  <TagDropdown
                    onChange={(tags) => onChange({ ...formState, tags }, 'tags')}
                    onBlur={() => onValidateFormItem('tags')}
                    defaultValue={formState.tags}
                  />
                </div>
                <ValidationIcon
                  status={getValidationStatus('tags')}
                  className={styles.validationIcon}
                />
                {(formState.errors.tags || showSensitivity) && (
                  <div className={styles.errorText}>{formState.errors.tags}</div>
                )}
              </div>

              {showSensitivity ? (
                <div className={getFormItemClass('sensitivity')}>
                  <label></label>
                  <FormControl component="fieldset">
                    <RadioGroup
                      row
                      aria-label="sensitivity"
                      name="sensitivity"
                      value={formState.sensitivity}
                      onChange={(event) =>
                        onChange({
                          ...formState,
                          sensitivity: (event.target as HTMLInputElement).value as NoteSensitivity,
                        })
                      }
                      onBlur={() => onValidateFormItem('sensitivity')}>
                      <FormControlLabel
                        value="sensitive"
                        control={<Radio />}
                        label={
                          <>
                            <span>Sensitive</span>
                            <Tooltip
                              className={styles.informationIcon}
                              title={
                                <span className={styles.informationText}>
                                  Marking this note as sensitive will make this note visible to only
                                  M & A users.
                                </span>
                              }>
                              <InfoOutlined />
                            </Tooltip>
                          </>
                        }
                      />
                      <FormControlLabel
                        value="non-sensitive"
                        control={<Radio />}
                        label={
                          <>
                            <span>Non-Sensitive</span>
                            <Tooltip
                              className={styles.informationIcon}
                              title={
                                <span className={styles.informationText}>
                                  Marking this note as non-sensitive will make this note visible to
                                  M, A, B1 & B2 users. <br />C users will not have visibility.
                                </span>
                              }>
                              <InfoOutlined />
                            </Tooltip>
                          </>
                        }
                      />
                    </RadioGroup>
                  </FormControl>
                  <ValidationIcon
                    status={getValidationStatus('sensitivity')}
                    className={styles.validationIcon}
                  />
                  <div className={styles.errorText}>{formState.errors.sensitivity}</div>
                </div>
              ) : formState.tags?.some((p) => p.isSensitive) ? (
                <div className={styles.sensitiveText}>You have selected a sensitive tag</div>
              ) : formState.tags?.some((p) => p.reviewRequired) ? (
                <div className={styles.sensitiveText}>
                  You have selected a sensitive tag which requires a review
                </div>
              ) : (
                !formState.errors.tags && <div className={styles.sensitiveText} />
              )}

              <div className={getFormItemClass('subject')}>
                <label>Subject</label>
                <div className={styles.inputContainer}>
                  <TextField
                    placeholder="Enter subject"
                    variant="outlined"
                    fullWidth
                    InputLabelProps={{ shrink: false }}
                    value={formState.subject}
                    onChange={(event) =>
                      onChange({ ...formState, subject: event.currentTarget.value })
                    }
                    onBlur={() => onValidateFormItem('subject')}
                    inputProps={{
                      'data-testid': 'subject',
                    }}
                  />
                </div>
                <ValidationIcon
                  status={getValidationStatus('subject')}
                  className={styles.validationIcon}
                />
                <div className={styles.errorText}>{formState.errors.subject}</div>
              </div>

              <div className={getFormItemClass('description')}>
                <label>Details</label>
                <div className={styles.inputContainer}>
                  <MarkdownTextArea
                    content={formState.description}
                    onChange={(description) => onChange({ ...formState, description })}
                    onBlur={() => isFormStateDirty && onValidateFormItem('description')}
                  />
                </div>
                <ValidationIcon
                  status={getValidationStatus('description')}
                  className={styles.validationIcon}
                />
                <div className={styles.markdownTextContainer}>
                  <div className={styles.errorText}>{formState.errors.description}</div>
                  <span className={styles.markdownSupportedText}>
                    <a
                      href="https://www.markdownguide.org/cheat-sheet"
                      target="_blank"
                      rel="noopener noreferrer">
                      Markdown
                    </a>{' '}
                    is supported
                  </span>
                </div>
              </div>

              <div className={getFormItemClass('isPeopleLeaderConversation')}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formState.isPeopleLeaderConversation}
                      onChange={(event) =>
                        onChange({ ...formState, isPeopleLeaderConversation: event.target.checked })
                      }
                      inputProps={
                        {
                          'data-testid': 'isPeopleLeaderConvoCheckbox',
                        } as CheckboxProps
                      }
                    />
                  }
                  label="This is a People Leader conversation"
                  className={styles.checkbox}
                />
              </div>

              <div className={styles.buttonsContainer}>
                {isSubmitting ? (
                  <LoadingIndicator text={SUBMISSION_TEXT[isSubmitting]} />
                ) : (
                  <>
                    <Button
                      variant="contained"
                      color="default"
                      disabled={!isFormStateDirty}
                      onClick={onUndoChanges}
                      data-testid={'undo-changes'}>
                      Undo Changes
                      <FontAwesomeIcon icon={faUndo} />
                    </Button>
                    <div className={styles.rightButtonsContainer}>
                      {(mode === 'create' || formState.noteStatus === 'draft') && (
                        <Button
                          variant="contained"
                          disabled={!isOkToSaveAsDraft}
                          onClick={handleSaveAsDraft}
                          data-testid={'save-as-draft'}>
                          Save Draft
                          <FontAwesomeIcon icon={faSave} />
                        </Button>
                      )}
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        data-testid={'submit'}>
                        {mode === 'create' || formState.noteStatus === 'draft' ? 'Submit' : 'Save'}
                        <FontAwesomeIcon icon={faArrowCircleRight} />
                      </Button>
                    </div>
                  </>
                )}
              </div>
            </form>
          </div>
        </div>
      </>
    );
  }
);
