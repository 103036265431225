import React from 'react';
import { INote } from 'domain/store/NoteModel';
import moment from 'moment';
import { Typography } from '@material-ui/core';
import { Timeline } from '@material-ui/lab';
import { SquadFeedTimelineItem } from './SquadFeedTimelineItem';

export const SquadFeedTimeline: React.FC<{ notes: INote[]; group: string }> = ({
  notes,
  group,
}) => {
  const currentYear = moment().format('YYYY');

  const title =
    moment(group).format('YYYY') === currentYear
      ? moment(group).format('MMMM')
      : moment(group).format('MMMM YYYY');

  const isFirstOfDate = (note: INote, index: number) => {
    const date = (note.modifiedDateMoment || note.createdDateMoment)
      .local()
      .startOf('day')
      .valueOf();

    return (
      notes.findIndex(
        (n) =>
          (n.modifiedDateMoment || n.createdDateMoment).local().startOf('day').valueOf() === date
      ) === index
    );
  };

  return (
    <>
      <Typography variant={'h4'}>{title}</Typography>

      <Timeline>
        {notes.map((n, index, array) => (
          <SquadFeedTimelineItem
            note={n}
            key={index}
            connected={index < array.length - 1}
            isFirstOfDate={isFirstOfDate(n, index)}
          />
        ))}
      </Timeline>
    </>
  );
};
