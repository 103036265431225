import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import React, { ChangeEvent } from 'react';
import { LeaveDatepicker } from './LeaveDatepicker';

interface IDialogWithDatepickerProps {
  isOpen: boolean;
  title: string;
  employeeDisplayName: string;
  currentExpectedReturn: string | null;
  onDateChange: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onAgreeClick?: () => void;
  onDisagreeClick?: () => void;
  isDisabled: boolean;
}

export const DialogWithDatepicker: React.FC<IDialogWithDatepickerProps> = (
  props: IDialogWithDatepickerProps
) => {
  const onAgreeClick = () => {
    props.onAgreeClick && props.onAgreeClick();
  };

  const onDisagreeClick = () => {
    props.onDisagreeClick && props.onDisagreeClick();
  };
  return (
    <Dialog
      open={props.isOpen}
      onClose={onDisagreeClick}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title" data-testid={'dialog-title'}>
        {props.title}
      </DialogTitle>
      <DialogContent>
        <>
          {!props.currentExpectedReturn ? (
            <>
              <DialogContentText>
                You are editing {props.employeeDisplayName}&apos;s Long Term Leave to
              </DialogContentText>
              <br />
              <LeaveDatepicker
                value={props.currentExpectedReturn}
                isUpdating={false}
                onChange={props.onDateChange}
              />
            </>
          ) : (
            <DialogContentText>
              You are clearing {props.employeeDisplayName}&apos;s Long Term Leave
            </DialogContentText>
          )}
        </>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onAgreeClick}
          color="primary"
          autoFocus
          disabled={!props.currentExpectedReturn ? props.isDisabled : false}
          data-testid={'dialog-button-agree'}>
          {'Submit'}
        </Button>
        {props.onDisagreeClick && (
          <Button
            onClick={onDisagreeClick}
            color="secondary"
            data-testid={'dialog-button-disagree'}>
            {'Cancel'}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
