import { errorLog } from 'index';
import { Instance, types } from 'mobx-state-tree';
import moment, { utc } from 'moment';
import { api } from 'shared/api/interfaces';
import { flow, getEnv, LoadingStateModel } from '../util';

export const GroupMemberModel = types
  .model('GroupMemberModel', {
    idpId: types.string,
    displayName: types.string,
    givenName: types.string,
    familyName: types.string,
    lastCatchupDateUTC: types.maybeNull(types.string),
    nextCatchupDateUTC: types.maybeNull(types.string),
    catchupFrequencyInWeeks: types.number,
    catchupGroupId: types.number,
  })
  .views((self) => ({
    get lastCatchupDateAsLocalString() {
      return utc(self.lastCatchupDateUTC).local().format('DD/MM/YY');
    },
    get nextCatchupDateAsLocalString() {
      return utc(self.nextCatchupDateUTC).local().format('DD/MM/YY');
    },
    get daysUntilNextCatchup() {
      if (!self.lastCatchupDateUTC) return undefined;
      const nextCatchupDateLocal = utc(self.nextCatchupDateUTC).local().startOf('day');
      const now = moment().startOf('day');
      return nextCatchupDateLocal.diff(now, 'days');
    },
  }));

export interface IGroupMemberModel extends Instance<typeof GroupMemberModel> {}

export function createCatchupGroupMember(member: api.CatchupGroupMemberDto): IGroupMemberModel {
  return GroupMemberModel.create({
    ...member,
    nextCatchupDateUTC: member.nextCatchupDateUTC,
    lastCatchupDateUTC: member.lastCatchupDateUTC,
  });
}

export const GroupModel = types.model('GroupModel', {
  catchupGroupId: types.number,
  catchupGroupName: types.string,
  members: types.array(GroupMemberModel),
});

export interface IGroupModel extends Instance<typeof GroupModel> {}

export function createCatchupGroup(cug: api.CatchupGroupDto): IGroupModel {
  return GroupModel.create({
    catchupGroupId: cug.catchupGroupId,
    catchupGroupName: cug.catchupGroupName,
    members: cug.members.map((m) => createCatchupGroupMember(m)),
  });
}

export const CatchUpsModel = types
  .model('GroupListModel', {
    groups: types.optional(types.array(GroupModel), []),
    state: LoadingStateModel,
  })
  .actions((self) => ({
    loadGroups: flow(function* (): Generator<Promise<IGroupModel[]>, void, IGroupModel[]> {
      const { ajax } = getEnv(self);

      self.state = 'loading';

      try {
        self.groups.clear();
        const result = yield ajax
          .get('Catchup/groups/me')
          .json<api.CatchupGroupDto[]>()
          .then((cugs) => cugs.map((cug) => createCatchupGroup(cug)));

        self.groups.clear();
        result.forEach((g: IGroupModel) => self.groups.push(g));
        self.state = 'done';
      } catch (error) {
        errorLog('Failed to load groups', error);
        self.state = 'error';
      }
    }),
    createGroup: flow(function* (
      catchupGroupName: string
    ): Generator<Promise<IGroupModel>, void, IGroupModel> {
      const { ajax } = getEnv(self);

      try {
        const result = yield ajax
          .post('Catchup/groups', {
            json: {
              catchupGroupName,
            },
          })
          .json<api.CatchupGroupDto>()
          .then((cug) => createCatchupGroup(cug));

        self.groups.push(result);
      } catch (error) {
        errorLog('Failed to get catchup groups', error);
        throw error;
      }
    }),
    addMember: flow(function* (
      employeeIdpId: string,
      groupId: number,
      frequency: number
    ): Generator<Promise<IGroupMemberModel>, void, IGroupMemberModel> {
      const { ajax } = getEnv(self);

      const result = yield ajax
        .post('Catchup/groups/members', {
          json: {
            groupId,
            employeeIdpId,
            frequency,
          },
        })
        .json<api.CatchupGroupMemberDto>()
        .then((cugm) => createCatchupGroupMember(cugm));

      self.groups.find((g) => g.catchupGroupId === groupId)?.members.push(result);
    }),
    deleteGroup: flow(function* (catchupGroupId: number): Generator<Promise<void>, void, void> {
      const { ajax } = getEnv(self);
      yield ajax
        .delete('Catchup/groups', {
          searchParams: {
            catchupGroupId,
          },
        })
        .json<void>();

      const group = self.groups.find((g) => g.catchupGroupId === catchupGroupId);
      group && self.groups.remove(group);
    }),
    renameGroup: flow(function* (
      catchupGroupId: number,
      groupName: string
    ): Generator<Promise<void>, void, void> {
      const { ajax } = getEnv(self);
      return yield ajax
        .put('Catchup', {
          json: {
            catchupGroupId,
            groupName,
          },
        })
        .json<void>();
    }),
    updateCatchupFrequency: flow(function* (
      catchupGroupId: number,
      catchupGroupMemberIdpId: string,
      frequency: number
    ): Generator<Promise<IGroupMemberModel>, void, IGroupMemberModel> {
      const { ajax } = getEnv(self);

      const result = yield ajax
        .put('Catchup/groups/members/frequency', {
          json: {
            catchupGroupId,
            catchupGroupMemberIdpId,
            frequency,
          },
        })
        .json<api.CatchupGroupMemberDto>()
        .then((cugm) => createCatchupGroupMember(cugm));

      const group = self.groups.find((g) => g.catchupGroupId === catchupGroupId);
      const member = group?.members.find((m) => m.idpId === result.idpId);
      if (group && member) {
        member.nextCatchupDateUTC = result.nextCatchupDateUTC;
      }
    }),
    removeMemberFromGroup: flow(function* (
      catchupGroupId: number,
      catchupGroupMemberIdpId: string
    ): Generator<Promise<void>, void, void> {
      const { ajax } = getEnv(self);
      yield ajax
        .delete('Catchup/groups/members', {
          searchParams: {
            catchupGroupId,
            catchupGroupMemberIdpId,
          },
        })
        .json<void>();

      const group = self.groups.find((g) => g.catchupGroupId === catchupGroupId);
      const member = group?.members.find((m) => m.idpId === catchupGroupMemberIdpId);

      if (group && member) {
        group.members.remove(member);
      }
    }),
  }));

export interface ICatchUpsModel extends Instance<typeof CatchUpsModel> {}
