import { Instance, types } from 'mobx-state-tree';
import { flow, getEnv, LoadingStateModel } from '../util';
import moment, { utc } from 'moment';
import { api } from 'shared/api/interfaces';
import { errorLog } from 'index';

const PersonModel = types.model('Person', {
  idpId: types.string,
  givenName: types.string,
  familyName: types.string,
  displayName: types.string,
  roleType: types.maybeNull(types.string),
  squadName: types.maybeNull(types.string),
});

export interface IPerson extends Instance<typeof PersonModel> {}

const SquadCatchUpItemModel = types
  .model('SquadCatchUpItem', {
    employee: PersonModel,
    personWhoCaughtUpWithEmployee: types.maybeNull(PersonModel),
    lastCatchupDateUTC: types.maybeNull(types.string),
  })
  .views((self) => ({
    get lastCatchupDateAsLocalString() {
      return utc(self.lastCatchupDateUTC).local().format('DD/MM/YY');
    },
    get daysSinceLastCatchup() {
      if (!self.lastCatchupDateUTC) return undefined;
      const lastCatchupDateLocal = utc(self.lastCatchupDateUTC).local().startOf('day');
      const now = moment().startOf('day');
      return now.diff(lastCatchupDateLocal, 'days');
    },
  }));

export interface ISquadCatchUpItem extends Instance<typeof SquadCatchUpItemModel> {}

export function createSquadCatchUpItem(item: api.SquadCatchupDto): ISquadCatchUpItem {
  return SquadCatchUpItemModel.create({
    employee: item.employee,
    lastCatchupDateUTC: item.lastCatchupDateUTC,
    personWhoCaughtUpWithEmployee: item.personWhoCaughtUpWithEmployee,
  });
}

export const SquadCatchUpsModel = types
  .model('SquadCatchUpsModel', {
    squadCatchUps: types.array(SquadCatchUpItemModel),
    state: LoadingStateModel,
  })
  .actions((self) => ({
    loadCatchupsForSquad: flow(function* (
      squadName: string
    ): Generator<Promise<ISquadCatchUpItem[]>, void, ISquadCatchUpItem[]> {
      const { ajax } = getEnv(self);

      try {
        self.state = 'loading';
        self.squadCatchUps.clear();
        const result = yield ajax
          .get('Catchup/squad', { searchParams: { squadName } })
          .json<api.SquadCatchupDto[]>()
          .then((scus) => scus.map((scu) => createSquadCatchUpItem(scu)));

        result.map((s) => self.squadCatchUps.push(s));
        self.state = 'done';
      } catch (error) {
        errorLog('Failed to load squad catch-ups', error);
        self.state = 'error';
      }
    }),
  }));

export interface ISquadCatchUpsModel extends Instance<typeof SquadCatchUpsModel> {}
