import { createMuiTheme } from '@material-ui/core';
import { siteDefault } from './views/themes';

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: siteDefault['--primary-one'],
    },
  },
});
