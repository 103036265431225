import React from 'react';
import { INote } from 'domain/store/NoteModel';
import { SquadFeedTimeline } from './SquadFeedTimeline';

interface ISquadFeedTimelineGroupsProps {
  groups: { [key: string]: INote[] };
}

export const SquadFeedTimelineGroups: React.FC<ISquadFeedTimelineGroupsProps> = ({ groups }) => {
  const entries = Object.entries(groups);

  return (
    <>
      {entries.length > 0 ? (
        entries.map(([key, value], i) => (
          <SquadFeedTimeline
            group={key}
            notes={value.sort((a, b) =>
              (b.modifiedDateMoment ?? b.createdDateMoment).diff(
                a.modifiedDateMoment ?? a.createdDateMoment
              )
            )}
            key={i}
          />
        ))
      ) : (
        <p>There are no notes currently available</p>
      )}
    </>
  );
};
