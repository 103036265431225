import { flow, getEnv } from 'domain/store/util';
import { Instance, types } from 'mobx-state-tree';
import { api } from 'shared/api/interfaces';
import { IEmployeeStatusDto } from '../dataTranferObjects/IEmployeeStatusDto';

export const EmployeeStatusModifier = types.model('EmployeeStatusModifier', {
  idpId: types.identifier,
  displayName: types.string,
});

const toDictionary: { [id: number]: string } = {};

toDictionary[0] = 'No concern';
toDictionary[1] = 'Few things to keep an eye on';
toDictionary[2] = 'Needs close attention';

export const EmployeeStatusLabels = toDictionary;

export interface IEmployeeStatusModel extends Instance<typeof EmployeeStatusModel> {}

export interface IEmployeeStatusWithMobx extends IEmployeeStatusDto {
  modifiedBy: Instance<typeof EmployeeStatusModifier>;
}

export interface IEmployeeStatusModelValue extends Instance<typeof EmployeeStatusModelValue> {}

export const EmployeeStatusModelValue = types.model('EmployeeStatusModelValue', {
  status: types.number,
  modifiedBy: EmployeeStatusModifier,
  modifiedDateTime: types.string,
});

export const EmployeeStatusModel = types
  .model('EmployeeStatusModel', {
    value: types.maybeNull(EmployeeStatusModelValue),
  })
  .actions((self) => {
    const updateEmployeeStatus = function* (
      statusData: IUpdateStatusRequest
    ): Generator<
      Promise<IEmployeeStatusWithMobx>,
      IEmployeeStatusWithMobx,
      IEmployeeStatusWithMobx
    > {
      const { ajax } = getEnv(self);

      const result = yield ajax
        .post('employee/status', {
          json: statusData,
        })
        .json<api.EmployeeStatusDto>();

      self.value = result;
      return result;
    };

    return {
      updateEmployeeStatus: flow(updateEmployeeStatus),
    };
  });

export interface IEmployeeStatus extends Instance<typeof EmployeeStatusModel> {}

export interface IEmployeeStatuses extends Instance<typeof EmployeeStatusModel> {}

export interface IUpdateStatusRequest {
  employeeIdpId: string;
  status: number;
}
