import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid } from '@material-ui/core';
import React from 'react';
import styles from './NoAccessToApp.module.scss';

export const NoAccessToApp: React.FC = function () {
  return (
    <div className={styles.container}>
      <Grid
        container
        className={styles.container}
        direction="column"
        justify="center"
        alignItems="center"
        spacing={4}>
        <Grid item>
          <FontAwesomeIcon className={styles.iconColor} icon={faExclamationCircle} size={'10x'} />
        </Grid>
        <Grid className={styles.bold} item>
          Oops
        </Grid>
        <Grid item>
          You don&apos;t currently have access to this app. If you believe you should have access,
          please contact your local People Partner.
        </Grid>
      </Grid>
    </div>
  );
};
