import React from 'react';

import { Link } from 'react-router-dom';

import { ReactComponent as MyLogo } from 'assets/purple-logo.svg';

interface Props {
  className?: string;
}

export const Logo: React.FC<Props> = ({ className }) => {
  return (
    <Link to="/" className={className}>
      <MyLogo />
    </Link>
  );
};
