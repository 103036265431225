import { errorLog } from 'index';
import { Instance, types } from 'mobx-state-tree';
import { api } from 'shared/api/interfaces';

import { flow, getEnv, LoadingStateModel } from './util';

export const FlagModel = types.model('Flag', {
  flagId: types.identifierNumber,
  name: types.string,
  description: types.maybeNull(types.string),
});

export const FlagsModel = types
  .model('FlagsModel', {
    state: LoadingStateModel,
    flags: types.map(FlagModel),
  })
  .views((self) => ({
    get all() {
      return Array.from(self.flags.values());
    },
    getFlag(flagId?: number): IFlag | undefined {
      if (flagId) {
        return Array.from(self.flags.values()).find((flag) => {
          return flag.flagId === flagId;
        });
      }
      return undefined;
    },
  }))
  .actions((self) => ({
    afterAttach: flow(function* (): Generator<Promise<IFlag[]>, void, IFlag[]> {
      if (self.state === 'done') return;

      const { ajax } = getEnv(self);
      try {
        const data = yield ajax
          .get('Flags', {})
          .json<api.FlagDto[]>()
          .then((flags) => flags.map((flag) => FlagModel.create(flag)));
        data.forEach((flag) => self.flags.put(flag));
        self.state = 'done';
      } catch (e) {
        errorLog('GET flags failed.', e);
        self.state = 'error';
      }
    }),
  }));

export interface IFlag extends Instance<typeof FlagModel> {}

export interface IFlags extends Instance<typeof FlagsModel> {}
