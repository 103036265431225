import querystring from 'query-string';
import { useHistory } from 'react-router-dom';

interface IQueryParams {
  [key: string]: string;
}

type UseNavigateReturn = (route: string, queryParams?: IQueryParams) => void;

export function useNavigate(): UseNavigateReturn {
  const history = useHistory();
  return (route: string, queryParams?: IQueryParams) => {
    history.push({
      pathname: route,
      search: queryParams ? querystring.stringify(queryParams) : undefined,
    });
  };
}
