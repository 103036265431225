import { types } from 'mobx-state-tree';

export enum PermissionLevel {
  None,
  View,
  Edit,
}

const SquadsPermissionModel = types.model('Squads', {
  canViewTrends: types.optional(types.boolean, false),
  canViewFeed: types.optional(types.boolean, false),
  canViewNoteReview: types.optional(types.boolean, false),
  canViewTalentAnalysis: types.optional(types.boolean, false),
  roleChanges: types.optional(types.number, PermissionLevel.None),
});

const EmployeePermissionModel = types.model('Employees', {
  primaryFlag: types.optional(types.number, PermissionLevel.None),
  flightRisk: types.optional(types.number, PermissionLevel.None),
  roleChangePathway: types.optional(types.number, PermissionLevel.None),
  longTermLeave: types.optional(types.number, PermissionLevel.None),
  status: types.optional(types.number, PermissionLevel.None),
  roleChanges: types.optional(types.number, PermissionLevel.None),
  canViewLeaveBalances: types.optional(types.boolean, false),
  canViewPurpleMeLink: types.optional(types.boolean, false),
  canViewPurpleMeRetroDate: types.optional(types.boolean, false),
  canSearchForFormerEmployees: types.optional(types.boolean, false),
  canViewNoneSquadEmployees: types.optional(types.boolean, false),
});

const NotesPermissionModel = types.model('Notes', {
  canViewPreviousSquadNotes: types.optional(types.boolean, false),
  canViewSensitive: types.optional(types.boolean, false),
  canBypassReview: types.optional(types.boolean, false),
  canDeleteSubmitted: types.optional(types.boolean, false),
});

const CatchUpsPermissionModel = types.model('CatchUps', {
  canViewMyCatchUps: types.optional(types.boolean, false),
  canViewSquadCatchUps: types.optional(types.boolean, false),
});

export const PermissionsModel = types
  .model('Permissions', {
    canViewAllSquads: types.optional(types.boolean, false),

    canViewSquadTab: types.optional(types.boolean, false),
    canViewEmployeeTab: types.optional(types.boolean, false),
    canViewNoteTab: types.optional(types.boolean, false),
    canViewCatchupGroupTab: types.optional(types.boolean, false),

    canManageRoles: types.optional(types.boolean, false),
    canAssignSquads: types.optional(types.boolean, false),

    squads: types.optional(SquadsPermissionModel, {}),
    employees: types.optional(EmployeePermissionModel, {}),
    notes: types.optional(NotesPermissionModel, {}),
    catchUps: types.optional(CatchUpsPermissionModel, {}),
  })
  .views(() => {
    return {
      canView(permission: PermissionLevel): boolean {
        return permission === PermissionLevel.View || permission === PermissionLevel.Edit;
      },
      canEdit(permission: PermissionLevel): boolean {
        return permission === PermissionLevel.Edit;
      },
    };
  });
