import React from 'react';
import { History } from 'history';
import { Router } from 'react-router-dom';

import { siteDefault } from './themes';
import styles from './App.module.scss';
import { Routes } from 'views/routes/Routes';
import { IRootStoreModel } from 'domain/store/RootStoreModel';
import { useAuthentication } from 'hooks/useAuthentication';
import { IAuthenticationProvider } from 'auth/AuthenticationTypes';
import { Notifications } from 'views/components/notification/Notifications';

import { LocalizationProvider } from '@material-ui/pickers';
import MomentUtils from '@material-ui/pickers/adapter/moment';
import { observer } from 'mobx-react-lite';
import { Unauthorised } from './routes/unauthorised/Unauthorised';
import { NoAccessToApp } from './components/noAccessToApp/NoAccessToApp';
import { Box, Button } from '@material-ui/core';

import { ThemeProvider } from '@material-ui/core';
import { theme } from 'theme';

interface IAppProps {
  history: History;
  store: IRootStoreModel;
  auth: IAuthenticationProvider;
}

export const StoreContext = React.createContext<IRootStoreModel | undefined>(undefined);

export const App: React.FC<IAppProps> = observer(({ history, store, auth }) => {
  const authState = useAuthentication(auth);

  if (authState === 'login-error') {
    return (
      <Box data-testid={'auth-state-login-error'} className={styles.root} style={siteDefault}>
        <NoAccessToApp />
      </Box>
    );
  }

  if (authState === 'signed-in' && store.state === 'unauthorized') {
    return (
      <Box data-testid={'auth-state-unauthorized'} className={styles.root} style={siteDefault}>
        <Unauthorised />
        <Box marginLeft={'3.5em'}>
          <Button data-testid={'auth-button-sign-out'} onClick={() => auth.signOut()}>
            Sign out
          </Button>
        </Box>
      </Box>
    );
  }

  if (authState === 'loading' || store.isLoading) {
    return (
      <Box data-testid={'auth-state-loading'} className={styles.root} style={siteDefault}>
        <p>Loading {store.isLoading && <span>your account</span>} please wait...</p>
      </Box>
    );
  }

  if (authState === 'signed-in') {
    return (
      <ThemeProvider theme={theme}>
        <Box data-testid={'auth-state-signed-in'} className={styles.root} style={siteDefault}>
          <LocalizationProvider dateAdapter={MomentUtils}>
            <StoreContext.Provider value={store}>
              <Router history={history}>
                <Routes />
              </Router>
              <Notifications />
            </StoreContext.Provider>
          </LocalizationProvider>
        </Box>
      </ThemeProvider>
    );
  }

  return (
    <Box data-testid={'auth-state-sign-in'} className={styles.root} style={siteDefault}>
      <p>
        Please{' '}
        <button data-testid={'auth-button-sign-in'} onClick={() => auth.signIn()}>
          sign in
        </button>
      </p>
    </Box>
  );
});
