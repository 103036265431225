import { MenuItem, FormControl, InputLabel, Select } from '@material-ui/core';
import React from 'react';
import { observer } from 'mobx-react-lite';
import { StatusIcon } from 'views/components/EmployeeStatus/StatusIcon/StatusIcon';
import { EmployeeStatusLabels } from 'domain/store/employeeJourney/EmployeeStatusModel';

interface IEmployeeStatusSelectProps {
  id: string;
  options: string[];
  onChanged: (selectedOptions: string) => void;
  label: string;
  value?: string;
}

export const EmployeeStatusSelect: React.FC<IEmployeeStatusSelectProps> = observer((props) => {
  return (
    <FormControl variant={'outlined'} fullWidth={true}>
      <InputLabel id={props.id}>{props.label}</InputLabel>
      <Select
        fullWidth={true}
        labelId={props.id}
        label={props.label}
        onChange={(event) => props.onChanged(event.target.value as string)}
        value={props.value}>
        {props.options.map((option) => (
          <MenuItem key={option} value={option}>
            {option ? (
              <StatusIcon color={option} status={EmployeeStatusLabels[Number(option)]} />
            ) : (
              'Any'
            )}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
});
