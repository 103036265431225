import { Instance, types } from 'mobx-state-tree';
import { flow, getEnv } from 'domain/store/util';
import { IFlightRiskDto } from 'domain/store/dataTranferObjects/IFlightRiskDto';
import { api } from 'shared/api/interfaces';

export const FlightRiskStatusModifier = types.model('FlightRiskStatusModifier', {
  idpId: types.identifier,
  displayName: types.string,
});

export interface IFlightRiskStatusModel extends Instance<typeof FlightRiskStatusModel> {}

export interface IFlightRiskWithMobx extends IFlightRiskDto {
  modifiedBy: Instance<typeof FlightRiskStatusModifier>;
}

export interface IFlightRiskStatusModelValue extends Instance<typeof FlightRiskStatusModelValue> {}

export const FlightRiskStatusModelValue = types.model('FlightRiskStatusModelValue', {
  isFlightRisk: types.boolean,
  modifiedBy: FlightRiskStatusModifier,
  modifiedDateTime: types.string,
});

export const FlightRiskStatusModel = types
  .model('FlightRiskStatusModel', {
    value: types.maybeNull(FlightRiskStatusModelValue),
  })
  .actions((self) => {
    const toggleFlightRiskStatus = function* (
      employeeIdpId: string
    ): Generator<Promise<IFlightRiskWithMobx>, IFlightRiskWithMobx, IFlightRiskWithMobx> {
      const { ajax } = getEnv(self);

      const result = yield ajax
        .post('Employee/FlightRisk', {
          json: {
            employeeIdpId,
            flagValue: self.value ? !self.value.isFlightRisk : true,
          },
        })
        .json<api.FlightRiskDto>();

      self.value = FlightRiskStatusModelValue.create(result);
      return result;
    };

    return {
      toggleFlightRiskStatus: flow(toggleFlightRiskStatus),
    };
  });

export function createFlightRiskStatusModel(
  model: api.FlightRiskDtoValueDto
): IFlightRiskStatusModel | null {
  if (!model) {
    return null;
  }

  return FlightRiskStatusModel.create(model);
}
