import { Instance, types } from 'mobx-state-tree';
import { flow, getEnv } from 'domain/store/util';
import { api } from 'shared/api/interfaces';

export const PrimaryFlagModifier = types.model('PrimaryFlagModifier', {
  idpId: types.identifier,
  displayName: types.string,
});

export const PrimaryFlagModelValue = types.model('PrimaryFlagModelValue', {
  flagId: types.number,
  name: types.string,
  modifiedBy: PrimaryFlagModifier,
  modifiedDateTime: types.string,
});

export const PrimaryFlagModel = types
  .model('PrimaryFlagModel', {
    value: types.maybeNull(PrimaryFlagModelValue),
  })
  .actions((self) => {
    const updatePrimaryFlag = function* (
      primaryFlagData: IUpdatePrimaryFlagRequest
    ): Generator<Promise<api.FlagDto>, api.FlagDto, api.FlagDto> {
      const { ajax } = getEnv(self);
      return yield ajax.post('employee/primaryflag', { json: primaryFlagData }).json();
    };

    return {
      update: flow(updatePrimaryFlag),
    };
  });

export interface IPrimaryFlagModel extends Instance<typeof PrimaryFlagModel> {}

export function createPrimaryFlagModel(pfm: api.FlagDtoValueDto): IPrimaryFlagModel | null {
  if (!pfm) {
    return null;
  }

  return PrimaryFlagModel.create(pfm);
}

export interface IUpdatePrimaryFlagRequest {
  employeeIdpId: string;
  flagId: number;
}
