import React, { useState } from 'react';
import styles from 'views/routes/employeeJourney/components/employeeTimeline/EmployeeTimeline.module.scss';
import cn from 'classnames';
import { NoteTimelineItem } from 'views/routes/employeeJourney/components/employeeTimeline/components/noteTimelineItem/NoteTimelineItem';
import { useEmployeeJourney } from 'hooks/useEmployeeJourney';
import { observer } from 'mobx-react-lite';
import moment, { Moment, MomentInput } from 'moment';
import { INote } from 'domain/store/NoteModel';
import { useUser } from 'hooks/useUser';
import { useNavigate } from 'hooks/useNavigate';
import { LoadingIndicator } from 'views/components/loadingIndicator/LoadingIndicator';
import { DateRange, DateRangePicker } from '@material-ui/pickers';
import { Button, TextField } from '@material-ui/core';
import { TagDropdown } from 'views/components/tagDropdown/TagDropdown';
import { BackToTopButton } from './components/backToTopButton/BackToTopButton';
import { useBackToTopButton } from 'hooks/useBackToTopButton';
import { CREATE_NOTE_ROUTE_PATH, EDIT_NOTE_ROUTE_PATH } from 'views/routes/Routes';
import { ITag } from 'domain/store/TagsModel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile } from '@fortawesome/free-regular-svg-icons';
import { DateRangeValidationError } from '@material-ui/pickers/_helpers/date-utils';
import { useStore } from 'hooks/useStore';
import { AlertDialog } from 'views/components/alertDialog/AlertDialog';

interface EmployeeTimelineProps {
  idpId?: string;
  className?: string;
}

interface NotesProps {
  notes: { [key: string]: INote[] };
  editNoteHandler: (noteId: number) => void;
  deleteNoteHandler: (noteId: number) => void;
  editDisabled: boolean;
}

const Notes: React.FC<NotesProps> = ({
  notes,
  editNoteHandler,
  deleteNoteHandler,
  editDisabled,
}) => {
  const currentYear = moment().format('YYYY');
  const noteEntries = Object.entries(notes);

  return (
    <div className={styles.notesContainer}>
      {noteEntries.map(([key, monthNotes]) => {
        const monthTitle =
          moment(key).format('YYYY') === currentYear
            ? moment(key).format('MMMM')
            : moment(key).format('MMMM YYYY');

        return (
          <div className={styles.monthNotesContainer} key={key}>
            <h3>{monthTitle}</h3>
            <ul>
              {monthNotes?.map((note, index) => {
                return (
                  <NoteTimelineItem
                    key={index}
                    note={note}
                    deletable={note.canDelete}
                    editable={note.canEdit}
                    onEdit={editNoteHandler}
                    onDelete={deleteNoteHandler}
                    editDisabled={editDisabled}
                  />
                );
              })}
            </ul>
          </div>
        );
      })}
    </div>
  );
};

export const EmployeeTimeline: React.FC<EmployeeTimelineProps> = observer(
  (props: EmployeeTimelineProps) => {
    const { notifications } = useStore();
    const user = useUser();
    const { loadNotes } = useEmployeeJourney();
    const navigate = useNavigate();
    const { isLoaded, notes, employee } = useEmployeeJourney();
    const [selectedDateRange, setDateRange] = useState<DateRange<Moment>>([null, null]);
    const [selectedTags, setSelectedTags] = useState<ITag[]>([]);
    const [
      dateRangeValidationError,
      setDateRangeValidationError,
    ] = React.useState<DateRangeValidationError>([null, null]);
    const {
      showBackToTopButton,
      scrollToTopOfTimeline,
      scrollableContainerRef,
    } = useBackToTopButton();

    const [showAlertDialog, setShowAlertDialog] = useState<boolean>(false);
    const [noteIdToDelete, setNoteIdToDelete] = useState<number | null>(null);

    const noteIsIncludedByFilter = (note: INote) => {
      const noteDate = note.eventDateMoment;
      const [fromDate, toDate] = selectedDateRange;

      const isSameOrAfterFromDate = !fromDate || noteDate!.isSameOrAfter(fromDate);
      const isBeforeToDate = !toDate || noteDate!.isBefore(toDate.clone().add(1, 'day'));

      const noteTags = note.tags.map((t) => t.tagId);
      const noteTagsAreSelected =
        selectedTags.length === 0 ||
        noteTags.filter((tagId) => selectedTags.map((t) => t.tagId).indexOf(tagId) >= 0).length > 0;

      return isSameOrAfterFromDate && isBeforeToDate && noteTagsAreSelected;
    };

    const groupedNotes =
      notes?.filter(noteIsIncludedByFilter).reduce((acc: NotesProps['notes'], cur: INote) => {
        const key = cur.eventDateMoment!.format('YYYY-MM');
        if (Object.prototype.hasOwnProperty.call(acc, key)) {
          acc[key].push(cur);
        } else {
          acc[key] = [cur];
        }
        return acc;
      }, {}) || {};

    const isFiltered = () => {
      const [fromDate, toDate] = selectedDateRange;
      return fromDate || toDate || selectedTags.length > 0;
    };

    const storeNoteToDelete = (noteId: number) => {
      setNoteIdToDelete(noteId);
      setShowAlertDialog(true);
    };

    const resetNoteDeleteStates = () => {
      setNoteIdToDelete(null);
      setShowAlertDialog(false);
    };

    const onAgreeClickDeleteNote = () => {
      user
        .deleteNote(noteIdToDelete as number)
        .then(() => loadNotes())
        .then(() => {
          notifications.addSuccess('The note was deleted successfully');
        })
        .catch((e) => {
          if (e?.response?.status === 409) {
            notifications.addError(
              'Conflict occurred saving changes, please refresh the page, and try again after.'
            );
          } else if (e?.response?.status === 403) {
            notifications.addError('Not authorised to save changes.');
          } else {
            notifications.addError('An error occurred whilst deleting the note, please try again');
          }
        });
      resetNoteDeleteStates();
    };

    const onDisagreeClick = () => resetNoteDeleteStates();

    const editNote = (noteId: number) => {
      navigate(`${EDIT_NOTE_ROUTE_PATH}/${noteId}`);
    };

    return (
      <div
        className={cn(props.className, styles.employeeTimelineComponent)}
        ref={scrollableContainerRef}>
        <div className={styles.timelineContainer}>
          <div className={styles.filterContainer}>
            <DateRangePicker
              calendars={1}
              startText={'Start Date'}
              endText={'End Date'}
              inputFormat={'DD/MM/YYYY'}
              maxDate={moment()}
              renderInput={(startProps, endProps) => (
                <div className={styles.dateRange}>
                  <TextField
                    className={cn(styles.startDate, styles.roundInputBox)}
                    {...startProps}
                    error={!!dateRangeValidationError[0]}
                    helperText={''}
                  />
                  <TextField
                    className={styles.roundInputBox}
                    {...endProps}
                    error={!!dateRangeValidationError[1]}
                    helperText={''}
                  />
                </div>
              )}
              value={selectedDateRange}
              onChange={setDateRange}
              onError={(reason, value) => {
                // Fix for known bug https://github.com/mui-org/material-ui-pickers/issues/1801
                if (reason[0] === 'invalidRange' && value[0] && value[1]) {
                  if (moment(value[0] as MomentInput).isSame(moment(value[1] as MomentInput))) {
                    setDateRangeValidationError([null, null]);
                    return;
                  }
                }
                setDateRangeValidationError(reason);
              }}
            />

            <TagDropdown
              className={cn(styles.tag, styles.roundInputBox)}
              onChange={(tags) => setSelectedTags(tags)}
              defaultValue={selectedTags}
            />
            {isLoaded && (
              <div className={styles.addNoteButtonContainer}>
                <Button
                  variant="contained"
                  color="primary"
                  classes={{ containedPrimary: styles.materialOverride }}
                  onClick={() =>
                    navigate(CREATE_NOTE_ROUTE_PATH, { employeeId: props.idpId || '' })
                  }
                  disabled={employee?.isFormerEmployee}
                  data-testid="add-note-button">
                  Add A Note
                </Button>
              </div>
            )}
          </div>

          {isLoaded ? (
            groupedNotes && Object.entries(groupedNotes).length > 0 ? (
              <div className={styles.backToTopButtonContainer}>
                <Notes
                  notes={groupedNotes}
                  editNoteHandler={editNote}
                  deleteNoteHandler={storeNoteToDelete}
                  editDisabled={!!employee?.isFormerEmployee}
                />
                <AlertDialog
                  isOpen={showAlertDialog}
                  title={'Delete note'}
                  text={'Are you sure you want to delete this note?'}
                  onAgreeClick={onAgreeClickDeleteNote}
                  onDisagreeClick={onDisagreeClick}
                />
              </div>
            ) : (
              <div className={styles.emptyState}>
                <FontAwesomeIcon icon={faFile} size={'10x'} />
                <h3>No notes were found</h3>
                {isFiltered() && (
                  <p>
                    You could try adjusting your search filters to help find what you&apos;re
                    looking for.
                  </p>
                )}
              </div>
            )
          ) : (
            <LoadingIndicator className={styles.loading} />
          )}

          {showBackToTopButton && (
            <div className={styles.backToTopContainer}>
              <BackToTopButton scrollToTop={scrollToTopOfTimeline} edge={'end'} />
            </div>
          )}
        </div>
      </div>
    );
  }
);
