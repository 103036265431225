import { Autocomplete } from '@material-ui/lab';
import React from 'react';
import { Checkbox, Chip, TextField } from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { observer } from 'mobx-react-lite';

interface MultiSelectProps {
  id: string;
  options: string[];
  onChanged: (selectedOptions: string[]) => void;
  label: string;
  values?: string[];
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export const MultiSelect: React.FC<MultiSelectProps> = observer((props: MultiSelectProps) => {
  const [selectedOptions, setValues] = React.useState<string[] | undefined>(props.values);

  return (
    <Autocomplete
      multiple
      limitTags={2}
      id={props.id}
      value={selectedOptions}
      onChange={(_, values) => {
        setValues(values);
        props.onChanged(values);
      }}
      options={props.options}
      getOptionLabel={(option) => option}
      disableCloseOnSelect
      renderOption={(option, { selected }) => (
        <React.Fragment>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option}
        </React.Fragment>
      )}
      renderTags={(value, getTagProps) =>
        value.map((v, index) => (
          <Chip variant="outlined" key={v} label={v} size="small" {...getTagProps({ index })} />
        ))
      }
      renderInput={(params) => <TextField {...params} variant="outlined" label={props.label} />}
    />
  );
});
