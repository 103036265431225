import { Typography } from '@material-ui/core';
import React from 'react';
import styles from './StatusIcon.module.scss';

interface IStatusIconProps {
  color: string | number;
  status?: string;
}

export const StatusIcon: React.FC<IStatusIconProps> = (props: IStatusIconProps) => {
  return (
    <div className={styles.statusContainer}>
      <div className={`${styles.circle} ${styles['status_' + props.color]}`} />
      {props.status && (
        <Typography color={'textPrimary'} variant={'h6'} component={'span'} noWrap={true}>
          {props.status}
        </Typography>
      )}
    </div>
  );
};
