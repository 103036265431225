import { Instance, types } from 'mobx-state-tree';

import { TagModel } from './TagsModel';
import { utc } from 'moment';
import { api } from 'shared/api/interfaces';

export const NotePersonNameModel = types
  .model('NotePersonName', {
    idpId: types.string,
    givenName: types.string,
    familyName: types.string,
  })
  .views((self) => ({
    get displayName(): string {
      return `${self.givenName} ${self.familyName}`.trim();
    },
  }));

export type NoteStatus = 'draft' | 'pending' | 'published';
export type NoteSensitivity = 'sensitive' | 'non-sensitive' | 'none';

export const NoteModel = types
  .model('Note', {
    noteId: types.identifierNumber,
    employee: NotePersonNameModel,
    content: types.string,
    tags: types.array(TagModel),
    eventDate: types.maybeNull(types.string),
    createdDate: types.string,
    modifiedDate: types.maybeNull(types.string),
    author: NotePersonNameModel,
    modifier: NotePersonNameModel,
    subject: types.string,
    isPeopleLeaderConversation: types.boolean,
    status: types.optional(
      types.enumeration<NoteStatus>(['draft', 'pending', 'published']),
      'draft'
    ),
    isFaceToFaceDiscussion: types.maybe(types.boolean),
    canDelete: types.boolean,
    canEdit: types.boolean,
    sensitivity: types.optional(
      types.enumeration<NoteSensitivity>(['sensitive', 'non-sensitive', 'none']),
      'none'
    ),
  })
  .views((self) => ({
    get eventDateMoment() {
      return self.eventDate && self.eventDate.trim().length > 0 ? utc(self.eventDate) : null;
    },
    get createdDateMoment() {
      return utc(self.createdDate);
    },
    get modifiedDateMoment() {
      return self.modifiedDate && self.modifiedDate.trim().length > 0
        ? utc(self.modifiedDate)
        : null;
    },
  }));

export interface INoteRequest {
  employeeIdpId: string;
  content: string;
  tags: number[];
  eventDate: string;
  subject: string;
  isPeopleLeaderConversation: boolean;
  noteStatus: NoteStatus | undefined;
  sensitivity: NoteSensitivity | undefined;
  isFaceToFaceDiscussion: boolean | undefined;
}

export interface INote extends Instance<typeof NoteModel> {}

export function createNote(note: api.NoteDto): INote {
  return NoteModel.create({
    noteId: note.noteId,
    employee: note.employee,
    status: note.status as 'draft' | 'pending' | 'published',
    content: note.content,
    tags: note.tags,
    eventDate: note.eventDate,
    createdDate: note.createdDate,
    modifiedDate: note.modifiedDate,
    author: note.author,
    modifier: note.modifier,
    subject: note.subject,
    isPeopleLeaderConversation: note.isPeopleLeaderConversation,
    canDelete: note.canDelete,
    canEdit: note.canEdit,
    sensitivity: note.sensitivity as 'non-sensitive' | 'sensitive' | 'none',
    isFaceToFaceDiscussion: note.isFaceToFaceDiscussion,
  });
}

export interface IPaginatedNotes {
  notes: INote[];
  totalCount: number;
}
