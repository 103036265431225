import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import styles from './NoteItemContent.module.scss';
import ReactMarkdown from 'react-markdown';
import { Box, Button } from '@material-ui/core';
import 'github-markdown-css';

const FADE_HEIGHT = 114;
export const NoteItemContent: React.FC<{ content: string }> = ({ content }) => {
  const item = useRef<HTMLDivElement | null>(null);

  const [needsFade, setNeedsFade] = useState<boolean>(false);
  const [showAll, setShowAll] = useState<boolean>(false);
  const toggleShowMore = () => setShowAll(!showAll);

  useEffect(() => {
    setNeedsFade((item.current?.clientHeight ?? 0) > FADE_HEIGHT);
  }, [item]);

  return (
    <>
      <div
        style={
          { '--fade-height': `${FADE_HEIGHT}px` } as CSSProperties & { '--fade-height': string }
        }
        className={cn({ [styles.root]: needsFade, [styles.rootOpen]: showAll && needsFade })}>
        <div ref={(e) => (item.current = e)} className="markdown-body">
          <ReactMarkdown source={content} skipHtml={true} />
        </div>
        {needsFade && <div className={cn(styles.readMore, { [styles.readMoreOpen]: showAll })} />}
      </div>
      <Box mt={4}>
        {needsFade && (
          <Button className={styles.readMoreBtn} onClick={toggleShowMore}>
            {showAll ? 'Show less...' : 'Show more...'}
          </Button>
        )}
      </Box>
    </>
  );
};
