import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, IconButton, TableCell, TableRow } from '@material-ui/core';
import React, { useState } from 'react';
import { EmployeeDropdown } from 'views/components/employeeDropdown/EmployeeDropdown';
import { LoadingIndicator } from 'views/components/loadingIndicator/LoadingIndicator';
import { FrequencySelector } from '../FrequencySelector/FrequencySelector';
import styles from './AddMemberForm.module.scss';

export interface AddMemberFormProps {
  isAddingMember: boolean;
  onCancelClick: () => void;
  onOkClick: (idpId: string, frequencyId: number) => void;
}

export const AddMemberForm: React.FC<AddMemberFormProps> = ({
  isAddingMember,
  onCancelClick,
  onOkClick,
}) => {
  const [selectedFrequency, setSelectedFrequency] = useState<number>(0);
  const [selectedEmployee, setSelectedEmployee] = useState<string>('');

  const handleFrequencyChange = (frequencyId: number) => {
    setSelectedFrequency(frequencyId);
  };

  const handleSaveClick = () => {
    if (selectedEmployee && selectedFrequency > 0) {
      onOkClick(selectedEmployee, selectedFrequency);
    }
  };

  return (
    <TableRow>
      <TableCell>
        <EmployeeDropdown
          defaultValue={null}
          onChange={(employee, clearCallback) => {
            if (employee) {
              setSelectedEmployee(employee?.idpId);
            }
          }}
        />
      </TableCell>
      <TableCell align="center">-</TableCell>
      <TableCell align="center">-</TableCell>
      <TableCell align="right">-</TableCell>
      <TableCell align="center">
        <FrequencySelector
          onFrequencyChange={(id) => handleFrequencyChange(id)}
          value={0}
          showSelectOption={true}
        />
      </TableCell>
      <TableCell align="center">
        <Button disabled variant="contained" color="primary" size="large" className={styles.button}>
          Add a Note
        </Button>
      </TableCell>
      <TableCell align="center">
        {isAddingMember ? (
          <LoadingIndicator size={'1rem'} />
        ) : (
          <>
            <IconButton onClick={onCancelClick}>
              <FontAwesomeIcon icon={faTimes} color="red" size="xs" />
            </IconButton>
            <IconButton onClick={handleSaveClick}>
              <FontAwesomeIcon icon={faCheck} color="green" size="xs" />
            </IconButton>
          </>
        )}
      </TableCell>
    </TableRow>
  );
};
