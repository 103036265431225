import { errorLog } from 'index';
import { cast, flow, types } from 'mobx-state-tree';
import { api } from 'shared/api/interfaces';
import { LoadingStateModel, getEnv } from '../util';
import { makeAdminUrl } from './AdminModel';
import { EmployeeModel, IEmployeeItem } from './EmployeeListModel';

export const SquadAssignmentEmployeeListModel = types
  .model('EmployeeModel', {
    squadAssignmentEmployees: types.optional(types.array(EmployeeModel), []),
    state: LoadingStateModel,
    squadAssignmentEmployeesSearch: types.optional(types.array(EmployeeModel), []),
    selectedEmployee: types.maybeNull(EmployeeModel),
    searchState: LoadingStateModel,
  })
  .actions((self) => ({
    loadEmployeesEligibleForSquadAssignment: flow(function* (): Generator<
      Promise<IEmployeeItem[]>,
      void,
      IEmployeeItem[]
    > {
      const { ajax } = getEnv(self);

      self.state = 'loading';

      try {
        self.squadAssignmentEmployees.clear();
        const value = yield ajax
          .get(makeAdminUrl('MultipleSquadEmployees'))
          .json<api.UserDto[]>()
          .then((values) => values.map((item) => EmployeeModel.create(item)));
        value.forEach((i) => self.squadAssignmentEmployees.push(i));

        self.state = 'done';
      } catch (e) {
        errorLog('Failed to load employees', e);
        self.state = 'error';
        throw e;
      }
    }),
    assignSquads: flow(function* (
      assignSquadsRequest: api.AssignSquadsRequest
    ): Generator<Promise<void>, void, void> {
      const { ajax } = getEnv(self);

      try {
        yield ajax.post(makeAdminUrl('AssignSquads'), { json: assignSquadsRequest }).json<void>();

        const employee = self.squadAssignmentEmployees.find(
          (emp) => emp.idpId === assignSquadsRequest.employeeIdpId
        );

        if (employee) {
          employee.assignedSquads = cast(assignSquadsRequest.squads);
        }

        self.state = 'done';
      } catch (e) {
        errorLog('Failed to assign squads', e);
        self.state = 'error';
        throw e;
      }
    }),
    setSquadAssignmentEmployees(item: IEmployeeItem | null) {
      self.state = 'loading';
      self.squadAssignmentEmployees.clear();
      const employee = item as IEmployeeItem;
      if (employee) self.squadAssignmentEmployees.push(employee);
      self.selectedEmployee = employee;
      self.state = 'done';
    },
    searchEmployeesEligibleForSquadAssignment: flow(function* (
      nameFilter?: string,
      titleFiltered?: boolean
    ): Generator<Promise<IEmployeeItem[]>, void, IEmployeeItem[]> {
      const { ajax } = getEnv(self);
      self.searchState = 'loading';

      const searchParams = new URLSearchParams();
      if (nameFilter !== undefined) searchParams.set('nameFilter', nameFilter);
      if (titleFiltered !== undefined) searchParams.set('titleFiltered', titleFiltered.toString());
      try {
        const value = yield ajax
          .get(makeAdminUrl('MultipleSquadEmployees'), {
            searchParams: searchParams,
          })
          .json<api.UserDto[]>()
          .then((values) => values.map((item) => EmployeeModel.create(item)));
        self.squadAssignmentEmployeesSearch.clear();
        value.forEach((i) => self.squadAssignmentEmployeesSearch.push(i));
        self.searchState = 'done';
      } catch (e) {
        errorLog('Failed to load employees', e);
        self.searchState = 'error';
        throw e;
      }
    }),
  }));
