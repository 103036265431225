import React from 'react';
import { Tooltip } from '@material-ui/core';
import moment from 'moment';
import { InfoOutlined } from '@material-ui/icons';

export interface UpdatedByTooltipProps {
  updatedByDisplayName: string;
  modifiedDateTime: string;
}

export const UpdatedByInfoIcon: React.FunctionComponent<UpdatedByTooltipProps> = (props) => {
  return (
    <Tooltip
      title={
        <>
          Updated by: {props.updatedByDisplayName} on{' '}
          {moment(props.modifiedDateTime).format('DD/MM/YYYY')}
        </>
      }>
      <InfoOutlined />
    </Tooltip>
  );
};
