import { auth, errorLog } from 'index';
import { getDefaultAjaxInstance } from 'domain/store/util';
import { useState, useCallback } from 'react';
import { api } from 'shared/api/interfaces';
import { IUpdateLongTermLeaveDto } from 'domain/store/dataTranferObjects/ILongTermLeaveDto';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useLongTermLeaveService = (
  initLeaveValue: boolean,
  initExpectedReturn: string | null
) => {
  const [isOnLongTermLeave, setIsOnLongTermLeave] = useState(initLeaveValue);
  const [expectedReturn, setExpectedReturn] = useState(initExpectedReturn);
  const [isLoading, setIsLoading] = useState(false);

  const ajax = getDefaultAjaxInstance(auth);
  const updateLongTermLeave = useCallback(
    async (updateDto: IUpdateLongTermLeaveDto) => {
      setIsLoading(true);
      try {
        const result = await ajax
          .post('Employee/LongTermLeave', {
            json: {
              employeeIdpId: updateDto.employeeIdpId,
              flagValue: !isOnLongTermLeave || updateDto.expectedReturn !== null,
              expectedReturn: updateDto.expectedReturn,
            } as api.UpdateLongTermLeaveRequest,
          })
          .json<api.LongTermLeaveDto>();

        setIsOnLongTermLeave(result.isOnLongTermLeave);
        setExpectedReturn(result.expectedReturn || null);
        return result;
      } catch (error) {
        errorLog('Failed to update employee long term leave.', error);
        throw error;
      } finally {
        setIsLoading(false);
      }
    },
    [ajax, isOnLongTermLeave]
  );

  return { isOnLongTermLeave, isLoading, updateLongTermLeave, expectedReturn };
};
