import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid } from '@material-ui/core';
import cn from 'classnames';
import React from 'react';
import styles from './NoRoleChangeSubmission.module.scss';

export const NoRoleChangeSubmissions: React.FC = () => {
  return (
    <Grid
      container
      className={styles.container}
      direction="column"
      justify="center"
      alignItems="center">
      <Grid item className={cn(styles.icon)}>
        <FontAwesomeIcon icon={faExclamationCircle} size={'7x'} />
      </Grid>
      <Grid className={styles.spacing} item>
        There is no role change submission for this employee
      </Grid>
    </Grid>
  );
};
