import React from 'react';

import ReactDOM from 'react-dom';
import { createBrowserHistory } from 'history';

import 'typeface-roboto';
import 'typeface-open-sans';

import 'index.scss';
import { App } from 'views/App';
import { getDefaultStore } from 'domain/store/RootStoreModel';
import { AUTHORITY, CLIENT_ID, isDevelopment } from 'appSettings';
import { AuthenticationProvider } from 'auth/AuthenticationProvider';
import { IAuthenticationProvider } from 'auth/AuthenticationTypes';

//
// Make the FontAwesome CSS part of the bundle instead of
// having a <style> tag added at runtime for CSP.
//
// See <repo-root>/docs/csp/font-awesome.md
//
import { config as fontAwesomeConfig } from '@fortawesome/fontawesome-svg-core';
import '@fortawesome/fontawesome-svg-core/styles.css';

//
// Make the Chart.js CSS part of the bundle instead of having
// a <style> tag added at runtime for CSP.
//
// See <repo-root>/docs/csp/chart-js.md
//
import { Chart } from 'chart.js';
import 'chart.js/dist/Chart.min.css';

import { ApplicationInsights, SeverityLevel } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { setLivelinessChecking } from 'mobx-state-tree';

if (isDevelopment) {
  setLivelinessChecking('error');
}

//
// Disable runtime CSS injection for FontAwesome and Chart.js for CSP
//
fontAwesomeConfig.autoAddCss = false;
Chart.platform.disableCSSInjection = true;

const history = createBrowserHistory();

const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: process.env.REACT_APP_APPINSIGHTS_KEY,
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: history },
    },
  },
});
// Don't want to send logs to AppInsights if in local development and instrumentation key is not set
if (!isDevelopment || process.env.REACT_APP_APPINSIGHTS_KEY) {
  appInsights.loadAppInsights();
}

window.onunhandledrejection = function (error: PromiseRejectionEvent) {
  appInsights.trackException({
    exception: new Error(error.reason.message),
    properties: { stack: error.reason.stack, type: error.type },
    severityLevel: SeverityLevel.Error,
  });
  console.error(`Promise failed: ${error.reason}`);
};

export const errorLog = (message: string, e: Error): void => {
  appInsights.trackException({
    exception: e,
    severityLevel: SeverityLevel.Error,
  });
  console.error(`${message} ${e.message}`);
};

export const auth: IAuthenticationProvider = new AuthenticationProvider(
  {
    auth: {
      clientId: CLIENT_ID,
      authority: AUTHORITY,
      validateAuthority: true,
      redirectUri: window.location.origin,
      postLogoutRedirectUri: window.location.origin,
    },
  },
  {
    scopes: [CLIENT_ID],
  }
);

const store = getDefaultStore(auth);

if (isDevelopment) {
  (async () => {
    const { enable } = await import('devtools');
    enable(store);
  })().catch((reason) => {
    console.error('Failed to load dev tools', reason);
  });
}

ReactDOM.render(
  <App history={history} store={store} auth={auth} />,
  document.getElementById('root')
);
