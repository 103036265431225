import React from 'react';
import { Button, Link, TableCell, TableRow } from '@material-ui/core';
import { Avatar } from 'views/components/avatar/Avatar';
import { Link as RouterLink } from 'react-router-dom';
import { EMPLOYEE_JOURNEYS_ROUTE_PATH } from 'views/routes/Routes';
import { Skeleton } from '@material-ui/lab';
import styles from './EmployeeListRow.module.scss';
import { IEmployeeItem } from 'domain/store/admin/EmployeeListModel';
import { useStore } from 'hooks/useStore';
import { observer } from 'mobx-react-lite';
interface EmployeeListRowProps {
  employee: IEmployeeItem | null;
  canView: boolean;
  canChangeAccess: boolean;
  onAssignEmployee: (employee: IEmployeeItem) => void;
}

export const EmployeeListRow: React.FC<EmployeeListRowProps> = observer(
  ({ canView, canChangeAccess, onAssignEmployee, employee }) => {
    const {
      notifications: { addError },
    } = useStore();

    return (
      <>
        <TableRow className={styles.root}>
          <TableCell className={styles.nameCell}>
            <div className={styles.nameCellRoot}>
              <div className={styles.avatarContainer}>
                <Avatar
                  className={styles.avatar}
                  idpId={employee?.idpId}
                  givenName={employee?.givenName}
                  familyName={employee?.familyName}
                />
              </div>
              <div className={styles.nameLink}>
                {employee ? (
                  canView ? (
                    <Link
                      component={RouterLink}
                      to={`${EMPLOYEE_JOURNEYS_ROUTE_PATH}/${employee?.idpId}`}>
                      {employee.name}
                    </Link>
                  ) : (
                    <Link className={styles.forbidden}>{employee.name}</Link>
                  )
                ) : (
                  <Skeleton width={'50%'} />
                )}
              </div>
            </div>
          </TableCell>
          <TableCell className={styles.positionCell}>
            {employee ? <>{`${employee?.position}`}</> : <Skeleton width={'50%'} />}
          </TableCell>
          <TableCell className={styles.positionCell}>
            {employee ? (
              <>
                {employee.accessLevelDisplayName} {employee.noAccessRequested ? '(Blocked)' : null}
              </>
            ) : (
              <Skeleton width={'50%'} />
            )}
          </TableCell>
          <TableCell className={styles.positionCell}>
            {employee ? (
              canChangeAccess ? (
                <Button
                  color="primary"
                  onClick={() =>
                    !canChangeAccess
                      ? addError("You cannot send invitations for this person's role")
                      : onAssignEmployee(employee)
                  }>
                  Change Access
                </Button>
              ) : null
            ) : (
              <Skeleton width={'50%'} />
            )}
          </TableCell>
        </TableRow>
      </>
    );
  }
);
