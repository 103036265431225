import { AppBar, Tab, Tabs } from '@material-ui/core';
import { useBackToTopButton } from 'hooks/useBackToTopButton';
import { usePermissions } from 'hooks/usePermissions';
import React, { ReactNode } from 'react';
import { Link, Switch, Redirect, useLocation, Route } from 'react-router-dom';
import { BackToTopButton } from '../employeeJourney/components/employeeTimeline/components/backToTopButton/BackToTopButton';
import styles from './CatchUps.module.scss';
import { MyCatchUps } from './components/myCatchUps/MyCatchUps';
import { SquadCatchUps } from './components/squadCatchUps/SquadCatchUps';
import { useUser } from 'hooks/useUser';
import { SquadNotSelected } from 'views/components/squadNotSelected/SquadNotSelected';
import { observer } from 'mobx-react-lite';

interface TabItem {
  link: string;
  title: ReactNode;
  component: ReactNode;
  disabled: boolean;
}

export const CatchUps: React.FC = observer(() => {
  const { canViewCatchupGroupTab, catchUps } = usePermissions();
  const { selectedSquad } = useUser();
  const location = useLocation();

  const {
    showBackToTopButton,
    scrollToTopOfTimeline,
    scrollableContainerRef,
  } = useBackToTopButton();

  if (!canViewCatchupGroupTab) return null;

  const tabItems: TabItem[] = [
    {
      title: 'My Catch-Ups',
      component: <MyCatchUps />,
      link: 'my-catch-ups',
      disabled: !catchUps.canViewMyCatchUps,
    },
    {
      title: 'Squad Catch-Ups',
      component: selectedSquad ? <SquadCatchUps /> : <SquadNotSelected />,
      link: 'squad-catch-ups',
      disabled: !catchUps.canViewSquadCatchUps,
    },
  ].filter((t) => !t.disabled);

  const activeTab: number = tabItems.findIndex(
    (item) => location.pathname === `/catch-ups/${item.link}`
  );

  return (
    <div className={styles.root}>
      <AppBar position={'static'} color={'default'} className={styles.appBar}>
        <Tabs
          value={activeTab === -1 ? 0 : activeTab}
          centered={true}
          classes={{ indicator: styles.indicator }}>
          {tabItems.map((item, index) => (
            <Tab
              component={Link}
              to={`/catch-ups/${item.link}`}
              key={index}
              label={item.title}
              disabled={item.disabled}
            />
          ))}
        </Tabs>
      </AppBar>
      <div className={styles.tabBodyContainer} ref={scrollableContainerRef}>
        <Switch>
          {tabItems.map(
            (item) =>
              !item.disabled && (
                <Route path={`/catch-ups/${item.link}`} key={item.link}>
                  {item.component}
                </Route>
              )
          )}
          <Redirect to={'/catch-ups/my-catch-ups'} />
        </Switch>
        {showBackToTopButton && (
          <div className={styles.backToTopContainer}>
            <BackToTopButton scrollToTop={scrollToTopOfTimeline} edge={'end'} />
          </div>
        )}
      </div>
    </div>
  );
});
