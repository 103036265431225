import { errorLog } from 'index';
import { Instance, types } from 'mobx-state-tree';
import { api } from 'shared/api/interfaces';
import { flow, getEnv, LoadingStateModel } from '../util';

export const TalentAnalysisEmployeeModel = types.model('TalentAnalysisEmployee', {
  idpId: types.string,
  givenName: types.string,
  familyName: types.string,
  role: types.string,
  department: types.string,
  talentCode: types.string,
  statusType: types.number,
  roleChangePathwayTimeframeInMonths: types.number,
  isFlightRisk: types.optional(types.boolean, false),
  isRoleChangePathway: types.optional(types.boolean, false),
  isOnProbation: types.optional(types.boolean, false),
  isOnLongTermLeave: types.optional(types.boolean, false),
  longTermLeaveExpectedReturn: types.maybeNull(types.string),
});

export interface IEmployeeTalentModel extends Instance<typeof TalentAnalysisEmployeeModel> {}

export const TalentAnalysisToolModel = types
  .model('TalentAnalysisTool', {
    state: LoadingStateModel,
    employees: types.optional(types.array(TalentAnalysisEmployeeModel), []),
  })
  .actions((self) => ({
    loadEmployees: flow(function* (
      squadName: string
    ): Generator<Promise<IEmployeeTalentModel[]>, void, IEmployeeTalentModel[]> {
      const { ajax } = getEnv(self);
      self.state = 'loading';
      try {
        const result = yield ajax
          .get('TalentAnalysisTool', { searchParams: { squadName }, timeout: 600000 })
          .json<api.EmployeeDto[]>()
          .then((values) => values.map((item) => TalentAnalysisEmployeeModel.create(item)));
        self.employees.clear();
        result.forEach((i) => self.employees.push(i));
        self.state = 'done';
      } catch (e) {
        errorLog('Unable to get all employees for squad: ' + squadName, e);
        self.state = 'error';
      }
    }),
    updateTalentCode: flow(function* (
      employeeIdpId: string,
      talentCode: number
    ): Generator<Promise<void>, void, void> {
      const { ajax } = getEnv(self);

      yield ajax
        .post('employee/primaryflag', {
          json: {
            employeeIdpId,
            flagId: talentCode,
            reason: '',
          },
        })
        .json();
    }),
  }));

export interface ITalentAnalysisTool extends Instance<typeof TalentAnalysisToolModel> {}
