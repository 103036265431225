import { errorLog } from 'index';
import { Instance, types } from 'mobx-state-tree';
import { api } from 'shared/api/interfaces';
import { flow, getEnv, LoadingStateModel } from '../util';
import { makeTrendsUrl } from './TrendsModel';

export type TimeRange = 'last_fy_quarter' | 'current_fy_quarter' | 'last_fy' | 'current_fy';

const PersonModel = types.model('Person', {
  idpId: types.string,
  givenName: types.string,
  familyName: types.string,
  displayName: types.string,
  roleType: types.maybeNull(types.string),
  squadName: types.maybeNull(types.string),
});

export interface ISupportingOpportunityEmployee extends Instance<typeof PersonModel> {}

const SupportingOpportunitiesWithEmployeesItemModel = types.model(
  'SupportingOpportunitiesWithEmployees',
  {
    tag: types.string,
    people: types.optional(types.array(PersonModel), []),
  }
);

interface ISupportingOpportunitiesWithEmployeesItem
  extends Instance<typeof SupportingOpportunitiesWithEmployeesItemModel> {}

export const SupportingOpportunitiesWithEmployeesModel = types
  .model('SupportingOpportunitiesWithEmployees', {
    value: types.optional(types.array(SupportingOpportunitiesWithEmployeesItemModel), []),
    state: LoadingStateModel,
    selectedTimeRange: types.optional(
      types.enumeration<TimeRange>([
        'last_fy_quarter',
        'current_fy_quarter',
        'last_fy',
        'current_fy',
      ]),
      'current_fy_quarter'
    ),
  })
  .actions((self) => ({
    loadSupportingOpportunitiesWithEmployees: flow(function* (
      selectedSquad: string | null
    ): Generator<
      Promise<ISupportingOpportunitiesWithEmployeesItem[]>,
      void,
      ISupportingOpportunitiesWithEmployeesItem[]
    > {
      if (selectedSquad) {
        const { ajax } = getEnv(self);

        self.state = 'loading';
        try {
          self.value.clear();
          const value = yield ajax
            .get(makeTrendsUrl('SupportingOpportunitiesWithEmployees'), {
              searchParams: { squadName: selectedSquad, timeRange: self.selectedTimeRange },
            })
            .json<api.SupportingOpportunitiesWithEmployeesDto[]>()
            .then((values) =>
              values.map((item) => SupportingOpportunitiesWithEmployeesItemModel.create(item))
            );
          value.forEach((i) => self.value.push(i));
          self.state = 'done';
        } catch (e) {
          errorLog('Failed to load supporting opportunities with employees', e);
          self.state = 'error';
        }
      }
    }),
    selectTimeRange(timeRage: TimeRange) {
      self.selectedTimeRange = timeRage;
    },
  }))
  .views((self) => ({
    sortByName(employees: ISupportingOpportunityEmployee[]) {
      return employees.slice().sort((a, b) => (a.displayName > b.displayName ? 1 : -1));
    },
    employeesWithMatchingTag(tag: string) {
      return this.sortByName(self.value.find((e) => e.tag === tag)?.people || []);
    },
  }));
