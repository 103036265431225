import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTrends } from 'hooks/useTrends';
import { MenuItem, Typography } from '@material-ui/core';
import { D3BubbleChart } from 'views/components/charts/d3BubbleChart/D3BubbleChart';
import { Skeleton } from '@material-ui/lab';
import { CustomSelection } from 'views/components/customSelection/CustomSelection';
import { TimeRange } from 'domain/store/trends/SupportingOpportunitiesWithEmployeesModel';
import { useUser } from 'hooks/useUser';
import { ColourSet, generateColours } from '../../ColourGenerator';
import { ChartData } from '../../../../../../components/charts/d3BubbleChart/types';

const TIME_RANGES: { text: string; value: TimeRange }[] = [
  { text: 'Last FY Quarter', value: 'last_fy_quarter' },
  { text: 'Current FY Quarter', value: 'current_fy_quarter' },
  { text: 'Last FY', value: 'last_fy' },
  { text: 'Current FY', value: 'current_fy' },
];

export const SupportingOpportunitiesChart: React.FC = observer(() => {
  const { selectedSquad } = useUser();
  const {
    supportingOpportunitiesWithEmployees: {
      state,
      value,
      selectedTimeRange,
      loadSupportingOpportunitiesWithEmployees,
      selectTimeRange,
    },
  } = useTrends();

  const [colours, setColours] = useState([] as string[]);
  const [chartData, setChartData] = useState([] as ChartData[]);

  useEffect(() => {
    loadSupportingOpportunitiesWithEmployees(selectedSquad).then(() =>
      setColours(generateColours(value.length, ColourSet.TealGreen))
    );
  }, [loadSupportingOpportunitiesWithEmployees, selectedSquad, selectedTimeRange, value]);

  useEffect(() => {
    setChartData(
      value.map((item, index) => ({
        name: item.tag,
        value: item.people.length,
        colour: colours[index],
      }))
    );
  }, [colours, value]);

  return (
    <>
      {state === 'done' ? (
        <Typography component={'span'} variant={'body1'}>
          <D3BubbleChart data={chartData} chartType="supportingOpportunities" size={[450, 450]} />
        </Typography>
      ) : (
        <Skeleton variant={'rect'} height={450} />
      )}
      <div>
        <CustomSelection label={'Time Range'} value={selectedTimeRange} onChange={selectTimeRange}>
          {TIME_RANGES.map((t) => (
            <MenuItem value={t.value} key={t.text}>
              {t.text}
            </MenuItem>
          ))}
        </CustomSelection>
      </div>
    </>
  );
});
