import React from 'react';
import { IconButton } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleUp } from '@fortawesome/free-regular-svg-icons';
import styles from './BackToTop.module.scss';

export interface BackToTopButtonProps {
  scrollToTop: () => void;
  edge?: 'start' | 'end';
}

export const BackToTopButton: React.FC<BackToTopButtonProps> = ({ scrollToTop, edge }) => {
  return (
    <IconButton
      onClick={scrollToTop}
      className={styles.root}
      classes={{ root: styles.materialOverride }}
      title="Back to top"
      tabIndex={0}
      role="button"
      edge={edge}>
      <FontAwesomeIcon icon={faArrowAltCircleUp} size={'2x'} />
    </IconButton>
  );
};
