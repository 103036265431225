import React, { useEffect, useRef, useState } from 'react';
import { select } from 'd3-selection';
import cn from 'classnames';
import { ChartData, GroupDatum } from './types';
import { createChart } from './d3chart';
import styles from './D3BubbleChart.module.scss';
import { useEmployeeListPopper } from 'hooks/useEmployeeListPopper';
import { EmployeeListPopper } from 'views/routes/squad/components/squadTrends/components/employeeListPopper/EmployeeListPopper';
import { useTrends } from 'hooks/useTrends';
import { D3BubbleChartTooltip } from './D3BubbleChartTooltip';

interface ID3BubbleChartProps {
  className?: string;
  data: ChartData[];
  size: [number, number];
  chartType: 'talentCodes' | 'supportingOpportunities';
  shouldEmployeeListFlip?: boolean;
}

export const D3BubbleChart: React.FC<ID3BubbleChartProps> = ({
  data,
  className,
  size,
  shouldEmployeeListFlip,
  chartType,
}) => {
  const chartRef = useRef(null);
  const [selectedBubbleDatum, setSelectedBubbleDatum] = React.useState<GroupDatum | null>(null);
  const [hoveredBubbleRef, setHoveredBubbleRef] = useState<{
    element: SVGElement;
    datum: GroupDatum;
  } | null>(null);

  const { trendsEmployees, supportingOpportunitiesWithEmployees } = useTrends();

  useEffect(() => {
    if (chartRef.current) {
      const ref = (chartRef.current as unknown) as HTMLElement;
      const chart = createChart(
        styles,
        select(ref),
        data,
        size,
        (d, e) => {
          e.onclick = () => setSelectedBubbleDatum(d);
          setHoveredBubbleRef({ element: e, datum: d });
        },
        () => {
          setHoveredBubbleRef(null);
        }
      );
      return () => {
        chart.remove();
      };
    }
    return () => {};
  }, [chartRef, data, size]);

  const employeeList = useEmployeeListPopper();

  const handleClick = () => {
    employeeList.setAnchorEl(hoveredBubbleRef?.element || null);

    // Without this, selecting subsequent bars will fire the ClickAwayListener's handleClose() *after* this assigns anchorEl, thereby dismissing the new popper
    if (employeeList.anchorEl) {
      employeeList.setIgnoreNextClose(true);
    }
  };

  return !data.length ? (
    <div className={styles.noData}>
      <span>No data available</span>
    </div>
  ) : (
    <>
      {hoveredBubbleRef && (
        <D3BubbleChartTooltip
          anchorEl={hoveredBubbleRef.element}
          title={hoveredBubbleRef.datum.data.name}
          value={hoveredBubbleRef.datum.data.value}
        />
      )}
      <div ref={chartRef} className={cn(styles.d3ChartBase, className)} onClick={handleClick} />
      {selectedBubbleDatum && (
        <EmployeeListPopper
          anchorEl={employeeList.anchorEl}
          handleClose={employeeList.handleClose}
          color={selectedBubbleDatum.data.colour}
          title={selectedBubbleDatum.data.name}
          employees={
            chartType === 'talentCodes'
              ? trendsEmployees.employeesWithMatchingTalentCode(selectedBubbleDatum.data.name)
              : supportingOpportunitiesWithEmployees.employeesWithMatchingTag(
                  selectedBubbleDatum.data.name
                )
          }
          flip={shouldEmployeeListFlip}
        />
      )}
    </>
  );
};
