import { easeQuad, format, hierarchy, HierarchyNode, nest, pack, Selection } from 'd3';
import cn from 'classnames';
import {
  ChartData,
  ChartEventCallback,
  GroupDatum,
  IdProvider,
  PackData,
  PackDatum,
} from './types';
import { color } from 'views/themes';

const d3Format = format(',d');

export const createChart = (
  styles: { [key: string]: string },
  parent: Selection<HTMLElement, undefined, null, undefined>,
  data: ChartData[],
  size: [number, number],
  onOverBubble: ChartEventCallback,
  onOutBubble: ChartEventCallback
): Selection<SVGSVGElement, undefined, null, undefined> => {
  const baseData: PackData = {
    value: 0,
    name: 'root',
    colour: '',
    children: data.map((d) => ({ ...d, children: [] })),
  };

  const dataHierarchy: HierarchyNode<PackData> = hierarchy(baseData)
    .sum((d) => d.value)
    .sort((a, b) => (b.value ?? 0) - (a.value ?? 0));

  const packed = pack<PackData>()
    .size(size)
    .padding(30)(dataHierarchy)
    .descendants()
    .filter((d) => !d.children);
  const nestedData = nest<PackDatum>()
    .key((d) => `${d.height}`)
    .entries(packed);

  const svg = parent
    .append('svg')
    .attr('width', size[0])
    .attr('height', size[1])
    .attr('viewBox', `${[0, 0, ...size]}`)
    .attr('class', cn(styles.bubbleChart))
    .attr('text-anchor', 'middle');

  const groups = svg
    .selectAll<SVGGElement, PackDatum>('g')
    .data(nestedData)
    .join('g')
    .attr('class', cn(styles.bubbleGroups))
    .selectAll<SVGGElement, PackDatum>('g')
    .data<GroupDatum>((d) => d.values)
    .join('g')
    .attr('class', cn(styles.bubbleGroup))
    .attr('transform', `translate(${size[0] / 2},${size[1] / 2})`)
    .on('mouseover click touchend', (datum, index, nodes) => {
      onOverBubble && onOverBubble(datum, nodes[index]);
    })
    .on('mouseout', (datum, index, nodes) => {
      onOutBubble && onOutBubble(datum, nodes[index]);
    });

  groups
    .transition('animate-groups')
    .duration(500)
    .ease(easeQuad)
    .attr('transform', (d) => `translate(${d.x + 1},${d.y + 1})`);

  groups.append('title').text((d) => `${d.data.name}\n${d3Format(d.data.value)}`);

  groups
    .append('circle')
    .attr('id', (d) => (d.leafUid = IdProvider.uid('leaf')).id)
    // .attr('fill', () => 'hsl(' + Math.random() * 360 + ',65%, 50%)')
    .attr('fill', (d) => d.data.colour)
    .attr('class', cn(styles.bubbleCircle))
    .transition('animate-circles')
    .duration(500)
    .attr('r', (d) => (d.r ? d.r + 11 : 0));

  groups
    .append('clipPath')
    .attr('id', (d) => (d.clipUid = IdProvider.uid('clip')).id)
    .append('use')
    .attr('xlink:href', (d) => d.leafUid.href);

  groups
    .filter((d) => d.r + 10 > 30)
    .append('text')
    .attr('clip-path', (d) => d.clipUid.uri)
    .attr('class', cn(styles.bubbleText))
    .attr('fill', (d) => color.white)
    .selectAll('tspan')
    .data<{ text: string; size: number }>((d) =>
      d.data.name.split(' ').map((v) => ({ text: `${v}`, size: d.r }))
    )
    .join('tspan')
    .attr('x', 0)
    .attr('y', (d, i, nodes) => `${i - nodes.length / 2 + 0.8}em`)
    .attr('class', (d) => cn({ [styles.fontSmall]: d.size < 50 }, styles.fontLarge))
    .text((d) => d.text);

  return svg;
};
