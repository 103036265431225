import React, { useState } from 'react';
import { Select, MenuItem } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronCircleDown } from '@fortawesome/free-solid-svg-icons';
import styles from 'views/components/primaryFlagDropdown/PrimaryFlagDropdown.module.scss';
import { siteDefault } from 'views/themes';

export interface TimeframeDropdownProps {
  value: number;
  onSelectChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
  timeframes: number[];
  isUpdatingTimeframe: boolean;
}

export const TimeframeDropdown: React.FunctionComponent<TimeframeDropdownProps> = (props) => {
  const [value, setValue] = useState<number>(props.value);
  const onSelectChange = (
    event: React.ChangeEvent<{
      value: unknown;
    }>
  ) => {
    !props.isUpdatingTimeframe && setValue(event.target.value as number);
    props.onSelectChange(event);
  };
  return (
    <Select
      labelId="timeframe-dropdown-label"
      id="timeframe-dropdown-label"
      value={value}
      onChange={(event) => onSelectChange(event)}
      style={siteDefault}
      IconComponent={(values) => {
        const propsWithDropdownIcon = {
          ...values,
          className: `${values.className} ${styles.dropdownIcon} `,
        };
        return (
          <div className={styles.dropdownIconContainer}>
            <FontAwesomeIcon {...propsWithDropdownIcon} icon={faChevronCircleDown} />
          </div>
        );
      }}
      variant="outlined"
      className={styles.selectInput}>
      {props.timeframes.map((key) => {
        return (
          <MenuItem key={key} value={key}>
            {key > 12 ? '12+' : key} months
          </MenuItem>
        );
      })}
    </Select>
  );
};
