import { Checkbox, FormControlLabel } from '@material-ui/core';
import { SwitchBaseProps } from '@material-ui/core/internal/SwitchBase';
import React from 'react';
import styles from '../../RoleChange.module.scss';
import cn from 'classnames';

interface IRoleChangeCheckboxProps {
  onChange?: (value: boolean) => void;
  disabled: boolean;
  selectedValue: boolean;
  label: string;
  inputProps?: React.InputHTMLAttributes<HTMLInputElement> | undefined;
  dataTestId?: string;
}

export const RoleChangeCheckbox: React.FC<IRoleChangeCheckboxProps> = (props) => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={props.selectedValue}
          disabled={props.disabled}
          onChange={(event) =>
            props.onChange && props.onChange((event.target as HTMLInputElement).checked)
          }
          inputProps={
            props.dataTestId
              ? ({
                  'data-testid': props.dataTestId,
                } as SwitchBaseProps['inputProps'] & { 'data-testid': string })
              : undefined
          }
        />
      }
      label={props.label}
      className={cn(styles.formInputItem, styles.reverse)}
    />
  );
};
