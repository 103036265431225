import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button } from '@material-ui/core';
import { useCatchUpGroups } from 'hooks/useCatchUpGroups';
import { usePermissions } from 'hooks/usePermissions';
import { useStore } from 'hooks/useStore';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { AddGroupDialog } from './components/AddGroupDialog/AddGroupDialog';
import { GroupCard } from './components/GroupCard/GroupCard';
import styles from './MyCatchUps.module.scss';

export interface MyCatchUpsProps {}

export const MyCatchUps: React.FC<MyCatchUpsProps> = observer(() => {
  const { loadGroups, createGroup, groups, state } = useCatchUpGroups();
  const {
    catchUps: { canViewMyCatchUps },
  } = usePermissions();
  const store = useStore();

  if (!canViewMyCatchUps) return null;

  const [isAddGroupDialogOpen, showAddGroupDialog] = useState<boolean>(false);

  const handleGroupDialogOkClick = (groupName: string) => {
    if (groupName) {
      createGroup(groupName)
        .then(() => showAddGroupDialog(false))
        .catch((e) => {
          if (e?.response?.status === 409) {
            store.notifications.addError(
              'Conflict occurred saving changes, please refresh the page, and try again after.'
            );
          } else if (e?.response?.status === 403) {
            store.notifications.addError('Not authorised to save changes.');
          } else {
            store.notifications.addError('Unable to create group, please try again.');
          }
        });
    }
  };

  const openDialog = (e: React.MouseEvent) => {
    showAddGroupDialog(true);
    document.activeElement && (document.activeElement as HTMLElement).blur();
  };

  useEffect(() => {
    loadGroups();
  }, [loadGroups]);

  return (
    <Box maxWidth="1274px" margin="auto">
      <Box display="flex" justifyContent="flex-end" my={2}>
        <Button
          className={styles.button}
          variant="contained"
          color="primary"
          size="large"
          startIcon={<FontAwesomeIcon icon={faPlusCircle} />}
          onClick={(e) => openDialog(e)}>
          Create New Group
        </Button>
        <AddGroupDialog
          isOpen={isAddGroupDialogOpen}
          onOkClick={handleGroupDialogOkClick}
          onCancelClick={() => showAddGroupDialog(false)}
        />
      </Box>
      {state === 'done' ? (
        groups.map((group, index) => <GroupCard group={group} key={index} />)
      ) : (
        <GroupCard group={null} />
      )}
    </Box>
  );
});
