import { ResponsePromise } from 'ky';
import { Instance, types } from 'mobx-state-tree';
import { createNote, INote, INoteRequest, IPaginatedNotes, NoteModel } from './NoteModel';
import { flow, getEnv } from './util';
import { ReviewModel } from './ReviewModel';
import { PermissionsModel } from './PermissionsModel';
import { api } from 'shared/api/interfaces';

export const NotesModelMap = types.map(NoteModel);

export interface ISquadUser extends api.PersonDto {}

const selectedSquadStorageKey = 'YOUtopia.User.SquadName';

export const UserModel = types
  .model('User', {
    idpId: types.maybe(types.string),
    familyName: types.maybe(types.string),
    givenName: types.maybe(types.string),
    squadName: types.maybe(types.string),
    permissions: types.optional(PermissionsModel, {}),
    draftNotes: NotesModelMap,
    submittedNotes: NotesModelMap,
    review: types.optional(ReviewModel, { numberOfPendingNotes: 0 }),
    selectedSquad: types.optional(types.string, ''),
    accessLevel: types.maybe(types.string),
    accessLevelDisplayName: types.maybe(types.string),
    assignedSquads: types.optional(types.array(types.string), []),
  })
  .actions((self) => {
    const loadEditNote = function* (noteId: number): Generator<Promise<INote>, INote, INote> {
      const { ajax } = getEnv(self);
      return yield ajax
        .get('Notes/noteId', { searchParams: { noteId } })
        .json<api.NoteDto>()
        .then((note) => createNote(note));
    };

    return {
      loadEditNote: flow(loadEditNote),
    };
  })
  .actions((self) => ({
    loadSquadNotes: flow(function* (
      squad: string,
      page: number,
      size: number
    ): Generator<Promise<IPaginatedNotes>, IPaginatedNotes, IPaginatedNotes> {
      const { ajax } = getEnv(self);
      return yield ajax
        .get('Notes/squad', { searchParams: { squadName: squad, page, size } })
        .json<api.PaginatedNoteDto>()
        .then((result) => {
          return {
            notes: result.notes.map((note) => createNote(note)),
            totalCount: result.totalCount,
          };
        });
    }),
  }))
  .actions((self) => {
    const saveNote = function* (
      formData: INoteRequest,
      noteId?: number
    ): Generator<Promise<Response>, Response, Response> {
      const { ajax } = getEnv(self);
      return yield ajax('Notes', {
        json: {
          ...formData,
          noteId: noteId || undefined /* just in case NaN is provided here */,
        },
        method: noteId ? 'put' : 'post',
      }).json();
    };

    const deleteNote = function* (noteId: number): Generator<ResponsePromise, void, Response> {
      const { ajax } = getEnv(self);
      yield ajax.delete('Notes', { searchParams: { noteId } });
    };

    return {
      saveNote: flow(saveNote),
      deleteNote: flow(deleteNote),
    };
  })
  .actions((self) => {
    const loadDraftNotes = function* (): Generator<Promise<INote[]>, void, INote[]> {
      const { ajax } = getEnv(self);
      const response = yield ajax
        .get('Notes/drafts/me')
        .json<api.NoteDto[]>()
        .then((notes) => notes.map((note) => createNote(note)));
      self.draftNotes.clear();
      response.forEach((note: INote) => self.draftNotes.put(note));
    };

    const loadSubmittedNotes = function* (): Generator<Promise<INote[]>, void, INote[]> {
      const { ajax } = getEnv(self);
      const response = yield ajax
        .get('Notes/submitted_notes/me')
        .json<api.NoteDto[]>()
        .then((notes) => notes.map((note) => createNote(note)));
      self.submittedNotes.clear();
      response.forEach((note: INote) => self.submittedNotes.put(note));
    };

    return {
      loadDraftNotes: flow(loadDraftNotes),
      loadSubmittedNotes: flow(loadSubmittedNotes),
    };
  })
  .actions((self) => ({
    getEmployeesForSquad: flow(function* (
      squadName: string
    ): Generator<Promise<ISquadUser[]>, ISquadUser[], ISquadUser[]> {
      const { ajax } = getEnv(self);
      return yield ajax
        .get('Employee/List', { searchParams: { squadName } })
        .json<api.PersonDto[]>();
    }),
    getAllSquads: flow(function* (): Generator<Promise<string[]>, string[], string[]> {
      const { ajax } = getEnv(self);
      return yield ajax.get('Squads').json<string[]>();
    }),
  }))
  .actions((self) => ({
    setSquad: (squad: string) => {
      if (!self.permissions.canViewAllSquads && self.assignedSquads.length <= 0) return;

      self.selectedSquad = squad;
      sessionStorage.setItem(selectedSquadStorageKey, squad);
    },
  }))
  .views((self) => {
    const sortNotes = (a: INote, b: INote): number => {
      const aDate = a.modifiedDateMoment ?? a.createdDateMoment;
      const bDate = b.modifiedDateMoment ?? b.createdDateMoment;
      return bDate.diff(aDate);
    };

    return {
      get drafts() {
        return Array.from(self.draftNotes?.values() || []).sort(sortNotes);
      },
      get previous() {
        return Array.from(self.submittedNotes?.values() || []).sort(sortNotes);
      },
    };
  });

export interface IUser extends Instance<typeof UserModel> {}

export interface IPermissions extends Instance<typeof PermissionsModel> {}

export const createUser = (user: api.LoggedInUserDto): IUser => {
  const getUserSquad = () => {
    const selectedSquad = sessionStorage.getItem(selectedSquadStorageKey);
    if (selectedSquad) {
      if (user.permissions.canViewAllSquads) {
        return selectedSquad;
      } else if (user.assignedSquads && user.assignedSquads.length > 0) {
        if (user.assignedSquads.includes(selectedSquad)) return selectedSquad;
      }
    }
    return user.squadName;
  };

  return UserModel.create({
    idpId: user.idpId,
    familyName: user.familyName,
    givenName: user.givenName,
    squadName: user.squadName,
    selectedSquad: getUserSquad(),
    permissions: user.permissions,
    draftNotes: {},
    submittedNotes: {},
    accessLevel: user.accessLevel,
    accessLevelDisplayName: user.accessLevelDisplayName,
    assignedSquads: user.assignedSquads,
  });
};
