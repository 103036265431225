import { List, ListItem, ListItemText } from '@material-ui/core';
import { useNavigate } from 'hooks/useNavigate';
import { useStore } from 'hooks/useStore';
import { useUser } from 'hooks/useUser';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { LoadingIndicator } from 'views/components/loadingIndicator/LoadingIndicator';
import { CREATE_NOTE_ROUTE_PATH, EDIT_NOTE_ROUTE_PATH } from 'views/routes/Routes';
import { NoteCard } from './components/NoteCard';

interface IDraftListProps {
  selectedNoteId: number;
  resetForm: () => void;
}

export const DraftList = observer((props: IDraftListProps) => {
  const { drafts, deleteNote, loadDraftNotes } = useUser();
  const navigate = useNavigate();
  const { notifications } = useStore();
  const [isLoading, setLoading] = useState<boolean>(true);
  const history = useHistory();

  useEffect(() => {
    let active = true;
    loadDraftNotes()
      .then(() => {
        active && setLoading(false);
      })
      .catch(() => {
        active && notifications.addError('Unable to load your draft notes');
        active && setLoading(false);
      });
    return () => {
      active = false;
    };
  }, [loadDraftNotes, notifications]);

  if (drafts?.length && !isLoading) {
    const handleNoteSelected = (noteId: number) => {
      if (props.selectedNoteId === noteId) return;

      navigate(`${EDIT_NOTE_ROUTE_PATH}/${noteId}`);
    };

    const onAgreeClickDeleteNote = (noteId: number) => {
      deleteNote(noteId).then(() => {
        loadDraftNotes();
        props.resetForm();
        history.replace(CREATE_NOTE_ROUTE_PATH);
      });
    };

    return (
      <>
        <List>
          {drafts.map((note, index, array) => (
            <NoteCard
              key={note.noteId}
              note={note}
              selected={props.selectedNoteId === note.noteId}
              onClick={() => handleNoteSelected(note.noteId)}
              onDelete={onAgreeClickDeleteNote}
              divider={index < array.length - 1}
            />
          ))}
        </List>
      </>
    );
  }
  return (
    <>
      {isLoading ? (
        <LoadingIndicator text={'Loading your drafts'} />
      ) : (
        <List>
          <ListItem alignItems={'flex-start'}>
            <ListItemText
              primary={<span data-testid="list-note-no-drafts">No drafts to display</span>}
            />
          </ListItem>
        </List>
      )}
    </>
  );
});
