import React, { useState } from 'react';
import { Checkbox } from '@material-ui/core';
import { LoadingIndicator } from 'views/components/loadingIndicator/LoadingIndicator';
import { AlertDialog } from 'views/components/alertDialog/AlertDialog';

export interface AlertCheckboxProps {
  isLoading: boolean;
  checked: boolean;
  onAgreeClick?: () => void;
  dialogTitle?: string;
  dialogText: string;
}

export const AlertCheckbox: React.FunctionComponent<AlertCheckboxProps> = (props) => {
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  const onAgree = async () => {
    resetDialogStates();
    props.onAgreeClick && props.onAgreeClick();
  };

  const onDisagree = () => {
    resetDialogStates();
  };

  const onCheckChangeHandler = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsDialogOpen(true);
  };

  const resetDialogStates = () => {
    setIsDialogOpen(false);
  };
  return (
    <>
      <AlertDialog
        isOpen={isDialogOpen}
        title={props.dialogTitle || 'Dialog'}
        text={props.dialogText}
        onAgreeClick={onAgree}
        onDisagreeClick={onDisagree}
      />
      {props.isLoading ? (
        <LoadingIndicator size={'1.5em'} />
      ) : (
        <Checkbox
          onChange={onCheckChangeHandler}
          checked={props.checked}
          data-testid="alert-checkbox"
        />
      )}
    </>
  );
};
