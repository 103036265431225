import { FormControl, MenuItem, Select } from '@material-ui/core';
import React from 'react';
import styles from '../../../../RoleChange.module.scss';
import { api } from 'shared/api/interfaces';
import { ratings } from 'domain/store/employeeJourney/RoleChangeModel';
import { SwitchBaseProps } from '@material-ui/core/internal/SwitchBase';

interface IRatingProps {
  coreValueId: string;
  skill: string;
  currentRating: number;
  index: number;
  handleSkillChange: (item: api.SkillRatingDto) => void;
  disabled: boolean;
  dataTestId: string;
  showValidationError: boolean;
}

export const SkillRating: React.FC<IRatingProps> = (props) => {
  return (
    <FormControl component="fieldset" className={styles.formInputItem} disabled={props.disabled}>
      <label>{props.skill}</label>
      <Select
        className={styles.select}
        value={props.currentRating.toString()}
        onChange={(e) =>
          props.handleSkillChange({
            coreValueId: props.coreValueId,
            rating: parseInt((e.target as HTMLInputElement).value) as api.Rating,
          })
        }
        inputProps={
          {
            'data-testid': 'skill-rating-dropdown-input',
          } as SwitchBaseProps['inputProps'] & { 'data-testid': string }
        }
        data-testid="skill-rating-dropdown"
        disableUnderline>
        {ratings.map((r, i) => (
          <MenuItem key={i} value={r.value}>
            {r.description}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
