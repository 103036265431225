import { Button, Typography, Box } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { api } from 'shared/api/interfaces';
import { RoleChangeStatusChip } from 'views/components/roleChangeStatusChip/RoleChangeStatusChip';
import { NoRoleChangeSubmissions } from './components/noRoleChangeSubmission/NoRoleChangeSubmission';
import styles from './RoleChange.module.scss';
import { useRoleChange } from 'hooks/useRoleChange';
import { useEmployeeJourney } from 'hooks/useEmployeeJourney';
import { observer } from 'mobx-react-lite';
import { usePermissions } from 'hooks/usePermissions';
import AddBoxIcon from '@material-ui/icons/AddBox';
import { RoleChange } from './RoleChange';
import { useStore } from 'hooks/useStore';
import { LoadingIndicator } from 'views/components/loadingIndicator/LoadingIndicator';
import { getStatus, RoleChangeStatus } from 'domain/store/employeeJourney/RoleChangeModel';
import { Positions } from '../../../../../shared/constants/Positions';

export const RoleChangesList: React.FC = observer(() => {
  const { employee, positions } = useEmployeeJourney();
  const { getRoleChangeCycle, getAllRoleChangeSubmissions } = useRoleChange();
  const {
    canEdit,
    employees: { roleChanges },
  } = usePermissions();
  const {
    notifications: { addError },
  } = useStore();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [newRoleChangeHasBeenStarted, setNewRoleChangeHasBeenStarted] = useState(false);
  const [allRoleChanges, setAllRoleChanges] = useState<api.RoleChangeDto[] | null>([]);
  const [selectedRoleChange, setSelectedRoleChange] = useState<api.RoleChangeDto | undefined>(
    undefined
  );
  const previousRole = (targetRole: string) => {
    const index = positions.indexOf(targetRole);
    return positions[index - 1];
  };

  const handleRoleChangeHasStarted = (value: boolean) => {
    setNewRoleChangeHasBeenStarted(value);
  };

  const canEditRoleChange = canEdit(roleChanges);

  useEffect(() => {
    setAllRoleChanges([]);
    setIsLoading(true);
    employee &&
      getAllRoleChangeSubmissions(employee.idpId)
        .then((result) => {
          setAllRoleChanges(result);
        })
        .catch((err) => {
          if (err?.response?.status === 403) {
            addError('Not authorised to access role changes.');
          } else {
            addError(`Failed to get role changes for ${employee.givenName} ${employee.familyName}`);
          }
        })
        .finally(() => setIsLoading(false));
  }, [
    getAllRoleChangeSubmissions,
    employee,
    setAllRoleChanges,
    addError,
    newRoleChangeHasBeenStarted,
  ]);

  return (
    <div className={styles.root}>
      {!newRoleChangeHasBeenStarted ? (
        <div className={styles.roleChangeList}>
          <div className={styles.roleChangeAction}>
            <h2>Role Change</h2>
            {canEditRoleChange && employee?.position !== Positions.PrincipalConsultant && (
              <Button
                data-testid="new-role-change-button"
                variant="contained"
                color="primary"
                startIcon={<AddBoxIcon />}
                onClick={() => handleRoleChangeHasStarted(true)}>
                Create New
              </Button>
            )}
            {employee?.position === Positions.PrincipalConsultant && (
              <label>
                {`'${Positions.PrincipalConsultant}' is the final role within the career framework.
                Further role changes for Principal Consultants should be managed outside of
                YOUtopia.`}
              </label>
            )}
          </div>
          {!isLoading ? (
            allRoleChanges && allRoleChanges.length > 0 ? (
              allRoleChanges.map((r, i) => (
                <div className={styles.previousRoleChangeContainer} key={i}>
                  <div className={styles.roleDetails}>
                    <Typography color={'textPrimary'} variant={'subtitle1'} component={'span'}>
                      <Box fontWeight="fontWeightMedium" component={'span'}>
                        {previousRole(r.targetRole)} to {r.targetRole} &middot;
                      </Box>
                    </Typography>
                    <Typography color={'textPrimary'} variant={'subtitle1'} component={'span'}>
                      <Box fontWeight="fontWeightLight" component={'span'}>
                        {getRoleChangeCycle(r)}
                      </Box>
                    </Typography>
                  </div>
                  <div className={styles.status}>
                    <RoleChangeStatusChip
                      status={getStatus(r.isDraft, r.isApproved, r.isActive) as RoleChangeStatus}
                    />
                    <Button
                      color="primary"
                      data-testid="view-role-change-button"
                      onClick={() => {
                        setSelectedRoleChange(r);
                        handleRoleChangeHasStarted(true);
                      }}>
                      View
                    </Button>
                  </div>
                </div>
              ))
            ) : (
              <NoRoleChangeSubmissions />
            )
          ) : (
            <LoadingIndicator />
          )}
        </div>
      ) : (
        <RoleChange
          roleChange={selectedRoleChange}
          setSelectedRoleChange={setSelectedRoleChange}
          setRoleChangeHasStarted={handleRoleChangeHasStarted}
          roleChangeHasStarted={newRoleChangeHasBeenStarted}
        />
      )}
    </div>
  );
});
