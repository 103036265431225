import { auth, errorLog } from 'index';
import { getDefaultAjaxInstance } from 'domain/store/util';
import { useState, useCallback } from 'react';
import { IUpdateRoleChangePathwayDto } from 'domain/store/dataTranferObjects/IRoleChangePathwayDto';
import { api } from 'shared/api/interfaces';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useRoleChangePathwayService = (initTimeframeValue: number) => {
  const [hasRoleChangePathway, setHasRoleChangePathway] = useState(initTimeframeValue > 0);
  const [timeframe, setTimeframe] = useState(initTimeframeValue);
  const [isLoading, setIsLoading] = useState(false);

  const ajax = getDefaultAjaxInstance(auth);
  const updateRoleChangePathway = useCallback(
    async (updateDto: IUpdateRoleChangePathwayDto) => {
      setIsLoading(true);
      try {
        const result = await ajax
          .post('Employee/rolechangepathway', {
            json: {
              employeeIdpId: updateDto.employeeIdpId,
              flagValue: updateDto.timeframe !== 0 ? true : false,
              timeframe: updateDto.timeframe,
            } as api.UpdateRoleChangePathwayRequest,
          })
          .json<api.RoleChangePathwayDto>();

        setHasRoleChangePathway(result.hasRoleChangePathway);
        setTimeframe(result.timeframe);
        return result;
      } catch (error) {
        errorLog('Failed to update employee role progression.', error);
        throw error;
      } finally {
        setIsLoading(false);
      }
    },
    [ajax]
  );

  return { hasRoleChangePathway, timeframe, isLoading, updateRoleChangePathway };
};
